import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { EmptyPageBadgeComponent } from "./empty-page-badge.component";

@NgModule({
    imports: [CommonModule, MatIconModule],
    exports: [EmptyPageBadgeComponent],
    declarations: [EmptyPageBadgeComponent]
})
export class EmptyPageBadgeModule {}
