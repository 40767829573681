import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

import { SubSink } from "@dffedb/rxjs";
import { DriftsstatusService } from "@e-forsyning/common/driftsstatus";
import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

@Component({
    selector: "driftsstatus-knap", // Fordi vi også bruger den som almindelig (ikke webcomponent) komponent.
    templateUrl: "./dffedb-driftsstatus-knap.component.html",
    styleUrls: ["./dffedb-driftsstatus-knap.component.scss"],
    animations: [trigger("fadeIn", [transition(":enter", [style({ opacity: 0 }), animate(600)])])]
})
export class DffedbDriftsstatusKnapComponent implements OnInit, OnDestroy {
    private readonly iconActive = "driftsstatus-active";
    private subs = new SubSink();
    public vm: DriftsstatusViewModel;

    @HostBinding("@fadeIn")
    public fadeInAnimation = true;

    @HostBinding("class")
    @Input()
    public class: string;

    @Input()
    public titel = "Driftsstatus";

    @Input()
    public driftsstatusforstyrrelsertekst = "Du kan i øjeblikket opleve driftsforstyrrelser";

    @Input()
    public driftsstatusingenforstyrrelsertekst = "Der er ingen kendte driftsforstyrrelser";

    @Input()
    public visIkon = true;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly indstillingerObserver: UmbracoIndstillingerObserver,
        private readonly driftsstatusService: DriftsstatusService
    ) {
        // WebComponents fungerer ikke så godt med auto-change detection, så vi kobler det helt fra.
        cd.detach();
    }

    public ngOnInit(): void {
        const harForsyningWeb$ = this.indstillingerObserver.valueChanges.pipe(map((i) => !!i.forsyningWeb.nodeId));
        const erDerDriftsforstyrrelser$ = this.driftsstatusService.erDerAktuelleDriftsforstyrrelser().pipe(startWith(false));
        const driftsstatusUrl$ = this.hentDriftsstatusUrl().pipe(startWith(""));
        combineLatest([harForsyningWeb$, erDerDriftsforstyrrelser$, driftsstatusUrl$]).subscribe(
            ([harForsyningWeb, erDerDriftsforstyrrelser, driftsstatusUrl]) => {
                this.class += erDerDriftsforstyrrelser ? " driftsstatus-alert" : "";
                this.vm = this.mapToMenu(harForsyningWeb, erDerDriftsforstyrrelser, driftsstatusUrl);
                this.cd.detectChanges();
            }
        );
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private hentDriftsstatusUrl(): Observable<string> {
        return this.indstillingerObserver.valueChanges.pipe(map((i) => (i.forsyningWeb && i.forsyningWeb.driftsstatusUrl) || ""));
    }

    private mapToMenu(harForsyningWeb: boolean, erDerDriftsforstyrrelser: boolean, driftsstatusUrl: string): DriftsstatusViewModel {
        return {
            visible: harForsyningWeb,
            active: erDerDriftsforstyrrelser,
            href: driftsstatusUrl,
            svgIcon: this.visIkon && erDerDriftsforstyrrelser ? this.iconActive : ""
        };
    }
}

interface DriftsstatusViewModel {
    visible: boolean;
    active: boolean;
    svgIcon: string;
    href: string;
}
