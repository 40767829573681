import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import MD5 from "md5-es";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { AuthenticationResult } from "./authentication-result.model";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
    private authTimer: number;
    constructor(private readonly http: HttpClient) {}

    public authenticate(brugernavn: string, hashedKodeord: string, forsyningId: string): Observable<AuthenticationResult> {
        return this.hentAppServerUrl(forsyningId).pipe(
            switchMap((appServerUrl) =>
                this.getSecurityToken(appServerUrl, brugernavn).pipe(
                    map((token) => this.createCryptId(hashedKodeord, token)),
                    map((cryptId) => ({ cryptId, url: this.getAuthenticationUrl(brugernavn, cryptId) })),
                    switchMap((result: { cryptId: string; url: string }) =>
                        this.httpAuthenticate(appServerUrl + result.url, result.cryptId)
                    ),
                    map((result: { success: boolean; cryptId: string }) => {
                        this.resetAuthenticationTimeout();
                        return new AuthenticationResult(result.success, result.success ? result.cryptId : "");
                    })
                )
            )
        );
    }

    public isAuthenticationTimeout(): boolean {
        if (!this.authTimer) {
            return true;
        }
        const timeout = Date.now() > this.authTimer;
        if (!timeout) {
            this.resetAuthenticationTimeout();
        }
        //console.log("isAuthenticationTimeout:", this.authTimer);
        return this.authTimer < Date.now();
    }

    public resetAuthenticationTimeout(): void {
        //auth timeout er 3 timer
        this.authTimer = Date.now() + 10800000;
        //console.log("ny auth timer sat til:", this.authTimer);
    }
    public reAuthenticate(): Observable<AuthenticationResult> {
        return null; //authenticate(credentials: LoginData);
    }

    private getSecurityToken(appServerUrl: string, brugernavn: string): Observable<string> {
        if (!brugernavn) {
            return of("");
        }

        const url = this.getSecurityTokenUrl(brugernavn);
        return this.http.get(appServerUrl + url).pipe(map((result: any) => result.Token));
    }

    private getSecurityTokenUrl(brugernavn: string): string {
        const userType = brugernavn.indexOf("@") > -1 ? "euser" : isNaN(parseInt(brugernavn, 10)) ? "user" : "consumer";
        return `system/getsecuritytoken/project/app/${userType}/${brugernavn}`;
    }

    private createCryptId(hashedPassword: string, token: string): string {
        if (!token) {
            return "";
        }
        return MD5.hash(hashedPassword + token);
    }

    private getAuthenticationUrl(brugernavn: string, cryptId: string): string {
        const userType = brugernavn.indexOf("@") > -1 ? "euser" : isNaN(parseInt(brugernavn, 10)) ? "user" : "consumer";
        return `system/login/project/app/${userType}/${brugernavn}/installation/1/id/${cryptId}`;
    }

    private hentAppServerUrl(forsyningId: string): Observable<string> {
        const url = `/umbraco/dff/dffapi/GetVaerkSettings?forsyningid=${forsyningId}`;
        return this.http.get(url).pipe(map((r: any) => r.AppServerUri));
    }

    private httpAuthenticate(url: string, cryptId: string): Observable<AuthenticationResult> {
        if (!cryptId) {
            return of({ success: false, cryptId: "" });
        }

        return this.http.get(url).pipe(map((authResult: any) => ({ cryptId, success: !!authResult.Result })));
    }
}
