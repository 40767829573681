import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { MenuItem } from "@dffedb/ui";

import { UmbracoMenuObserver } from "./umbraco-menu.observer";

@Injectable({ providedIn: "root" })
export class ForsyningWebMenuForSidebarObserver {
    private readonly _valueChanges: Observable<MenuItem[]>;

    constructor(umbracoMenuObserver: UmbracoMenuObserver) {
        this._valueChanges = umbracoMenuObserver.valueChanges.pipe(
            map((menu) => menu.forsyningWeb),
            map((menu) => this.moveSearchMenuToTop(menu))
        );
    }

    public get valueChanges(): Observable<MenuItem[]> {
        return this._valueChanges;
    }

    private moveSearchMenuToTop(menu: MenuItem[]): MenuItem[] {
        const searchMenuItem = menu.find((i) => i.icon === "menu-search");
        const rest = menu.filter((i) => i !== searchMenuItem);
        return searchMenuItem ? [searchMenuItem, ...rest] : menu;
    }
}
