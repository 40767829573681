<div class="popup">
    <a href="#" class="close-button" (click)="onHidePopup($event)" title="Luk">
        <mat-icon svgIcon="dff-info-popup-close"></mat-icon>
    </a>
    <div class="scroll-container">
        <div class="padding-container">
            <img *ngIf="imageSrc" [src]="imageSrc" alt="Billede" />
            <h3 *ngIf="header">{{ header }}</h3>
            <div *ngIf="content" [innerHtml]="content"></div>
            <a
                *ngIf="buttonHref && buttonText"
                [href]="buttonHref"
                [target]="buttonTarget ?? '_self'"
                class="button"
                mat-flat-button
                color="primary"
                >{{ buttonText }}</a
            >
        </div>
    </div>
</div>

<a class="toggle-corner" href="#" (click)="onShowPopup($event)" role="button" aria-label="Luk popup">
    <mat-icon svgIcon="dff-info-popup-open"></mat-icon>
</a>
