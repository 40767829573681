import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { defineCustomElements } from "@dffedb/util";
import { ForsyningPaaAdresseModule } from "@e-forsyning/common/forsyning-paa-adresse";
import { TilslutningsanmodningModule } from "@e-forsyning/common/tilslutningsanmodning";

import { DffedbTilslutningsanmodningComponent } from "./dffedb-tilslutningsanmodning.component";
@NgModule({
    imports: [CommonModule, TilslutningsanmodningModule, ForsyningPaaAdresseModule, FlexLayoutModule],
    exports: [DffedbTilslutningsanmodningComponent],
    declarations: [DffedbTilslutningsanmodningComponent]
})
export class DffedbTilslutningsanmodningModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-tilslutningsanmodning", DffedbTilslutningsanmodningComponent, { injector });
    }
}
