import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { catchError, distinctUntilChanged, shareReplay, switchMap, tap } from "rxjs/operators";

import { DffObserverState } from "@dffedb/rxjs";
import { DffError, getErrorMessage } from "@dffedb/util";

import { LogLevel } from "../log/log.model";
import { LogService } from "../log/log.service";

export abstract class ObserverBase<TSource, TResult> {
    private readonly stateChangesSubject = new ReplaySubject<DffObserverState>(1);
    private readonly errorChangesSubject = new BehaviorSubject<any>(null);
    private readonly valueChanges$: Observable<TResult>;
    public readonly stateChanges = this.stateChangesSubject.asObservable();
    public readonly errorChanges = this.errorChangesSubject.asObservable();

    constructor(
        private readonly logService: LogService,
        sourceListener: Observable<TSource>,
        innerProject: (value: TSource, index: number) => Observable<TResult>,
        errorProject: (value: TSource, index: number) => Observable<TResult> = () => of(null)
    ) {
        this.valueChanges$ = sourceListener.pipe(
            tap(() => this.stateChangesSubject.next({ loading: true })),
            tap(() => this.errorChangesSubject.next(null)),
            switchMap((value, index) =>
                innerProject(value, index).pipe(
                    catchError((err) => {
                        const error = new DffError(`${this.constructor.name}: Fejl i forbindelse med observable: ${getErrorMessage(err)}`, {
                            innerError: err
                        });
                        this.logService.logError(error, LogLevel.Warning);
                        console.warn(`${name}: ${getErrorMessage(error)}`);
                        this.errorChangesSubject.next(error);
                        return errorProject(value, index);
                    })
                )
            ),
            tap(() => setTimeout(() => this.stateChangesSubject.next({ loading: false }))),
            distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
            shareReplay(1)
        );
    }

    public get valueChanges(): Observable<TResult> {
        return this.valueChanges$;
    }
}
