import { Injectable } from "@angular/core";

import { Rolle } from "./model/rolle.model";

@Injectable({ providedIn: "root" })
export class RolleService {
    public erForbruger(bruger: any): boolean {
        return this.harRolle(bruger, Rolle.Forbruger);
    }

    public erFofBruger(bruger: any): boolean {
        return this.harRolle(bruger, Rolle.FofBruger);
    }

    public erEjendomsadministrator(bruger: any): boolean {
        return this.harRolle(bruger, Rolle.Ejendomsadministrator);
    }

    private harRolle(bruger: any, rolle: Rolle): boolean {
        const brugerRolle = bruger && typeof bruger === "object" ? bruger.rolle : bruger;
        return brugerRolle === rolle;
    }
}
