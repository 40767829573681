import { registerLocaleData } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import localeDa from "@angular/common/locales/da";
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CookieService } from "ngx-cookie-service";

import { DffRedirectService } from "@dffedb/router";
import { ENV, LogentriesLoggerService, LOGGER } from "@dffedb/util";
import { AppShellModule } from "@e-forsyning/common/app-shell";
import { EforsyningErrorHandler } from "@e-forsyning/common/error";
import { EforsyningHttpInterceptor } from "@e-forsyning/common/http";
import { initUmbracoIndstillinger } from "@e-forsyning/common/umbraco-indstillinger";
import {
    DffedbCookieConsentModule,
    DffedbCookieConsentOverlayModule,
    DffedbDriftsstatusKnapModule,
    DffedbEBoksTilmeldModule,
    DffedbFancyboxModule,
    DffedbForsyningPaaAdresseModule,
    DffedbIframeModule,
    DffedbInfoPopupModule,
    DffedbPrisberegnerModule,
    DffedbTilslutningsanmodningModule,
    EForsyningLogindKnapModule
} from "@e-forsyning/web-components";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";

registerLocaleData(localeDa);

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        MatSnackBarModule,
        AppShellModule,
        EForsyningLogindKnapModule,
        DffedbCookieConsentModule,
        DffedbCookieConsentOverlayModule,
        DffedbDriftsstatusKnapModule,
        DffedbEBoksTilmeldModule,
        DffedbFancyboxModule,
        DffedbIframeModule,
        DffedbInfoPopupModule,
        DffedbPrisberegnerModule,
        DffedbTilslutningsanmodningModule,
        DffedbForsyningPaaAdresseModule // Registrerer WebComponent <dffedb-forsyning-paa-adresse>
    ],
    declarations: [AppComponent],
    providers: [
        // CookieService skal 'provides' for at undgå NullInjectorError i produktionsbuild.
        CookieService,
        {
            provide: LOCALE_ID,
            useValue: "da"
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: EforsyningHttpInterceptor,
            multi: true
        },
        {
            provide: ENV,
            useValue: environment
        },
        {
            provide: LOGGER,
            useClass: LogentriesLoggerService
        },
        {
            provide: ErrorHandler,
            useClass: EforsyningErrorHandler
        },
        {
            provide: DffRedirectService,
            useValue: null // Bruges af menuen til at tjekke om vi kan Angular-route eller skal bruge location.href
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [Injector],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function appInit(injector: Injector): () => void {
    return () => {
        initUmbracoIndstillinger(injector, (window as any).initUmbracoIndstillinger);
    };
}
