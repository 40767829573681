import { Component, OnInit } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { filter, map, shareReplay, switchMap } from "rxjs/operators";

import { UmbracoHttpClient } from "@e-forsyning/common/http";
import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { DffCookieConsentService } from "../dff-cookie-consent";
import { DffCookieConsentCookie } from "../dff-cookie-consent/dff-cookie-consent-cookie.model";

@Component({
    selector: "dff-cookie-consent-dialog",
    templateUrl: "./dff-cookie-consent-dialog.component.html",
    styleUrls: ["./dff-cookie-consent-dialog.component.scss"]
})
export class DffCookieConsentDialogComponent implements OnInit {
    public side = "consent";
    public vm$: Observable<ViewModel>;

    constructor(
        private readonly cookieConsentService: DffCookieConsentService,
        private readonly indstillingerObserver: UmbracoIndstillingerObserver,
        private readonly umbracoHttpClient: UmbracoHttpClient
    ) {}

    public ngOnInit(): void {
        this.vm$ = this.indstillingerObserver.valueChanges.pipe(
            map((i) => (i.forsyningWeb && i.forsyningWeb.nodeId) || (i.aktuelEforsyning && i.aktuelEforsyning.nodeId)),
            filter((nodeId) => !!nodeId),
            switchMap((nodeId) =>
                combineLatest([
                    this.umbracoHttpClient.get<string>(`GetCookieConsentText?nodeId=${nodeId}`),
                    this.umbracoHttpClient.get<DffCookieConsentCookie[]>(`GetCookies?nodeId=${nodeId}`)
                ])
            ),
            map(([html, cookies]) => ({
                html,
                cookies: {
                    mandatory: cookies.filter((c) => c.mandatory),
                    optional: cookies.filter((c) => !c.mandatory)
                }
            })),
            shareReplay(1)
        );
    }

    public onGotoReadMoreClick(event: Event): void {
        event.preventDefault();
        this.side = "laes-mere";
    }

    public onGotoConsentClick(event: Event): void {
        event.preventDefault();
        this.side = "consent";
    }

    public onConsentApproveClick(): void {
        this.cookieConsentService.approveCookies();
    }

    public onConsentRejectClick(): void {
        this.cookieConsentService.rejectCookies();
    }
}

interface ViewModel {
    html: string;
    cookies: {
        optional: DffCookieConsentCookie[];
        mandatory: DffCookieConsentCookie[];
    };
}
