<ng-container *ngIf="vm$ | async as vm">
    <ng-container *ngIf="vm.enabled">
        <a href="#" (click)="onClick($event)">
            <div class="triangle"></div>
            <mat-icon svgIcon="dffedb-cookie-consent.cookie" aria-hidden="false" aria-label="Cookie Consent">home</mat-icon>
        </a>
        <div class="overlay" *ngIf="vm.reloading">
            <div>
                <mat-spinner></mat-spinner>
                <h2>Genindlæser siden...</h2>
            </div>
        </div>
    </ng-container>
</ng-container>
