import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { EmptyPageBadgeModule } from "../empty-page-badge";
import { ErrorComponent } from "./error.component";

@NgModule({
    imports: [CommonModule, EmptyPageBadgeModule],
    exports: [ErrorComponent],
    declarations: [ErrorComponent]
})
export class ErrorModule {}
