import MegaPixImage from "@koba04/ios-imagefile-megapixel";

export function getImageFromFile(file: any): Promise<HTMLImageElement> {
    return new Promise<HTMLImageElement>(resolve => {
        const reader = new FileReader();
        reader.onload = (ev: any) => {
            const image = new Image();
            image.src = ev.target.result;
            const dimensions = getNewDimensions(image, 1920);
            const mediaType = getMediaTypeFromDataUrl(ev.target.result);

            const mpImg = new MegaPixImage(file);
            mpImg.render(
                image,
                {
                    maxWidth: dimensions.width,
                    maxHeight: dimensions.height,
                    quality: 0.5,
                    format: mediaType
                },
                () => {
                    image.title = file.name;
                    resolve(image);
                }
            );
        };
        reader.readAsDataURL(file);
    });
}

export function rotateImageData(imageData: string): Promise<string> {
    const sourceImage = new Image();
    sourceImage.src = imageData;
    const mpImg = new MegaPixImage(sourceImage);
    return new Promise<string>(resolve => {
        const rotateRight = 6;
        const targetImage = new Image();
        mpImg.render(targetImage, { orientation: rotateRight }, () => {
            resolve(targetImage.src);
        });
    });
}

function getNewDimensions(image: HTMLImageElement, maxWidthAndHeight: number): { height: number; width: number } {
    const isPortrait = image.height > image.width;
    const height = isPortrait ? maxWidthAndHeight : Math.floor((maxWidthAndHeight / image.width) * image.height);
    const width = isPortrait ? Math.floor((maxWidthAndHeight / image.height) * image.width) : maxWidthAndHeight;
    return { height, width };
}

function getMediaTypeFromDataUrl(dataUrl: string): string {
    if (!dataUrl) {
        return null;
    }

    const start = dataUrl.indexOf(":");
    const end = dataUrl.indexOf(";");
    return dataUrl.substr(start + 1, end - (start + 1));
}
