import { Injectable } from "@angular/core";

import { ObserverBasedService } from "../util/observer-based-service.service";
import { AktuelBrugerObserver } from "./aktuel-bruger.observer";
import { Bruger } from "./model/bruger.model";

@Injectable({ providedIn: "root" })
export class AktuelBrugerService extends ObserverBasedService<Bruger> {
    constructor(observer: AktuelBrugerObserver) {
        super(observer);
    }
}
