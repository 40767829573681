import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";

import {
    mdiAccountCircleOutline,
    mdiBellAlertOutline,
    mdiCash100,
    mdiChartBar,
    mdiCheckCircleOutline,
    mdiClipboardTextOutline,
    mdiCounter,
    mdiDomain,
    mdiFileDocumentEditOutline,
    mdiFileDocumentOutline,
    mdiLockOpenVariantOutline,
    mdiLockOutline,
    mdiLogin,
    mdiLogout,
    mdiMenu,
    mdiMessageTextOutline,
    mdiPhoneOutline,
    mdiScaleBalance,
    mdiTrainCar
} from "@mdi/js";

import { SvgIconRegistry } from "@dffedb/util";
import { DffedbDriftsstatusKnapModule } from "@e-forsyning/web-components";

import { EforsyningSidenavMenuModule, ForsyningwebSidenavMenuModule } from "../eforsyning-menu";
import { AppShellComponent } from "./app-shell.component";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatSidenavModule,
        EforsyningSidenavMenuModule,
        ForsyningwebSidenavMenuModule,
        DffedbDriftsstatusKnapModule
    ],
    declarations: [AppShellComponent],
    exports: [AppShellComponent]
})
export class AppShellModule {
    constructor(svgIconRegistry: SvgIconRegistry) {
        // Ikoner findes på: https://materialdesignicons.com/

        svgIconRegistry.registerIcon(mdiMenu, "menu");
        svgIconRegistry.registerIcon(mdiLockOutline, "lock");
        svgIconRegistry.registerIcon(mdiLockOpenVariantOutline, "lock-open");

        svgIconRegistry.registerIcon(mdiCheckCircleOutline, "fakturagodkendelse", "check-circle-outline", "menu-fakturagodkendelse");
        svgIconRegistry.registerIcon(mdiClipboardTextOutline, "opgaver", "menu-opgaver");
        svgIconRegistry.registerIcon(mdiAccountCircleOutline, "mine-oplysninger", "forbruger", "menu-forbruger", "menu-mineoplysninger");
        svgIconRegistry.registerIcon(mdiCash100, "beregnet-varmeregnskab", "menu-calculatedvarmeregnskab");
        svgIconRegistry.registerIcon(mdiLogout, "menu-logout");
        svgIconRegistry.registerIcon(mdiLogin, "menu-login");
        svgIconRegistry.registerIcon(mdiDomain, "installationer", "menu-installationer");
        svgIconRegistry.registerIcon(mdiFileDocumentEditOutline, "noter", "menu-noter");
        svgIconRegistry.registerIcon(mdiPhoneOutline, "kontakt", "menu-kontakt");
        svgIconRegistry.registerIcon(mdiChartBar, "mit-forbrug", "menu-mitforbrug");
        svgIconRegistry.registerIcon(mdiFileDocumentOutline, "mine-dokumenter", "menu-minedokumenter");
        svgIconRegistry.registerIcon(mdiBellAlertOutline, "forbrugsoverblik", "menu-beskeder");
        svgIconRegistry.registerIcon(mdiMessageTextOutline, "sms-service", "menu-smsservice");
        svgIconRegistry.registerIcon(mdiTrainCar, "meld-flytning", "menu-meldflytning");
        svgIconRegistry.registerIcon(mdiScaleBalance, "sammenlign-forbrug", "menu-sammenlignforbrug");
        svgIconRegistry.registerIcon(mdiCounter, "indtast-aflaesninger", "menu-indtastaflaesninger");
    }
}
