import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { SpinnerComponent } from "./spinner.component";

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [SpinnerComponent],
    declarations: [SpinnerComponent],
    providers: []
})
export class SpinnerModule {}
