// eslint-disable-next-line max-classes-per-file
class FirmaInfo {
    public email?: string;
    public firmaNavn?: string;
    public telefon?: string;
}

export class EforsyningIndstilling {
    public appServerUrl: string;
    public forsyningId: string;
    public siteUrl: string;
    public logoUrl: string;
    public emailLogoUrl: string;
    public emailSignatur: string;
    public nodeId: number;
    public overskriftPaaLoginside: string;
    public firmaInfo: FirmaInfo = {};
}
