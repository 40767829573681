import { AbstractControl } from "@angular/forms";

import { formatDate, parseDate } from "@dffedb/util";

/*
Bemærk, at det ikke længere er sikkert at benytte Modulus 11-check på CPR-numre:
https://www.cpr.dk/cpr-systemet/personnumre-uden-kontrolciffer-modulus-11-kontrol/
Vi kan imidlertid checke om de første 6 cifre i personummeret udgør en gyldig dato.
F.eks. er 300219, 320119 og 021319 ikke gyldige datoer.
*/
export function cprNummerValidator(): any {
    return (control: AbstractControl) => {
        if (erBlankVaerdi(control.value)) {
            return null; // Vi anser blank værdi for valid. Ønskes dette ikke, kan man benytte en RequiredValidator.
        }
        return erValidCprNummer(control.value.toString()) ? null : error(control.value);
    };
}

export function cprNummerMedBindestregValidator(): any {
    return (control: AbstractControl) => {
        if (erBlankVaerdi(control.value)) {
            return null; // Vi anser blank værdi for valid. Ønskes dette ikke, kan man benytte en RequiredValidator.
        }
        return erValidCprNummerMedBindestreg(control.value.toString()) ? null : error(control.value);
    };
}

export function cprNummerUdenBindestregValidator(): any {
    return (control: AbstractControl) => {
        if (erBlankVaerdi(control.value)) {
            return null; // Vi anser blank værdi for valid. Ønskes dette ikke, kan man benytte en RequiredValidator.
        }
        return erValidCprNummerUdenBindestreg(control.value.toString()) ? null : error(control.value);
    };
}

function erBlankVaerdi(value: string): boolean {
    return value === null || value === undefined || value === "";
}

function erValidCprNummer(value: string): boolean {
    return erValidCprNummerMedBindestreg(value) || erValidCprNummerUdenBindestreg(value);
}

function erValidCprNummerMedBindestreg(value: string): boolean {
    return /^\d{6}-\d{4}$/.test(value) && erFoerste6cifreValidDato(value);
}

function erValidCprNummerUdenBindestreg(value: string): boolean {
    return /^\d{10}$/.test(value) && erFoerste6cifreValidDato(value);
}

function erFoerste6cifreValidDato(value: string): boolean {
    const expected = value.slice(0, 6);
    const actual = formatDate(parseDate(expected, "DDMMYY"), "DDMMYY");
    return actual === expected;
}

function error(cprNummer: string): any {
    return { cprNummer };
}
