import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({ providedIn: "root" })
export class ToastService {
    private readonly matSnackBarConfig: MatSnackBarConfig;

    constructor(private readonly snackBar: MatSnackBar) {
        this.matSnackBarConfig = new MatSnackBarConfig();
        this.matSnackBarConfig.duration = 3000;
        this.matSnackBarConfig.horizontalPosition = "right";
        this.matSnackBarConfig.verticalPosition = "top";
    }

    public visFejl(tekst: string): void {
        this.snackBar.open(tekst, null, this.matSnackBarConfig);
    }

    public visInfo(tekst: string): void {
        this.snackBar.open(tekst, null, this.matSnackBarConfig);
    }
}
