import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { EmptyPageBadgeModule } from "@dffedb/ui";
import { defineCustomElements } from "@dffedb/util";

import { DffedbPrisberegnerComponent } from "./dffedb-prisberegner.component";
import { PrisberegnerSpecifikationComponent } from "./prisberegner-specifikation/prisberegner-specifikation.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        EmptyPageBadgeModule
    ],
    exports: [DffedbPrisberegnerComponent],
    declarations: [DffedbPrisberegnerComponent, PrisberegnerSpecifikationComponent],
    providers: []
})
export class DffedbPrisberegnerModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-prisberegner", DffedbPrisberegnerComponent, { injector });
    }
}
