import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DeviceService {
    constructor(private readonly breakpointObserver: BreakpointObserver) {}

    public erMobilVisning(): boolean {
        return this.breakpointObserver.isMatched("(max-width: 360px)");
    }
}
