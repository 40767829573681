<form *ngIf="vm$ | async as vm" [formGroup]="formGroup" action="">
    <mat-form-field>
        <mat-label>Søg på adresse</mat-label>
        <input matInput type="search" formControlName="searchValue" autocorrect="off" [matAutocomplete]="autocomplete" />
    </mat-form-field>
    <mat-autocomplete
        #autocomplete="matAutocomplete"
        [autoActiveFirstOption]="true"
        (optionSelected)="onSearchValueChange($event.option.value)"
        [displayWith]="displayFn"
    >
        <mat-option *ngFor="let adresse of adresser$ | async" [value]="adresse">
            {{ adresse.tekst }}
        </mat-option>
    </mat-autocomplete>
    <div class="result" *ngIf="result$ | async as result" [ngSwitch]="result.status">
        <div *ngSwitchCase="-1"><small>Henter data...</small></div>
        <div *ngSwitchCase="0" [innerHTML]="result.statusTekst || vm.konfiguration.svarmuligheder.nej"></div>
        <div *ngSwitchCase="1" [innerHTML]="result.statusTekst || vm.konfiguration.svarmuligheder.ja"></div>
        <div *ngSwitchCase="2" [innerHTML]="result.statusTekst || vm.konfiguration.svarmuligheder.snart"></div>
        <div *ngSwitchDefault></div>
    </div>
</form>
