import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";

import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { MenuItem } from "@dffedb/ui";

import { AktuelBrugerObserver, Bruger } from "../bruger";
import { UmbracoMenuObserver } from "../eforsyning-menu/observers/umbraco-menu.observer";

@Injectable({ providedIn: "root" })
export class MenuBegraensningGuard implements CanActivate {
    constructor(private readonly aktuelBrugerObserver: AktuelBrugerObserver, private readonly umbracoMenuObserver: UmbracoMenuObserver) {}

    public canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean | UrlTree> {
        // Denne guard tjekker om der er sat begrænsning for menupunktet.
        // I FOF kan man angive, at kun nogle menupunkter skal være tilgængelige for f.eks. en VVS'er eller et bestyrelsesmedlem.
        // Er der angivet 'ingen adgang', sørger guarden for, at man ikke kan tilgå menpunktet.
        const bruger$ = this.aktuelBrugerObserver.valueChanges;
        const rootMenuItem$ = this.umbracoMenuObserver.valueChanges.pipe(map((m) => m.eforsyninger));
        const result$ = combineLatest([bruger$, rootMenuItem$]).pipe(
            map(([bruger, rootMenuItem]) => ({ bruger, menuItem: this.findMenupunkt(rootMenuItem, route) })),
            map(({ bruger, menuItem }) => this.harBrugerAdgangTilMenupunkt(bruger, menuItem))
        );
        return result$;
    }

    private findMenupunkt(menuItem: MenuItem, route: ActivatedRouteSnapshot): MenuItem {
        if (menuItem?.href?.endsWith("/#/" + route.url.toString() + "/")) {
            return menuItem;
        }
        for (const child of menuItem.children ?? []) {
            const result = this.findMenupunkt(child, route);
            if (result) {
                return result;
            }
        }
        return null;
    }

    private harBrugerAdgangTilMenupunkt(bruger: Bruger, menuItem: MenuItem): boolean {
        if (!bruger) {
            return false;
        }
        if (!menuItem) {
            return true;
        }
        if (!menuItem.adgangsstyret) {
            return true;
        }
        return (bruger.menupunkter ?? []).includes(menuItem.name);
    }
}
