import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DffCookieConsent, DffCookieConsentService } from "../shared/dff-cookie-consent";
import { DffCookieConsentCookieListModule } from "../shared/dff-cookie-consent-cookie-list/dff-cookie-consent-cookie-list.module";
import { DffedbIframeViewModel } from "./dffedb-iframe.view-model";

@Injectable({ providedIn: "root" })
export class DffedbIframeSandbox {
    constructor(
        private readonly cookieConsentService: DffCookieConsentService,
        private readonly cookieConsentCookieListModule: DffCookieConsentCookieListModule
    ) {}

    public selectViewModel(source: string): Observable<DffedbIframeViewModel> {
        return this.cookieConsentService.selectCookieConsent().pipe(map((c) => this.mapViewModel(c, source)));
    }

    public getViewModel(source: string): DffedbIframeViewModel {
        return this.mapViewModel(this.cookieConsentService.getCookieConsent(), source);
    }

    private mapViewModel(value: DffCookieConsent, source: string): DffedbIframeViewModel {
        return {
            consentAccepted: source.includes("sms-service") ? true : value.consent.statistics && value.consent.thirdparty
        };
    }
}
