import { Injectable, OnDestroy } from "@angular/core";

import { SubSink } from "./subsink";

/**
 * A class that automatically unsubscribes all observables when the object gets destroyed
 * https://blog.angulartraining.com/how-to-automatically-unsubscribe-your-rxjs-observables-tutorial-2f98b0560298
 */
@Injectable({
    providedIn: "root"
})
export class UnsubscribeOnDestroy implements OnDestroy {
    // The subscription sink object that stores all subscriptions
    public subs = new SubSink();

    // The lifecycle hook that unsubscribes all subscriptions when the component / object gets destroyed
    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
