import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { merge } from "rxjs";
import { filter, first, map, pairwise } from "rxjs/operators";

import { SubSink } from "@dffedb/rxjs";
import { BodyClassService, trim } from "@dffedb/util";

@Injectable({ providedIn: "root" })
export class RouteBodyClassService {
    private readonly subs = new SubSink();

    constructor(private readonly router: Router, private readonly bodyClassService: BodyClassService) {}

    public setup(): void {
        const firstEvent$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            first(),
            map(event => ({ enter: this.getRoute(event as NavigationEnd), leave: "" }))
        );
        const followingEvents$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            pairwise(),
            map(([prev, curr]) => ({ enter: this.getRoute(curr as NavigationEnd), leave: this.getRoute(prev as NavigationEnd) }))
        );

        const events$ = merge(firstEvent$, followingEvents$);

        this.subs.sink = events$.subscribe(event => {
            this.bodyClassService.remove(`route-${event.leave}`);
            this.bodyClassService.add(`route-${event.enter}`);
        });
    }

    public teardown(): void {
        this.subs.unsubscribe();
    }

    private getRoute(event: NavigationEnd): string {
        const result = trim(event.url, "/").replace("/", "-");
        switch (result) {
            case "":
            case "logout":
                return "login";
            default:
                return result;
        }
    }
}
