import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { defineCustomElements } from "@dffedb/util";
import { EBoksTilmeldingModule } from "@e-forsyning/common/e-boks";

import { DffedbEBoksTilmeldComponent } from "./dffedb-e-boks-tilmeld.component";

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule, EBoksTilmeldingModule],
    exports: [DffedbEBoksTilmeldComponent],
    declarations: [DffedbEBoksTilmeldComponent]
})
export class DffedbEBoksTilmeldModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-e-boks-tilmeld", DffedbEBoksTilmeldComponent, { injector });
    }
}
