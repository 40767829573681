import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";

import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { SubSink } from "@dffedb/rxjs";
import { MenuItem } from "@dffedb/ui";
import { AppserverInstallationCredentialsRepository } from "@e-forsyning/common/credentials";
import { EforsyningMenuerObserver } from "@e-forsyning/common/eforsyning-menu";
import { UmbracoIndstillinger, UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { EForsyningLogindKnapMenuItem, EForsyningLogindKnapViewModel } from "./e-forsyning-logind-knap.view-model";

@Component({
    templateUrl: "./e-forsyning-logind-knap.component.html",
    styleUrls: ["./e-forsyning-logind-knap.component.scss"],
    animations: [trigger("fadeIn", [transition(":enter", [style({ opacity: 0 }), animate(600)])])]
})
export class EForsyningLogindKnapComponent implements OnInit, OnDestroy {
    private readonly iconLoggedIn = "lock-open";
    private readonly iconLoggedOut = "lock";
    private subs = new SubSink();
    public vm: EForsyningLogindKnapViewModel;

    @HostBinding("@fadeIn")
    public fadeInAnimation = true;

    @Input()
    public class: string;

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly indstillingerObserver: UmbracoIndstillingerObserver,
        private readonly credentialsObserver: AppserverInstallationCredentialsRepository,
        private readonly eforsyningMenuerObserver: EforsyningMenuerObserver
    ) {
        // WebComponents fungerer ikke så godt med auto-change detection, så vi kobler det helt fra.
        cd.detach();
    }

    public ngOnInit(): void {
        this.subs.sink = combineLatest([
            this.indstillingerObserver.valueChanges,
            this.eforsyningMenuerObserver.valueChanges,
            this.credentialsObserver.valueChanges
        ])
            .pipe(map(([indstillinger, menuer, credentials]) => this.mapToMenu(indstillinger, menuer, credentials.forsyningNodeId)))
            .subscribe((vm) => {
                this.vm = vm;
                this.cd.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public onClick(event: MouseEvent, href: string): void {
        if (!href) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private mapToMenu(
        indstillinger: UmbracoIndstillinger,
        menuItem: MenuItem,
        currentEforsyningNodeId: number
    ): EForsyningLogindKnapViewModel {
        const eforsyninger = menuItem.children.map((c) => this.mapToSubmenu(c, currentEforsyningNodeId));
        const svgIcon = eforsyninger.some((i) => i.svgIcon === this.iconLoggedIn) ? this.iconLoggedIn : this.iconLoggedOut;
        const harForsyningWeb = !!indstillinger.forsyningWeb.nodeId;
        const visible = (harForsyningWeb && eforsyninger.length >= 1) || (!harForsyningWeb && eforsyninger.length > 1);
        const result = {
            visible,
            titel: menuItem.title || "Min side",
            svgIcon,
            href: eforsyninger.length === 1 ? eforsyninger[0].href : "",
            items: eforsyninger.length === 1 ? [] : eforsyninger
        };
        return result;
    }

    private mapToSubmenu(menuItem: MenuItem, currentEforsyningNodeId: number): EForsyningLogindKnapMenuItem {
        const loggedIn = menuItem.id === currentEforsyningNodeId;
        return {
            titel: menuItem.title,
            href: menuItem.href,
            svgIcon: loggedIn ? this.iconLoggedIn : this.iconLoggedOut
        };
    }
}
