import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

import { formatDate, parseDate } from "@dffedb/util";

function padLeft(text: string, padChar: string, size: number): string {
    return (padChar.repeat(size) + text).substr(size * -1, size);
}

function dffParseDateExact(dateStr: string, template: string): Date | null {
    const result = parseDate(dateStr, template);
    return dateStr === formatDate(result, template) ? result : null;
}

// dffParseDate parser en dato-streng i dansk format.
// Hvis man tænker sig at aktuelle dato er 07. maj 2019, vil man opnå følgende resultater:
// Input: 20-05-2019, Output: 20-05-2019
// Input: 20/05/2019, Output: 20-05-2019
// Input: 200519, Output: 20-05-2019
// Input: 20-05, Output: 20-05-2019
// Input: 2005, Output: 20-05-2019
// Input: 20, Output: 20-05-2019
// Input: d, Output: 07-05-2019
function dffParseDate(value: string): Date | null {
    const today = new Date();
    const currentYear = today.getFullYear().toString();
    const currentMonth = padLeft((today.getMonth() + 1).toString(), "0", 2);
    return (
        dffParseDateExact(value, "DD-MM-YYYY") || // 20-05-2019 -> 20-05-2019
        dffParseDateExact(value, "DD/MM/YYYY") || // 20/05/2019 -> 20-05-2019
        dffParseDateExact(value, "DDMMYY") || // 200519 -> 20-05-2019
        dffParseDateExact(value + currentYear, "DD-MMYYYY") || // 20-05 -> 20-05-2019
        dffParseDateExact(value + currentYear, "DD/MMYYYY") || // 20/05 -> 20-05-2019
        dffParseDateExact(value + currentYear, "DDMMYYYY") || // 2005 -> 20-05-2019
        dffParseDateExact(value + currentMonth + currentYear, "DDMMYYYY") || // 20 -> 20-05-2019
        ((value || "").toUpperCase() === "D" ? new Date() : null) // d -> 20-05-2019
    );
}

// dffFormatDate formatterer en dato til en streng i dansk format.
// Default benyttes formatet 'DD-MM-YYYY', så man få vist dato som f.eks. '20-05-2019'
function dffFormatDate(date: Date, template: string = "DD-MM-YYYY"): string {
    return formatDate(date, template);
}

@Injectable()
export class DffDateAdapter extends NativeDateAdapter {
    public parse(value: any): Date | null {
        return dffParseDate(value);
    }

    public format(date: Date, _displayFormat: any): string {
        return dffFormatDate(date);
    }
}
