import { AbstractControl } from "@angular/forms";

import { trim } from "@dffedb/util";

import { erValidTelefonr } from "./validators.telefonnummer";

export function telefonnumreValidator(separator: string = ","): any {
    return (control: AbstractControl) => {
        if (control.value === null || control.value === undefined || control.value.length === 0) {
            return null;
        }

        const telefonnumre = (control.value + "").split(separator).map((telefonnr) => trim(telefonnr));
        const results = telefonnumre.map((telefonnr) => erValidTelefonr(telefonnr));
        return results.every((r) => r) ? null : { telefonnr: true };
    };
}
