import { Injectable } from "@angular/core";
import { ActivationStart, NavigationCancel, NavigationEnd, Router } from "@angular/router";

import { Observable } from "rxjs";
import { filter, map, startWith } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class DffRouteChangingObserver {
    public readonly valueChanges: Observable<boolean>;

    constructor(router: Router) {
        this.valueChanges = router.events.pipe(
            filter(e => e instanceof ActivationStart || e instanceof NavigationEnd || e instanceof NavigationCancel),
            map(e => e instanceof ActivationStart),
            startWith(false)
        );
    }
}
