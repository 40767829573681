import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { dbToDate, dbToInt } from "@dffedb/util";

import { AppserverHttpClient, BrugerHttpClient, InstallationHttpClient } from "../http";
import { ForbrugerOplysningerDto } from "./model/forbruger-oplysninger.dto";
import { ForbrugerOplysninger } from "./model/forbruger-oplysninger.model";

@Injectable({ providedIn: "root" })
export class ForbrugerOplysningerRepository {
    constructor(private readonly brugerHttp: BrugerHttpClient, private readonly installationHttp: InstallationHttpClient) {}

    public hentForbrugerOplysningerForAktuelleInstallation(): Observable<ForbrugerOplysninger> {
        return this.hentForbrugerOplysninger(this.installationHttp, this.installationHttp.credentials.ejendomNr);
    }

    public hentForbrugerOplysningerForBruger(ejendomNr?: number): Observable<ForbrugerOplysninger> {
        return this.hentForbrugerOplysninger(this.brugerHttp, ejendomNr);
    }

    public hentForbrugerOplysninger(httpClient: AppserverHttpClient, ejendomNr?: number): Observable<ForbrugerOplysninger> {
        const url = ejendomNr ? `api/getebrugerinfo?unr=${ejendomNr}` : "api/getebrugerinfo";
        return httpClient.get<ForbrugerOplysningerDto>(url).pipe(map((dto) => this.map(dto)));
    }

    private map(f: ForbrugerOplysningerDto): ForbrugerOplysninger {
        return {
            ejendomNr: dbToInt(f.ejendomnr),
            navn: f.navn,
            adresse: `${f.vej} ${f.husnr} ${f.litra} ${f.litra2}`.trim(),
            postnr: f.postnr,
            by: f.bynavn,
            indflyttet: dbToDate(f.indflyttet)
        };
    }
}
