export interface EBrugerInfo {
    id: number;
    navn: string;
    navn2: string;
    vejl: string;
    husnr: string;
    litra: string;
    litra2: string;
    postnr: string;
    bynavn: string;
    email: string;
    tlf: string;
    rolle: number;
    indflyttet: string;
    notifikationmail: string;
    notifikationmobil: string;
    notifikationtype: string;
    ejendomnr: number;
    frekvens: number;
    overvaagningstypeforbrug: string;
    vaerdiforbrug: number;
    overvaagningstypeinstallation: string;
    vaerdiinstallation: number;
}
