<ng-container *ngIf="vm$ | async as vm">
    <empty-page-badge overskrift="Konfigurationsfejl" *ngIf="!vm.konfigurationOk">
        Prisberegneren mangler at blive konfigureret
    </empty-page-badge>

    <form [formGroup]="vm.formGroup" *ngIf="vm.konfigurationOk">
        <div class="panel-beregner-og-resultat">
            <div class="panel-beregner">
                <mat-form-field
                    *ngIf="
                        vm.konfiguration.fjernvarme.bbrPrisPrM2 ||
                        vm.konfiguration.fjernvarme.bbrPriserPrM2?.length ||
                        vm.konfiguration.fjernvarme.groenOmstillingsbidragPrM2
                    "
                >
                    <mat-label>Hvor stort er dit hus (BBR-boligareal)?</mat-label>
                    <input matInput type="number" id="Boligareal" formControlName="boligareal" (focus)="onFocus($event)" />
                    <span matSuffix>m<sup>2</sup></span>
                    <mat-hint></mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Hvordan opvarmer du dit hus?</mat-label>
                    <mat-select #NuvaerendeVarmekilde formControlName="varmekilde">
                        <mat-option *ngFor="let varmekilde of vm.varmekilder" [value]="varmekilde.id">
                            {{ varmekilde.navn }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <ng-container [ngSwitch]="vm.formGroup.get('varmekilde').value">
                    <!-- El -->
                    <mat-form-field *ngSwitchCase="1">
                        <mat-label>Hvor meget el bruger du til opvarmning om året?</mat-label>
                        <input matInput type="number" formControlName="forbrugEl" (focus)="onFocus($event)" />
                        <span matSuffix>kWh</span>
                        <mat-hint></mat-hint>
                    </mat-form-field>

                    <!-- Olie -->
                    <ng-container *ngSwitchCase="3">
                        <mat-form-field>
                            <mat-label>Hvor meget olie bruger du om året?</mat-label>
                            <input matInput type="number" formControlName="forbrugOlie" (focus)="onFocus($event)" />
                            <span matSuffix>liter</span>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                        <mat-form-field *ngIf="vm.konfiguration.olie.virkningsgrad.vaelgKedlensAlder">
                            <mat-label>Hvor gammelt er dit oliefyr?</mat-label>
                            <mat-select formControlName="olieFyrAlder">
                                <mat-option [value]="1" *ngIf="!!vm.konfiguration.olie.virkningsgrad.virkningsgrad0til4Aar">
                                    0-4 år</mat-option
                                >
                                <mat-option [value]="2" *ngIf="!!vm.konfiguration.olie.virkningsgrad.virkningsgrad5til8Aar">
                                    5-8 år</mat-option
                                >
                                <mat-option [value]="3" *ngIf="!!vm.konfiguration.olie.virkningsgrad.virkningsgrad9tilNAar">
                                    Ældre end 8 år</mat-option
                                >
                            </mat-select>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </ng-container>

                    <!-- Naturgas -->
                    <ng-container *ngSwitchCase="2">
                        <mat-form-field>
                            <mat-label>Hvor meget naturgas bruger du om året?</mat-label>
                            <input matInput type="number" formControlName="forbrugNaturgas" (focus)="onFocus($event)" />
                            <span matSuffix>m<sup>3</sup></span>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                        <mat-form-field *ngIf="vm.konfiguration.naturgas.virkningsgrad.vaelgKedlensAlder">
                            <mat-label>Hvor gammelt er dit naturgasfyr?</mat-label>
                            <mat-select formControlName="naturgasKeddelAlder">
                                <mat-option [value]="1" *ngIf="!!vm.konfiguration.naturgas.virkningsgrad.virkningsgrad0til4Aar">
                                    0-4 år</mat-option
                                >
                                <mat-option [value]="2" *ngIf="!!vm.konfiguration.naturgas.virkningsgrad.virkningsgrad5til8Aar">
                                    5-8 år</mat-option
                                >
                                <mat-option [value]="3" *ngIf="!!vm.konfiguration.naturgas.virkningsgrad.virkningsgrad9tilNAar">
                                    Ældre end 8 år</mat-option
                                >
                            </mat-select>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </ng-container>

                    <!-- Træpiller -->
                    <ng-container *ngSwitchCase="4">
                        <mat-form-field>
                            <mat-label>Hvor mange træpiller bruger du om året?</mat-label>
                            <input matInput type="number" formControlName="forbrugTraepiller" (focus)="onFocus($event)" />
                            <span matSuffix>kg</span>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                        <mat-form-field *ngIf="vm.konfiguration.traepiller.virkningsgrad.vaelgKedlensAlder">
                            <mat-label>Hvor gammelt er dit pillefyr?</mat-label>
                            <mat-select formControlName="pillefyrAlder">
                                <mat-option [value]="1" *ngIf="!!vm.konfiguration.traepiller.virkningsgrad.virkningsgrad0til4Aar">
                                    0-4 år</mat-option
                                >
                                <mat-option [value]="2" *ngIf="!!vm.konfiguration.traepiller.virkningsgrad.virkningsgrad5til8Aar">
                                    5-8 år</mat-option
                                >
                                <mat-option [value]="3" *ngIf="!!vm.konfiguration.traepiller.virkningsgrad.virkningsgrad9tilNAar">
                                    Ældre end 8 år</mat-option
                                >
                            </mat-select>
                            <mat-hint></mat-hint>
                        </mat-form-field>
                    </ng-container>

                    <!-- Varmepumpe -->
                    <mat-form-field *ngSwitchCase="5">
                        <mat-label>Hvor meget el bruger din varmepumpe om året?</mat-label>
                        <input matInput type="number" formControlName="forbrugVarmepumpe" (focus)="onFocus($event)" />
                        <span matSuffix>kWh</span>
                        <mat-hint></mat-hint>
                    </mat-form-field>
                </ng-container>

                <mat-slide-toggle
                    *ngIf="
                        !!vm.konfiguration.fjernvarme.varmemesterordningPrAar &&
                        (!!vm.konfiguration.fjernvarme.nyUnitPris || !!vm.konfiguration.fjernvarme.investeringsomkostninger)
                    "
                    formControlName="oenskerVarmemesterOrdning"
                >
                    {{ vm.konfiguration.tekster.medVarmemesterordning }}
                </mat-slide-toggle>

                <ng-container
                    *ngIf="
                        vm.konfiguration.fjernvarme.bbrTrappemodel && vm.konfiguration.fjernvarme.bbrPriserPrM2[0].konverteringsbidragPrM2
                    "
                >
                    <mat-card-content>
                        <h4 class="konverteringbidragHeader">{{ vm.konfiguration.tekster.konverteringsbidragOverskrift }}</h4>
                        <p>{{ vm.konfiguration.tekster.konverteringsbidragSubtekst1 }}</p>
                        <p>{{ vm.konfiguration.tekster.konverteringsbidragSubtekst2 }}</p>
                    </mat-card-content>
                    <mat-slide-toggle
                        formControlName="oenskerFordelingAfKonverteringsbidrag"
                        [checked]="vm.state.oenskerFordelingAfKonverteringsbidrag"
                        (change)="toggle($event)"
                    >
                        {{
                            vm.state.oenskerFordelingAfKonverteringsbidrag
                                ? vm.konfiguration.tekster.konverteringsbidragToggle2
                                : vm.konfiguration.tekster.konverteringsbidragToggle1
                        }}
                        {{
                            findKonverterinsbidrag(vm.resultat.fjernvarme.detaljer, vm.konfiguration.tekster.konverteringsbidragOverskrift)
                                | number : "0.0-0"
                        }}
                        kr.
                        {{ vm.state.oenskerFordelingAfKonverteringsbidrag ? "pr. år" : " " }}
                    </mat-slide-toggle>
                </ng-container>
                <mat-form-field *ngIf="vm.state.oenskerFordelingAfKonverteringsbidrag" style="margin-top: 10px">
                    <mat-label>Vælg antal år til fordeling</mat-label>
                    <mat-select #nuvaerendeAntalAar formControlName="konverteringbidragAntalAar">
                        <mat-option *ngFor="let antalAar of vm.konfiguration.fjernvarme.konverteringsbidragAntalAar" [value]="antalAar">
                            betal over {{ antalAar }} år
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!--mat-form-field *ngIf="vm.state.oenskerFordelingAfKonverteringsbidrag">
                    <mat-label>Fordeling af konverteringsbidrag</mat-label>
                    <input matInput type="number" formControlName="konverteringbidragAntalAar"
                        (focus)="onFocus($event)" />
                    <span matSuffix>år</span>
                    <mat-hint>Hvor mange år ønsker du at fordele dit konverteringsbidrag over</mat-hint>
                </mat-form-field-->
            </div>

            <!-- Resultat -->
            <mat-card-content>
                <div class="panel-resultat">
                    <h3>Resultat</h3>
                    <table [class.table-kun1]="vm.konfiguration.visKunUdgiftResultat">
                        <tr>
                            <td>Vejledende årlige udgifter ved fjernvarme:</td>
                            <td>
                                <div class="resultat">{{ vm.resultat.fjernvarme.ialt.beloeb | number : "1.0-0" }} kr.</div>
                                <small>inkl. moms pr. år</small>
                            </td>
                        </tr>
                        <tr *ngIf="vm.resultat.besparelseKr && !vm.konfiguration.visKunUdgiftResultat">
                            <td>Årlige besparelse ved skift til fjernvarme:</td>
                            <td>
                                <div class="resultat">{{ vm.resultat.besparelseKr | number : "1.0-0" }} kr.</div>
                                <small>inkl. moms pr. år</small>
                            </td>
                        </tr>
                        <tr *ngIf="vm.resultat.besparelseKr && !vm.konfiguration.visKunUdgiftResultat">
                            <td>Årlig besparelse svarer til:</td>
                            <td>
                                <div class="resultat">{{ vm.resultat.besparelsePct | number : "1.0-0" }}%</div>
                                <small>&nbsp;</small>
                            </td>
                        </tr>
                    </table>
                </div>
            </mat-card-content>
        </div>

        <hr />

        <!-- Beregningsgrundlag -->
        <div class="panel-beregningsgrundlag" *ngIf="vm.konfiguration.visDetaljeretResultat">
            <h3>Beregningsgrundlag</h3>
            <div>Hvad koster det dig at varme dit hus op?</div>
            <div>
                <div>Dit varmeforbrug pr. år</div>
                <div>
                    <strong>{{ vm.resultat.mwh | number : "1.2-2" }} MWh</strong>&nbsp;
                    <small>{{ vm.resultat.mwhForklaring }}</small>
                </div>
            </div>
            <div *ngIf="!!vm.resultat.sammenligningskilde.ialt.beloeb">
                <div>
                    Vejledende årlige varmeregning med <strong>{{ vm.varmekildeTekst | lowercase }}</strong>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        <strong>{{ vm.resultat.sammenligningskilde.ialt.beloeb | number : "1.0-0" }} kr.</strong>&nbsp;
                        <small [innerHTML]="vm.resultat.sammenligningskilde.ialt.tekst2"></small>
                    </div>
                    <small>
                        <a (click)="vm.state.visDetaljerSammenligningskilde = !vm.state.visDetaljerSammenligningskilde">
                            {{ getDetaljerText(vm.state.visDetaljerSammenligningskilde) }}</a
                        >
                    </small>
                </div>
                <prisberegner-specifikation
                    *ngIf="vm.state.visDetaljerSammenligningskilde"
                    [linjer]="vm.resultat.sammenligningskilde.detaljer"
                >
                </prisberegner-specifikation>
            </div>
            <div>
                <div>Vejledende årlige varmeregning med <strong>fjernvarme</strong></div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                        <strong>{{ vm.resultat.fjernvarme.ialt.beloeb | number : "1.0-0" }} kr.</strong>&nbsp;
                        <small [innerHTML]="vm.resultat.fjernvarme.ialt.tekst2"></small>
                    </div>
                    <small>
                        <a (click)="vm.state.visDetaljerFjernvarme = !vm.state.visDetaljerFjernvarme">
                            {{ getDetaljerText(vm.state.visDetaljerFjernvarme) }}</a
                        >
                    </small>
                </div>
                <prisberegner-specifikation *ngIf="vm.state.visDetaljerFjernvarme" [linjer]="vm.resultat.fjernvarme.detaljer">
                </prisberegner-specifikation>
            </div>
            <div *ngIf="!!vm.resultat.sammenligningskilde.ialt.beloeb">
                <div>Årlig besparelse ift. {{ vm.varmekildeTekst | lowercase }}</div>
                <div>
                    <strong>{{ vm.resultat.besparelseKr | number : "1.0-0" }} kr.</strong>&nbsp;
                    <small
                        >svarende til en besparelse på <strong>{{ vm.resultat.besparelsePct | number : "1.0-0" }}%</strong></small
                    >
                </div>
            </div>
        </div>

        <div *ngIf="false">
            <pre>
                {{ vm.konfiguration | json }}
            </pre>
            <pre>
                {{ vm.model | json }}
            </pre>
            <pre>
                {{ vm.resultat | json }}
            </pre>
        </div>
    </form>
</ng-container>
