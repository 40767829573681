import {
    addDate as tsAddDate,
    addMonth as tsAddMonth,
    addYear as tsAddYear,
    asValidDateOrNull as tsAsValidDateOrNull,
    isValidDate as tsIsValidDate,
    resetDate as tsResetDate,
    resetMonth as tsResetMonth,
    resetYear as tsResetYear
} from "ts-date";
import { format as tsFormat, parse as tsParse } from "ts-date/esm/locale/da";

export function asValidDateOrNull(date: Date): Date | null {
    return tsAsValidDateOrNull(date);
}

export function isValidDate(date: Date): boolean {
    return tsIsValidDate(date);
}

export function minDate(...values: Date[]): Date {
    return new Date(Math.min(...values.filter((v) => !!v).map((v) => v.getTime())));
}

export function maxDate(...values: Date[]): Date {
    return new Date(Math.max(...values.filter((v) => !!v).map((v) => v.getTime())));
}

export function formatDate(date: Date | null, dateFormat: string): string {
    return tsFormat(date, dateFormat) ?? "";
}

export function parseDate(value: string, dateFormat: string): Date | null {
    const result = tsParse(value, dateFormat);
    const expected = tsFormat(result, dateFormat);
    return expected === value ? result : null;
}

export function addYears(date: Date, years: number): Date {
    return tsAddYear(date, years);
}

export function addMonths(date: Date, months: number): Date {
    return tsAddMonth(date, months);
}

export function addDays(date: Date, days: number): Date {
    return tsAddDate(date, days);
}

export function resetDate(date: Date): Date {
    return tsResetDate(date);
}

export function resetMonth(date: Date): Date {
    return tsResetMonth(date);
}

export function resetYear(date: Date): Date {
    return tsResetYear(date);
}

export function getUtcDate(): Date {
    return toUtc(new Date());
}

export function toUtc(date: Date): Date {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMinutes()
    );
}

export function formatDayInterval(from: Date, to: Date): string {
    const fullDateFormat = "Do MMMM YYYY";
    if (from > to) {
        return formatDayInterval(to, from);
    }
    if (from === to) {
        return formatDate(to, fullDateFormat);
    }
    const newYear = from.getFullYear() !== to.getFullYear();
    const newMonth = from.getMonth() !== to.getMonth();
    const fromFormat = newYear ? "Do MMMM YYYY" : newMonth ? "Do MMMM" : "Do";
    return formatDate(from, fromFormat) + " - " + formatDate(to, fullDateFormat);
}

export function formatYearInterval(from: Date, to: Date): string {
    if (from > to) {
        return formatYearInterval(to, from);
    }
    const newYear = from.getFullYear() !== to.getFullYear();
    return newYear ? formatDate(from, "YYYY") + " - " + formatDate(to, "YYYY") : formatDate(from, "YYYY");
}
