import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { first, map, switchMap } from "rxjs/operators";

import { UmbracoHttpClient } from "../http";
import { DffMailInternal } from "./dff-mail.model";

@Injectable({ providedIn: "root" })
export class EforsyningMailSenderService {
    constructor(private readonly umbracoHttp: UmbracoHttpClient) {}

    /* public sendMail(mail: DffMail | Observable<DffMail>): Promise<void> {
        const mail$ = mail instanceof Observable ? mail : of(mail);
        return mail$
            .pipe(
                first(),
                switchMap((m) =>
                    !m.to
                        ? of(null)
                        : this.umbracoHttp.post("/umbraco/dff/dffapi/SendMail", {
                              to: m.to,
                              cc: m.cc,
                              bcc: m.bcc,
                              subject: m.subject,
                              body: m.body,
                              isHtml: true
                          })
                ),
                map(() => null)
            )
            .toPromise();
    }*/

    public sendForsyningsanmodningMail(mail: DffMailInternal | Observable<DffMailInternal>): Promise<void> {
        const mail$ = mail instanceof Observable ? mail : of(mail);
        return mail$
            .pipe(
                first(),
                switchMap((m) =>
                    !m.to
                        ? of(null)
                        : this.umbracoHttp.post("/umbraco/dff/dffapi/SendForsyningsanmodningMail", {
                              sideId: m.sideId,
                              to: m.to,
                              model: m.model
                          })
                ),
                map(() => null)
            )
            .toPromise();
    }

    public sendMitforbrugDatafejlMail(mail: DffMailInternal | Observable<DffMailInternal>): Promise<void> {
        const mail$ = mail instanceof Observable ? mail : of(mail);
        return mail$
            .pipe(
                first(),
                switchMap((m) =>
                    !m.to
                        ? of(null)
                        : this.umbracoHttp.post("/umbraco/dff/dffapi/SendMitforbrugDatafejlMail", {
                              sideId: m.sideId,
                              to: m.to,
                              model: m.model
                          })
                ),
                map(() => null)
            )
            .toPromise();
    }

    public sendMailFraUmbraco(api: string, mail: DffMailInternal | Observable<DffMailInternal>): Promise<void> {
        const mail$ = mail instanceof Observable ? mail : of(mail);
        return mail$
            .pipe(
                first(),
                switchMap((m) =>
                    !m.to
                        ? of(null)
                        : this.umbracoHttp.post("/umbraco/dff/dffapi/" + api, {
                              sideId: m.sideId,
                              to: m.to,
                              model: m.model
                          })
                ),
                map(() => null)
            )
            .toPromise();
    }
}
