import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { DffButtonPanelComponent } from "./dff-button-panel/dff-button-panel.component";
import { DffSpinnerButtonComponent } from "./dff-spinner-button/dff-spinner-button.component";

@NgModule({
    imports: [CommonModule, MatButtonModule, MatProgressSpinnerModule],
    exports: [DffButtonPanelComponent, DffSpinnerButtonComponent],
    declarations: [DffButtonPanelComponent, DffSpinnerButtonComponent],
    providers: []
})
export class DffButtonModule {}
