import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from "@angular/core";

import { Observable } from "rxjs";

import { UnsubscribeOnDestroy } from "@dffedb/util";

import { DffedbCookieConsentOverlaySandbox } from "./dffedb-cookie-consent-overlay.sandbox";
import { DffedbCookieConsentOverlayViewModel } from "./dffedb-cookie-consent-overlay.view-model";

@Component({
    templateUrl: "./dffedb-cookie-consent-overlay.component.html",
    styleUrls: ["./dffedb-cookie-consent-overlay.component.scss"],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class DffedbCookieConsentOverlayComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() public visible: boolean | string = true;
    @Input() public pageReload: boolean | string = true;
    @HostBinding("class.initialized") public classInitialized = true; // Bruges i CSS til at skjule boksen indtil den er initialiseret

    public vm$: Observable<DffedbCookieConsentOverlayViewModel>;

    constructor(private readonly sandbox: DffedbCookieConsentOverlaySandbox) {
        super();
    }

    public ngOnInit(): void {
        this.vm$ = this.sandbox.selectViewModel(this.visible);
        this.sandbox.setCookieConsentChangeRequiresPageReload(this.pageReload);
    }
}
