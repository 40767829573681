import { Component, Input } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";

import { DffErrorStateMatcher, IFormArray } from "@dffedb/forms";
import { EjendomsfaktorDefinition } from "@e-forsyning/common/ejendomsfaktor/model/ejendomsfaktor-definition.model";
import { Ejendomsfaktor } from "@e-forsyning/common/ejendomsfaktor/model/ejendomsfaktor.model";

@Component({
    selector: "tilslutningsanmodning-step-ejendomsfaktorer",
    templateUrl: "./step-ejendomsfaktorer.component.html",
    styleUrls: ["./step-ejendomsfaktorer.component.scss"],
    providers: [{ provide: ErrorStateMatcher, useClass: DffErrorStateMatcher }]
})
export class TilslutningsanmodningStepEjendomsfaktorerComponent {
    @Input() public ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[];
    @Input() public formArray: IFormArray<Ejendomsfaktor>;
}
