import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";

export function scrollIntoView(element: Element): void {
    scrollIntoViewIfNeeded(element, {
        behavior: "smooth",
        block: "end",
        inline: "nearest",
        scrollMode: "if-needed"
    });
}
