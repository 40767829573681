import { animate, keyframes, style, transition, trigger } from "@angular/animations";
import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "empty-page-badge",
    templateUrl: "./empty-page-badge.component.html",
    styleUrls: ["./empty-page-badge.component.scss"],
    animations: [
        trigger("slideInFromTop", [
            transition(":enter", [
                animate(
                    "200ms ease-in",
                    keyframes([
                        style({ opacity: 0, transform: "scale(1)", offset: 0 }),
                        style({ opacity: 0.5, transform: "scale(1.05)", offset: 0.5 }),
                        style({ opacity: 1, transform: "scale(1)", offset: 1 })
                    ])
                )
            ])
        ])
    ]
})
export class EmptyPageBadgeComponent {
    @Input() public overskrift = "";
    @Input() public iconClass = "";
    @Input() public matIcon = "";
    @Input() public svgIcon = "";
    @HostBinding("@slideInFromTop") public slideInFromTop = true;
    @HostBinding("class.empty-page-badge") public cssClass = true;
}
