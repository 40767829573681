import { Component, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
    selector: "accordion-status-indicator",
    templateUrl: "./accordion-status-indicator.component.html",
    styleUrls: ["./accordion-status-indicator.component.scss"]
})
export class AccordionStatusIndicatorComponent {
    @Input() public formGroup: UntypedFormGroup;

    public get status(): "unknown" | "invalid" | "valid" | "pristine" {
        if (!this.formGroup) {
            return "unknown";
        }
        if (this.formGroup.invalid) {
            return "invalid";
        }
        if (this.formGroup.dirty) {
            return "valid";
        }
        return "pristine";
    }
}
