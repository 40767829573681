import { Injectable } from "@angular/core";

import { dbToInt, dbToString, intToDb, stringToDb } from "@dffedb/util";
import { Adresse, AdresseMapper } from "@e-forsyning/common/adresse";

import { EBoksTilmelding } from "../model/e-boks-tilmelding.model";

@Injectable({ providedIn: "root" })
export class EBoksTilmeldingMapper {
    constructor(private readonly adresseMapper: AdresseMapper) {}

    public dtoToEBoksTilmeldinger(data: any): EBoksTilmelding[] {
        data = data || [];
        const result = data.map((d: any) => this.dtoToEBoksTilmelding(d));
        return result;
    }

    public dtoToEBoksTilmelding(data: any): EBoksTilmelding {
        data = data || {};
        const result: EBoksTilmelding = {
            id: dbToInt(data.id),
            cprNummer: dbToString(data.cprNummer),
            cvrNummer: dbToString(data.cvrNummer),
            pNummer: dbToString(data.pNummer),
            samtykkeTekst: dbToString(data.samtykkeTekst)
        };
        return result;
    }

    public dtoToAdresse(data: any): Adresse {
        return this.adresseMapper.dtoToAdresse(data);
    }

    public eboksTilmeldingToDto(tilmelding: EBoksTilmelding): any {
        // Fjern linjeskift, da de ikke spiller så godt sammen med JSON
        const samtykkeTekst = (tilmelding.samtykkeTekst || "").replace(/\n+/g, "");
        const result = {
            id: intToDb(tilmelding.id),
            cprNummer: stringToDb(tilmelding.cprNummer),
            cvrNummer: stringToDb(tilmelding.cvrNummer),
            pNummer: stringToDb(tilmelding.pNummer),
            samtykkeTekst: stringToDb(samtykkeTekst)
        };
        return result;
    }
}
