import { combineLatest, Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";

import { ObserverBase } from "./observer-base.service";

/**
 * ObserverBasedService er en basisklasse, som bruges til at generere services, der nedarver fra ObserverBase<TSource, TResult>.
 * F.eks. kan vi have en AktuelForbrugerService, som lytter til AktuelForbrugerObserver.
 * Man benytter XyzService, hvis man skal have den aktuelle værdi.
 * Man benytter XyzObserver, hvis man har behov for at lytte til løbende ændringer af Xyz.
 *
 * Observers lytter ofte til SessionStorage (ny forbruger valgt) og loader data når datagrundlaget kræver det.
 * Load af data kan tage tid, så vi risikerer at starte med data fra det gamle valg et par sekunder inden nye data er loadet.
 * Ved brug af en XyzService, som arver fra ObserverBasedService<TResult>, undgår man selv at skulle styre hvornår data er loadet.
 */
export abstract class ObserverBasedService<TResult> {
    constructor(private readonly observer: ObserverBase<unknown, TResult>) {}

    public select(): Observable<TResult> {
        return combineLatest([this.observer.valueChanges, this.observer.stateChanges, this.observer.errorChanges]).pipe(
            filter(([_, state]) => !state.loading),
            map(([value, __, error]) => {
                if (error) {
                    throw error;
                }
                return value;
            }),
            first()
        );
    }
}
