import { Injectable } from "@angular/core";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { EntitetStatus } from "@dffedb/util";
import { Ejendomsfaktor, EjendomsfaktorDefinition } from "@e-forsyning/common/ejendomsfaktor";
import { ForsyningPaaAdresseLeveringStatus, ForsyningPaaAdresseResult } from "@e-forsyning/common/forsyning-paa-adresse";

import { TilslutningsanmodningViewModel } from "../model/tilslutningsanmodning.view-model";
import { TilslutningsanmodningMailService } from "./tilslutningsanmodning-mail.service";
import { TilslutningsanmodningFormBuilder } from "./tilslutningsanmodning.form-builder";
import { TilslutningsanmodningRepository } from "./tilslutningsanmodning.repository";

@Injectable({ providedIn: "root" })
export class TilslutningsanmodningSandbox {
    private readonly forsyningPaaAdresseResult$ = new BehaviorSubject<ForsyningPaaAdresseResult>({
        ejendomNr: null,
        status: ForsyningPaaAdresseLeveringStatus.Arbejder,
        statusTekst: null,
        tekst: null
    });

    constructor(public readonly repository: TilslutningsanmodningRepository, public readonly formBuilder: TilslutningsanmodningFormBuilder, private readonly mailService: TilslutningsanmodningMailService) {}

    public selectViewModel(nodeId: number): Observable<TilslutningsanmodningViewModel> {
        const konfiguration$ = this.repository.selectKonfiguration(nodeId);
        return konfiguration$.pipe(
            switchMap((konfiguration) =>
                combineLatest([this.forsyningPaaAdresseResult$, this.repository.hentEjendomsfaktorDefinitioner(konfiguration.eforsyning.nodeId)]).pipe(
                    map(([forsyningPaaAdresseResult, ejendomsfaktorDefinitioner]) => {
                        const ejendomsfaktorer = this.mapEjendomsfaktorDefinitionerToEjendomsfaktorer(ejendomsfaktorDefinitioner);
                        const formGroup = this.formBuilder.opretFormGroup(konfiguration, ejendomsfaktorer, ejendomsfaktorDefinitioner);
                        konfiguration.widgetNodeId = String(nodeId);
                        return {
                            konfiguration,
                            ejendomsfaktorDefinitioner,
                            formGroup,
                            forsyningPaaAdresseResult
                        };
                    })
                )
            )
        );
    }

    public setForsyningPaaAdresseResult(value: ForsyningPaaAdresseResult): void {
        this.forsyningPaaAdresseResult$.next(value);
    }

    public async submit(vm: TilslutningsanmodningViewModel): Promise<void> {
        const model = this.formBuilder.formGroupToTilslutningsanmodningModel(vm.formGroup);
        await this.repository.submit(vm.konfiguration.eforsyning.nodeId, vm.forsyningPaaAdresseResult.ejendomNr, model);
        //await
        await this.mailService.sendForsyningsanmodningMail(vm.konfiguration.widgetNodeId, vm.konfiguration, vm.ejendomsfaktorDefinitioner, vm.forsyningPaaAdresseResult.tekst, model);
    }

    private mapEjendomsfaktorDefinitionerToEjendomsfaktorer(ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]): Ejendomsfaktor[] {
        return ejendomsfaktorDefinitioner.map((e) => ({
            definitionId: e.id,
            id: null,
            status: EntitetStatus.Uaendret,
            vaerdi: {
                oprindelig: null,
                aktuel: null
            }
        }));
    }
}
