import { Component, HostBinding, Input, OnInit } from "@angular/core";

import { WebComponentInitializerService } from "../shared/web-component-initializer.service";

@Component({
    templateUrl: "./dffedb-forsyning-paa-adresse.component.html",
    styleUrls: ["./dffedb-forsyning-paa-adresse.component.scss"]
})
export class DffedbForsyningPaaAdresseComponent implements OnInit {
    @HostBinding("class.mat-app-background") public classMatAppBackground = true;
    @Input() public nodeId: number;

    constructor(private readonly webComponentInitializer: WebComponentInitializerService) {}

    public ngOnInit(): void {
        this.webComponentInitializer.init(this.nodeId);
    }
}
