import { AppserverCredentials } from "./appserver-credentials.model";

export function serialiserAppserverCredentials(credentials: AppserverCredentials): string {
    return encodeURIComponent(btoa(JSON.stringify(credentials)));
}

export function deserialiserAppserverCredentials(value: string): AppserverCredentials {
    return JSON.parse(atob(decodeURIComponent(value)));
}

export function opretTomAppserverCredentials(): AppserverCredentials {
    return { forsyningNodeId: 0, forsyningId: "", cryptId: "" };
}
