import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, NgZone } from "@angular/core";
import { MatSidenavContent } from "@angular/material/sidenav";

import { fromEvent, Observable, Subject, Subscription } from "rxjs";
import { map } from "rxjs/operators";

export interface AppShellScrollEvent {
    scrollTop: number;
    clientHeight: number;
    scrollBottom: number;
    scrollHeight: number;
}

@Injectable({ providedIn: "root" })
export class AppShellScrollService {
    private subscription: Subscription;
    private readonly subject = new Subject<AppShellScrollEvent>();

    constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly zone: NgZone) {
        this.init(null);
    }

    public select(): Observable<AppShellScrollEvent> {
        return this.subject.asObservable();
    }

    public init(matSidenavContent: MatSidenavContent): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
        if (matSidenavContent) {
            this.subscription = matSidenavContent
                .elementScrolled()
                .pipe(
                    map(() => matSidenavContent.getElementRef().nativeElement),
                    map(element => ({
                        scrollTop: element.scrollTop,
                        clientHeight: element.clientHeight,
                        scrollBottom: element.clientHeight + element.scrollTop,
                        scrollHeight: element.scrollHeight
                    }))
                )
                .subscribe(e => this.zone.run(() => this.subject.next(e)));
        } else {
            this.subscription = fromEvent(this.document, "scroll")
                .pipe(
                    map(() => ({
                        scrollTop: window.scrollY,
                        clientHeight: window.innerHeight,
                        scrollBottom: window.innerHeight + window.scrollY,
                        scrollHeight: this.document.documentElement.scrollHeight
                    }))
                )
                .subscribe(e => this.subject.next(e));
        }
    }
}
