import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";

import { combineLatest, Observable } from "rxjs";
import { first, map, shareReplay, startWith, switchMap } from "rxjs/operators";

import { LogService } from "@e-forsyning/common/log";

import { PrisberegnerFormModel } from "./model/prisberegner-form.model";
import { PrisberegnerKonfiguration, PrisberegnerVarmekilde } from "./model/prisberegner-konfiguration.model";
import { prisberegnerVarmekilder } from "./model/prisberegner-varmekilder.model";
import { PrisberegnerStateViewModel, PrisberegnerViewModel } from "./model/prisberegner.view-model";
import { PrisberegnerViewModelMapper } from "./services/prisberegner-view-model.mapper";
import { PrisberegnerService } from "./services/prisberegner.service";

@Injectable({ providedIn: "root" })
export class DffedbPrisberegnerSandbox {
    // Defineres her, så ændringer beholdes selvom beregningsgrundlaget ændrer sig.
    // Hvis vi flyttede det ned i 'selectViewModel', ville værdierne blive resat hver gang beregningsgrundlaget ændrer sig.
    public _state: PrisberegnerStateViewModel = {
        visDetaljerFjernvarme: false,
        visDetaljerSammenligningskilde: false,
        oenskerFordelingAfKonverteringsbidrag: false
    };
    constructor(
        private readonly service: PrisberegnerService,
        private readonly viewModelMapper: PrisberegnerViewModelMapper,
        private readonly logger: LogService,
        private readonly sanitizer: DomSanitizer
    ) {}

    public selectViewModel(nodeId: number): Observable<PrisberegnerViewModel> {
        const konfiguration$ = this.service.selectKonfiguration(nodeId).pipe(shareReplay(1));

        const formGroup$ = konfiguration$.pipe(
            map((konfiguration) => this.createFormGroup(konfiguration)),
            shareReplay(1)
        );
        const model$ = formGroup$.pipe(
            switchMap((formGroup) => formGroup.valueChanges.pipe(startWith(formGroup.value))),
            map((model) => model as PrisberegnerFormModel)
        );

        const beregnetResultat$ = combineLatest([model$, konfiguration$]).pipe(
            map(([model, konfiguration]) =>
                this.viewModelMapper.modelToViewModel(konfiguration, model, this.service.beregnForbrugPrAar(model, konfiguration))
            )
        );

        return combineLatest([beregnetResultat$, konfiguration$, model$, formGroup$]).pipe(
            map(([resultat, konfiguration, model, formGroup]) => ({
                varmekilder: konfiguration.varmekilder,
                model,
                formGroup,
                varmekildeTekst: this.getVarmekildeTekst(konfiguration.varmekilder, formGroup.get("varmekilde").value),
                konfiguration,
                konfigurationOk: this.beregnOmKonfigurationErOk(konfiguration),
                resultat,
                state: this._state
            }))
        );
    }

    private getVarmekildeTekst(varmekilder: PrisberegnerVarmekilde[], valgtVarmekildeId: number): string {
        return varmekilder.find((k) => k.id === valgtVarmekildeId)?.navn;
    }

    private beregnOmKonfigurationErOk(konfiguration: PrisberegnerKonfiguration): boolean {
        const fjernvarmeKonfigurationOk = !!konfiguration.fjernvarme.prisPrMwh;
        const naturgasKonfigurationOk =
            !konfiguration.varmekilder.find((k) => k.id === prisberegnerVarmekilder.naturgas.id) ||
            (!!konfiguration.naturgas.braendvaerdiKwhPrM3 && !!konfiguration.naturgas.virkningsgrad);
        const olieKonfigurationOk =
            !konfiguration.varmekilder.find((k) => k.id === prisberegnerVarmekilder.olie.id) ||
            (!!konfiguration.olie.braendvaerdiKwhPrLiter && !!konfiguration.olie.virkningsgrad);
        const traepillerKonfigurationOk =
            !konfiguration.varmekilder.find((k) => k.id === prisberegnerVarmekilder.traepiller.id) ||
            (!!konfiguration.traepiller.braendvaerdiKwhPrKg && !!konfiguration.traepiller.virkningsgrad);
        return fjernvarmeKonfigurationOk && naturgasKonfigurationOk && olieKonfigurationOk && traepillerKonfigurationOk;
    }

    private createFormGroup(konfiguration: PrisberegnerKonfiguration): UntypedFormGroup {
        const defaultVarmekilde = konfiguration.varmekilder[0];
        const oenskerVarmemesterOrdning = konfiguration.fjernvarme.fjernvarmeVarmemesterordningStandardVaerdi;
        const oenskerFordelingAfKonverteringsbidrag = false; //!!konfiguration.fjernvarme.konverteringsbidrag;
        const defaultKonverteringbidragAntalAar =
            konfiguration.fjernvarme.konverteringsbidragAntalAar[konfiguration.fjernvarme.konverteringsbidragAntalAar.length - 1];
        const result = new UntypedFormGroup({
            boligareal: new UntypedFormControl(0),
            varmekilde: new UntypedFormControl(defaultVarmekilde && defaultVarmekilde.id),
            forbrugEl: new UntypedFormControl(0),
            forbrugNaturgas: new UntypedFormControl(0),
            forbrugOlie: new UntypedFormControl(0),
            forbrugTraepiller: new UntypedFormControl(0),
            forbrugVarmepumpe: new UntypedFormControl(0),
            naturgasKeddelAlder: new UntypedFormControl(null),
            olieFyrAlder: new UntypedFormControl(null),
            pillefyrAlder: new UntypedFormControl(null),
            oenskerVarmemesterOrdning: new UntypedFormControl(oenskerVarmemesterOrdning),
            oenskerFordelingAfKonverteringsbidrag: new UntypedFormControl(oenskerFordelingAfKonverteringsbidrag),
            konverteringbidragAntalAar: new UntypedFormControl(defaultKonverteringbidragAntalAar)
        });
        this.setupLogging(result);
        return result;
    }

    private setupLogging(formGroup: UntypedFormGroup): void {
        /* eslint-disable @typescript-eslint/naming-convention */
        this.logger.log({ Besked: "Prisberegner loadet" });
        formGroup.statusChanges.pipe(first()).subscribe(() => this.logger.log({ Besked: "Prisberegner benyttet" }));
        /* eslint-enable @typescript-eslint/naming-convention */
    }
}
