import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSliderModule } from "@angular/material/slider";

import { DffMatSliderWrapperComponent } from "./dff-mat-slider-wrapper.component";

@NgModule({
    imports: [CommonModule, MatSliderModule],
    declarations: [DffMatSliderWrapperComponent],
    exports: [DffMatSliderWrapperComponent]
})
export class DffMatSliderWrapperModule {}
