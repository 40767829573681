import { Component, Input } from "@angular/core";

@Component({
    templateUrl: "./dffedb-info-popup.component.html",
    styleUrls: ["./dffedb-info-popup.component.scss"]
})
export class DffedbInfoPopupComponent {
    @Input() public header: string;
    @Input() public content: string;
    @Input() public imageSrc: string;
    @Input() public buttonText: string;
    @Input() public buttonHref: string;
    @Input() public buttonTarget: string;
    @Input() public showAfterMs = 2000;
    @Input() public hideAfterMs = 0;
    @Input() public visibleStartTime: number;
    @Input() public visibleEndTime: number;

    public toDateTime(value: number): Date {
        // Ved værdien '0' genererer vi en invalid dato for at indikere 'ikke udfyldt'
        return +value === 0 ? new Date("") : new Date(+value);
    }
}
