import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ControlsOf } from "@dffedb/forms";

import { EBoksTilmeldingIdentitetModel } from "../../../shared/e-boks-tilmelding.form-builder";

@Component({
    selector: "step-identitet",
    templateUrl: "./step-identitet.component.html",
    styleUrls: ["./step-identitet.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepIdentitetComponent {
    @Input()
    public formGroup: FormGroup<ControlsOf<EBoksTilmeldingIdentitetModel>>;

    @Input()
    public revealOnFocus: any;

    public get tabDisabled(): boolean {
        return this.formGroup.get("type").disabled;
    }

    public get title(): string {
        return this.selectedIndex === 0 ? "Indtast dit CPR-nummer" : "Indtast CVR- og eventuelt P-nummer";
    }

    private get selectedIndex(): number {
        return this.formGroup.get("type").value;
    }

    public onSelectedTabChange(tabIndex: number): void {
        this.formGroup.get("type").setValue(tabIndex);
    }

    public skalGentagCprNummerFejlVises(): boolean {
        if (this.formGroup.get("person.cprNummer").invalid) {
            return false;
        }
        const value = this.formGroup.get("person.cprNummerGentag").value;
        const errors = this.formGroup.get("person.cprNummerGentag").errors;
        return value && value.length === 10 && errors && !!errors.mustMatch;
    }
}
