import { getUrlQuery as baseGetUrlQuery, getUrlOrigin } from "@dffedb/util";

export function getUrlQuery(): any {
    return baseGetUrlQuery(getMainScriptUrl());
}

export function getNgZone(): "noop" | "zone.js" {
    const query = getUrlQuery();
    return query.ngzone === "noop" ? "noop" : "zone.js";
}

export function getUmbracoBaseUrl(): string {
    return getUrlOrigin(getMainScriptUrl());
}

function getMainScriptUrl(): string {
    const pattern = /\/dist\/web-components\/v\d+\/main.*\.js/;
    return (
        Array.from(document.scripts)
            .map((s) => s.src)
            .find((s) => !!s.match(pattern)) ?? ""
    );
}
