import { Component, ElementRef, ViewChild } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { EBoksPopupCloseArgs, EBoksPopupRef } from "../e-boks-popup-ref";

@Component({
    selector: "e-boks-popup-content",
    templateUrl: "./e-boks-popup-content.component.html",
    styleUrls: ["./e-boks-popup-content.component.scss"]
})
export class EBoksPopupContentComponent {
    public firmaNavn$: Observable<string>;
    public step = 1;
    public minHeight = 0;

    @ViewChild("step1")
    public step1: ElementRef;

    constructor(private readonly popupRef: EBoksPopupRef, private readonly indstillinger: UmbracoIndstillingerObserver) {
        this.firmaNavn$ = this.indstillinger.valueChanges.pipe(map((i) => i.aktuelEforsyning.firmaInfo.firmaNavn));
    }

    public onNext(): void {
        if (this.step === 1) {
            // Vi vil gerne have step2 til at starte med samme højde som step1 for at undgå 'hoppe-effekt'.
            this.minHeight = this.step1.nativeElement.offsetHeight;
        }
        this.step++;
    }

    public onLukOgSpoergMigIkkeIgen(): void {
        this.onLuk({ spoergMigIkkeIgen: true });
    }

    public onLuk(args: EBoksPopupCloseArgs = { spoergMigIkkeIgen: false }): void {
        this.popupRef.close(args);
    }
}
