import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";

import { defineCustomElements } from "@dffedb/util";

import { DffCookieConsentOverlayModule } from "../shared/dff-cookie-consent-overlay";
import { DffedbCookieConsentOverlayComponent } from "./dffedb-cookie-consent-overlay.component";

@NgModule({
    imports: [CommonModule, DffCookieConsentOverlayModule],
    exports: [DffedbCookieConsentOverlayComponent],
    declarations: [DffedbCookieConsentOverlayComponent],
    providers: []
})
export class DffedbCookieConsentOverlayModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-cookie-consent-overlay", DffedbCookieConsentOverlayComponent, { injector });
    }
}
