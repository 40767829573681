import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { CorrelationIdService, DffError, SessionIdService } from "@dffedb/util";

import { LogService } from "../log/log.service";

@Injectable()
export class EforsyningHttpInterceptor implements HttpInterceptor {
    constructor(
        private readonly sessionIdService: SessionIdService,
        private readonly correlationIdService: CorrelationIdService,
        private readonly logService: LogService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const startTime = new Date();
        const sessionId = this.sessionIdService.hentId();
        const correlationId = this.correlationIdService.genererId();

        request = request.clone({
            setHeaders: {
                /* eslint-disable @typescript-eslint/naming-convention */
                "X-Session-ID": sessionId,
                "X-Correlation-ID": correlationId
                /* eslint-enable @typescript-eslint/naming-convention */
            }
        });

        this.safe(() => this.logStart(correlationId, request));
        return next.handle(request).pipe(
            tap((response) => {
                this.safe(() => this.logEnd(correlationId, startTime, request, response));
            }),
            catchError((error) => {
                this.safe(() => this.logError(correlationId, startTime, request, error));
                return throwError(new DffError(error.message, { innerError: error, correlationId }));
            })
        );
    }

    private safe(fn: () => void): void {
        try {
            fn();
        } catch (error) {
            console.error("DffHttpInterceptor:", error);
        }
    }

    private logStart(correlationId: string, request: HttpRequest<any>): void {
        this.logService.logHttpStart(correlationId, request);
    }

    private logEnd(correlationId: string, startTime: Date, request: HttpRequest<any>, response: HttpEvent<any>): void {
        if (response instanceof HttpResponse) {
            const duration = new Date().getTime() - startTime.getTime();
            this.logService.logHttpEnd(correlationId, duration, request, response);
        }
    }

    private logError(correlationId: string, startTime: Date, request: HttpRequest<any>, error: any): void {
        const duration = new Date().getTime() - startTime.getTime();
        this.logService.logHttpError(correlationId, duration, request, error);
    }
}
