import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";

import { defineCustomElements } from "@dffedb/util";

import { DffCookieConsentOverlayModule } from "../shared/dff-cookie-consent-overlay";
import { DffedbIframeComponent } from "./dffedb-iframe.component";

@NgModule({
    imports: [CommonModule, DffCookieConsentOverlayModule],
    exports: [DffedbIframeComponent],
    declarations: [DffedbIframeComponent],
    providers: []
})
export class DffedbIframeModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-iframe", DffedbIframeComponent, { injector });
    }
}
