import { Component, Input } from "@angular/core";

import { PrisberegnerResultatDetaljelinjeViewModel } from "../model/prisberegner.view-model";

@Component({
    selector: "prisberegner-specifikation",
    templateUrl: "./prisberegner-specifikation.component.html",
    styleUrls: ["./prisberegner-specifikation.component.scss"]
})
export class PrisberegnerSpecifikationComponent {
    @Input()
    public linjer: PrisberegnerResultatDetaljelinjeViewModel[];
}
