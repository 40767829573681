import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "dff-info",
    templateUrl: "./dff-info.component.html",
    styleUrls: ["./dff-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DffInfoComponent {
    public type: "success" | "error" | "warning";
}
