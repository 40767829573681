import { Injectable } from "@angular/core";
import { NavigationExtras, Router, UrlTree } from "@angular/router";

@Injectable({ providedIn: "root" })
export class DffRedirectService {
    constructor(private readonly router: Router) {}

    public redirect(url: string, params?: any): void {
        this.router.navigateByUrl(this.genererUrl(url, { queryParams: params }));
    }

    public replace(url: string): void {
        this.router.navigateByUrl(this.genererUrl(url, { replaceUrl: true }));
    }

    private genererUrl(url: string, navigationExtras?: NavigationExtras): UrlTree {
        return this.router.createUrlTree([url], navigationExtras);
    }
}
