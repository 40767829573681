import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DffCookieConsentService } from "../shared/dff-cookie-consent";
import { DffedbFancyboxViewModel } from "./dffedb-fancybox.view-model";

@Injectable({ providedIn: "root" })
export class DffedbFancyboxSandbox {
    constructor(private readonly cookieConsentService: DffCookieConsentService) {}

    public getViewModel(requiredConsent: string): Observable<DffedbFancyboxViewModel> {
        return this.cookieConsentService.selectCookieConsent().pipe(map(c => ({ consentAccepted: !!c.consent[requiredConsent] })));
    }
}
