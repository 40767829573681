<dff-info-popup
    [header]="header"
    [content]="content"
    [imageSrc]="imageSrc"
    [buttonText]="buttonText"
    [buttonHref]="buttonHref"
    [buttonTarget]="buttonTarget"
    [showAfterMs]="showAfterMs"
    [hideAfterMs]="hideAfterMs"
    [visibleStartTime]="toDateTime(visibleStartTime)"
    [visibleEndTime]="toDateTime(visibleEndTime)"
></dff-info-popup>
