import { AbstractControl, Validators } from "@angular/forms";

export const emailRegexp = /^[_a-zA-Z0-9ÆØÅæøå-]+(\.[_a-zA-Z0-9ÆØÅæøå-]+)*@[a-zA-Z0-9ÆØÅæøå-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$/;

export function erstatEmailValidatorMedDffEdbUdgave(): void {
    Validators.email = emailValidator;
}

// Hugget fra @angular/forms og erstattet med vores egen e-mail-validering
// Angulars validering accepterer mail-adresser uden punktum, f.eks.: "info@softvaerket"
export function emailValidator(control: AbstractControl): any {
    if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
    }
    return emailRegexp.test(control.value) ? null : { email: true };
}

function isEmptyInputValue(value: string): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}
