import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { mdiCookie } from "@mdi/js";

import { defineCustomElements, SvgIconRegistry } from "@dffedb/util";

import { DffCookieConsentDialogModule } from "../shared/dff-cookie-consent-dialog";
import { DffedbCookieConsentComponent } from "./dffedb-cookie-consent.component";

@NgModule({
    imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatProgressSpinnerModule, DffCookieConsentDialogModule],
    exports: [DffedbCookieConsentComponent],
    declarations: [DffedbCookieConsentComponent],
    providers: []
})
export class DffedbCookieConsentModule {
    constructor(injector: Injector, svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiCookie, "dffedb-cookie-consent.cookie");
        defineCustomElements("dffedb-cookie-consent", DffedbCookieConsentComponent, { injector });
    }
}
