import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { DffUploadProgressOverlayComponent } from "./dff-upload-progress-overlay.component";

@NgModule({
    imports: [CommonModule, MatProgressSpinnerModule],
    exports: [DffUploadProgressOverlayComponent],
    declarations: [DffUploadProgressOverlayComponent]
})
export class DffUploadProgressOverlayModule {}
