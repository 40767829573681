import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";

import { PanelComponent } from "./panel.component";

@NgModule({
    imports: [CommonModule, MatCardModule],
    exports: [PanelComponent],
    declarations: [PanelComponent],
    providers: []
})
export class PanelModule {}
