import { Injectable } from "@angular/core";

import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger";

@Injectable({ providedIn: "root" })
export class EforsyningUrlService {
    constructor(private readonly indstillingerObserver: UmbracoIndstillingerObserver) {}

    public async hentAbsolutUrl(side: string): Promise<string> {
        const indstillinger = await this.indstillingerObserver.getValue();
        return indstillinger.aktuelEforsyning.siteUrl + "#/" + side;
    }
}
