import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

import { Observable } from "rxjs";

import { WebComponentInitializerService } from "../shared/web-component-initializer.service";
import { DffedbPrisberegnerSandbox } from "./dffedb-prisberegner.sandbox";
import { PrisberegnerResultatDetaljelinjeViewModel, PrisberegnerViewModel } from "./model/prisberegner.view-model";

@Component({
    templateUrl: "./dffedb-prisberegner.component.html",
    styleUrls: ["./dffedb-prisberegner.component.scss"]
})
export class DffedbPrisberegnerComponent implements OnInit {
    @HostBinding("class.mat-app-background") public classMatAppBackground = true;
    @Input() public nodeId: number;

    public vm$: Observable<PrisberegnerViewModel>;

    constructor(
        private readonly webComponentInitializer: WebComponentInitializerService,
        private readonly sandbox: DffedbPrisberegnerSandbox
    ) {}

    public toggle(event: MatSlideToggleChange): void {
        //console.log("toggle", event.checked);
        this.sandbox._state.oenskerFordelingAfKonverteringsbidrag = event.checked;
        // this.useDefault = event.checked;
    }

    public findKonverterinsbidrag(resultat: PrisberegnerResultatDetaljelinjeViewModel[], tekst: string): number {
        const res = resultat.find((e) => e.tekst === tekst);
        if (res === undefined || res === null || !res) return null;
        return res.beloeb;
    }

    public async ngOnInit(): Promise<void> {
        this.webComponentInitializer.init(+this.nodeId);
        this.vm$ = this.sandbox.selectViewModel(+this.nodeId);
    }

    public onFocus(event: FocusEvent): void {
        (event.target as any).select();
    }

    public getDetaljerText(erSynlig: boolean): string {
        return erSynlig ? "Skjul detaljer" : "Vis detaljer";
    }
}
