import { Injectable } from "@angular/core";

import { AppserverCredentialsRepositoryBase } from "./appserver-credentials-repository-base.service";
import { AppserverCredentialsRepository } from "./appserver-credentials-repository.service";

@Injectable({ providedIn: "root" })
export class AppserverInstallationCredentialsRepository extends AppserverCredentialsRepositoryBase {
    constructor(repository: AppserverCredentialsRepository) {
        super(repository, "installation");
    }
}
