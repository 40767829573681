import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { isObservable, Observable, of } from "rxjs";

import { Pattern } from "@dffedb/util";

import { DffFormControl, DffFormControlDropdownListOptions, DffFormControlType } from "./dff-form-control";

@Component({
    selector: "dff-form-control",
    templateUrl: "./dff-form-control.component.html",
    styleUrls: ["./dff-form-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DffFormControlComponent,
            multi: true
        }
    ]
})
export class DffFormControlComponent implements ControlValueAccessor, OnInit {
    public get controlType(): DffFormControlType {
        return (this.formControl && this.formControl.controlType) || DffFormControlType.TextInput;
    }

    public get placeholder(): string {
        return (this.formControl && this.formControl.placeholder) || "";
    }

    public get suffix(): string {
        return (this.formControl && this.formControl.suffix) || "";
    }

    public get hint(): string {
        return (this.formControl && this.formControl.hint) || "";
    }

    public get options$(): Observable<DffFormControlDropdownListOptions> {
        const result = (this.formControl && this.formControl.dropdownListOptions) || [];
        return isObservable(result) ? result : of(result);
    }

    public get hidden(): boolean {
        return this.formControl && this.formControl.hidden;
    }

    @Input() public formControl: DffFormControl;

    public formControlType: typeof DffFormControlType = DffFormControlType;

    public ngOnInit(): void {
        if (!this.formControl) {
            throw new Error("DffFormControlComponent: formControl forventer en FormControl-instans.");
        }
    }

    public getErrorText(formControl: DffFormControl): string {
        return (
            this.getRequiredErrorText(formControl.errors, formControl.controlType) ||
            this.getMaxLengthErrorText(formControl.errors) ||
            this.getDateErrorText(formControl.errors) ||
            this.getEmailErrorText(formControl.errors) ||
            this.getMinMaxErrorText(formControl.errors) ||
            this.getMaxDecimalsErrorText(formControl.errors) ||
            this.getPatternErrorText(formControl.errors)
        );
    }

    // Dummy ControlValueAccessor-metoder for at kunne have en [formControl]-property
    public writeValue(): void {}
    public registerOnChange(): void {}
    public registerOnTouched(): void {}

    private getRequiredErrorText(errors: any, formControlType: DffFormControlType): string {
        if (!errors.required) {
            return null;
        }

        switch (formControlType) {
            case DffFormControlType.DropdownList:
                return "Vælg en værdi";
            case DffFormControlType.Checkbox:
                return "Feltet skal afkrydses";
            case DffFormControlType.DateInput:
                return errors.matDatepickerParse ? null : "Indtast en dato";
            case DffFormControlType.TextInput:
            case DffFormControlType.NumberInput:
            default:
                return "Indtast en værdi";
        }
    }

    private getDateErrorText(errors: any): string {
        return errors.matDatepickerParse || errors.mindate || errors.maxdate ? "Indtast en gyldig dato" : null;
    }

    private getMaxLengthErrorText(errors: any): string {
        return errors.maxlength ? `Feltet må maksimalt indeholde ${errors.maxlength.requiredLength} tegn` : null;
    }

    private getEmailErrorText(errors: any): string {
        return errors.email ? "Indtast en gyldig e-mail" : null;
    }

    private getMinMaxErrorText(errors: any): string {
        return errors.min
            ? `Indtast en værdi på ${errors.min.min} eller derover`
            : errors.max
            ? `Indtast en værdi på ${errors.max.max} eller derunder`
            : null;
    }

    private getMaxDecimalsErrorText(errors: any): string {
        if (!errors.decimals) {
            return null;
        }

        const max = errors.decimals.max || 0;
        if (max === 0) {
            return "Indtast et heltal";
        }
        return `Indtast et tal med op til ${max} decimaler`;
    }

    private getPatternErrorText(errors: any): string {
        if (!errors.pattern) {
            return null;
        }

        switch (errors.pattern.requiredPattern) {
            case Pattern.helTal:
                return "Indtast et heltal";
            case Pattern.positivHelTal:
                return "Indtast et positivt heltal";
            case Pattern.kommaTal:
                return "Indtast et tal";
            case Pattern.positivKommaTal:
                return "Indtast et positivt tal";
            case Pattern.postNr:
                return "Indtast et gyldigt postummmer";
            case Pattern.registreringsNr:
                return "Indtast et gyldigt registreringsnummer";
            case Pattern.kontoNr:
                return "Indtast et gyldigt kontonummer";
            default:
                return "Indtast en gyldig værdi";
        }
    }
}
