import { Inject, Injectable } from "@angular/core";

import { ENV } from "../environment/environment.token";
import { LogentriesEnvironment } from "./logentries-environment.model";
import { Logger } from "./logger.interface";

@Injectable() // Injectes med @Inject(LOGGER)
export class LogentriesLoggerService implements Logger {
    private baseUrl = "https://webhook.logentries.com/noformat/logs/";

    constructor(@Inject(ENV) public environment: LogentriesEnvironment) {}

    public log(logFields: any): void {
        const hostname = window.location.hostname;
        if (hostname === "localhost" || hostname === "dev.eforsyning.dk") {
            return;
        }

        logFields = { ...logFields, Application: this.environment.logentries.application };
        const value = this.genererLogValue(logFields);
        this.post(value);
    }

    private genererLogValue(logFields: any): string {
        const result = Object.keys(logFields)
            .filter((key) => typeof logFields[key] !== "undefined")
            .map((key) => `${key}: ${this.formatValue(logFields[key])}`);
        // Indtil videre fjerner vi linjeskift, da Log Entries afslutter ved første linjeskift.
        return result.join(", ").replace(/\n/g, " ");
    }

    private formatValue(value: any): string {
        const validChars = /^[0-9a-zA-Z_\-. ]*$/g;
        const isValid = validChars.test(value);
        return isValid ? (value || "").toString() : `"${value}"`;
    }

    private post(value: any): void {
        const request = new XMLHttpRequest();
        request.open("POST", this.baseUrl + this.environment.logentries.token);
        request.send(value);
    }
}
