import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { from } from "rxjs";

import { Processing } from "@dffedb/rxjs";
import { DffSimpelDialog } from "@dffedb/ui";

import { EBoksTilmelding } from "../../../model/e-boks-tilmelding.model";
import { formatterCprNummer, formatterCvrNummer, formatterPNummer } from "../../../shared/e-boks-util";

@Component({
    selector: "step-eksisterende-tilmeldinger",
    templateUrl: "./step-eksisterende-tilmeldinger.component.html",
    styleUrls: ["./step-eksisterende-tilmeldinger.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepEksisterendeTilmeldingerComponent {
    @Input()
    public tilmeldinger: EBoksTilmelding[];

    @Output()
    public afmeld = new EventEmitter<EBoksTilmelding>();

    @Input()
    public afmeldStatus: Processing<EBoksTilmelding>;

    @Input()
    public disabled = false;

    constructor(private readonly dialog: DffSimpelDialog) {}

    public onAfmeld(event: Event, tilmelding: EBoksTilmelding): void {
        event.stopPropagation();
        from(this.dialog.openJaNej("Afmeld e-Boks", "Ønsker du at afmelde post via e-Boks?")).subscribe((result) => {
            if (result) {
                this.afmeld.emit(tilmelding);
            }
        });
    }

    public formatterCprNummer(tilmelding: EBoksTilmelding): string {
        return formatterCprNummer(tilmelding.cprNummer);
    }

    public formatterCvrNummer(tilmelding: EBoksTilmelding): string {
        return formatterCvrNummer(tilmelding.cvrNummer);
    }

    public formatterPNummer(tilmelding: EBoksTilmelding): string {
        return formatterPNummer(tilmelding.pNummer);
    }
}
