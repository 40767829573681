import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { mdiMagnify } from "@mdi/js";

import { MenuModule } from "@dffedb/ui";
import { SvgIconRegistry } from "@dffedb/util";

import { ForsyningwebSidenavMenuComponent } from "./forsyningweb-sidenav-menu.component";

@NgModule({
    declarations: [ForsyningwebSidenavMenuComponent],
    imports: [CommonModule, MenuModule],
    exports: [ForsyningwebSidenavMenuComponent]
})
export class ForsyningwebSidenavMenuModule {
    constructor(svgIconRegistry: SvgIconRegistry) {
        // Ikoner findes på: https://materialdesignicons.com/
        svgIconRegistry.registerIcon(mdiMagnify, "menu-search");
    }
}
