import { Injectable } from "@angular/core";

// ReSharper disable once InconsistentNaming
import * as Hammer from "hammerjs";

import { getOffsetLeft, getOffsetTop, getScrollBottom, getScrollLeft, getScrollRight, getScrollTop, getWidth } from "./dom-util";

type RedrawCallback = (scale: number) => Promise<void>;
interface CanvasZoomResult {
    zoomIn: () => void;
    zoomOut: () => void;
}

@Injectable({ providedIn: "root" })
export class CanvasZoomService {
    public setup(canvas: HTMLElement, redrawCallback: RedrawCallback): CanvasZoomResult {
        return hammerIt(canvas, redrawCallback);
    }
}

function hammerIt(elm: HTMLElement, redrawCallback: RedrawCallback): CanvasZoomResult {
    const maxScale = 2;
    const minScale = 1;
    const doubletapScaleFactor = 2;

    let realScale = 1;
    let pinchPosX = 0;
    let pinchPosY = 0;
    let pinchScale = 1;
    let pinchTranslateX = 0;
    let pinchTranslateY = 0;
    let currentDoubletapScaleFactor = 2;

    init();

    function init(): void {
        const hammertime = new Hammer(elm, { touchAction: "pan-x pan-y" });
        hammertime.get("pinch").set({
            enable: true
        });
        hammertime.on("doubletap pinch pinchstart pinchend", handleHammerEvent);
    }

    function zoomIn(): void {
        zoom(realScale * 2);
    }

    function zoomOut(): void {
        zoom(realScale / 2);
    }

    function zoom(scale: number): void {
        const pos = getWidth(elm) / realScale / 2;
        realZoom({ scale, posX: pos, posY: 0 }).then((result: any) => result);
    }

    function handleHammerEvent(ev: any): void {
        ev.preventDefault();

        if (ev.type === "pinchstart") {
            pinchPosX = ev.center.x - getOffsetLeft(elm);
            pinchPosY = ev.center.y - getOffsetTop(elm);
        }

        if (ev.type === "pinch") {
            const scale = adjustOverUnderScale(ev.scale * realScale) / realScale;

            const posX = pinchPosX + getScrollLeft(elm);
            const posY = pinchPosY + getScrollTop(elm);

            let translateX = ((posX + pinchTranslateX) * scale) / pinchScale - posX;
            let translateY = ((posY + pinchTranslateY) * scale) / pinchScale - posY;
            translateX = Math.max(-getScrollLeft(elm), Math.min(translateX, elm.clientWidth * (scale - 1) + getScrollRight(elm)));
            translateY = Math.max(-getScrollTop(elm), Math.min(translateY, elm.clientHeight * (scale - 1) + getScrollBottom(elm)));

            elm.style.transform = `translate3d(${-translateX}px,${-translateY}px, 0) scale3d(${scale}, ${scale}, 1)`;

            pinchTranslateX = translateX;
            pinchTranslateY = translateY;
            pinchScale = scale;
        }

        if (ev.type === "pinchend") {
            realZoom({ posX: pinchPosX, posY: pinchPosY, scale: realScale * pinchScale }).then(() => {
                pinchTranslateX = pinchTranslateY = 0;
                pinchScale = 1;
            });
        }

        if (ev.type === "doubletap") {
            currentDoubletapScaleFactor =
                realScale <= minScale
                    ? doubletapScaleFactor
                    : realScale >= maxScale
                    ? 1 / doubletapScaleFactor
                    : currentDoubletapScaleFactor;
            const scale = realScale * currentDoubletapScaleFactor;
            const posX = ev.center.x - getOffsetLeft(elm);
            const posY = ev.center.y - getOffsetTop(elm);
            realZoom({ posX, posY, scale });
        }
    }

    function realZoom(ev: any): any {
        const scale = adjustOverUnderScale(ev.scale);
        if (scale === realScale) {
            return new Promise<void>((resolve) => resolve());
        }

        const scrollLeft = ((ev.posX + getScrollLeft(elm)) * scale) / realScale - ev.posX;
        const scrollTop = ((ev.posY + getScrollTop(elm)) * scale) / realScale - ev.posY;

        return redrawCallback(scale).then(() => {
            elm.style.transform = "none";
            elm.parentElement.scrollLeft = scrollLeft;
            elm.parentElement.scrollTop = scrollTop;
            realScale = ev.scale;
        });
    }

    function adjustOverUnderScale(scale: number): number {
        return Math.max(minScale, Math.min(scale, maxScale));
    }

    return {
        zoomIn,
        zoomOut
    };
}
