export interface DffMailInternal {
    sideId: string;
    to: string;
    model: string;
}

export interface DffMail {
    to: string;
    cc?: string;
    bcc?: string;
    subject: string;
    body: string;
}

export interface DffMailFirmaoplysninger {
    firmaNavn: string;
    logoUrl: string;
    signatur: string;
}
