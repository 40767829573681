import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { addDays, formatDate } from "@dffedb/util";

@Component({
    selector: "dff-date-field",
    templateUrl: "./dff-date-field.component.html",
    styleUrls: ["./dff-date-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DffDateFieldComponent,
            multi: true
        }
    ]
})
export class DffDateFieldComponent implements ControlValueAccessor, OnInit {
    @Input() public formControl: UntypedFormControl;
    @Input() public placeholder: string;
    @Input() public required = false;
    @Input() public hint: string;
    @Input() public min: Date;
    @Input() public max: Date;
    @Input() public label: string;

    public ngOnInit(): void {
        if (!this.formControl) {
            throw new Error("DffDateFieldComponent: formControl forventer en FormControl-instans.");
        }
    }

    public getErrorText(formControl: UntypedFormControl): string {
        return (
            this.getRequiredErrorText(formControl.errors) ||
            this.getMinErrorText(formControl.errors) ||
            this.getMaxErrorText(formControl.errors) ||
            this.getAnyErrorText(formControl.errors)
        );
    }

    // Dummy ControlValueAccessor-metoder for at kunne have en [formControl]-property
    public writeValue(): void {}
    public registerOnChange(): void {}
    public registerOnTouched(): void {}

    private getRequiredErrorText(errors: any): string {
        if (!errors.required) {
            return null;
        }
        return errors.matDatepickerParse ? null : "Indtast en dato";
    }

    private getMinErrorText(errors: any): string {
        return errors.matDatepickerMin ? `Indtast en dato større end ${this.formatDate(addDays(errors.matDatepickerMin.min, -1))}` : null;
    }

    private getMaxErrorText(errors: any): string {
        return errors.matDatepickerMax ? `Indtast en dato mindre end ${this.formatDate(addDays(errors.matDatepickerMax.max, 1))}` : null;
    }

    private getAnyErrorText(errors: any): string {
        return errors ? "Indtast en gyldig dato" : null;
    }

    private formatDate(min: Date): string {
        return formatDate(min, "DD-MM-YYYY");
    }
}
