import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import MD5 from "md5-es";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { LocalStorage, SessionStorage } from "@dffedb/util";
import { DffError } from "@dffedb/util";
import { AppserverBrugerCredentialsRepository, AppserverInstallationCredentialsRepository } from "@e-forsyning/common/credentials";
import { Installation, InstallationService } from "@e-forsyning/common/installation";
import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { AuthenticationResult } from "./authentication-result.model";
import { AuthenticationService } from "./authentication.service";
import { HuskMigCredentialsService } from "./husk-mig-credentials.service";

@Injectable({ providedIn: "root" })
export class LoginService {
    private readonly indstillinger$ = this.indstillinger.valueChanges.pipe(map((u) => u.aktuelEforsyning));

    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly brugerCredentials: AppserverBrugerCredentialsRepository,
        private readonly http: HttpClient,
        private readonly installationCredentials: AppserverInstallationCredentialsRepository,
        private readonly installationService: InstallationService,
        private readonly huskMigCredentialsService: HuskMigCredentialsService,
        private readonly indstillinger: UmbracoIndstillingerObserver,
        private readonly sessionStorage: SessionStorage,
        private readonly localStorage: LocalStorage
    ) {}

    public loginMedGemteCredentials(): Observable<AuthenticationResult> {
        const userCredentials = this.localStorage.getItem("userCredentials");
        const credentials = (userCredentials && JSON.parse(atob(userCredentials))) as { username: string; password: string };
        return credentials
            ? this.login(credentials.username, credentials.password, true)
            : throwError("Kan ikke udføre autologin uden gemte credentials");
    }

    public login(brugernavn: string, kodeord: string, huskMig: boolean): Observable<AuthenticationResult> {
        brugernavn = this.fixCasing((brugernavn || "").toString());
        kodeord = (kodeord || "").toString();
        const hashedKodeord = this.hashKodeord(brugernavn, kodeord);
        return this.authenticate(brugernavn, hashedKodeord).pipe(
            switchMap((result) => {
                if (!result.success) {
                    return of(result);
                }
                return this.gemCredentials(result.cryptId).pipe(
                    tap(() => this.gemHuskMigCredentials(brugernavn, hashedKodeord, huskMig)),
                    switchMap(() => this.gemBruger(result.cryptId)),
                    switchMap(() => this.gemAktuelInstallation()),
                    map(() => result)
                );
            }),
            catchError((error: any) => throwError(this.createError(error)))
        );
    }

    public logout(): void {
        this.sessionStorage.removeItem("currentUser");
        this.sessionStorage.removeItem("user");
        // this.sessionStorage.removeItem("vaerksData");
        this.sessionStorage.removeItem("credentials");
        this.sessionStorage.removeItem("installationer");
    }

    public erLoggetInd(forsyningNodeId?: number): boolean {
        const isAuthenticated = !!this.installationCredentials.hent().cryptId;
        const currentForsyningNodeId = this.installationCredentials.hent().forsyningNodeId;

        if (!isAuthenticated) {
            return false;
        }

        if (!currentForsyningNodeId) {
            return false;
        }

        if (!forsyningNodeId) {
            // Vi spørger om man er logget ind på en hvilken som helst eForsyning.
            return true;
        }

        return forsyningNodeId === currentForsyningNodeId;
    }

    public tilknytEmail(forbrugernr: string, kode: string, email: string, kodeord: string): Observable<void> {
        email = this.fixCasing(email);
        const hashedPinkode = this.hashKodeord(forbrugernr, kode);
        const hashedPassword = this.hashKodeord(email, kodeord);
        return this.indstillinger$.pipe(
            switchMap((indstillinger) => {
                const url = indstillinger.appServerUrl + "api/tilknytemail";
                return this.http
                    .post(url, { forbrugernr, hashedPinkode, email, hashedPassword }, { responseType: "text" })
                    .pipe(map(() => null));
            })
        );
    }

    public glemtKodeord(email: string): Observable<void> {
        email = this.fixCasing(email);
        return this.indstillinger$.pipe(
            switchMap((indstillinger) => {
                const url = indstillinger.appServerUrl + "api/glemtpwd";
                const vaerkurl = indstillinger.siteUrl + "#/";
                return this.http.post(url, { email, vaerkurl }, { responseType: "text" }).pipe(map(() => null));
            })
        );
    }

    public resetPwd(kodeord: string, secGUID: string): Observable<void> {
        const saltedPwd = MD5.hash(kodeord);

        return this.indstillinger$.pipe(
            switchMap((indstillinger) => {
                const url = indstillinger.appServerUrl + "api/resetpwd/";
                return this.http.post(url, { saltedPwd, secGUID }, { responseType: "text" }).pipe(map(() => null));
            })
        );
    }

    private fixCasing(brugernavn: string): string {
        brugernavn = brugernavn || "";
        const erEmail = brugernavn.indexOf("@") > -1;
        return erEmail ? brugernavn.toLowerCase() : brugernavn.toUpperCase();
    }

    private authenticate(brugernavn: string, hashedKodeord: string): Observable<AuthenticationResult> {
        return this.indstillinger$.pipe(
            switchMap((indstillinger) => this.authenticationService.authenticate(brugernavn, hashedKodeord, indstillinger.forsyningId))
        );
    }

    private gemCredentials(cryptId: string): Observable<void> {
        const brugerCredentials = this.brugerCredentials.hent();
        const installationCredentials = this.installationCredentials.hent();
        brugerCredentials.cryptId = cryptId;
        return this.indstillinger$.pipe(
            tap((indstillinger) => {
                brugerCredentials.forsyningId = indstillinger.forsyningId;
                brugerCredentials.forsyningNodeId = indstillinger.nodeId;
                this.brugerCredentials.gem(brugerCredentials);
                // I tilfælde af re-login fordi cryptId er udløbet, ønsker vi at beholde den valgte installation.
                // Dette kan dog kun ske, hvis vi ikke arbejder på tværs af forsyninger.
                const newInstallationCredentials =
                    installationCredentials.forsyningNodeId === brugerCredentials.forsyningNodeId
                        ? { ...installationCredentials, ...brugerCredentials }
                        : brugerCredentials;
                this.installationCredentials.gem(newInstallationCredentials);
                this.installationCredentials.gem(
                    installationCredentials.forsyningNodeId === brugerCredentials.forsyningNodeId
                        ? { ...installationCredentials, ...brugerCredentials }
                        : brugerCredentials
                );
            }),
            map(() => null)
        );
    }

    private gemAktuelInstallation(): Observable<void> {
        return this.hentFoersteInstallation().pipe(
            tap((installation) => this.gemInstallation(installation)),
            map(() => null)
        );
    }

    private gemInstallation(installation: Installation): void {
        const credentials = this.installationCredentials.hent();
        credentials.ejendomNr = installation && installation.ejendomNr;
        credentials.aktivNr = installation && installation.aktivNr;
        credentials.installationNr = installation && installation.installationNr;
        this.installationCredentials.gem(credentials);
    }

    private hentFoersteInstallation(): Observable<Installation> {
        return this.hentInstallationer().pipe(map((installationer) => installationer[0]));
    }

    private hentInstallationer(): Observable<Installation[]> {
        return this.installationService
            .hentEgneInstallationer()
            .pipe(
                switchMap((installationer) =>
                    installationer && installationer.length !== 0
                        ? of(installationer)
                        : this.installationService.hentEgneOgTilknyttedeInterneInstallationer().pipe(map((r) => r.items))
                )
            );
    }

    private gemBruger(cryptId: string): Observable<void> {
        return this.hentBruger(cryptId).pipe(
            // TODO: Fjernes når vi engang har flyttet alt over på Angular 8+ og ikke gemmer bruger i sessionStorage
            tap((bruger: any) => this.sessionStorage.setItem("user", JSON.stringify(bruger)))
        );
    }

    private hentBruger(cryptId: string): Observable<any> {
        // TODO: Flyt til BrugerService?
        return this.indstillinger$.pipe(
            switchMap((indstillinger) => {
                const url = `${indstillinger.appServerUrl}api/getebrugerinfo/id/${cryptId}`;
                return this.http.get(url).pipe(
                    map((result: any) => {
                        const b = result || {};
                        b.adresse = b.vej + " " + b.husnr + " " + b.litra + " " + b.litra2;
                        b.postnrby = b.postnr + " " + b.bynavn;
                        b.erForbruger = () => b.rolle === 1;
                        return b;
                    })
                );
            })
        );
    }

    private hashKodeord(brugernavn: string, kodeord: string): string {
        const huskMigCredentials = this.huskMigCredentialsService.hent();
        return huskMigCredentials.brugernavn === brugernavn && huskMigCredentials.kodeord === kodeord ? kodeord : MD5.hash(kodeord);
    }

    private gemHuskMigCredentials(brugernavn: string, hashedKodeord: string, huskMig: boolean): void {
        this.huskMigCredentialsService.clear();
        if (huskMig) {
            this.huskMigCredentialsService.gem({ brugernavn, kodeord: hashedKodeord, huskMig });
        }
    }

    private createError(error: any): DffError {
        return new DffError("Der er opstået en fejl i forbindelse med login", { innerError: error });
    }
}
