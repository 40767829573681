import { Injectable } from "@angular/core";

import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CookieStorage {
    private subject: BehaviorSubject<CookieStorage> = new BehaviorSubject<CookieStorage>(this);
    private observable$: Observable<CookieStorage> = this.subject.asObservable();
    private updateCount = 0;

    constructor(private readonly cookie: CookieService) {}

    /**
     * @param name Cookie name
     * @returns boolean - whether cookie with specified name exists
     */
    public check(name: string): boolean {
        return this.cookie.check(name);
    }

    /**
     * @param name Cookie name
     * @returns property value
     */
    public get(name: string): string {
        return this.cookie.get(name);
    }

    /**
     * @returns all the cookies in json
     */
    public getAll(): { [key: string]: string } {
        return this.cookie.getAll();
    }

    /**
     * @param name     Cookie name
     * @param value    Cookie value
     * @param expires  Number of days until the cookies expires or an actual `Date`
     * @param path     Cookie path
     * @param domain   Cookie domain
     * @param secure   Secure flag
     * @param sameSite OWASP samesite token `Lax`, `None`, or `Strict`. Defaults to `Lax`
     */
    public set(
        name: string,
        value: string,
        expires?: number | Date,
        path?: string,
        domain?: string,
        secure?: boolean,
        sameSite?: "Lax" | "None" | "Strict"
    ): void {
        this.cookie.set(name, value, expires, path, domain, secure, sameSite);
        this.notifyChanged();
    }

    /**
     * @param name   Cookie name
     * @param path   Cookie path
     * @param domain Cookie domain
     */
    public delete(name: string, path?: string, domain?: string, secure?: boolean, sameSite?: "Lax" | "None" | "Strict"): void {
        this.cookie.delete(name, path, domain, secure, sameSite);
        this.notifyChanged();
    }

    /**
     * @param path   Cookie path
     * @param domain Cookie domain
     */
    public deleteAll(path?: string, domain?: string, secure?: boolean, sameSite?: "Lax" | "None" | "Strict"): void {
        this.cookie.deleteAll(path, domain, secure, sameSite);
        this.notifyChanged();
    }

    public select(key: string): Observable<string> {
        return this.observable$.pipe(
            map(s => s.get(key)),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    public beginUpdate(): void {
        this.updateCount++;
    }

    public endUpdate(): void {
        this.updateCount--;
        this.notifyChanged();
    }

    private notifyChanged(): void {
        if (this.updateCount === 0) {
            this.subject.next(this);
        }
    }
}
