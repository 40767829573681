import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerIntl, MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import { DffDateFieldComponent } from "./dff-date-field.component";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { CustomDatepickerIntl } from "./dff-fate-field.service";

@NgModule({
    imports: [CommonModule, FormsModule, MatDatepickerModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule],
    exports: [DffDateFieldComponent],
    declarations: [DffDateFieldComponent],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: "da-DK" }, // Change to your locale
        { provide: MatDatepickerIntl, useClass: CustomDatepickerIntl }
    ]
})
export class DffDateFieldModule {}
