<table mat-table class="mat-elevation-z2" [dataSource]="installationer">
    <ng-container matColumnDef="adresse">
        <th mat-header-cell *matHeaderCellDef>Adresse</th>
        <td mat-cell *matCellDef="let installation">
            <div>{{ installation.adresse }}</div>
            <small>{{ installation.postNrBy }}</small>
        </td>
    </ng-container>

    <ng-container matColumnDef="maalerOgType">
        <th mat-header-cell *matHeaderCellDef>Måler</th>
        <td mat-cell *matCellDef="let installation">
            <div>{{ installation.maalerNr }}</div>
            <small>{{ installation.maalerTypeTekst }}</small>
        </td>
    </ng-container>

    <ng-container matColumnDef="maalerOgForsyning">
        <th mat-header-cell *matHeaderCellDef>Måler</th>
        <td mat-cell *matCellDef="let installation">
            <div>{{ installation.maalerNr }}</div>
            <small>{{ installation.forsyningIndstillinger?.forsyningNavn }}</small>
        </td>
    </ng-container>

    <ng-container matColumnDef="forbruger">
        <th mat-header-cell *matHeaderCellDef>Forbruger</th>
        <td mat-cell *matCellDef="let installation" class="fit">
            <div>{{ installation.ejendomNr }}</div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="visteKolonner$ | async"></tr>
    <tr
        mat-row
        *matRowDef="let row; columns: visteKolonner$ | async"
        (click)="select.emit(row)"
        [class.aktuel-installation]="erAktuelleInstallation$(row) | async"
    ></tr>
</table>
