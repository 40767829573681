import { AbstractControlOptions, AsyncValidatorFn, UntypedFormArray, UntypedFormGroup, ValidatorFn } from "@angular/forms";

import {
    ControlState,
    IFormArray as ITypedFormArray,
    IFormBuilder as ITypedFormBuilder,
    IFormControl as ITypedFormControl,
    IFormGroup as ITypedFormGroup
} from "@rxweb/types";
import { ControlConfig } from "@rxweb/types/reactive-form/control-config";

type Unarray<T> = T extends (infer U)[] ? U : T;

export interface IFormBuilder extends ITypedFormBuilder {
    array<Item = any>(
        controlsConfig: ControlConfig<Item>[],
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ): IFormArray<Item>;
    control<T = any>(
        formState: ControlState<T>,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ): IFormControl<T>;
    group(
        controlsConfig: {
            [key: string]: any;
        },
        options?:
            | AbstractControlOptions
            | {
                  [key: string]: any;
              }
            | null
    ): UntypedFormGroup;
    group<T extends object = any>(
        controlsConfig: {
            [key in keyof T]: ControlConfig<T[key]>;
        },
        options?:
            | AbstractControlOptions
            | {
                  [key: string]: any;
              }
            | null
    ): IFormGroup<T>;
}

export interface IFormArray<Item> extends ITypedFormArray<Item> {
    groups(): IFormGroup<Item>[] | null;
}

export type IFormControl<T> = ITypedFormControl<T>;

export interface IFormGroup<T> extends ITypedFormGroup<T> {
    array<K extends keyof T>(index: K): IFormArray<Unarray<T[K]>>;
    control<K extends keyof T>(index: K): IFormControl<T[K]>;
    group<K extends keyof T>(index: K): IFormGroup<T[K]>;
}

declare module "@angular/forms" {
    interface FormGroup {
        array(key: string | number | symbol): IFormArray<any> | null;
        control(key: string | number | symbol): IFormControl<any> | null;
        group(key: string | number | symbol): IFormGroup<any> | null;
    }

    interface FormArray {
        //[index: number]: IFormGroup<any> | null; // TODO: Hvordan implementerer man en indexer via FormArray.prototype?
        groups(): IFormGroup<any>[] | null; // TODO: Kan man implenetere en property i stedet for en funktion via FormArray.prototype?
    }
}

UntypedFormGroup.prototype.array = function (path: string): IFormArray<any> | null {
    return this.controls[path];
};

UntypedFormGroup.prototype.control = function (path: string): IFormControl<any> | null {
    return this.controls[path];
};

UntypedFormGroup.prototype.group = function (path: string): IFormGroup<any> | null {
    return this.controls[path];
};

UntypedFormArray.prototype.groups = function (): IFormGroup<any>[] | null {
    return this.controls;
};
