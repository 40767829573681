import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

import { SessionStorage } from "@dffedb/util";

import { AppserverCredentials } from "./appserver-credentials.model";

interface CredentialsMap {
    [key: string]: AppserverCredentials;
}

@Injectable({ providedIn: "root" })
export class AppserverCredentialsRepository {
    private _valueChanges = new Map<string, Observable<AppserverCredentials>>();

    constructor(private readonly sessionStorage: SessionStorage) {}

    public hentAlle(): CredentialsMap {
        const result: CredentialsMap = {};
        const allCredentials = JSON.parse(this.sessionStorage.getItem("credentials") || null) || {};
        const keys = Object.keys(allCredentials);
        for (const key of keys) {
            const credentials = allCredentials[key];
            if (credentials.cryptId) {
                // Kun indgange med 'cryptId' indeholder egentlige credentials. Andre indgange er lookup-keys.
                result[key] = credentials;
            }
        }
        return result;
    }

    public valueChanges(key: string): Observable<AppserverCredentials> {
        if (!this._valueChanges.has(key)) {
            const result = this.sessionStorage.select("credentials").pipe(
                map((credentials) => this.internalHent(credentials, key)),
                distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
                shareReplay(1)
            );
            this._valueChanges.set(key, result);
        }
        return this._valueChanges.get(key);
    }

    public hent(key: string): AppserverCredentials {
        return this.internalHent(this.sessionStorage.getItem("credentials"), key);
    }

    public gem(key: string, value: AppserverCredentials): void {
        const allCredentials = JSON.parse(this.sessionStorage.getItem("credentials") || null) || {};
        const nodeId = this.genererNodeId(value);
        allCredentials[key] = nodeId;
        allCredentials[nodeId] = value;
        this.sessionStorage.setItem("credentials", JSON.stringify(allCredentials));
    }

    private genererNodeId(value: AppserverCredentials): string {
        return value.ejendomNr ? value.forsyningNodeId + "-" + value.ejendomNr : value.forsyningNodeId + "";
    }

    private internalHent(rawCredentials: string, key: string): AppserverCredentials {
        const allCredentials = JSON.parse(rawCredentials || null) || {};
        const nodeId = allCredentials[key];
        const credentials = allCredentials[nodeId] || {};
        return credentials;
    }
}
