import { AppserverCredentials } from "../../credentials";
import { ForsyningIndstillinger } from "../../forsyning-indstillinger";

export interface Installation {
    adresse: string;
    postNrBy: string;
    aktivNr: number;
    ejendomNr: number;
    installationNr: number;
    maalerNr: string;
    maalerTypeTekst: string;
    forsyningIndstillinger: ForsyningIndstillinger;
    credentials: AppserverCredentials;
}
