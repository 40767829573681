/* eslint-disable @typescript-eslint/naming-convention */
export enum LogLevel {
    Information = "Info",
    Warning = "Warning",
    Error = "Error",
    Critical = "Critical"
}

export interface LogFelter {
    Besked: string;
    Detaljer: string;
    Duration: number;
    ForsyningId: string;
    ForsyningNodeId: number;
    HttpStatus: number;
    HttpVerb: string;
    Id: string;
    LogLevel: LogLevel;
    Origin: string;
    RequestUrl: string;
    UserAgent: string;
    UTC: string;
    Version: string;
}
