import { Injectable } from "@angular/core";

import { of } from "rxjs";

import { AppserverBrugerCredentialsRepository, AppserverCredentials } from "@e-forsyning/common/credentials";

import { LogService } from "../log";
import { ObserverBase } from "../util/observer-base.service";
import { BrugerRepository } from "./bruger.repository";
import { Bruger } from "./model/bruger.model";

@Injectable({ providedIn: "root" })
export class AktuelBrugerObserver extends ObserverBase<AppserverCredentials, Bruger> {
    constructor(logService: LogService, brugerCredentials: AppserverBrugerCredentialsRepository, repository: BrugerRepository) {
        super(
            logService,
            brugerCredentials.valueChanges,
            (credentials) => (credentials && credentials.cryptId && repository.hentAktuelleBruger()) || of(null)
        );
    }
}
