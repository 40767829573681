import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";

import { DffFormControlModule } from "@dffedb/forms";
import { AccordionStatusIndicatorModule } from "@dffedb/ui";

import { EjendomsfaktorComponent } from "./ejendomsfaktor/ejendomsfaktor.component";

@NgModule({
    imports: [AccordionStatusIndicatorModule, CommonModule, DffFormControlModule, FormsModule, MatExpansionModule, ReactiveFormsModule],
    exports: [EjendomsfaktorComponent],
    declarations: [EjendomsfaktorComponent],
    providers: []
})
export class EjendomsfaktorModule {}
