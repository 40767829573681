import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { mdiClose, mdiInformationOutline } from "@mdi/js";

import { SvgIconRegistry } from "@dffedb/util";

import { DffInfoPopupComponent } from "./dff-info-popup.component";

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatIconModule],
    declarations: [DffInfoPopupComponent],
    exports: [DffInfoPopupComponent],
    providers: []
})
export class DffInfoPopupModule {
    constructor(iconRegistry: SvgIconRegistry) {
        iconRegistry.registerIcon(mdiClose, "dff-info-popup-close");
        iconRegistry.registerIcon(mdiInformationOutline, "dff-info-popup-open");
    }
}
