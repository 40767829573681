import { EforsyningIndstilling } from "../model/e-forsyning-indstilling.model";
import { UmbracoIndstillinger } from "../model/umbraco-indstillinger.model";

// TODO: Flyttes til @dffedb/test eller noget i den stil
export class TestDataFactory<T> {
    constructor(private generatorFn: () => T) {}

    public getSingleRecord(override: any = {}): T {
        return {
            ...this.generatorFn(),
            ...override
        };
    }

    public getArray(length: number = 20, overrides: any[] = []): T[] {
        return Array.from(new Array(length)).map((_, i) => this.getSingleRecord(overrides[i]));
    }
}

export const eforsyningIndstillingFactory = new TestDataFactory<EforsyningIndstilling>(() => {
    const id = Math.ceil(Math.random() * 1000);
    return {
        nodeId: id,
        appServerUrl: `APP_SERVER_URL_${id}`,
        forsyningId: `FORSYNING_ID_${id}`,
        siteUrl: `SITE_URL_${id}`,
        logoUrl: `LOGO_URL_${id}`,
        emailLogoUrl: `LOGO_URL_${id}`,
        emailSignatur: `SIGNATUR_${id}`,
        overskriftPaaLoginside: `OVERSKRIFT_${id}`,
        firmaInfo: {
            firmaNavn: `FIRMA_NAVN_${id}`,
            telefon: `TELEFON_${id}`,
            email: `${id}@foo.dk`
        }
    };
});

export const umbracoIndstillingerFactory = new TestDataFactory<UmbracoIndstillinger>(() => {
    const result: UmbracoIndstillinger = {
        eforsyninger: [],
        aktuelEforsyningNodeId: null,
        forsyningWeb: {
            siteUrl: "SITE_URL",
            nodeId: 0
        },
        firmaNavn: "FIRMA_NAVN",
        vaerkslisteUrl: "VAERKSLISTE_URL",
        vaerkNodeId: 0,
        themeUrl: "THEME_URL",
        logoUrl: "LOGO_URL",
        visDriftsstatus: true,
        driftsstatusForstyrrelserTekst: "DRIFTSSTATUS_FORSTYRRELSER",
        driftsstatusIngenForstyrrelserTekst: "DRIFTSSTATUS_INGEN_FORSTYRRELSER",
        matomoSiteId: "1",
        aktuelEforsyning: null,
        featureToggles: {
            cookiepolitik: true
        }
    };
    Object.setPrototypeOf(result, Object.getPrototypeOf(new UmbracoIndstillinger()));
    return result;
});
