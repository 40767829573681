import { Injectable } from "@angular/core";

import { combineLatest, Observable, of } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";

import { dbToInt, dbToString } from "@dffedb/util";

import { BrugerRepository, Rolle } from "../bruger";
import { AktuelBrugerService } from "../bruger/aktuel-bruger.service";
import { AppserverCredentials } from "../credentials";
import { ForbrugerTilknytning, ForbrugerTilknytningService } from "../forbruger-tilknytning";
import { ForsyningIndstillinger, ForsyningIndstillingerService } from "../forsyning-indstillinger";
import { BrugerHttpClient, EksternForsyningHttpClient, ParametreForAktuelleInstallation } from "../http";
import { Installation } from "./model/installation.model";

export interface InstallationAfgraensning {
    skip?: number;
    take?: number;
    huskelisteId?: number;
    soegetekst?: string;
    ejendomNr?: number;
}

const defaultInstallationAfgraensning: InstallationAfgraensning = {
    skip: 0,
    take: 10,
    huskelisteId: null,
    soegetekst: ""
};

@Injectable({ providedIn: "root" })
export class InstallationService {
    constructor(
        private readonly brugerHttp: BrugerHttpClient,
        private readonly eksternHttp: EksternForsyningHttpClient,
        private readonly brugerRepository: BrugerRepository,
        private readonly aktuelBrugerService: AktuelBrugerService,
        private readonly forbrugerTilknytningService: ForbrugerTilknytningService,
        private readonly forsyningIndstillingerService: ForsyningIndstillingerService
    ) {}

    public hentInstallationerForEjendom(ejendomNr: number): Observable<Installation[]> {
        return this.hentInterneInstallationer({ ejendomNr }, true).pipe(map((r) => r.items));
    }

    public hentEgneInstallationer(parametre: InstallationAfgraensning = defaultInstallationAfgraensning): Observable<Installation[]> {
        return this.hentInterneInstallationer(parametre, false).pipe(map((r) => r.items));
    }

    public hentEgneOgTilknyttedeInterneInstallationer(
        parametre: InstallationAfgraensning = defaultInstallationAfgraensning
    ): Observable<HentInstallationerResultat> {
        return this.hentInterneInstallationer(parametre, true);
    }

    public hentInstallationer(
        parametre: InstallationAfgraensning = defaultInstallationAfgraensning
    ): Observable<HentInstallationerResultat> {
        const interneInstallationer$ = this.hentEgneOgTilknyttedeInterneInstallationer(parametre);
        const eksterneInstallationer$ = this.hentEksterneInstallationer();
        return combineLatest([interneInstallationer$, eksterneInstallationer$]).pipe(
            map(([interne, eksterne]) => ({
                eof: interne.eof,
                items: [...interne.items, ...eksterne]
            }))
        );
    }

    public hentEksterneInstallationer(): Observable<Installation[]> {
        const result = this.forbrugerTilknytningService.hentTilknytningerForAktuelBruger().pipe(
            map((tilknytninger) => tilknytninger.filter((t) => t.erEksternTilknytning)),
            switchMap((tilknytninger) => {
                const empty = new Array<Installation[]>();
                const installationer = tilknytninger.map((t) => this.hentEksterneInstallationerForTilknytning(t));
                return installationer.length ? combineLatest(installationer) : of(empty);
            }),
            map((installationer) => Array<Installation>().concat(...installationer))
        );
        return result;
    }

    private hentEksterneInstallationerForTilknytning(tilknytning: ForbrugerTilknytning): Observable<Installation[]> {
        return this.eksternHttp.authenticate(tilknytning.credentials).pipe(
            switchMap((http) =>
                this.brugerRepository.hentBruger(http).pipe(
                    map((bruger) => ({
                        /* eslint-disable @typescript-eslint/naming-convention */
                        Soegetekst: "",
                        Skip: 0,
                        Take: 9999,
                        EBrugerId: bruger.id,
                        MedtagTilknyttede: false
                        /* eslint-enable @typescript-eslint/naming-convention */
                    })),
                    switchMap((params) => http.post("api/FindInstallationer", params, ParametreForAktuelleInstallation.false)),
                    map((installationer: any) =>
                        installationer.Installationer.map((i: any) =>
                            this.mapInstallation(i, tilknytning.forsyningIndstillinger, tilknytning.credentials)
                        )
                    )
                )
            ),
            catchError(() =>
                // Den eksterne bruger kan have ændret kodeord, hvilket vil medføre fejl.
                // Det skal ikke trække resten ned. I stedet returnerer vi en tom liste
                of([])
            )
        );
    }

    private hentInterneInstallationer(
        parametre: InstallationAfgraensning,
        medtagTilknyttede: boolean
    ): Observable<HentInstallationerResultat> {
        const credentials = this.brugerHttp.credentials;
        const forsyningIndstillinger$ = this.forsyningIndstillingerService.hentForsyningIndstillinger(credentials.forsyningId);
        const bruger$ = this.aktuelBrugerService.select();
        return combineLatest([forsyningIndstillinger$, bruger$]).pipe(
            switchMap(([forsyningIndstillinger, bruger]) => {
                if (!bruger) {
                    return of({
                        eof: true,
                        items: []
                    });
                }
                const erForbruger = Rolle.erForbruger(bruger.rolle);
                // Forbruger: Hent alle installationer da vi ikke benytter paging og kan have eksterne tilknytninger.
                // Medarbejdere: Hent 10 (default) ad gangen.
                const take = erForbruger ? 9999 : parametre.take || defaultInstallationAfgraensning.take;
                const params = {
                    /* eslint-disable @typescript-eslint/naming-convention */
                    Soegetekst: parametre.soegetekst || defaultInstallationAfgraensning.soegetekst,
                    Skip: parametre.skip || defaultInstallationAfgraensning.skip,
                    Take: take + 1, // Vi henter en ekstra installation for at kunne bestemme 'eof'.
                    EBrugerId: bruger.id,
                    Huskeliste: bruger.huskelisteId || parametre.huskelisteId,
                    MedtagTilknyttede: medtagTilknyttede,
                    EjendomNr: parametre.ejendomNr
                    /* eslint-enable @typescript-eslint/naming-convention */
                };
                return this.brugerHttp.post("api/FindInstallationer", params, ParametreForAktuelleInstallation.false).pipe(
                    map((result) => result.Installationer as any[]),
                    map((installationer) => installationer.map((i) => this.mapInstallation(i, forsyningIndstillinger, credentials))),
                    map((installationer) => ({
                        eof: installationer.length <= take,
                        items: installationer.splice(0, take) // Eventuel ekstra installation til eof-beregning fjernes den fra resultatet
                    }))
                );
            }),
            first()
        );
    }

    private mapInstallation(data: any, forsyningIndstillinger: ForsyningIndstillinger, credentials: AppserverCredentials): Installation {
        return {
            adresse: dbToString(data.Adresse),
            postNrBy: dbToString(data.PostNr) + " " + dbToString(data.By),
            aktivNr: dbToInt(data.AktivNr),
            ejendomNr: dbToInt(data.EjendomNr),
            installationNr: dbToInt(data.InstallationNr),
            maalerNr: dbToString(data["MålerNr"]),
            maalerTypeTekst: dbToString(data["Målertype"]),
            forsyningIndstillinger,
            credentials
        };
    }
}

export interface HentInstallationerResultat {
    items: Installation[];
    eof: boolean;
}
