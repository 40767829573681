import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    selector: "dff-side-titel",
    template: `<h1 id="page-header"><ng-content></ng-content></h1>`,
    styles: [
        `
            h1 {
                margin-top: 0;
                font-weight: 300;
            }

            :host ::ng-deep h1 button {
                top: -4px;
                margin-right: -6px;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DffSideTitelComponent {}
