import { AbstractControl } from "@angular/forms";

import { formatDate } from "@dffedb/util";

function toDate(value: any): Date {
    // Null / undefined
    if (!value) {
        return null;
    }
    // Rigtig Javascript dato
    if (value instanceof Date) {
        return value;
    }
    // F.eks. Moment.js dato
    if (typeof value.toDate === "function") {
        return value.toDate();
    }
    return null;
}

function toString(value: Date): string {
    return formatDate(value, "DD-MM-YYYY");
}

export function minDateValidator(minDate: Date): any {
    return (control: AbstractControl) => {
        const controlDate = toDate(control.value);
        if (!controlDate) {
            return null;
        }
        const isSameOrAfter = controlDate >= minDate;
        return isSameOrAfter ? null : { mindate: { minDate: toString(minDate), actualDate: toString(controlDate) } };
    };
}

export function maxDateValidator(maxDate: Date): any {
    return (control: AbstractControl) => {
        const controlDate = toDate(control.value);
        if (!controlDate) {
            return null;
        }
        const isSameOrBefore = controlDate <= maxDate;
        return isSameOrBefore ? null : { maxdate: { maxDate: toString(maxDate), actualDate: toString(controlDate) } };
    };
}
