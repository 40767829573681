import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthenticationService } from "../auth";
import { AppserverBrugerCredentialsRepository } from "../credentials";
import { AppserverHttpClient } from "./appserver-http-client.service";

@Injectable({ providedIn: "root" })
export class BrugerHttpClient extends AppserverHttpClient {
    constructor(
        http: HttpClient,
        credentialsRepository: AppserverBrugerCredentialsRepository,
        authenticationService: AuthenticationService
    ) {
        //   authenticationService.isAuthenticationTimeout();
        super(http, credentialsRepository, authenticationService);
    }
}
