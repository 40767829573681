import { Component, Input } from "@angular/core";

import { DffGroupedContent } from "@dffedb/util";

@Component({
    selector: "dff-kvittering",
    templateUrl: "./dff-kvittering.component.html",
    styleUrls: ["./dff-kvittering.component.scss"]
})
export class DffKvitteringComponent {
    @Input() public kvittering: DffGroupedContent;
}
