import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppserverHttpClient, BrugerHttpClient, InstallationHttpClient, ParametreForAktuelleInstallation } from "../http";
import { Bruger } from "./model/bruger.model";

@Injectable({ providedIn: "root" })
export class BrugerRepository {
    constructor(private readonly brugerHttp: BrugerHttpClient, private readonly installationHttp: InstallationHttpClient) {}

    public hentAktuelleBruger(): Observable<Bruger> {
        return this.hentBruger(this.brugerHttp);
    }

    public hentBrugerForAktuelleInstallation(): Observable<Bruger> {
        return this.hentBruger(this.installationHttp);
    }

    public hentBruger(http: AppserverHttpClient): Observable<Bruger> {
        return http.get("api/getebrugerinfo", ParametreForAktuelleInstallation.true).pipe(map((bruger: any) => this.map(bruger)));
    }

    private map(value: any): Bruger {
        return {
            id: value.id,
            navn: value.navn,
            rolle: value.rolle,
            email: value.email,
            huskelisteId: this.mapHuskelisteId(value.huskelisteId),
            menupunkter: value.menupunkter || [],
            adresse: {
                adresselinie1: "",
                adresselinie2: "",
                adresselinie3: "",
                adresselinie4: "",
                adressenr: -1,
                bynavn: value.bynavn,
                husnr: (value.husnr && +value.husnr) || null,
                litra: value.litra,
                litra2: value.litra2,
                postnr: value.postnr,
                vejnavn: value.vej,
                vejnr: -1
            }
        };
    }

    private mapHuskelisteId(value: number): number {
        // Fix til problem med at FOF returnerer -1 i huskelisteId.
        // Det betyder at forbrugere ikke kan se deres egen installation.
        return value === -1 ? null : value;
    }
}
