import { DffOprindeligAktuelVaerdi } from "@dffedb/util";

import { formatDate, parseDate } from "./date-util";

// Date
export function dbToDates(dates: any): DffOprindeligAktuelVaerdi<Date> {
    return {
        oprindelig: dbToDate(dates.oprindelig),
        aktuel: dbToDate(dates.aktuel)
    };
}

export function dbToDate(value: string): Date | null {
    if (!value || value === "30-12-1899") {
        return null;
    }
    return parseDate(value, "DD-MM-YYYY");
}

export function datesToDb(dates: DffOprindeligAktuelVaerdi<Date>): any {
    const result = {
        oprindelig: dateToDb(dates.oprindelig),
        aktuel: dateToDb(dates.aktuel)
    };
    return result;
}

function toDate(value: any): Date | null {
    // Null / undefined
    if (!value) {
        return null;
    }
    // Rigtig Javascript dato
    if (value instanceof Date) {
        return value;
    }
    // F.eks. Moment.js dato
    if (typeof value.toDate === "function") {
        return value.toDate();
    }
    return null;
}

export function dateToDb(value: Date | null): string {
    // Vi kalder lige toDate i tilfælde af Moment-dato.
    return formatDate(toDate(value), "DD-MM-YYYY") || "";
}

// Int
export function dbToInts(values: any): DffOprindeligAktuelVaerdi<number> {
    return {
        oprindelig: dbToInt(values.oprindelig),
        aktuel: dbToInt(values.aktuel)
    };
}

export function dbToInt(value: any): number | null {
    const result = parseInt(value && value.toString(), 10);
    const isValid = !isNaN(result);
    return isValid ? result : null;
}

export function intsToDb(values: DffOprindeligAktuelVaerdi<number>): any {
    const result = {
        oprindelig: intToDb(values.oprindelig),
        aktuel: intToDb(values.aktuel)
    };
    return result;
}

export function intToDb(value: number | null): number | null {
    const isValid = !isNaN(parseInt(value?.toString() ?? "", 10));
    return isValid ? value : null;
}

// Boolean
export function dbToBooleans(values: any): DffOprindeligAktuelVaerdi<boolean> {
    return {
        oprindelig: dbToBoolean(values && values.oprindelig),
        aktuel: dbToBoolean(values && values.aktuel)
    };
}

export function dbToBoolean(value: any): boolean {
    if (value === "False") {
        return false;
    }
    return !!value;
}

export function booleansToDb(values: DffOprindeligAktuelVaerdi<boolean>): DffOprindeligAktuelVaerdi<boolean> {
    const result = {
        oprindelig: booleanToDb(values.oprindelig),
        aktuel: booleanToDb(values.aktuel)
    };
    return result;
}

export function booleanToDb(value: boolean | null): boolean {
    return !!value;
}

// Float
export function dbToFloats(values: any): DffOprindeligAktuelVaerdi<number> {
    return {
        oprindelig: dbToFloat(values.oprindelig),
        aktuel: dbToFloat(values.aktuel)
    };
}

// "4.25" => 4.25
// "4,25" => 4.25
// "12.25%" => 0.1225
export function dbToFloat(value: string): number | null {
    value = value && value.replace(".", ""); // Fjern tusindadskiller
    value = value && value.replace(",", "."); // Lav dansk decimal-separator om til international
    const divisor = (value || "").endsWith("%") ? 100 : 1;
    return value ? parseFloat(value) / divisor : null;
}

export function dbToFloatString(value: string): string | null {
    const result = dbToFloat(value);
    return result?.toString() ?? null;
}

export function floatsToDb(values: DffOprindeligAktuelVaerdi<number>): any {
    const result = {
        oprindelig: floatToDb(values.oprindelig),
        aktuel: floatToDb(values.aktuel)
    };
    return result;
}

export function floatToDb(value: number | null): string | null {
    const isValid = !isNaN(parseFloat(value?.toString() ?? ""));
    return isValid ? value?.toString().replace(".", ",") ?? null : null; // Lav international decimal-separator om til dansk
}

// String
export function dbToStrings(values: any): DffOprindeligAktuelVaerdi<string> {
    return {
        oprindelig: dbToString(values.oprindelig),
        aktuel: dbToString(values.aktuel)
    };
}

export function dbToString(value: string): string {
    return value;
}

export function stringsToDb(values: DffOprindeligAktuelVaerdi<string>): any {
    const result = {
        oprindelig: stringToDb(values.oprindelig),
        aktuel: stringToDb(values.aktuel)
    };
    return result;
}

export function stringToDb(value: string | null): string {
    return value ?? "";
}
