import { Injector } from "@angular/core";

import { UmbracoMenu } from "../eforsyning-menu/model/umbraco-menu.model";
import { UmbracoMenuObserver } from "../eforsyning-menu/observers/umbraco-menu.observer";
import { UmbracoIndstillinger } from "./model/umbraco-indstillinger.model";
import { UmbracoIndstillingerObserver } from "./umbraco-indstillinger.observer";

interface InitUmbracoIndstillinger {
    umbraco: UmbracoIndstillinger;
    menu: UmbracoMenu;
}

type InitUmbracoIndstillingerFn = (indstillinger: InitUmbracoIndstillinger) => void;

export function initUmbracoIndstillinger(injector: Injector, fn: InitUmbracoIndstillingerFn): void {
    const umbracoIndstillinger = new UmbracoIndstillinger();
    const indstillinger: InitUmbracoIndstillinger = { umbraco: umbracoIndstillinger, menu: null };
    if (fn) {
        fn(indstillinger);
        // indstillinger.umbraco kan være blevet overskrevet af et 'dumt' objekt.
        // Vi tilføjer derfor metoder som er angivet på klassen UmbracoIndstillinger.
        Object.setPrototypeOf(indstillinger.umbraco, Object.getPrototypeOf(umbracoIndstillinger));
    }

    const umbracoIndstillingerObserver = injector.get(UmbracoIndstillingerObserver);
    umbracoIndstillingerObserver.next(indstillinger.umbraco);

    const umbracoMenuObserver = injector.get(UmbracoMenuObserver);
    umbracoMenuObserver.next(indstillinger.menu);
}
