import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { combineLatest, Observable, of } from "rxjs";
import { catchError, first, map, mapTo, switchMap } from "rxjs/operators";

import { getErrorMessage } from "@dffedb/util";

import { AuthenticationService } from "../auth/authentication.service";
import { BrugerRepository } from "../bruger/bruger.repository";
import { AppserverInstallationCredentialsRepository } from "../credentials";
import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger";
import { LoginService } from "./login.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
    constructor(
        private readonly router: Router,
        private readonly brugerRepository: BrugerRepository,
        private readonly loginService: LoginService,
        private readonly installationCredentialsObserver: AppserverInstallationCredentialsRepository,
        private readonly umbracoIndstillingerObserver: UmbracoIndstillingerObserver,
        private readonly authenticationService: AuthenticationService
    ) {}

    public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        if (this.authenticationService.isAuthenticationTimeout()) {
            //console.log("timeout i AuthGuard:");
            this.authenticationService.resetAuthenticationTimeout();
            return this.erAktuelleEforsyningAuthenticated().pipe(
                first(),
                //switchMap((minpara) =>(of(this.authenticationService.isAuthenticationTimeout()) )),
                switchMap((erAktuelleEforsyningAuthenticated) =>
                    erAktuelleEforsyningAuthenticated ? this.erBrugerLoggetInd() : of(false)
                ),
                switchMap((authenticated) => (authenticated ? of(authenticated) : this.loginMedGemteCredentials())),
                map((authenticated) => authenticated || this.router.parseUrl(`login${state.url}`))
            );
        } else {
            //console.log("Ingen timeout kør bare:");
            return of(true);
        }
    }

    /**
     * Undersøger om den aktuelle E|Forsyning er den samme, som vi har gemt credentials for.
     * Hvis vi har flere E|Forsyninger på samme domæne, vil de kunne se hinandens credentials, da de gemmes én gang pr. domæne.
     */
    private erAktuelleEforsyningAuthenticated(): Observable<boolean> {
        const authenticatedEforsyningNodeId$ = this.installationCredentialsObserver.valueChanges.pipe(map((c) => c.forsyningNodeId));
        const aktuelEforsyningNodeId$ = this.umbracoIndstillingerObserver.valueChanges.pipe(map((i) => i.aktuelEforsyningNodeId));
        return combineLatest([authenticatedEforsyningNodeId$, aktuelEforsyningNodeId$]).pipe(
            map(([authenticatedEforsyningNodeId, aktuelEforsyningNodeId]) => authenticatedEforsyningNodeId === aktuelEforsyningNodeId)
        );
    }

    /**
     * Undersøger om vi har valide credentials for den aktuelle bruger.
     * Hvis der er gået noget tid uden aktivitet, kan credentials være timet ud.
     */
    private erBrugerLoggetInd(): Observable<boolean> {
        // Vi bruger repository direkte for at kalde undgå cache, da vi ønsker at tjekke om serveren stadig kender os.
        return this.brugerRepository.hentAktuelleBruger().pipe(
            mapTo(true),
            catchError((error) => {
                console.warn("canActivate: " + getErrorMessage(error));
                return of(false);
            })
        );
    }

    /**
     * Logger ind med de credentials man har gemt via "Husk mig"-checkboks i loginskærmbilledet.
     */
    private loginMedGemteCredentials(): Observable<boolean> {
        return this.loginService.loginMedGemteCredentials().pipe(
            map((result) => result.success),
            catchError(() => of(false))
        );
    }
}
