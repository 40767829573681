import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AppserverHttpClient } from "../http";
import { Forbruger } from "./model/forbruger.model";

@Injectable({ providedIn: "root" })
export class ForbrugerRepository {
    constructor() {}

    public hentForbruger(httpClient: AppserverHttpClient): Observable<Forbruger> {
        const url = "api/getebrugerinfo";
        return httpClient.get(url).pipe(
            map((f: any) => ({
                id: f.id,
                navn: f.navn,
                mailAdresse: f.email
            }))
        );
    }
}
