import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { mdiAlertCircleOutline } from "@mdi/js";

import { SvgIconRegistry } from "@dffedb/util";

import { EmptyPageBadgeModule } from "../empty-page-badge";
import { PageErrorComponent } from "./page-error.component";

@NgModule({
    imports: [CommonModule, EmptyPageBadgeModule],
    exports: [PageErrorComponent],
    declarations: [PageErrorComponent]
})
export class PageErrorModule {
    constructor(iconRegistry: SvgIconRegistry) {
        iconRegistry.registerIcon(mdiAlertCircleOutline, "page-error-icon");
    }
}
