import { NgModule } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS } from "@angular/material/core";

import { DffDateAdapter } from "./dff-date-adapter";

@NgModule({
    providers: [
        {
            provide: DateAdapter,
            useClass: DffDateAdapter
        }
    ]
})
export class DffDateModule {}

// eslint-disable-next-line max-classes-per-file
@NgModule({
    imports: [DffDateModule],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS }]
})
export class MatDffDateModule {}
