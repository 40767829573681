import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { Observable } from "rxjs";

import { DffedbFancyboxSandbox } from "./dffedb-fancybox.sandbox";
import { DffedbFancyboxViewModel } from "./dffedb-fancybox.view-model";

@Component({
    templateUrl: "./dffedb-fancybox.component.html",
    styleUrls: ["./dffedb-fancybox.component.scss"]
})
export class DffedbFancyboxComponent implements OnInit, OnChanges {
    @Input() public imageUrl: string;
    @Input() public videoUrl: string;
    @Input() public requiredConsent: string;
    @Input() public buttonClass: string;

    public vm$: Observable<DffedbFancyboxViewModel>;

    constructor(private readonly cd: ChangeDetectorRef, private readonly sandbox: DffedbFancyboxSandbox) {
        // WebComponents fungerer ikke så godt med auto-change detection, så vi kobler det helt fra.
        // cd.detach();
    }

    public ngOnInit(): void {
        this.vm$ = this.sandbox.getViewModel(this.requiredConsent);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        console.log("DffedbFancyboxComponent.ngOnChanges", changes);
    }
}
