import { BehaviorSubject, merge, Observable, of } from "rxjs";
import { concatMap, filter, first, map, scan, shareReplay, tap } from "rxjs/operators";

export interface PagineretRessourceResultData<T> {
    items: T[];
    isLoading: boolean;
    isLoadingFirstPage: boolean;
    eof: boolean;
    pageNo: number;
}

export interface PagineretRessourceResult<T> {
    result$: Observable<PagineretRessourceResultData<T>>;
    loadMore(): void;
    reset(loadFirstPage: boolean): void;
}

export type PagineretRessourceCallback<T> = (sideNummer: number) => Observable<{ items: T[]; eof: boolean }>;

interface Page {
    pageNo: number;
    resetting: boolean;
}

export function brugPagineretRessource<T>(callback: PagineretRessourceCallback<T>): PagineretRessourceResult<T> {
    let sideNummer = -1;
    let eof = false;

    const initialValue: PagineretRessourceResultData<T> = {
        items: [],
        eof: false,
        isLoading: false,
        isLoadingFirstPage: false,
        pageNo: -1
    };

    const loadingSubject = new BehaviorSubject<number>(-1);
    const loading$: Observable<Partial<PagineretRessourceResultData<T>>> = loadingSubject.pipe(
        map((pageNo) =>
            pageNo <= 0 ? { isLoading: true, isLoadingFirstPage: true, items: [] } : { isLoading: true, isLoadingFirstPage: false }
        )
    );

    const fetchSubject = new BehaviorSubject<number>(-1);
    const fetch$: Observable<PagineretRessourceResultData<T>> = fetchSubject.pipe(
        filter(() => !eof),
        tap((pageNo) => loadingSubject.next(pageNo)),
        concatMap((pageNo) => fetchPage(pageNo).pipe(map((result) => ({ pageNo, ...result })))),
        scan((acc, value) => (value.pageNo <= 0 ? value : { ...value, items: [...acc.items, ...value.items] })),
        map((result) => ({ ...result, isLoading: false, isLoadingFirstPage: false })),
        tap((result) => (eof = result.eof))
    );

    const result$: Observable<PagineretRessourceResultData<T>> = merge(loading$, fetch$).pipe(
        scan((acc, value) => ({ ...acc, ...value }), initialValue),
        shareReplay(1)
    );

    function fetchPage(pageNo: number): Observable<{ items: T[]; eof: boolean }> {
        return pageNo === -1 ? of({ items: [], eof: false }) : callback(pageNo).pipe(first());
    }

    return {
        result$,
        loadMore(): void {
            fetchSubject.next(++sideNummer);
        },
        reset(loadFirstPage: boolean): void {
            sideNummer = loadFirstPage ? 0 : -1;
            eof = false;
            fetchSubject.next(sideNummer);
        }
    };
}
