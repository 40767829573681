import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { AppserverInstallationCredentialsRepository } from "@e-forsyning/common/credentials";

import { AppserverCredentials } from "../credentials/appserver-credentials.model";
import { InstallationKey } from "./model/installation-key.model";

@Injectable({ providedIn: "root" })
export class AktuelInstallationKeyObserver {
    private value$: Observable<InstallationKey>;

    constructor(installationCredentials: AppserverInstallationCredentialsRepository) {
        this.value$ = installationCredentials.valueChanges.pipe(
            map((c) => (c && c.ejendomNr && this.map(c)) || null),
            shareReplay(1)
        );
    }

    public get valueChanges(): Observable<InstallationKey> {
        return this.value$;
    }

    private map(credentials: AppserverCredentials): InstallationKey {
        const { ejendomNr, installationNr, aktivNr } = credentials;
        return { ejendomNr, installationNr, aktivNr };
    }
}
