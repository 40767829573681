/* Returnerer scheme og domæne fra en url.
 * Eksempel:
 * http://softvaerket.dk/et-eller-andet -> http://softvaerket.dk
 * //softvaerket.dk/et-eller-andet -> //softvaerket.dk
 */
export function getUrlOrigin(url: string): string {
    const matches = url.match(/^(?:https?:)?(?:\/\/)?([^\/\?]+)/i);
    return (matches && matches[0]) || "";
}

export function getUrlQuery(url: string): any {
    const query = url.split("?")[1] || "";
    const splits = query.split("&") || [];
    return splits.reduce((agg, curr) => {
        const [key, ...rest] = curr.split("=");
        const value = rest.join("=");
        return { ...agg, [key.toLowerCase()]: value };
    }, {});
}
