<div *ngIf="formControl && !hidden; else ingenFormControl" [ngSwitch]="true">
    <!-- Tal -->
    <mat-form-field *ngSwitchCase="controlType === formControlType.NumberInput">
        <mat-label>{{ placeholder }}</mat-label>
        <input type="number" matInput [formControl]="formControl" [required]="formControl.required" />
        <div matSuffix><ng-container *ngTemplateOutlet="matSuffix"></ng-container></div>
        <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
        <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
    </mat-form-field>

    <!-- Dropdown -->
    <mat-form-field *ngSwitchCase="controlType === formControlType.DropdownList">
        <mat-label>{{ placeholder }}</mat-label>
        <mat-select [formControl]="formControl" [required]="formControl.required">
            <mat-option *ngFor="let option of options$ | async" [value]="option.key">{{ option.value }}</mat-option>
        </mat-select>
        <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
        <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
    </mat-form-field>

    <!-- Autocomplete | AutocompleteNumeric -->
    <mat-form-field *ngSwitchCase="controlType === formControlType.Autocomplete || controlType === formControlType.AutocompleteNumeric">
        <mat-label>{{ placeholder }}</mat-label>
        <input
            #SearchField
            matInput
            [type]="controlType === formControlType.AutocompleteNumeric ? 'number' : 'text'"
            [formControl]="formControl"
            autocorrect="off"
            [matAutocomplete]="auto"
            [required]="formControl.required"
        />
        <div matSuffix><ng-container *ngTemplateOutlet="matSuffix"></ng-container></div>
        <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
        <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of options$ | async" [value]="option.key">
                {{ option.value }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <!-- Checkbox -->
    <div *ngSwitchCase="controlType === formControlType.Checkbox">
        <mat-checkbox [formControl]="formControl" [required]="formControl.required">{{ placeholder }}</mat-checkbox>
        <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
    </div>

    <!-- Dato -->
    <mat-form-field *ngSwitchCase="controlType === formControlType.DateInput">
        <mat-label>{{ placeholder }}</mat-label>
        <input
            matInput
            #dateinput
            [matDatepicker]="datepicker"
            [formControl]="formControl"
            [required]="formControl.required"
            (focus)="datepicker.open()"
        />
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker (closed)="dateinput.blur()"></mat-datepicker>
        <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
        <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
    </mat-form-field>

    <!-- Tekst (default/fallback) -->
    <mat-form-field *ngSwitchDefault>
        <mat-label>{{ placeholder }}</mat-label>
        <input type="text" matInput [formControl]="formControl" [required]="formControl.required" />
        <div matSuffix><ng-container *ngTemplateOutlet="matSuffix"></ng-container></div>
        <mat-hint *ngIf="hint" [innerHTML]="hint"></mat-hint>
        <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
    </mat-form-field>
</div>
<ng-template #ingenFormControl>
    <div class="error" *ngIf="!hidden">Fejl: DffFormControlComponent.formControl forventer en FormControl-instans.</div>
</ng-template>
<ng-template #matSuffix>
    <span matSuffix>{{ suffix }}</span>
    <ng-content select="[matSuffix]"></ng-content>
</ng-template>
