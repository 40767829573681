import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DffErrorPanelComponent } from "./dff-error-panel.component";

@NgModule({
    imports: [CommonModule],
    exports: [DffErrorPanelComponent],
    declarations: [DffErrorPanelComponent]
})
export class DffErrorPanelModule {}
