import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DffCookieConsentCookieListComponent } from "./dff-cookie-consent-cookie-list.component";

@NgModule({
    imports: [CommonModule],
    exports: [DffCookieConsentCookieListComponent],
    declarations: [DffCookieConsentCookieListComponent],
    providers: []
})
export class DffCookieConsentCookieListModule {}
