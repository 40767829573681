<div [formGroup]="formGroup" class="step-adressekode side-by-side">
    <div class="step-content">
        <mat-form-field class="large-input">
            <mat-label>Kode</mat-label>
            <input formControlName="kode" type="text" mask="000000-000000999" matInput required autofocus />                    
        </mat-form-field>
        <button 
            mat-flat-button 
            color="primary" 
            (click)="onSoeg($event)" 
            [disabled]="formGroup.get('kode').invalid || adresseKodeStatus.processed">
            Find mig
        </button> 
        <div *ngIf="adresseKodeStatus.value" class="adresse">
            <small>Adresse:</small>
            <div class="monospace" [innerHTML]="formatterAdresse(adresseKodeStatus.value.adresse)"></div>
        </div>
        <div class="error" *ngIf="adresseKodeStatus.error">Du har indtastet en ugyldig kode</div>
        <mat-spinner *ngIf="adresseKodeStatus.processing" [diameter]="30"></mat-spinner>
    </div>
</div>
