import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UmbracoHttpClient } from "@e-forsyning/common/http";

import { PrisberegnerKonfigurationDto } from "../model/prisberegner-konfiguration.dto";
import { PrisberegnerKonfiguration } from "../model/prisberegner-konfiguration.model";
import { PrisberegnerKonfigurationMapper } from "./prisberegner-konfiguration.mapper";

@Injectable({ providedIn: "root" })
export class PrisberegnerRepository {
    constructor(private readonly http: UmbracoHttpClient, private readonly mapper: PrisberegnerKonfigurationMapper) {}

    public selectKonfiguration(nodeId: number): Observable<PrisberegnerKonfiguration> {
        return this.http.hentSideDataForId<PrisberegnerKonfigurationDto>(nodeId).pipe(map((data) => this.mapper.dtoToKonfiguration(data)));
    }
}
