<ng-container *ngIf="vm$ | async as vm">
    <dff-cookie-consent-overlay *ngIf="!vm.consentAccepted">
        <img [src]="imageUrl" class="img-responsive" width="100%" />
    </dff-cookie-consent-overlay>

    <a *ngIf="vm.consentAccepted" [href]="videoUrl" data-fancybox="video" class="video">
        <img [src]="imageUrl" class="img-responsive" width="100%" />
        <div class="video__positioner">
            <span class="video__icon fa fa-video-camera {{ buttonClass }}"></span>
        </div>
    </a>
</ng-container>
