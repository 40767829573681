export interface ForsyningPaaAdresseSvarmulighederKonfiguration {
    ja: string;
    nej: string;
    snart: string;
}

export interface ForsyningPaaAdresseAfgraensningKonfiguration {
    postNr: string[];
}

export interface ForsyningPaaAdresseKonfiguration {
    eforsyningNodeId: number;
    svarmuligheder: ForsyningPaaAdresseSvarmulighederKonfiguration;
    afgraensning: ForsyningPaaAdresseAfgraensningKonfiguration;
}

export enum ForsyningPaaAdresseLeveringStatus {
    Arbejder = -1,
    LevererIkke = 0,
    Leverer = 1,
    LevererIFremtiden = 2
}

export interface ForsyningPaaAdresseResult {
    status: ForsyningPaaAdresseLeveringStatus;
    statusTekst: string;
    ejendomNr: number;
    tekst: string;
}

export interface Adresse {
    kommunenr: number;
    vejnr: number;
    husnr: number;
    husnrBogstav: string;
    etage: string;
    doer: string;
    postnr: string;
}

export interface AdresseSearch {
    tekst: string;
    adresse: Adresse;
}
