<dff-dropdown-button *ngIf="vm.visible" [titel]="vm.titel" [svgIcon]="vm.svgIcon" [href]="vm.href">
    <ul>
        <li *ngFor="let item of vm.items">
            <a [href]="item.href" (click)="onClick($event, item.href)">
                <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.svgIcon" [svgAlt]="'Lås ikon'"></mat-icon>
                {{ item.titel }}
            </a>
        </li>
    </ul>
</dff-dropdown-button>
