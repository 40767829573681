import { Injectable } from "@angular/core";
import { MatDatepickerIntl } from "@angular/material/datepicker";

@Injectable()
export class CustomDatepickerIntl extends MatDatepickerIntl {
    closeCalendarLabel = "Luk Kalender";
    openCalendarLabel = "Åbn kalender";
    prevMonthLabel = "Forrige måned";
    nextMonthLabel = "Næste måned";
    prevMultiYearLabel = "Forrige 24 år";
    nextMultiYearLabel = "Næste 24 år";
    switchToMonthViewLabel = "Skift til månedsvisning";
    switchToMultiYearViewLabel = "Skift til flerårig visning";
    cancelLabel = "Annuller";
    setTodayLabel = "Indstil i dag";
    weekNumberLabel = "Ugenummer";
    changesDetected = "Ændringer registreret";
    saveAndCloseLabel = "Gem og luk";
}
