import { AbstractControl } from "@angular/forms";

/*
Særligt moduluscheck af P-nummer:
Ved en fejl blev der i kodningen af nummergeneratoren for produktionsenheder anvendt
forkerte vægte i forhold til de vægte, der indgår i modulus 11.
Den forkerte nummergenerator blev anvendt til at danne serien af P-numre til de
konverterede produktionsenheder i forbindelse med CVR's idriftsættelse.
De anvendte forkerte vægte i konverteringen er 1 5 6 7 3 6 4 8 9
Mens de rigtige vægte i modulus 11 er 4 3 2 7 6 5 4 3 2
Den serie af P-numre, der tildeles driftmæssigt i CVR efter konverteringen, er dannet på baggrund af de rigtige vægte.
Højeste P-nummer dannet med de forkerte vægte er: 1.006.959.421. Højere P-numre er dannet med den rette vægt.
Kilde: https://www.kmd.dk/~/media/documents/snitfladebeskrivelser/kmd-udbetaling/id141-gf200001q-version-12-13-04-2007-pdf.pdf?la=da-dk
*/
export function pNummerValidator(): any {
    return (control: AbstractControl) => {
        if (erBlankVaerdi(control.value)) {
            return null; // Vi anser blank værdi for valid. Ønskes dette ikke, kan man benytte en RequiredValidator.
        }
        return erValidPNummer(control.value.toString()) ? null : error(control.value);
    };
}

function erBlankVaerdi(value: string): boolean {
    return value === null || value === undefined || value === "";
}

function erValidPNummer(value: string): boolean {
    return /^\d{10}$/.test(value) && erModulusValid(value);
}

function erModulusValid(value: string): boolean {
    const nyAlgoritme = +value > 1006959421;
    const vaegt = nyAlgoritme ? [4, 3, 2, 7, 6, 5, 4, 3, 2, 1] : [1, 5, 6, 7, 3, 6, 4, 8, 9, 1];
    const total = vaegt.reduce((acc, curr, i) => acc + curr * +value[i], 0);
    return total % 11 === 0;
}

function error(pNummer: string): any {
    return { pNummer };
}
