import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { ValgtForbrugerObserver } from "../bruger";
import { InstallationHttpClient } from "../http";
import { AktuelBrugersForbrugerTilknytningerObserver } from "./aktuel-brugers-forbruger-tilknytninger.observer";
import { ForbrugerTilknytningRepository } from "./forbruger-tilknytning.repository";
import { ForbrugerTilknytning } from "./model/forbruger-tilknytning.model";

@Injectable({ providedIn: "root" })
export class ForbrugerTilknytningService {
    constructor(
        private readonly installationHttpClient: InstallationHttpClient,
        private readonly valgtForbrugerObserver: ValgtForbrugerObserver,
        private readonly forbrugerTilknytningRepository: ForbrugerTilknytningRepository,
        private readonly aktuelBrugersForbrugerTilknytningerObserver: AktuelBrugersForbrugerTilknytningerObserver
    ) {}

    public hentTilknytningerForAktuelBruger(): Observable<ForbrugerTilknytning[]> {
        return this.aktuelBrugersForbrugerTilknytningerObserver.valueChanges;
    }

    public hentTilknytningerForValgtForbruger(): Observable<ForbrugerTilknytning[]> {
        const brugerId$ = this.valgtForbrugerObserver.valueChanges.pipe(map(b => (b && b.id) || null));
        return brugerId$.pipe(
            switchMap(brugerId => this.forbrugerTilknytningRepository.hentTilknytninger(brugerId, this.installationHttpClient))
        );
    }

    public async opretTilknytning(forsyningId: string, brugernavn: string, kodeord: string): Promise<void> {
        await this.forbrugerTilknytningRepository.opretTilknytning(forsyningId, brugernavn, kodeord);
        this.aktuelBrugersForbrugerTilknytningerObserver.triggerChanged();
    }

    public async sletTilknytning(tilknytningId: number): Promise<void> {
        await this.forbrugerTilknytningRepository.sletTilknytning(tilknytningId);
        this.aktuelBrugersForbrugerTilknytningerObserver.triggerChanged();
    }
}
