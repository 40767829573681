import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";

import { MenuModule } from "@dffedb/ui";

import { EforsyningSidenavMenuComponent } from "./eforsyning-sidenav-menu.component";

@NgModule({
    declarations: [EforsyningSidenavMenuComponent],
    imports: [CommonModule, MatFormFieldModule, MatSelectModule, MatProgressSpinnerModule, MenuModule],
    exports: [EforsyningSidenavMenuComponent]
})
export class EforsyningSidenavMenuModule {}
