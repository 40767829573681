import { Injectable } from "@angular/core";

import { first } from "rxjs/operators";

import { PreviousRouteService } from "@dffedb/router";

import { AppserverInstallationCredentialsRepository } from "../credentials";
import { AktuelForsyningIndstillingerObserver } from "../forsyning-indstillinger";
import { BrugerHttpClient, EksternForsyningHttpClient } from "../http";
import { RedirectService } from "../router";
import { serialiserAppserverCredentials } from "./../credentials/appserver-credentials.util";
import { AktuelBrugersInstallationerService } from "./aktuel-brugers-installationer.service";
import { Installation } from "./model/installation.model";

@Injectable({ providedIn: "root" })
export class VaelgInstallationService {
    constructor(
        private readonly brugerHttp: BrugerHttpClient,
        private readonly eksternHttp: EksternForsyningHttpClient,
        private readonly indstillinger: AktuelForsyningIndstillingerObserver,
        private readonly installationCredentials: AppserverInstallationCredentialsRepository,
        private readonly redirectService: RedirectService,
        private readonly previousRouteService: PreviousRouteService,
        private readonly aktuelBrugersInstallationerService: AktuelBrugersInstallationerService
    ) {}

    public async vaelgFoeresteInstallation(redirect: boolean = true): Promise<void> {
        const installationer = await this.aktuelBrugersInstallationerService.select().pipe(first()).toPromise();
        if (installationer.length) {
            await this.vaelgInstallation(installationer[0], redirect);
        }
    }

    public async vaelgInstallation(installation: Installation, redirect: boolean = true): Promise<void> {
        const indstillinger = await this.indstillinger.valueChanges.pipe(first()).toPromise();

        const erIntern = installation.forsyningIndstillinger.forsyningNodeId === indstillinger.forsyningNodeId;
        if (erIntern) {
            this.vaelgInternInstallation(installation, redirect);
        } else {
            this.vaelgEksternInstallation(installation);
        }
    }

    private vaelgInternInstallation(installation: Installation, redirect: boolean): void {
        const credentials = this.installationCredentials.hent();
        credentials.forsyningNodeId = installation.credentials.forsyningNodeId;
        credentials.forsyningId = installation.credentials.forsyningId;
        credentials.cryptId = installation.credentials.cryptId;
        credentials.ejendomNr = installation.ejendomNr;
        credentials.aktivNr = installation.aktivNr;
        credentials.installationNr = installation.installationNr;
        this.installationCredentials.gem(credentials);
        if (redirect) {
            this.redirectService.redirect(this.getRedirectRoute(this.previousRouteService.value));
        }
    }

    private vaelgEksternInstallation(installation: Installation): void {
        const eforsyningUrl = installation.forsyningIndstillinger.eforsyningUrl;
        this.eksternHttp.authenticate(installation.credentials).subscribe((http) => {
            const { ejendomNr, installationNr, aktivNr } = installation;
            const cryptId = http.credentials.cryptId;
            const origin = serialiserAppserverCredentials(this.brugerHttp.credentials);
            // eslint-disable-next-line max-len
            const url = `${eforsyningUrl}#/silent-login/unr/${ejendomNr}/inr/${installationNr}/anr/${aktivNr}/id/${cryptId}/origin/${origin}`;
            window.location.href = url;
        });
    }

    private getRedirectRoute(route: string): string {
        const empty = /^$/;
        const slash = /^\/$/;
        const logout = /^\/logout$/;
        const login = /^\/login$/;
        const loginRedirect = /^\/login\//;
        const installationer = /^install-picker$/;
        const deeplink = /^\/deeplink\//;
        const notFound = /^\/404$/;
        const ignoredRoutes = [empty, slash, logout, login, loginRedirect, installationer, deeplink, notFound];

        const ignoreRoute = ignoredRoutes.some((r) => r.test(route));
        return ignoreRoute ? "/mit-forbrug" : route;
    }
}
