import { AbstractControl } from "@angular/forms";

export function telefonnummerValidator(): any {
    return (control: AbstractControl) => {
        if (erBlankVaerdi(control.value)) {
            return null; // Vi anser blank værdi for valid. Ønskes dette ikke, kan man benytte en RequiredValidator.
        }
        return erValidTelefonr(control.value.toString()) ? null : error(control.value);
    };
}

export function erValidTelefonr(value: string): boolean {
    return /^[+]?[0-9]{8,}$/.test(value);
}

function erBlankVaerdi(value: string): boolean {
    return value === null || value === undefined || value === "";
}

function error(pNummer: string): any {
    return { pNummer };
}
