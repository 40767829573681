import { Injectable } from "@angular/core";

import MD5 from "md5-es";
import { combineLatest, Observable, of, throwError } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";

import { ValgtForbrugerObserver } from "../bruger";
import { AppserverBrugerCredentialsRepository, AppserverCredentials, AppserverUnauthenticatedCredentials, opretTomAppserverCredentials } from "../credentials";
import { ForbrugerOplysningerRepository } from "../forbruger-oplysninger";
import { ForbrugerRepository } from "../forbruger/forbruger.repository";
import { ForsyningIndstillinger, ForsyningIndstillingerService } from "../forsyning-indstillinger";
import { AppserverHttpClient, BrugerHttpClient, EksternForsyningHttpClient, InstallationHttpClient, ParametreForAktuelleInstallation } from "../http";
import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger";
import { ForbrugerTilknytning } from "./model/forbruger-tilknytning.model";

@Injectable({ providedIn: "root" })
export class ForbrugerTilknytningRepository {
    constructor(
        private readonly brugerHttpClient: BrugerHttpClient,
        private readonly installationHttpClient: InstallationHttpClient,
        private readonly eksternHttpClient: EksternForsyningHttpClient,
        private readonly forbrugerOplysningerRepository: ForbrugerOplysningerRepository,
        private readonly brugerCredentialsRepository: AppserverBrugerCredentialsRepository,
        private readonly forsyningIndstillingerService: ForsyningIndstillingerService,
        private readonly valgtForbrugerObserver: ValgtForbrugerObserver,
        private readonly umbracoIndstillingerObserver: UmbracoIndstillingerObserver,
        private readonly forbrugerRepository: ForbrugerRepository
    ) {}

    public async opretTilknytning(forsyningId: string, brugernavn: string, kodeord: string): Promise<void> {
        const appServerHttpClient = await this.eksternHttpClient.authenticate(brugernavn, MD5.hash(kodeord), forsyningId).pipe(first()).toPromise();

        const forbrugerHvisInstallationSkalAdministreres = await this.forbrugerRepository.hentForbruger(appServerHttpClient).pipe(first()).toPromise();

        const valgtForbruger = await this.valgtForbrugerObserver.valueChanges.pipe(first()).toPromise();

        return this.erInternForsyning(forsyningId)
            ? this.opretInternTilknytning(valgtForbruger.id, brugernavn, kodeord)
            : this.opretEksternTilknytning(valgtForbruger.id, forbrugerHvisInstallationSkalAdministreres.mailAdresse, forsyningId, brugernavn, kodeord);
    }

    public async sletTilknytning(tilknytningId: any): Promise<void> {
        const url = "api/SletTilknytning";
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const params = { RelationId: tilknytningId };
        await this.installationHttpClient.postWithStringResult(url, params).pipe(first()).toPromise();
    }

    public hentTilknytninger(brugerId: number, httpClient: AppserverHttpClient): Observable<ForbrugerTilknytning[]> {
        if (brugerId === null) {
            return of([]);
        }
        const forsyningId$ = this.umbracoIndstillingerObserver.valueChanges.pipe(map((i) => i.aktuelEforsyning && i.aktuelEforsyning.forsyningId));
        return forsyningId$.pipe(
            switchMap((forsyningId) => {
                const url = "api/henttilknytninger";
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const params = { EBrugerId: brugerId };
                return httpClient.post(url, params, ParametreForAktuelleInstallation.false).pipe(
                    map((d) => this.dtoToTilknytninger(d, forsyningId)),
                    switchMap((d) => this.tilfoejAuthenticationData(d)),
                    switchMap((d) => this.tilfoejForsyningIndstillinger(d)),
                    switchMap((d) => this.tilfoejForbruger(d))
                );
            })
        );
    }

    private async opretInternTilknytning(ebrugerId: number, brugernavn: string, kodeord: string): Promise<void> {
        const url = "api/tilknytejendom";
        const params = {
            eBrugerId: ebrugerId,
            email: this.isNumber(brugernavn) ? "" : brugernavn,
            tilknytEjdNr: this.isNumber(brugernavn) ? brugernavn : -1,
            pwd: MD5.hash(kodeord)
        };
        await this.installationHttpClient.postWithStringResult(url, params).pipe(first()).toPromise();
    }

    private async opretEksternTilknytning(ebrugerId: number, notificerEmail: string, forsyningId: string, brugernavn: string, kodeord: string): Promise<void> {
        const url = "api/TilfoejEksternTilknytning";
        const params = {
            /* eslint-disable @typescript-eslint/naming-convention */
            EBrugerId: ebrugerId,
            ForsyningId: forsyningId,
            EBrugerNavn: brugernavn,
            EBrugerKodeordKrypteret: MD5.hash(kodeord),
            NotificerEmail: notificerEmail
            /* eslint-enable @typescript-eslint/naming-convention */
        };
        await this.installationHttpClient.postWithStringResult(url, params).pipe(first()).toPromise();
    }

    private erInternForsyning(forsyningId: string): boolean {
        return !forsyningId || forsyningId.toUpperCase() === (this.installationHttpClient.credentials.forsyningId || "").toUpperCase();
    }

    private isNumber(value: any): boolean {
        return new RegExp("^[0-9]+$").test(value);
    }

    private dtoToTilknytninger(data: any, forsyningId: string): ForbrugerTilknytning<AppserverUnauthenticatedCredentials>[] {
        const interne = data.InterneTilknytninger.map((t) => this.dtoToTilknytning(t, forsyningId, true));
        const eksterne = data.EksterneTilknytninger.map((t) => this.dtoToTilknytning(t, forsyningId, false));
        return [...interne, ...eksterne];
    }

    private dtoToTilknytning(data: any, forsyningId: string, erInternTilknytning: boolean): ForbrugerTilknytning<AppserverUnauthenticatedCredentials> {
        const brugerForsyningId = this.brugerCredentialsRepository.hent().forsyningId;
        const erAktuelleSite = !data.ForsyningId || data.ForsyningId === brugerForsyningId;
        return {
            tilknytningId: data.RelationId,
            ejendomNr: data.EjendomNr,
            forbrugerNavn: data.Navn,
            forbrugerAdresse: data.Adresse,
            erAktuelleSite,
            erInternTilknytning,
            erEksternTilknytning: !erInternTilknytning,
            credentials: {
                forsyningId: data.ForsyningId || forsyningId,
                brugerNavn: data.EBrugerNavn,
                kodeordKrypteret: data.EBrugerKodeordKrypteret
            },
            forsyningIndstillinger: {
                forsyningId: data.ForsyningId || forsyningId,
                forsyningNodeId: null,
                appServerUrl: null,
                eforsyningUrl: null,
                forsyningNavn: null,
                sideId: null
            }
        };
    }

    private tilfoejAuthenticationData(tilknytninger: ForbrugerTilknytning<AppserverUnauthenticatedCredentials>[]): Observable<ForbrugerTilknytning[]> {
        if (!tilknytninger.length) {
            return of([]);
        }

        return combineLatest(tilknytninger.map((t) => this.hentCredentials(t.credentials).pipe(map((credentials) => ({ ...t, credentials })))));
    }

    private hentCredentials(auth: AppserverUnauthenticatedCredentials): Observable<AppserverCredentials> {
        if (auth.brugerNavn && auth.kodeordKrypteret && auth.forsyningId) {
            return this.eksternHttpClient.authenticate(auth.brugerNavn, auth.kodeordKrypteret, auth.forsyningId).pipe(
                map((api) => api.credentials),
                catchError(() =>
                    // TODO: Log info/warning om at vi ikke kan authenticate. Der er måske ikke adgang til serveren.
                    of(opretTomAppserverCredentials())
                )
            );
        }

        const brugerCredentials = this.brugerHttpClient.credentials;
        if (auth.forsyningId === brugerCredentials.forsyningId) {
            return of(brugerCredentials);
        }

        const installationCredentials = this.installationHttpClient.credentials;
        if (auth.forsyningId === installationCredentials.forsyningId) {
            return of(installationCredentials);
        }

        return throwError("Kan ikke oprette authentication data");
    }

    private tilfoejForsyningIndstillinger(tilknytninger: ForbrugerTilknytning[]): Observable<ForbrugerTilknytning[]> {
        if (!tilknytninger.length) {
            return of(new Array<ForbrugerTilknytning>());
        }

        return combineLatest(tilknytninger.map((t) => this.hentForsyningIndstillinger(t.forsyningIndstillinger.forsyningId).pipe(map((forsyningIndstillinger) => ({ ...t, forsyningIndstillinger })))));
    }

    private hentForsyningIndstillinger(forsyningId: string): Observable<ForsyningIndstillinger> {
        return this.forsyningIndstillingerService.hentForsyningIndstillinger(forsyningId);
    }

    private tilfoejForbruger(tilknytninger: ForbrugerTilknytning[]): Observable<ForbrugerTilknytning[]> {
        if (!tilknytninger.length) {
            return of(new Array<ForbrugerTilknytning>());
        }

        return combineLatest(tilknytninger.map((tilknytning) => this.hentForbruger(tilknytning).pipe(map((forbruger) => ({ ...tilknytning, ...forbruger })))));
    }

    private hentForbruger(tilknytning: ForbrugerTilknytning): Observable<Partial<ForbrugerTilknytning>> {
        if (tilknytning.forbrugerNavn) {
            return of(tilknytning);
        }

        // Vi har ikke kunnet authenticate. Så vi har ikke noget sted at hente fra.
        if (!tilknytning.credentials.cryptId) {
            return of(tilknytning);
        }

        return this.eksternHttpClient.authenticate(tilknytning.credentials).pipe(
            switchMap((api) => this.forbrugerOplysningerRepository.hentForbrugerOplysninger(api)),
            map((forbruger) => ({
                ejendomNr: forbruger.ejendomNr,
                forbrugerNavn: forbruger.navn,
                forbrugerAdresse: forbruger.adresse
            })),
            catchError(() =>
                // TODO: Log info/warning om at vi ikke kan hente data. Der er måske ikke adgang til serveren.
                of({ ejendomNr: 0, forbrugerNavn: "", forbrugerAdresse: "" })
            )
        );
    }
}
