import { ObservableInput, ObservedValueOf, OperatorFunction, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { DffError, getErrorMessage } from "@dffedb/util";

/**
 * rethrowDffError sørger for at konvertere eventuelle fejl om til DffError.
 */
export function rethrowDffError<T, O extends ObservableInput<any>>(): OperatorFunction<T, T | ObservedValueOf<O>> {
    return catchError(error => {
        const newError = error instanceof DffError ? error : new DffError(getErrorMessage(error), { innerError: error });
        return throwError(newError);
    });
}
