import { Component, ElementRef, HostBinding, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from "@angular/core";

import { Observable } from "rxjs";

import { UnsubscribeOnDestroy } from "@dffedb/util";

import { DffedbIframeSandbox } from "./dffedb-iframe.sandbox";
import { DffedbIframeViewModel } from "./dffedb-iframe.view-model";

@Component({
    templateUrl: "./dffedb-iframe.component.html",
    styleUrls: ["./dffedb-iframe.component.scss"]
})
export class DffedbIframeComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
    private iframe: any;
    @Input() public height: string;
    @Input() public width: string;
    @Input() public imageUrl: string;
    @Input() public src: string; // Krævet for at kunne lytte til ngOnChanges i forbindelse med Fancybox-indlejring
    @HostBinding("style.width") public styleWidth: string;
    @HostBinding("style.height") public styleHeight: string;

    public vm$: Observable<DffedbIframeViewModel>;

    constructor(
        private readonly sandbox: DffedbIframeSandbox,
        private readonly hostElement: ElementRef,
        private readonly renderer: Renderer2,
        private readonly el: ElementRef
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.initializeIframe();
    }

    public ngOnInit(): void {
        this.createIframe();
        this.initializeIframe();
        this.vm$ = this.sandbox.selectViewModel(this.src);
        this.subs.sink = this.vm$.subscribe((vm) => {
            if (vm.consentAccepted) {
                this.renderer.appendChild(this.hostElement.nativeElement, this.iframe);
            } else {
                this.renderer.removeChild(this.hostElement, this.iframe);
            }
        });
    }

    private createIframe(): void {
        this.iframe = this.renderer.createElement("iframe");
        // Ved indlejring i Fancybox, forventes det at <dffedb-iframe> emitter et load-event.
        this.iframe.onload = () => {
            this.el.nativeElement.dispatchEvent(new CustomEvent("load"));
        };

        if (!this.sandbox.getViewModel(this.src).consentAccepted) {
            setTimeout(() => this.el.nativeElement.dispatchEvent(new CustomEvent("load")));
        }
    }

    private initializeIframe(): void {
        this.styleWidth = this.calculateSize(this.width);
        this.styleHeight = this.calculateSize(this.height);
        if (this.iframe) {
            const attributes = Array.from(this.hostElement.nativeElement.attributes as NamedNodeMap).filter(
                (a) => a.name !== "ng-version" && !a.name.startsWith("_nghost-")
            );
            for (const attribute of attributes) {
                this.renderer.setAttribute(this.iframe, attribute.name, attribute.value);
            }
        }
    }

    private calculateSize(value: string): string {
        return !value ? "" : isNaN(+value) ? value : `${value}px`;
    }
}
