import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";

import { defineCustomElements } from "@dffedb/util";

import { DffCookieConsentOverlayModule } from "../shared/dff-cookie-consent-overlay";
import { DffedbFancyboxComponent } from "./dffedb-fancybox.component";

@NgModule({
    imports: [CommonModule, DffCookieConsentOverlayModule],
    exports: [DffedbFancyboxComponent],
    declarations: [DffedbFancyboxComponent],
    providers: []
})
export class DffedbFancyboxModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-fancybox", DffedbFancyboxComponent, { injector });
    }
}
