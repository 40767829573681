import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { AuthenticationResult } from "../auth/authentication-result.model";
import { AuthenticationService } from "../auth/authentication.service";
import { AppserverCredentials, AppserverEksternForsyningCredentialsRepository } from "../credentials";
import { AppserverHttpClientFactory } from "./appserver-http-client-factory.service";
import { AppserverHttpClient } from "./appserver-http-client.service";

@Injectable({ providedIn: "root" })
export class EksternForsyningHttpClient {
    constructor(
        private readonly authenticationService: AuthenticationService,
        private readonly appserverHttpClientFactory: AppserverHttpClientFactory
    ) {}

    public authenticate(credentials: AppserverCredentials): Observable<AppserverHttpClient>;
    public authenticate(brugernavn: string, hashedKodeord: string, forsyningId: string): Observable<AppserverHttpClient>;

    public authenticate(
        brugernavnOrCredentials: string | AppserverCredentials,
        hashedKodeord?: string,
        forsyningId?: string
    ): Observable<AppserverHttpClient> {
        return typeof brugernavnOrCredentials === "object"
            ? this.authenticateWithCredentials(brugernavnOrCredentials)
            : this.authenticateWithUsername(brugernavnOrCredentials, hashedKodeord, forsyningId);
    }

    private authenticateWithCredentials(credentials: AppserverCredentials): Observable<AppserverHttpClient> {
        const credentialsRepository = new AppserverEksternForsyningCredentialsRepository(credentials);
        return of(this.appserverHttpClientFactory.create(credentialsRepository, this.authenticationService));
    }

    private authenticateWithUsername(brugernavn: string, hashedKodeord: string, forsyningId: string): Observable<AppserverHttpClient> {
        return this.authenticationService.authenticate(brugernavn, hashedKodeord, forsyningId).pipe(
            map((authenticationResult: AuthenticationResult) => {
                // TODO: Vi skal på en eller anden måde håndtere login-fejl. Skal der kastes en exception,
                // returneres null, eller returneres et objekt med success-property?
                // if (!authenticationResult.success)
                //    return throwError("Ugyldig brugernavn eller kodeord");

                const credentials: AppserverCredentials = {
                    forsyningNodeId: null, // TODO, NIM: Bruges denne til noget?
                    forsyningId,
                    cryptId: authenticationResult.cryptId
                };
                const credentialsRepository = new AppserverEksternForsyningCredentialsRepository(credentials);
                return this.appserverHttpClientFactory.create(credentialsRepository, this.authenticationService);
            })
        );
    }
}
