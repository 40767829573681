import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { mdiLockOpenVariantOutline, mdiLockOutline } from "@mdi/js";

import { defineCustomElements, SvgIconRegistry } from "@dffedb/util";

import { DffDropdownButtonModule } from "../shared/dff-dropdown-button";
import { EForsyningLogindKnapComponent } from "./e-forsyning-logind-knap.component";

@NgModule({
    imports: [CommonModule, MatIconModule, DffDropdownButtonModule],
    exports: [],
    declarations: [EForsyningLogindKnapComponent],
    providers: []
})
export class EForsyningLogindKnapModule {
    constructor(injector: Injector, svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiLockOutline, "lock");
        svgIconRegistry.registerIcon(mdiLockOpenVariantOutline, "lock-open");
        defineCustomElements("e-forsyning-logind-knap", EForsyningLogindKnapComponent, { injector });
    }
}
