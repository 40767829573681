<form #form="ngForm" [formGroup]="formGroup" class="step-oplysninger">
    <pre *ngIf="false">
        {{ formGroup.getRawValue() | json }}
    </pre>
    <div class="privat-eller-virksomhed">
        <mat-label color="accent" (click)="privatEllerVirksomhedToggle.toggle()"
            [class.active]="!privatEllerVirksomhedToggle.checked">Privat</mat-label>
        <mat-slide-toggle class="always-colored" color="primary" formControlName="erVirksomhed"
            #privatEllerVirksomhedToggle></mat-slide-toggle>
        <mat-label (click)="privatEllerVirksomhedToggle.toggle()" [class.active]="privatEllerVirksomhedToggle.checked">
            Erhverv</mat-label>
    </div>

    <div class="step-content">
        <mat-form-field class="large-input" *ngIf="!formGroup.control('erVirksomhed').value">
            <mat-label>For- og efternavn</mat-label>
            <input formControlName="navn" type="text" matInput required autocomplete="name" />
            <mat-error *ngIf="formGroup.get('navn').errors?.required"> Indtast dit fulde navn </mat-error>
            <mat-error *ngIf="formGroup.get('navn').errors?.maxlength"> Navn må indeholde 30 tegn </mat-error>
        </mat-form-field>
        <mat-form-field class="large-input" *ngIf="formGroup.control('erVirksomhed').value">
            <mat-label>Firmanavn</mat-label>
            <input formControlName="navn" type="text" matInput required />
            <mat-error *ngIf="formGroup.get('navn').errors?.required"> Indtast virksomhedens navn </mat-error>
            <mat-error *ngIf="formGroup.get('navn').errors?.maxlength"> Firmanavn må indeholde 30 tegn </mat-error>
        </mat-form-field>
        <mat-form-field class="large-input" *ngIf="formGroup.control('erVirksomhed').value">
            <mat-label>Kontaktperson</mat-label>
            <input formControlName="ekstraPersonNavn" type="text" matInput />
            <mat-error *ngIf="formGroup.get('ekstraPersonNavn').errors?.required"> Indtast kontaktpersonens fulde navn
            </mat-error>
            <mat-error *ngIf="formGroup.get('ekstraPersonNavn').errors?.maxlength"> Kontaktperson må indeholde 30 tegn
            </mat-error>
        </mat-form-field>

        <mat-label class="adresse">{{adresse2}}</mat-label>

        <mat-form-field class="large-input">
            <mat-label>Telefon</mat-label>
            <input formControlName="telefon" type="tel" matInput required />
            <mat-error *ngIf="formGroup.get('telefon').hasError('required')"> Indtast telefonnummer </mat-error>
            <mat-error *ngIf="formGroup.get('telefon').hasError('pattern')"> Indtast gyldigt telefonnummer </mat-error>
        </mat-form-field>
        <mat-form-field class="large-input">
            <mat-label>E-mail</mat-label>
            <input formControlName="email" type="email" matInput required />
            <mat-error *ngIf="formGroup.get('email').hasError('required')"> Indtast e-mail </mat-error>
            <mat-error *ngIf="formGroup.get('email').hasError('email')"> Indtast gyldig e-mail </mat-error>
        </mat-form-field>
        <mat-form-field class="large-input">
            <mat-label>Gentag e-mail</mat-label>
            <input name="search" formControlName="emailGentag" type="email" matInput required
                autocomplete="dont-autofill-hack" />
            <mat-error *ngIf="formGroup.get('emailGentag').hasError('required')"> Indtast e-mail </mat-error>
            <mat-error *ngIf="formGroup.get('emailGentag').hasError('email')"> Indtast gyldig e-mail </mat-error>
            <mat-error
                *ngIf="formGroup.get('emailGentag').hasError('mustMatch') && !formGroup.get('emailGentag').hasError('email')">
                De to e-mails stemmer ikke overens
            </mat-error>
        </mat-form-field>
        <mat-form-field class="large-input" *ngIf="konfiguration.steps.personligeOplysninger.visBemaerkning">
            <mat-label>Bemærkning</mat-label>
            <textarea formControlName="bemaerkning" matInput matTextareaAutosize matAutosizeMinRows="2"> </textarea>
        </mat-form-field>

        <ng-container *ngIf="konfiguration.visEBoksTilmelding">
            <hr />

            <ng-container *ngIf="!formGroup.control('erVirksomhed').value">
                <mat-checkbox formControlName="oenskerEboks" class="accepter-vilkaar">Jeg ønsker at modtage post via
                    e-Boks</mat-checkbox>
                <div class="step-content" *ngIf="formGroup.control('oenskerEboks').value">
                    <mat-form-field class="large-input">
                        <mat-label>CPR-nummer</mat-label>
                        <input formControlName="cprNummer" type="text" mask="000000-0000" matInput
                            [required]="isRequired('cprNummer')" />
                        <mat-error *ngIf="formGroup.get('cprNummer').invalid"> Indtast dit CPR-nummer </mat-error>
                    </mat-form-field>
                    <mat-form-field class="large-input">
                        <mat-label>Gentag CPR-nummer</mat-label>
                        <input formControlName="cprNummerGentag" type="text" mask="000000-0000" matInput
                            [required]="isRequired('cprNummerGentag')" autocomplete="dont-autofill-hack" />
                        <mat-error *ngIf="formGroup.get('cprNummerGentag').hasError('required')"> Indtast dit CPR-nummer
                        </mat-error>
                        <mat-error
                            *ngIf="formGroup.get('cprNummerGentag').hasError('mustMatch') && !formGroup.get('cprNummerGentag').hasError('required')">
                            De to CPR-numre stemmer ikke overens </mat-error>
                    </mat-form-field>
                    <div class="checkbox-wrapper">
                        <mat-checkbox formControlName="samtykkeTekstAccepteret" class="accepter-vilkaar"
                            id="mat-checkbox-samtykke">Jeg accepterer betingelserne</mat-checkbox>&nbsp;<a href="#"
                            (click)="onLaesSamtykkeClick($event, formGroup.control('samtykkeTekst').value)">(læs
                            betingelserne)</a>
                    </div>
                    <mat-error *ngIf="isErrorState('samtykkeTekstAccepteret')">
                        Vi har brug for samtykke for at kunne sende via e-Boks
                    </mat-error>
                </div>
            </ng-container>

            <ng-container *ngIf="formGroup.control('erVirksomhed').value">
                <mat-checkbox formControlName="oenskerEboks" class="accepter-vilkaar">Vi ønsker at modtage post via
                    e-Boks</mat-checkbox>
                <div class="step-content" *ngIf="formGroup.control('oenskerEboks').value">
                    <mat-form-field class="large-input">
                        <mat-label>CVR-nummer</mat-label>
                        <input formControlName="cvrNummer" type="text" mask="00000000" matInput
                            [required]="isRequired('cvrNummer')" />
                        <mat-error *ngIf="formGroup.get('cvrNummer').invalid"> Indtast virksomhedens CVR-nummer
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="large-input">
                        <mat-label>P-nummer</mat-label>
                        <input formControlName="pNummer" type="text" mask="0000000000" matInput />
                        <mat-error *ngIf="formGroup.get('pNummer').invalid"> Indtast eventuelt virksomhedens P-nummer
                        </mat-error>
                    </mat-form-field>
                    <div class="checkbox-wrapper">
                        <mat-checkbox formControlName="samtykkeTekstAccepteret" class="accepter-vilkaar">Vi accepterer
                            betingelserne</mat-checkbox>&nbsp;<a href="#"
                            (click)="onLaesSamtykkeClick($event, formGroup.control('samtykkeTekst').value)">(læs
                            betingelserne)</a>
                    </div>
                    <mat-error *ngIf="isErrorState('samtykkeTekstAccepteret')">
                        Vi har brug for samtykke for at kunne sende via e-Boks
                    </mat-error>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <ng-container *ngIf="!formGroup.control('erVirksomhed').value && konfiguration.visEBoksTilmelding">
        <hr />
        <mat-slide-toggle formControlName="ekstraPerson">Er der flere ejere på adressen?</mat-slide-toggle>
        <div class="step-content" *ngIf="formGroup.control('ekstraPerson').value">
            <mat-form-field class="large-input">
                <mat-label>Medejers for og -efternavn</mat-label>
                <input formControlName="ekstraPersonNavn" type="text" matInput
                    [required]="isRequired('ekstraPersonNavn')" />
                <mat-error *ngIf="formGroup.get('ekstraPersonNavn').errors?.required"> Indtast medejers fulde navn
                </mat-error>
                <mat-error *ngIf="formGroup.get('ekstraPersonNavn').errors?.maxlength"> Navn må indeholde 30 tegn
                </mat-error>
            </mat-form-field>
            <mat-form-field class="large-input">
                <mat-label>Telefon</mat-label>
                <input formControlName="ekstraPersonTelefon" type="tel" matInput />
                <mat-error *ngIf="formGroup.get('ekstraPersonTelefon').hasError('pattern')"> Indtast gyldigt
                    telefonnummer </mat-error>
            </mat-form-field>
            <mat-form-field class="large-input">
                <mat-label>E-mail</mat-label>
                <input formControlName="ekstraPersonEmail" type="email" matInput />
                <mat-error *ngIf="formGroup.get('ekstraPersonEmail').hasError('email')"> Indtast gyldig e-mail
                </mat-error>
            </mat-form-field>
            <mat-form-field class="large-input">
                <mat-label>Gentag e-mail</mat-label>
                <input name="search" formControlName="ekstraPersonEmailGentag" type="email" matInput
                    [required]="isDirty('ekstraPersonEmail')" autocomplete="dont-autofill-hack" />
                <mat-error *ngIf="formGroup.get('ekstraPersonEmailGentag').hasError('email')"> Indtast gyldig e-mail
                </mat-error>
                <mat-error
                    *ngIf="formGroup.get('ekstraPersonEmailGentag').hasError('mustMatch') && !formGroup.get('ekstraPersonEmailGentag').hasError('email')">
                    De to e-mails stemmer ikke overens
                </mat-error>
            </mat-form-field>
            <ng-container *ngIf="konfiguration.visEBoksTilmelding">
                <mat-checkbox formControlName="ekstraPersonOenskerEboks" class="accepter-vilkaar">Jeg (medejer) ønsker
                    at modtage post via e-Boks</mat-checkbox>
                <div class="step-content" *ngIf="formGroup.control('ekstraPersonOenskerEboks').value">
                    <mat-form-field class="large-input">
                        <mat-label>Medejers CPR-nummer</mat-label>
                        <input formControlName="ekstraPersonCprNummer" type="text" mask="000000-0000" matInput
                            [required]="isRequired('ekstraPersonCprNummer')" />
                        <mat-error *ngIf="formGroup.get('ekstraPersonCprNummer').invalid"> Indtast medejers CPR-nummer
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="large-input">
                        <mat-label>Gentag CPR-nummer</mat-label>
                        <input formControlName="ekstraPersonCprNummerGentag" type="text" mask="000000-0000" matInput
                            [required]="isRequired('ekstraPersonCprNummerGentag')" autocomplete="dont-autofill-hack" />
                        <mat-error *ngIf="formGroup.get('ekstraPersonCprNummerGentag').hasError('required')"> Indtast
                            dit CPR-nummer </mat-error>
                        <mat-error
                            *ngIf="formGroup.get('ekstraPersonCprNummerGentag').hasError('mustMatch') && !formGroup.get('ekstraPersonCprNummerGentag').hasError('required')">
                            De to CPR-numre stemmer ikke overens </mat-error>
                    </mat-form-field>




                    <div class="checkbox-wrapper">
                        <mat-checkbox formControlName="ekstraPersonSamtykkeTekstAccepteret" class="accepter-vilkaar">Jeg
                            accepterer betingelserne</mat-checkbox>&nbsp;<a href="#"
                            (click)="onLaesSamtykkeClick($event, formGroup.control('ekstraPersonSamtykkeTekst').value)">(læs
                            betingelserne)</a>
                    </div>
                    <mat-error *ngIf="isErrorState('ekstraPersonSamtykkeTekstAccepteret')">
                        Vi har brug for samtykke for at kunne sende via e-Boks
                    </mat-error>
                </div>
            </ng-container>
        </div>
    </ng-container>
</form>