<span (click)="killHostClickListener($event)">
    <button
        mat-flat-button
        [color]="color"
        (click)="onClick($event)"
        [disabled]="disabled || spinning"
        [class.spinning]="spinning"
        role="button"
        [attr.type]="buttonType"
    >
        <mat-spinner *ngIf="spinning" color="basic" diameter="25"></mat-spinner>
        <ng-content></ng-content>
    </button>
</span>
