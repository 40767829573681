import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";

import { ControlsOf } from "@dffedb/forms";

export interface EBoksAdressekodeModel {
    kode: string;
    valid: boolean;
}

export type EBoksAdressekodeControls = ControlsOf<EBoksAdressekodeModel>;

export class EBoksAdressekodeFormBuilder {
    public static opretAdressekodeFormGroup(disabled: boolean): FormGroup<EBoksAdressekodeControls> {
        const result = new FormGroup<EBoksAdressekodeControls>({
            kode: new FormControl(
                // kode består af 6 cifret checksum + 6-9 cifret adresseNr
                null,
                [Validators.required, Validators.minLength(12), Validators.maxLength(15)]
            ),
            valid: new FormControl(false, [Validators.requiredTrue])
        });
        this.updateDisabled(result, disabled);
        return result;
    }

    public static updateDisabled(formGroup: FormGroup, disabled: boolean): void {
        this.setDisabled(formGroup.get("kode"), disabled);
    }

    private static setDisabled(control: AbstractControl, disabled: boolean): void {
        if (disabled) {
            control.disable();
        } else {
            control.enable();
        }
    }
}
