import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { DffCookieConsentCookie } from "../dff-cookie-consent/dff-cookie-consent-cookie.model";

@Component({
    selector: "dff-cookie-consent-cookie-list",
    templateUrl: "./dff-cookie-consent-cookie-list.component.html",
    styleUrls: ["./dff-cookie-consent-cookie-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DffCookieConsentCookieListComponent {
    @Input()
    public cookies: DffCookieConsentCookie[];

    constructor() {}
}
