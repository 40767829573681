import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";

import { DffError, trim, trimRight } from "@dffedb/util";

import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger/umbraco-indstillinger.observer";

@Injectable({ providedIn: "root" })
export class UmbracoHttpClient {
    private baseUrl = "";

    constructor(private readonly http: HttpClient, private readonly indstillinger: UmbracoIndstillingerObserver) {}

    public setBaseUrl(value: string): void {
        this.baseUrl = trimRight(value || "", "/");
    }

    public get<T = any>(route: string): Observable<T> {
        const absoluteUrl = this.getAbsoluteUrl(`/umbraco/dff/dffapi/${route}`);
        return this.http.get<T>(absoluteUrl);
    }

    public hentSideDataForId<T = any>(nodeId: number): Observable<T> {
        const absoluteUrl = this.getAbsoluteUrl(`/${nodeId}`);
        return this.http.get(absoluteUrl).pipe(
            map((data: any) => this.arrayToObject((data && data.vaerksdata) || []) as T),
            catchError((error) => throwError(new DffError(`Fejl ved kald til hentSideDataForId('${nodeId}')`, { innerError: error })))
        );
    }

    public hentSideData<T = any>(side: string): Observable<T> {
        side = trim(side, "/");
        return this.indstillinger.valueChanges.pipe(
            first(),
            map((indstillinger) => indstillinger.aktuelEforsyning.siteUrl + side),
            map((url) => this.customTrim(url, "?alttemplate=json")), // I tilfælde af at side="" (henter E|Forsyning-indstillinger)
            switchMap((absoluteUrl) => this.http.get(absoluteUrl)),
            map((data: any) => this.arrayToObject((data && data.vaerksdata) || []) as T),
            catchError((error) => throwError(new DffError(`Fejl ved kald til hentSideData('${side}')`, { innerError: error })))
        );
    }

    public hent<T = any>(sektion: string, umbracoId: string): Observable<T> {
        sektion = sektion.replace("/", "");
        const indeholderQueryString = sektion.indexOf("?") !== -1;
        const queryStringChar = indeholderQueryString ? "&" : "?";
        const relativeUrl = `/umbraco/dff/dffapi/${sektion}${queryStringChar}id=${umbracoId}`;
        const absoluteUrl = this.getAbsoluteUrl(relativeUrl);
        return this.http.get<T>(absoluteUrl);
    }

    public post<T = any>(relativeUrl: string, body: any): Observable<T> {
        const absoluteUrl = this.getAbsoluteUrl(relativeUrl);
        return this.http.post<T>(absoluteUrl, body);
    }

    public postWithStringResult(relativeUrl: string, body: any): Observable<string> {
        const absoluteUrl = this.getAbsoluteUrl(relativeUrl);
        return this.http.post(absoluteUrl, body, { responseType: "text" });
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private arrayToObject<T>(data: { Alias: string; Value: string }[]): T {
        const result = data.reduce((acc, item) => ({ ...acc, [item.Alias]: item.Value }), {});
        return result as T;
    }

    private getAbsoluteUrl(relativeUrl: string): string {
        // baseUrl vil som regel være blank, da javascipt-koden oftest kører på samme site hvor Umbraco er installeret.
        // Den har imidlertid en værdi, når der er tale om Web Components, da de skal kunne køre på en ekstern side.
        const separator = this.baseUrl.endsWith("/") || relativeUrl.startsWith("/") ? "" : "/";
        return `${this.baseUrl}${separator}${relativeUrl}`;
    }

    private customTrim(str: string, suffix: string) {
        if (str.endsWith(suffix)) {
            return str.slice(0, -suffix.length);
        } else {
            return str;
        }
    }
}
