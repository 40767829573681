<a *ngIf="href; else typeButton" [href]="href" [id]="titel.replace(' ', '-') + 'link'" (click)="onClick($event, href)" [class]="class">
    <span>
        <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
        <span>{{ titel }}</span>
    </span>
</a>
<div
    class="dropdown-content"
    [id]="titel.replace(' ', '-') + '-dropdown-content'"
    [attr.aria-labelledby]="titel.replace(' ', '-') + '-button'"
>
    <ng-content></ng-content>
</div>

<ng-template #typeButton>
    <button
        #dropdown
        [id]="titel.replace(' ', '-') + '-button'"
        (click)="onClick($event, href)"
        [class]="class + ' dff-dropdown-button'"
        role="button"
        type="button"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <span>
            <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
            <span>{{ titel }}</span>
        </span>
    </button>
</ng-template>
