import { BehaviorSubject, Observable } from "rxjs";

export enum ProcessingState {
    idle,
    processing,
    justProcessed,
    processed,
    errored
}
export class Processing<T> {
    constructor(private state: ProcessingState, public value: T, public error: string) {}

    public get idle(): boolean {
        return this.state === ProcessingState.idle;
    }

    public get errored(): boolean {
        return this.state === ProcessingState.errored;
    }

    public get processing(): boolean {
        return this.state === ProcessingState.processing;
    }

    public get justProcessed(): boolean {
        return this.state === ProcessingState.justProcessed;
    }

    public get processed(): boolean {
        return this.state === ProcessingState.justProcessed || this.state === ProcessingState.processed;
    }
}

// eslint-disable-next-line max-classes-per-file
export class ProcessingSubject<T> {
    private subject = new BehaviorSubject<Processing<T>>(this.idleState(null));

    public asObservable(): Observable<Processing<T>> {
        return this.subject.asObservable();
    }

    public idle(value: T): void {
        this.subject.next(this.idleState(value));
    }

    public processing(value: T): void {
        this.subject.next(this.processingState(value));
    }

    public processed(value: T): void {
        this.subject.next(this.justProcessedState(value));
        this.subject.next(this.processedState(value));
    }

    public errored(error: any): void {
        this.subject.next(this.erroredState(error));
    }

    private idleState(value: T): Processing<T> {
        return new Processing<T>(ProcessingState.idle, value, null);
    }

    private processingState(value: T): Processing<T> {
        return new Processing<T>(ProcessingState.processing, value, null);
    }

    private processedState(value: T): Processing<T> {
        return new Processing<T>(ProcessingState.processed, value, null);
    }

    private justProcessedState(value: T): Processing<T> {
        return new Processing<T>(ProcessingState.justProcessed, value, null);
    }

    private erroredState(error: any): Processing<T> {
        const errorMessage = !error ? "Der er opstået en fejl" : typeof error === "string" ? error : error.message || error.toString();
        return new Processing<T>(ProcessingState.errored, null, errorMessage);
    }
}
