import { AbstractControl } from "@angular/forms";

import { trim } from "@dffedb/util";

import { emailRegexp } from "./validators.email";

export function emailsValidator(separator: string = ","): any {
    return (control: AbstractControl) => {
        if (control.value === null || control.value === undefined || control.value.length === 0) {
            return null;
        }

        const emails = (control.value + "").split(separator).map((email) => trim(email));
        const results = emails.map((email) => emailRegexp.test(email));
        return results.every((r) => r) ? null : { email: true };
    };
}
