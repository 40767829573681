import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";

import { defineCustomElements } from "@dffedb/util";
import { ForsyningPaaAdresseModule } from "@e-forsyning/common/forsyning-paa-adresse";

import { DffedbForsyningPaaAdresseComponent } from "./dffedb-forsyning-paa-adresse.component";

@NgModule({
    imports: [CommonModule, ForsyningPaaAdresseModule],
    exports: [DffedbForsyningPaaAdresseComponent],
    declarations: [DffedbForsyningPaaAdresseComponent],
    providers: []
})
export class DffedbForsyningPaaAdresseModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-forsyning-paa-adresse", DffedbForsyningPaaAdresseComponent, { injector });
    }
}
