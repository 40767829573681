import { Injectable, Injector, Type } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";

import { from, isObservable, Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SequentialGuards implements CanActivate {
    private route: ActivatedRouteSnapshot;
    private state: RouterStateSnapshot;

    constructor(private readonly injector: Injector) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        this.route = route;
        this.state = state;

        if (!this.route?.data?.sequentialGuards?.length) {
            return Promise.resolve(true);
        }
        const sequentialGuards = this.route.data.sequentialGuards.map(
            (g: Type<CanActivate>) => this.injector.get(g) as CanActivate
        ) as CanActivate[];

        return this.executeGuardsSequentially(sequentialGuards);
    }

    private executeGuardsSequentially(guards: CanActivate[]): Observable<boolean | UrlTree> {
        return guards.reduce(
            (prev, curr) => prev.pipe(switchMap((p) => (p === true ? this.toObservable(curr.canActivate(this.route, this.state)) : of(p)))),
            of(true as boolean | UrlTree)
        );
    }

    private toObservable(
        value: boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree>
    ): Observable<boolean | UrlTree> {
        return isObservable(value) ? value : from(Promise.resolve(value));
    }
}
