import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DffKvitteringComponent } from "./dff-kvittering.component";

@NgModule({
    imports: [CommonModule],
    exports: [DffKvitteringComponent],
    declarations: [DffKvitteringComponent]
})
export class DffKvitteringModule {}
