import { Injectable } from "@angular/core";

import { first } from "rxjs/operators";

import { DffCurrentRoute, DffRedirectService } from "@dffedb/router";

import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger";

@Injectable({ providedIn: "root" })
export class RedirectService {
    constructor(
        private readonly redirectService: DffRedirectService,
        private readonly currentRoute: DffCurrentRoute,
        private readonly indstillinger: UmbracoIndstillingerObserver
    ) {}

    public redirectTilDefault(): void {
        this.redirectService.redirect("/");
    }

    public redirectTilLogin(redirectTilAktuelleRouteEfterLogin: boolean = false): void {
        const redirectUrl = redirectTilAktuelleRouteEfterLogin ? this.currentRoute.value.path : "";
        this.redirectService.redirect(`/login${redirectUrl}`);
    }

    public redirectTilFlyttekvittering(parameter: any): void {
        this.redirectService.redirect("/meld-flytning/kvittering", { data: btoa(JSON.stringify(parameter)) });
    }

    public redirectTilInstallationer(): void {
        this.redirectService.redirect("/install-picker");
    }

    public redirectTilMitForbrug(): void {
        this.redirectService.redirect("/mit-forbrug");
    }

    public redirectTilMineDokumenter(): void {
        this.redirectService.redirect("/mine-dokumenter");
    }

    public redirect(path: string, params?: any): void {
        this.redirectService.redirect(path, params);
    }

    public redirectTilVaerksListe(): void {
        this.indstillinger.valueChanges
            .pipe(first()) // Auto-unsubscribe
            .subscribe((indstillinger) => {
                location.href = indstillinger.vaerkslisteUrl;
            });
    }
}
