<ng-content *ngIf="items"></ng-content>
<li *ngFor="let item of items" class="menu-item" [className]="item.children ? 'menu-item-internal' : 'menu-item-leaf'" role="listitem">
    <ng-container *ngIf="!item.children?.length">
        <a
            *ngIf="item.route"
            [routerLink]="item.route"
            (click)="onItemClick($event, item.href)"
            [attr.title]="item.title"
            routerLinkActive="active"
        >
            <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
            <span>{{ item.title }}</span></a
        >
        <a *ngIf="!item.route" [href]="item.href" (click)="onItemClick($event, item.href)" [attr.title]="item.title">
            <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
            <span>{{ item.title }}</span></a
        >
    </ng-container>
    <mat-expansion-panel *ngIf="item.children && item.children.length" class="mat-elevation-z0">
        <mat-expansion-panel-header>
            <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
            <span>
                {{ item.title }}
            </span>
        </mat-expansion-panel-header>
        <dff-sidenav-menu
            [items]="item.children"
            (itemClick)="itemClick.emit($event)"
            [id]="item.title + '-sidenav-menu'"
            role="list"
        ></dff-sidenav-menu>
    </mat-expansion-panel>
</li>
