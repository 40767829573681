import { Injectable } from "@angular/core";

import { beregnDecimalerIInternationalMaske, dkMaskeTilInternationalMaske } from "@dffedb/util";
import { EjendomsfaktorDefinition, EjendomsfaktorDefinitionValgmulighed } from "@e-forsyning/common/ejendomsfaktor";

import {
    TilslutningsanmodningDto,
    TilslutningsanmodningEjendomsfaktorDefinitionDto,
    TilslutningsanmodningEjendomsfaktorDefinitionValgmulighedDto,
    TilslutningsanmodningOplysningerDto
} from "../model/tilslutningsanmodning.dto";
import { TilslutningsanmodningModel, TilslutningsanmodningOplysningerModel } from "../model/tilslutningsanmodning.model";

@Injectable({ providedIn: "root" })
export class TilslutningsanmodningMapper {
    public dtoToEjendomsfaktorDefinitioner(dto: TilslutningsanmodningEjendomsfaktorDefinitionDto[]): EjendomsfaktorDefinition[] {
        const result = dto.map((d) => this.dtoToEjendomsfaktorDefinition(d));
        return result;
    }

    public dtoToEjendomsfaktorDefinition(dto: TilslutningsanmodningEjendomsfaktorDefinitionDto): EjendomsfaktorDefinition {
        const maske = dkMaskeTilInternationalMaske(dto.formater);
        const result: EjendomsfaktorDefinition = {
            datatype: dto.alfa,
            decimaler: beregnDecimalerIInternationalMaske(maske),
            enhed: dto.enhed,
            id: dto.faktor,
            maske,
            maxVaerdi: this.beregnMaxVaerdi(dto.vaerdiMaximum),
            minVaerdi: this.beregnMinVaerdi(dto.vaerdiMinimum),
            navn: dto.faktorTekst,
            readonly: false,
            valgmuligheder: this.dtoToEjendomsfaktorDefinitionValgmuligheder(dto.relationer)
        };
        return result;
    }

    public tilslutningsanmodningModelToDto(data: TilslutningsanmodningModel): TilslutningsanmodningDto {
        const result: TilslutningsanmodningDto = {
            oplysninger: this.tilslutningsanmodningOplysningerModelToDto(data.oplysninger),
            ejendomsfaktorer: data.ejendomsfaktorer.map((e) => ({
                definitionId: e.definitionId,
                vaerdi: e.vaerdi.aktuel
            }))
        };
        return result;
    }

    public tilslutningsanmodningOplysningerModelToDto(data: TilslutningsanmodningOplysningerModel): TilslutningsanmodningOplysningerDto {
        const cprGodkendt = !data.erVirksomhed && data.oenskerEboks && data.samtykkeTekstAccepteret;
        const cvrGodkendt = data.erVirksomhed && data.oenskerEboks && data.samtykkeTekstAccepteret;
        const ekstraPersonCprGodkendt = !data.erVirksomhed && data.ekstraPerson && data.ekstraPersonOenskerEboks && data.ekstraPersonSamtykkeTekstAccepteret;

        const result: TilslutningsanmodningOplysningerDto = {
            erVirksomhed: data.erVirksomhed,

            navn: data.navn,
            email: data.ekstraPerson || data.erVirksomhed ? data.email + ";" + data.ekstraPersonEmail : data.email,
            telefon: data.telefon,
            cprNummer: cprGodkendt ? data.cprNummer : null,
            cvrNummer: cvrGodkendt ? data.cvrNummer : null,
            pNummer: cvrGodkendt ? data.pNummer : null,
            oenskerEboks: cprGodkendt || cvrGodkendt,
            samtykkeTekst: cprGodkendt || cvrGodkendt ? data.samtykkeTekst : null,
            samtykkeTekstAccepteret: cprGodkendt || cvrGodkendt,

            ekstraPerson: data.ekstraPerson || data.erVirksomhed,
            ekstraPersonNavn: data.ekstraPerson || data.erVirksomhed ? data.ekstraPersonNavn : null,
            ekstraPersonTelefon: data.ekstraPerson || data.erVirksomhed ? data.ekstraPersonTelefon : null,
            ekstraPersonCprNummer: ekstraPersonCprGodkendt ? data.ekstraPersonCprNummer : null,
            ekstraPersonOenskerEboks: ekstraPersonCprGodkendt,
            ekstraPersonSamtykkeTekst: ekstraPersonCprGodkendt ? data.ekstraPersonSamtykkeTekst : null,
            ekstraPersonSamtykkeTekstAccepteret: ekstraPersonCprGodkendt
        };
        return result;
    }

    public dtoToEjendomsfaktorDefinitionValgmuligheder(dto: TilslutningsanmodningEjendomsfaktorDefinitionValgmulighedDto[]): EjendomsfaktorDefinitionValgmulighed[] {
        const result = dto.map((d) => this.dtoToEjendomsfaktorDefinitionValgmulighed(d));
        return result;
    }

    public dtoToEjendomsfaktorDefinitionValgmulighed(dto: TilslutningsanmodningEjendomsfaktorDefinitionValgmulighedDto): EjendomsfaktorDefinitionValgmulighed {
        const result: EjendomsfaktorDefinitionValgmulighed = {
            id: dto.relation,
            navn: dto.tekst
        };
        return result;
    }

    private beregnMinVaerdi(value: string): number {
        return value ? +value : -2_147_483_648; // Mindste integer vi kan hådtere i Delphi
    }

    private beregnMaxVaerdi(value: string): number {
        return value ? +value : 2_147_483_647; // Største integer vi kan håndtere i Delphi
    }
}
