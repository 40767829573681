import { Injectable } from "@angular/core";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, first, map, switchMap } from "rxjs/operators";

import { ProcessingSubject } from "@dffedb/rxjs";
import { EforsyningIndstilling, UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { EBoksIndstillinger } from "../model/e-boks-indstillinger.model";
import { EBoksTilmelding } from "../model/e-boks-tilmelding.model";
import { ValidererAdresseKodeResult } from "../model/validerer-adresse-kode-result";
import { EBoksTilmeldingRepository } from "./e-boks-tilmelding.repository";

@Injectable({ providedIn: "root" })
export class EBoksTilmeldingService {
    private tilmeldingerSubject = new BehaviorSubject<EBoksTilmelding[]>([]);
    private eforsyningIndstillingSubject = new BehaviorSubject<EforsyningIndstilling>(null);
    private tilmelderSubject = new ProcessingSubject<EBoksTilmelding>();
    private initialisererSubject = new ProcessingSubject<void>();
    private afmelderSubject = new ProcessingSubject<EBoksTilmelding>();
    private validererAdresseKodeSubject = new ProcessingSubject<ValidererAdresseKodeResult>();

    public tilmeldinger$ = this.tilmeldingerSubject.asObservable();
    public eforsyningIndstilling$ = this.eforsyningIndstillingSubject.asObservable();
    public tilmelder$ = this.tilmelderSubject.asObservable();
    public initialiserer$ = this.initialisererSubject.asObservable();
    public afmelder$ = this.afmelderSubject.asObservable();
    public validererAdresseKode$ = this.validererAdresseKodeSubject.asObservable();

    constructor(private readonly repository: EBoksTilmeldingRepository, private readonly indstillinger: UmbracoIndstillingerObserver) {
        this.initialiserEforsyningIndstilling();
    }

    public hentTilmeldinger(): void {
        this.initialisererSubject.processing(null);
        const tilmeldinger$ = this.internalHentTilmeldinger();
        tilmeldinger$.pipe(first()).subscribe(
            (tilmeldinger) => {
                this.initialisererSubject.processed(null);
                this.tilmeldingerSubject.next(tilmeldinger);
            },
            (error) => {
                this.initialisererSubject.errored(error);
                console.error(error);
            }
        );
    }

    public tilmeld(tilmelding: EBoksTilmelding): void {
        this.afmelderSubject.idle(null); // Fjern eventuelle fejlbeskeder opstået ved afmelding
        this.tilmelderSubject.processing(tilmelding);
        const result = this.internalTilmeld(tilmelding).pipe(
            first(),
            switchMap(() => this.internalHentTilmeldinger())
        );
        result.subscribe(
            (tilmeldinger) => {
                this.tilmeldingerSubject.next(tilmeldinger);
                this.tilmelderSubject.processed(tilmelding);
            },
            (error) => this.tilmelderSubject.errored(error)
        );
    }

    public afmeld(tilmelding: EBoksTilmelding): void {
        this.afmelderSubject.processing(tilmelding);
        const result = this.internalAfmeld(tilmelding).pipe(
            first(),
            switchMap(() => this.internalHentTilmeldinger())
        );
        result.subscribe(
            (tilmeldinger) => {
                this.tilmeldingerSubject.next(tilmeldinger);
                this.afmelderSubject.processed(tilmelding);
            },
            (error) => this.afmelderSubject.errored(error)
        );
    }

    public validerAdresseKode(adresseKode: string): void {
        this.tilmeldingerSubject.next([]);
        if (adresseKode.length < 12) {
            this.validererAdresseKodeSubject.idle(null);
            return;
        }
        this.validererAdresseKodeSubject.processing({ adresseKode, adresse: null });
        combineLatest([
            this.repository.hentTilmeldingerMedAdresseKode(adresseKode),
            this.repository.hentAdresseMedAdresseKode(adresseKode),
            this.repository.hentEforsyningIndstillingMedAdresseKode(adresseKode)
        ])
            .pipe(first())
            .subscribe(
                ([tilmeldinger, adresse, eforsyningIndstilling]) => {
                    this.tilmeldingerSubject.next(tilmeldinger);
                    this.eforsyningIndstillingSubject.next(eforsyningIndstilling);
                    this.validererAdresseKodeSubject.processed({ adresseKode, adresse });
                },
                (error) => this.validererAdresseKodeSubject.errored(error)
            );
    }

    public hentIndstillinger(): Observable<EBoksIndstillinger> {
        return this.repository.hentIndstillinger();
    }

    private internalHentTilmeldinger(): Observable<EBoksTilmelding[]> {
        return this.hentAdresseKode().pipe(
            switchMap((adresseKode) =>
                adresseKode ? this.repository.hentTilmeldingerMedAdresseKode(adresseKode) : this.repository.hentTilmeldinger()
            )
        );
    }

    private internalTilmeld(tilmelding: EBoksTilmelding): Observable<EBoksTilmelding> {
        return this.hentAdresseKode().pipe(
            switchMap((adresseKode) =>
                adresseKode
                    ? this.repository.opretTilmeldingMedAdresseKode(adresseKode, tilmelding)
                    : this.repository.opretTilmelding(tilmelding)
            )
        );
    }

    private internalAfmeld(tilmelding: EBoksTilmelding): Observable<void> {
        return this.hentAdresseKode().pipe(
            switchMap((adresseKode) =>
                adresseKode
                    ? this.repository.fjernTilmeldingMedAdresseKode(adresseKode, tilmelding)
                    : this.repository.fjernTilmelding(tilmelding)
            )
        );
    }

    private hentAdresseKode(): Observable<string> {
        return this.validererAdresseKodeSubject.asObservable().pipe(map((value) => (value.value && value.value.adresseKode) || ""));
    }

    private initialiserEforsyningIndstilling(): void {
        // Hvis man kører fra E|Forsyning, kender vi allerede EforsyningIndstilling og kan sætte dem med det samme.
        this.indstillinger.valueChanges
            .pipe(filter((i) => i && !!i.aktuelEforsyning))
            .pipe(first())
            .subscribe((i) => this.eforsyningIndstillingSubject.next(i.aktuelEforsyning));
    }
}
