import { Injectable } from "@angular/core";

import { ObservableStorageBase } from "./observable-storage-base";

@Injectable({ providedIn: "root" })
export class SessionStorage extends ObservableStorageBase {
    constructor() {
        super(window.sessionStorage);
    }
}
