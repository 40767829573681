import { Injectable } from "@angular/core";

import { combineLatest, merge, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

import { MenuItem } from "@dffedb/ui";
import { AktuelBrugerObserver, Bruger, Rolle } from "@e-forsyning/common/bruger";
import { AppserverInstallationCredentialsRepository } from "@e-forsyning/common/credentials";

import { UmbracoMenuObserver } from "./umbraco-menu.observer";

@Injectable({ providedIn: "root" })
export class EforsyningMenuerObserver {
    private readonly _valueChanges: Observable<MenuItem>;

    constructor(
        private readonly umbracoMenuObserver: UmbracoMenuObserver,
        private readonly aktuelBrugerObserver: AktuelBrugerObserver,
        private readonly credentialsObservable: AppserverInstallationCredentialsRepository
    ) {
        const eforsyningerMenu$ = this.umbracoMenuObserver.valueChanges.pipe(map((menu) => menu.eforsyninger));
        const aktuelBruger$ = this.aktuelBrugerObserver.valueChanges;
        const aktuelForsyningNodeId$ = this.credentialsObservable.valueChanges.pipe(
            map((c) => c.forsyningNodeId),
            distinctUntilChanged()
        );

        // initialMenu$ indeholder menpunktets navn, som vises indtil vi har fået loadet dat.
        // Uden denne kan man opleve at menpunket kortvarigt forsvinder.
        const initialMenu$ = combineLatest([eforsyningerMenu$, aktuelForsyningNodeId$]).pipe(
            map(([eforsyningerMenu, aktuelForsyningNodeId]) => ({ title: eforsyningerMenu.title, aktuelForsyningNodeId })),
            map(({ title, aktuelForsyningNodeId }) => this.genererDummyMenu(title, aktuelForsyningNodeId))
        );

        // realMenu$ indeholder den faktiske menu filtreret på brugerens rolle.
        const realMenu$ = combineLatest([eforsyningerMenu$, aktuelBruger$, aktuelForsyningNodeId$]).pipe(
            map(([eforsyningerMenu, aktuelBruger, aktuelForsyningNodeId]) =>
                this.genererMenu(eforsyningerMenu, aktuelBruger, aktuelForsyningNodeId)
            )
        );
        this._valueChanges = merge(initialMenu$, realMenu$).pipe(shareReplay(1));
    }

    public get valueChanges(): Observable<MenuItem> {
        return this._valueChanges;
    }

    private genererDummyMenu(title: string, aktuelForsyningNodeId: number): MenuItem {
        return {
            title,
            adgangsstyret: false,
            children: [
                {
                    id: aktuelForsyningNodeId,
                    adgangsstyret: false,
                    children: []
                }
            ]
        };
    }

    private genererMenu(eforsyningerMenu: MenuItem, aktuelBruger: Bruger, aktuelForsyningNodeId: number): MenuItem {
        const eforsyningerMenuChildren = eforsyningerMenu.children.map((eforsyningMenu) => {
            const erAktuelleEforsyning = eforsyningMenu.id === aktuelForsyningNodeId;
            const rolle = (erAktuelleEforsyning && aktuelBruger && aktuelBruger.rolle) || Rolle.LoggetUd;
            const menupunkterMedAdgang = (aktuelBruger && aktuelBruger.menupunkter) || [];
            let children = eforsyningMenu.children;
            children = this.filterByRole(children, rolle);
            children = this.filterByMenupunkterMedAdgang(children, menupunkterMedAdgang);
            return { ...eforsyningMenu, children };
        });
        return { ...eforsyningerMenu, children: eforsyningerMenuChildren };
    }

    private filterByRole(menuItems: MenuItem[], rolle: Rolle): MenuItem[] {
        return menuItems
            .filter((i) => (i.roles || []).includes(rolle))
            .map((i) => ({ ...i, children: this.filterByRole(i.children || [], rolle) }));
    }

    private filterByMenupunkterMedAdgang(menuItems: MenuItem[], menupunkterMedAdgang: string[]): MenuItem[] {
        return menuItems
            .filter((i) => !i.adgangsstyret || menupunkterMedAdgang.includes(i.name))
            .map((i) => ({ ...i, children: this.filterByMenupunkterMedAdgang(i.children || [], menupunkterMedAdgang) }));
    }
}
