import { AbstractControl } from "@angular/forms";

export function mustMatchValidator(matchingControlName: string): any {
    return (control: AbstractControl) => {
        if (!control.parent) {
            return null;
        }

        const matchingControl = control.parent.controls[matchingControlName];

        if (control.value !== matchingControl.value) {
            return { mustMatch: true };
        } else {
            return null;
        }
    };
}
