import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

import { trim, trimRight } from "@dffedb/util";

import { AuthenticationService } from "../auth/authentication.service";
import { AppserverCredentials, IAppserverCredentialsRepository } from "../credentials";

export enum ParametreForAktuelleInstallation {
    true,
    false,
    udenInstallationsNr
}

export class AppserverHttpClient {
    private getAppServerUrlCache = new Map<string, Observable<string>>();
    constructor(
        private readonly http: HttpClient,
        private readonly credentialsRepository: IAppserverCredentialsRepository,

        private readonly authenticationService: AuthenticationService
    ) {}

    public get appServerUrl(): Observable<string> {
        const result = this.getAppServerUrlCache.get(this.getVaerkSettingsUrl());
        return result;
    }

    public get credentials(): AppserverCredentials {
        return this.credentialsRepository.hent();
    }

    public get<T = any>(
        relativeUrl: string,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation = ParametreForAktuelleInstallation.false,
        inkluderCryptId: boolean = true
    ): Observable<T> {
        //if (this.authenticationService.isAuthenticationTimeout()) {
        //    console.log("Get: tid til at logg ind igen :");
        //}
        return this.getUrl(relativeUrl, inkluderParametreForAktuelleInstallation, inkluderCryptId).pipe(
            switchMap((absoluteUrl: string) => this.http.get<T>(absoluteUrl))
        );
    }

    public getWithStringResult(
        relativeUrl: string,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation = ParametreForAktuelleInstallation.false
    ): Observable<string> {
        return this.getUrl(relativeUrl, inkluderParametreForAktuelleInstallation).pipe(
            switchMap((absoluteUrl: string) => this.http.get(absoluteUrl, { responseType: "text" }))
        );
    }

    public post<T = any>(
        relativeUrl: string,
        body: any,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation,
        options?: DffHttpOptions<"body">
    ): Observable<T>;

    public post<T = any>(
        relativeUrl: string,
        body: any,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation,
        options?: DffHttpOptions<"events">
    ): Observable<HttpEvent<T>>;

    public post<T = any>(
        relativeUrl: string,
        body: any,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation,
        options?: DffHttpOptions<"events"> | DffHttpOptions<"body"> | DffHttpOptions<"response">
    ): Observable<any> {
        return this.getUrl(relativeUrl, inkluderParametreForAktuelleInstallation).pipe(
            switchMap((absoluteUrl: string) =>
                options && options.observe === "events"
                    ? this.http.post<T>(absoluteUrl, body, { ...options, observe: "events" })
                    : options && options.observe === "response"
                    ? this.http.post<T>(absoluteUrl, body, { ...options, observe: "response" })
                    : this.http.post<T>(absoluteUrl, body, { ...options, observe: "body" })
            )
        );
    }

    public postWithStringResult(
        relativeUrl: string,
        body: any,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation = ParametreForAktuelleInstallation.false
    ): Observable<string> {
        return this.getUrl(relativeUrl, inkluderParametreForAktuelleInstallation).pipe(
            switchMap((absoluteUrl: string) => this.http.post(absoluteUrl, body, { responseType: "text" }))
        );
    }

    public getUrl(
        relativeUrl: string,
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation = ParametreForAktuelleInstallation.false,
        inkluderCryptId: boolean = true
    ): Observable<string> {
        return this.getAppServerUrl().pipe(
            map((appServerUrl) => {
                const parameters = this.getParameterString(inkluderParametreForAktuelleInstallation, inkluderCryptId);
                return this.formatUrl(appServerUrl, relativeUrl, parameters);
            })
        );
    }

    public getAppServerUrl(): Observable<string> {
        const url = this.getVaerkSettingsUrl();
        let result = this.getAppServerUrlCache.get(url);

        if (!result) {
            result = this.http.get(url).pipe(
                map((r: any) => r.AppServerUri as string),
                shareReplay(1)
            );
        }

        this.getAppServerUrlCache.set(url, result);
        return result;
    }

    private formatUrl(appServerUrl: string, relativeUrl: string, parameters: string): string {
        const absoluteUrl = trimRight(appServerUrl, "/") + "/" + trim(relativeUrl, "/");
        const querySeparator = absoluteUrl.includes("?") ? "&" : "?";
        return absoluteUrl + querySeparator + parameters;
    }

    private getParameterString(
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation,
        inkluderCryptId: boolean
    ): string {
        const parameters = Array.from(this.getParameters(inkluderParametreForAktuelleInstallation, inkluderCryptId)).join("&");
        return parameters || "";
    }

    private *getParameters(
        inkluderParametreForAktuelleInstallation: ParametreForAktuelleInstallation,
        inkluderCryptId: boolean
    ): IterableIterator<string> {
        const { ejendomNr, aktivNr, installationNr, cryptId } = this.credentialsRepository.hent();
        //inkluderParametreForAktuelleInstallation = inkluderParametreForAktuelleInstallation && !!ejendomNr;
        if (!ejendomNr) {
            //false ved 0,null eller undfined
            inkluderParametreForAktuelleInstallation = ParametreForAktuelleInstallation.false;
        }
        if (inkluderCryptId) {
            yield `id=${cryptId}`;
        }
        if (inkluderParametreForAktuelleInstallation !== ParametreForAktuelleInstallation.false) {
            yield `unr=${ejendomNr}`;
            yield `anr=${aktivNr}`;
            if (inkluderParametreForAktuelleInstallation === ParametreForAktuelleInstallation.udenInstallationsNr) {
                yield `inr=${0}`;
            } else {
                yield `inr=${installationNr}`;
            }
        }
    }

    private getVaerkSettingsUrl(): string {
        const credentials = this.credentialsRepository.hent();
        return `/umbraco/dff/dffapi/GetVaerkSettings?forsyningid=${credentials.forsyningId}`;
    }
}

export type DffObserve = "body" | "events" | "response";

export interface DffHttpOptions<TObserve extends DffObserve> {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    observe?: TObserve;
    params?:
        | HttpParams
        | {
              [param: string]: string | string[];
          };
    reportProgress?: boolean;
    responseType?: "json";
    withCredentials?: boolean;
}
