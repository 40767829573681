/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse, HttpRequest, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import {
    CorrelationIdService,
    formatDate,
    getCorrelationId,
    getErrorDetails,
    getErrorMessage,
    getUtcDate,
    Logger,
    LOGGER,
    SessionIdService
} from "@dffedb/util";

import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger/umbraco-indstillinger.observer";
import { LogFelter, LogLevel } from "./log.model";

@Injectable({ providedIn: "root" })
export class LogService {
    private sessionId: string;
    private forsyningId: string;
    private forsyningNodeId: number;

    constructor(
        sessionIdService: SessionIdService,
        umbracoIndstillingerObserver: UmbracoIndstillingerObserver,
        private readonly correlationIdService: CorrelationIdService,
        @Inject(LOGGER) private readonly logger: Logger
    ) {
        this.sessionId = sessionIdService.hentId();
        umbracoIndstillingerObserver.valueChanges.subscribe((indstillinger) => {
            this.forsyningId = indstillinger.aktuelEforsyning && indstillinger.aktuelEforsyning.forsyningId;
            this.forsyningNodeId = indstillinger.aktuelEforsyningNodeId;
        });
    }

    public logHttpStart(correlationId: string, request: HttpRequest<any>): void {
        this.log({
            ...this.getIdFields(correlationId),
            ...this.getHttpRequestFields(request),
            Besked: "START"
        });
    }

    public logHttpEnd(correlationId: string, duration: number, request: HttpRequest<any>, response: HttpResponse<any>): void {
        this.log({
            ...this.getIdFields(correlationId),
            ...this.getDurationFields(duration),
            ...this.getHttpRequestFields(request),
            ...this.getHttpResponseFields(response),
            Besked: "END"
        });
    }

    public logHttpError(correlationId: string, duration: number, request: HttpRequest<any>, error: HttpErrorResponse): void {
        const logLevel = error.status >= 500 ? LogLevel.Error : LogLevel.Warning;
        this.log({
            ...this.getIdFields(correlationId),
            ...this.getDurationFields(duration),
            ...this.getHttpRequestFields(request),
            ...this.getHttpErrorResponseFields(error),
            LogLevel: logLevel,
            UserAgent: window.navigator.userAgent
        });
    }

    public async logError(error: any, logLevel: LogLevel = LogLevel.Error): Promise<void> {
        this.log({
            Besked: getErrorMessage(error),
            Detaljer: await getErrorDetails(error),
            LogLevel: logLevel,
            UserAgent: window.navigator.userAgent,
            ...this.getIdFields(getCorrelationId(error))
        });
    }

    public log(log: Partial<LogFelter>): void {
        log = this.sortFields(this.addDefaultFields(log));
        this.logger.log(log);
    }

    private getIdFields(correlationId: string): Partial<LogFelter> {
        return {
            Id: `${this.sessionId}-${correlationId}`
        };
    }

    private getDurationFields(duration: number): Partial<LogFelter> {
        return {
            Duration: duration
        };
    }

    private getHttpRequestFields(request: HttpRequest<any>): Partial<LogFelter> {
        return {
            HttpVerb: request.method,
            RequestUrl: request.urlWithParams
        };
    }

    private getHttpResponseFields(response: HttpResponse<any>): Partial<LogFelter> {
        return {
            HttpStatus: response.status
        };
    }

    private getHttpErrorResponseFields(response: HttpErrorResponse): Partial<LogFelter> {
        return {
            Besked: response.message,
            HttpStatus: response.status
        };
    }

    private addDefaultFields(log: Partial<LogFelter>): Partial<LogFelter> {
        const defaultFields = {
            Id: `${this.sessionId}-${this.correlationIdService.genererId()}`,
            LogLevel: LogLevel.Information,
            UTC: this.getUtc(),
            ForsyningId: this.forsyningId,
            ForsyningNodeId: this.forsyningNodeId,
            Origin: window.location.href
        };
        return { ...defaultFields, ...log };
    }

    private sortFields(log: Partial<LogFelter>): Partial<LogFelter> {
        return {
            UTC: log.UTC,
            Id: log.Id,
            LogLevel: log.LogLevel,
            Besked: log.Besked,
            HttpVerb: log.HttpVerb,
            RequestUrl: log.RequestUrl,
            ...log,
            Detaljer: log.Detaljer
        };
    }

    private getUtc(): string {
        const date = getUtcDate();
        return formatDate(date, "DD MMM YYYY HH:mm:ss.SSS");
    }
}
