import { Injectable } from "@angular/core";

import { first } from "rxjs/operators";

import { DffContentGroup, DffGroupedContent } from "@dffedb/util";
import { formatterCprNummer, formatterCvrNummer, formatterPNummer, maskCprNummer } from "@e-forsyning/common/e-boks";
import { EjendomsfaktorDefinition } from "@e-forsyning/common/ejendomsfaktor";
import { DffMailInternal, EforsyningMailBuilderService, EforsyningMailSenderService, MailHelper } from "@e-forsyning/common/mail";
import { EforsyningIndstilling, UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { TilslutningsanmodningKonfiguration, TilslutningsanmodningModel } from "../model/tilslutningsanmodning.model";

@Injectable({ providedIn: "root" })
export class TilslutningsanmodningMailService {
    constructor(
        private readonly eforsyningMailBuilder: EforsyningMailBuilderService,
        private readonly mailSender: EforsyningMailSenderService,
        private readonly umbracoIndstillingerObserver: UmbracoIndstillingerObserver
    ) {}

    public async sendForsyningsanmodningMail(
        umbracoId: string,
        konfiguration: TilslutningsanmodningKonfiguration,
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[],
        adresseTekst: string,
        model: TilslutningsanmodningModel
    ): Promise<void> {
        const indstillinger = await this.umbracoIndstillingerObserver.valueChanges.pipe(first()).toPromise();
        const aktuelEforsyning = indstillinger.eforsyninger.find((e) => e.nodeId === konfiguration.eforsyning.nodeId);
        const sideId = umbracoId; // TO DO
        //const firmaEmail = konfiguration.alternativVaerksEmail === undefined ? aktuelEforsyning.firmaInfo.email : konfiguration.alternativVaerksEmail;
        // Send mail til forbrugeren + cc: værket

        //Mail genereres via Umbraco for at undgå fremmed html

        const mailTilForbruger = await this.genererMail(
            aktuelEforsyning,
            ejendomsfaktorDefinitioner,
            adresseTekst,
            model,
            konfiguration.visEBoksTilmelding,
            model.oplysninger.ekstraPerson ? model.oplysninger.email + ";" + model.oplysninger.ekstraPersonEmail : model.oplysninger.email,
            sideId
        );
        await this.mailSender.sendForsyningsanmodningMail(mailTilForbruger);
    }

    private async genererMail(
        aktuelEforsyning: EforsyningIndstilling,
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[],
        adresseTekst: string,
        model: TilslutningsanmodningModel,
        visEBoksTilmelding: boolean,
        toMailAddress: string,
        sideId: string
    ): Promise<DffMailInternal> {
        const myModel = JSON.stringify(this.modelToGroupedContent(adresseTekst, model, visEBoksTilmelding, ejendomsfaktorDefinitioner));
        //this.genererBody(this.modelToGroupedContent(adresseTekst, model, visEBoksTilmelding, ejendomsfaktorDefinitioner));
        //const body = this.genererBody(aktuelEforsyning, mailSubject, mailBody, this.modelToGroupedContent(adresseTekst, model, visEBoksTilmelding, ejendomsfaktorDefinitioner));
        const result: DffMailInternal = {
            sideId,
            to: toMailAddress,
            model: myModel
        };
        return result;
    }

    private modelToGroupedContent(
        adresseTekst: string,
        model: TilslutningsanmodningModel,
        visEBoksTilmelding: boolean,
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]
    ): DffGroupedContent {
        const virksomhedensOplysninger: DffContentGroup = model.oplysninger.erVirksomhed
            ? {
                  title: "Virksomhedens oplysninger",
                  rows: [
                      { key: "Firmanavn", value: model.oplysninger.navn },
                      { key: "Kontaktperson", value: model.oplysninger.ekstraPersonNavn },
                      { key: "Adresse", value: adresseTekst },
                      { key: "Telefon", value: model.oplysninger.telefon },
                      { key: "E-mail", value: model.oplysninger.email },
                      model.oplysninger.bemaerkning ? { key: "Bemærkning", value: model.oplysninger.bemaerkning } : null,
                      visEBoksTilmelding
                          ? { key: "Ønsker at modtage post via e-Boks?", value: this.boolToJaNej(model.oplysninger.oenskerEboks) }
                          : null,
                      model.oplysninger.oenskerEboks ? { key: "CVR-nummer", value: formatterCvrNummer(model.oplysninger.cvrNummer) } : null,
                      model.oplysninger.oenskerEboks ? { key: "P-nummer", value: formatterPNummer(model.oplysninger.pNummer) } : null
                  ].filter((r) => !!r)
              }
            : null;
        const personligeOplysninger: DffContentGroup = !model.oplysninger.erVirksomhed
            ? {
                  title: "Personlige oplysninger",
                  rows: [
                      { key: "Navn", value: model.oplysninger.navn },
                      { key: "Adresse", value: adresseTekst },
                      { key: "Telefon", value: model.oplysninger.telefon },
                      { key: "E-mail", value: model.oplysninger.email },
                      model.oplysninger.bemaerkning ? { key: "Bemærkning", value: model.oplysninger.bemaerkning } : null,
                      visEBoksTilmelding
                          ? { key: "Ønsker at modtage post via e-Boks?", value: this.boolToJaNej(model.oplysninger.oenskerEboks) }
                          : null,
                      model.oplysninger.oenskerEboks
                          ? { key: "CPR-nummer", value: formatterCprNummer(maskCprNummer(model.oplysninger.cprNummer)) }
                          : null
                  ].filter((r) => !!r)
              }
            : null;
        const medejerPersonligeOplysninger: DffContentGroup =
            !model.oplysninger.erVirksomhed && model.oplysninger.ekstraPerson
                ? {
                      title: "Medejers oplysninger",
                      rows: [
                          { key: "Navn", value: model.oplysninger.ekstraPersonNavn },
                          { key: "Telefon", value: model.oplysninger.ekstraPersonTelefon },
                          { key: "E-mail", value: model.oplysninger.ekstraPersonEmail },
                          visEBoksTilmelding
                              ? {
                                    key: "Ønsker at modtage post via e-Boks?",
                                    value: this.boolToJaNej(model.oplysninger.ekstraPersonOenskerEboks)
                                }
                              : null,
                          model.oplysninger.ekstraPersonOenskerEboks
                              ? { key: "CPR-nummer", value: formatterCprNummer(maskCprNummer(model.oplysninger.ekstraPersonCprNummer)) }
                              : null
                      ].filter((r) => !!r)
                  }
                : null;
        const ejendomsfaktorOplysninger: DffContentGroup = model.ejendomsfaktorer.length
            ? {
                  title: "Oplysninger om ejendommen",
                  rows: model.ejendomsfaktorer.map((e) => ({
                      key: this.findEjendomsfaktorNavn(e.definitionId, ejendomsfaktorDefinitioner),
                      value: this.findEjendomsfaktorVaerdi(e.vaerdi.aktuel, e.definitionId, ejendomsfaktorDefinitioner)
                  }))
              }
            : null;
        return {
            groups: [virksomhedensOplysninger, personligeOplysninger, medejerPersonligeOplysninger, ejendomsfaktorOplysninger].filter(
                (e) => !!e
            )
        };
    }

    private findEjendomsfaktorNavn(definitionId: number, ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]): string {
        return this.findEjendomsfaktorDefinition(definitionId, ejendomsfaktorDefinitioner)?.navn ?? "-";
    }

    private findEjendomsfaktorVaerdi(vaerdi: string, definitionId: number, ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]): string {
        const definition = this.findEjendomsfaktorDefinition(definitionId, ejendomsfaktorDefinitioner);
        const valgmulighedNavn = definition?.valgmuligheder?.find((v) => v.id === vaerdi)?.navn;
        const result = (valgmulighedNavn ?? vaerdi)?.toString();
        return !!result ? result + " " + definition?.enhed : "";
    }

    private findEjendomsfaktorDefinition(
        definitionId: number,
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]
    ): EjendomsfaktorDefinition | null {
        return ejendomsfaktorDefinitioner.find((e) => e.id === definitionId);
    }

    private boolToJaNej(value: boolean): string {
        return value ? "Ja" : "Nej";
    }

    private genererBody(content: DffGroupedContent): string {
        return this.eforsyningMailBuilder.generer(MailHelper.kvitteringPanel(content));
    }
}
