import { HttpEvent, HttpEventType, HttpProgressEvent } from "@angular/common/http";

import { combineLatest, Observable, timer } from "rxjs";
import { filter, finalize, map, switchMap, tap } from "rxjs/operators";

export function executeWithProgress<T, U>(
    save: () => Observable<HttpEvent<T>>,
    load: (t: T) => Observable<U>,
    onProgress: (value: number) => void
): Promise<U> {
    const result = save().pipe(
        tap(event => event.type === HttpEventType.UploadProgress && onProgress(calculateProgressPercentage(event, 90))),
        filter(event => event.type === HttpEventType.Response),
        tap(() => onProgress(98)),
        map(event => event.type === HttpEventType.Response && event.body),
        switchMap(body => load(body))
    );
    return combineLatest([result, timer(1000)])
        .pipe(
            map(r => r[0]),
            finalize(() => onProgress(0))
        )
        .toPromise();
}

export function calculateProgressPercentage(event: HttpProgressEvent, maxPercentage: number = 100): number {
    const percentage = (event.loaded / event.total) * 100;
    return Math.round((percentage / 100) * maxPercentage);
}
