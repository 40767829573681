import { NgModule } from "@angular/core";

import { AccordionStatusIndicatorComponent } from "./accordion-status-indicator.component";

@NgModule({
    imports: [],
    exports: [AccordionStatusIndicatorComponent],
    declarations: [AccordionStatusIndicatorComponent],
    providers: []
})
export class AccordionStatusIndicatorModule {}
