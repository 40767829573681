import { NgModule } from "@angular/core";

import { RevealOnFocusDirective } from "./reveal-on-focus.directive";

@NgModule({
    imports: [],
    declarations: [RevealOnFocusDirective],
    exports: [RevealOnFocusDirective]
})
export class RevealOnFocusModule {}
