import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthenticationService } from "../auth/authentication.service";
import { IAppserverCredentialsRepository } from "../credentials";
import { AppserverHttpClient } from "./appserver-http-client.service";

@Injectable({ providedIn: "root" })
export class AppserverHttpClientFactory {
    constructor(private readonly http: HttpClient) {}

    public create(
        credentialsRepository: IAppserverCredentialsRepository,
        authenticationService: AuthenticationService
    ): AppserverHttpClient {
        authenticationService.isAuthenticationTimeout();
        return new AppserverHttpClient(this.http, credentialsRepository, authenticationService);
    }
}
