import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { PageErrorModule } from "../page-error";
import { DffSideTekstComponent } from "./dff-side-tekst.component";
import { DffSideTitelComponent } from "./dff-side-titel.component";
import { DffSideComponent } from "./dff-side.component";

@NgModule({
    imports: [CommonModule, PageErrorModule, MatProgressSpinnerModule],
    exports: [DffSideComponent, DffSideTekstComponent, DffSideTitelComponent],
    declarations: [DffSideComponent, DffSideTekstComponent, DffSideTitelComponent]
})
export class DffSideModule {}
