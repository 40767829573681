import { Injectable } from "@angular/core";

import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";

import { AktuelBrugerObserver } from "../bruger/aktuel-bruger.observer";
import { BrugerHttpClient } from "../http/bruger-http-client.service";
import { ForbrugerTilknytningRepository } from "./forbruger-tilknytning.repository";
import { ForbrugerTilknytning } from "./model/forbruger-tilknytning.model";

@Injectable({ providedIn: "root" })
export class AktuelBrugersForbrugerTilknytningerObserver {
    private readonly triggerChangedSubject = new BehaviorSubject<void>(null);
    public readonly valueChanges: Observable<ForbrugerTilknytning[]>;

    constructor(
        brugerHttpClient: BrugerHttpClient,
        aktuelBrugerObserver: AktuelBrugerObserver,
        forbrugerTilknytningRepository: ForbrugerTilknytningRepository
    ) {
        this.valueChanges = combineLatest([aktuelBrugerObserver.valueChanges, this.triggerChangedSubject]).pipe(
            map(([aktuelBruger]) => (aktuelBruger && aktuelBruger.id) || null),
            switchMap(brugerId => forbrugerTilknytningRepository.hentTilknytninger(brugerId, brugerHttpClient)),
            shareReplay(1)
        );
    }

    public triggerChanged(): void {
        this.triggerChangedSubject.next(null);
    }
}
