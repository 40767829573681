export interface Adresse {
    adresselinie1: string;
    adresselinie2: string;
    adresselinie3: string;
    adresselinie4: string;
    adressenr: number;
    bynavn: string;
    husnr: number;
    litra: string;
    litra2: string;
    postnr: string;
    vejnavn: string;
    vejnr: number;
}
