import { Injectable } from "@angular/core";

import { combineLatest, Observable, of } from "rxjs";
import { first, map, mapTo, shareReplay, switchMap } from "rxjs/operators";

import { DffCurrentRoute } from "@dffedb/router";
import { LocalStorage, SessionStorage } from "@dffedb/util";
import { LoginService } from "@e-forsyning/common/auth";
import { Rolle } from "@e-forsyning/common/bruger";
import { AktuelBrugerObserver } from "@e-forsyning/common/bruger";

import { EBoksTilmeldingRepository } from "../shared/e-boks-tilmelding.repository";
import { EBoksPopup } from "./e-boks-popup";
import { EBoksPopupRef } from "./e-boks-popup-ref";

@Injectable({ providedIn: "root" })
export class EBoksPopupService {
    private readonly erForbruger$: Observable<boolean>;
    private readonly erAlleredeTilmeldtEBoks$: Observable<boolean>;
    private readonly erEBoksKampagneAktiveret$: Observable<boolean>;

    constructor(
        private readonly popup: EBoksPopup,
        private readonly loginService: LoginService,
        private readonly sessionStorage: SessionStorage,
        private readonly localStorage: LocalStorage,
        private readonly aktuelBrugerObserver: AktuelBrugerObserver,
        private readonly eboksRepository: EBoksTilmeldingRepository,
        private readonly currentRoute: DffCurrentRoute
    ) {
        this.erForbruger$ = this.erForbruger();
        this.erAlleredeTilmeldtEBoks$ = this.erAlleredeTilmeldtEBoks();
        this.erEBoksKampagneAktiveret$ = this.erEBoksKampagneAktiveret();
    }

    public get popupSkalAldrigVisesIgen(): boolean {
        return this.localStorage.getItem("e-boks-popup-vis-aldrig-igen") === "true";
    }
    public set popupSkalAldrigVisesIgen(value: boolean) {
        this.localStorage.setItem("e-boks-popup-vis-aldrig-igen", value.toString());
    }
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public get popupSkalIkkeVisesIgenIDenneSession(): boolean {
        return this.sessionStorage.getItem("e-boks-popup-vis-ikke-igen-i-denne-session") === "true";
    }

    public set popupSkalIkkeVisesIgenIDenneSession(value: boolean) {
        this.sessionStorage.setItem("e-boks-popup-vis-ikke-igen-i-denne-session", value.toString());
    }

    public async aabnHvisIkkeAlleredeTilmeldt(): Promise<void> {
        if (await this.skalPopupVises()) {
            return this.open().closed.pipe(first(), mapTo(null)).toPromise();
        } else {
            return Promise.resolve();
        }
    }

    public async skalPopupVises(): Promise<boolean> {
        if (!this.erEBoksAktiveret()) {
            return Promise.resolve(false);
        }
        if (this.visIkkePopupPaaAktuelleSide()) {
            return Promise.resolve(false);
        }
        if (!this.loginService.erLoggetInd()) {
            return Promise.resolve(false);
        }
        if (this.popupSkalIkkeVisesIgenIDenneSession || this.popupSkalAldrigVisesIgen) {
            return Promise.resolve(false);
        }
        const [erForbruger, erAlleredeTilmeldtEBoks, erEBoksKampagneAktiveret] = await combineLatest([
            this.erForbruger$.pipe(first()),
            this.erAlleredeTilmeldtEBoks$.pipe(first()),
            this.erEBoksKampagneAktiveret$.pipe(first())
        ]).toPromise();

        return Promise.resolve(erForbruger && !erAlleredeTilmeldtEBoks && erEBoksKampagneAktiveret);
    }

    private open(): EBoksPopupRef {
        const result = this.popup.open();
        result.closed.pipe(first()).subscribe((args) => {
            this.popupSkalIkkeVisesIgenIDenneSession = true;
            this.popupSkalAldrigVisesIgen = args.spoergMigIkkeIgen;
        });
        return result;
    }

    private erEBoksAktiveret(): Observable<boolean> {
        return this.eboksRepository.hentIndstillinger().pipe(
            map((result) => result.visEBoksSektion),
            shareReplay(1)
        );
    }
    private erEBoksKampagneAktiveret(): Observable<boolean> {
        return this.eboksRepository.hentIndstillinger().pipe(
            map((result) => result.visEBoksKampagne),
            shareReplay(1)
        );
    }

    private erForbruger(): Observable<boolean> {
        return this.aktuelBrugerObserver.valueChanges.pipe(map((bruger) => bruger && bruger.rolle === Rolle.Forbruger));
    }

    private erAlleredeTilmeldtEBoks(): any {
        return this.aktuelBrugerObserver.valueChanges.pipe(
            switchMap((bruger) => (bruger && Rolle.erForbruger(bruger.rolle) && this.eboksRepository.hentTilmeldinger()) || of(null)),
            map((tilmeldinger) => tilmeldinger && tilmeldinger.length !== 0),
            shareReplay(1)
        );
    }

    private visIkkePopupPaaAktuelleSide(): boolean {
        const ignoredRoutes = [/^\/login$/, /^\/login\//, /^\/silent-login\//];
        return ignoredRoutes.some((r) => r.test(this.currentRoute.value.path));
    }
}
