<ng-container *ngIf="settings$ | async as settings">
    <ng-container *ngIf="indstillinger$ | async as indstillinger">
        <!-- '#top-navbar' pilles runtime ud af ASP.NET/C# siden og indsættes her -->

        <!-- Desktop -->
        <div *ngIf="settings.isDesktop" class="desktop-container">
            <ng-container *ngTemplateOutlet="leftmenu"></ng-container>
            <div>
                <ng-container *ngTemplateOutlet="content"></ng-container>
            </div>
        </div>

        <!-- Mobil -->
        <ng-container *ngIf="settings.isMobile">
            <!-- .navbar -->
            <div class="navbar-mobile backgroundcolor2" #navbarMobile>
                <div class="navbar-mobile__top">
                    <button
                        #LeftMobileMenuButton
                        id="LeftMobileMenuButton"
                        class="menu-button left"
                        (click)="sidenavLeft.toggle(); toggleAriaExpanded(LeftMobileMenuButton, 'sidemenu')"
                        aria-label="Åben sidemenu"
                        aria-expanded="false"
                        aria-controls="sidenavLeft"
                        type="button"
                        role="button"
                        *ngIf="indstillinger.eforsyninger?.length"
                    >
                        <mat-icon [svgIcon]="eforsyningIcon$ | async"></mat-icon>
                    </button>
                    <a [href]="indstillinger.forsyningWeb?.siteUrl">
                        <img *ngIf="indstillinger.logoUrl" [src]="indstillinger.logoUrl" [alt]="indstillinger.firmaNavn" />
                        <span *ngIf="!indstillinger.logoUrl">{{ indstillinger.firmaNavn }}</span>
                    </a>
                    <driftsstatus-knap
                        class="backgroundcolor2"
                        titel="Drift"
                        [visIkon]="false"
                        *ngIf="settings.visDriftsstatus"
                    ></driftsstatus-knap>
                    <button
                        #RightMobileMenuButton
                        id="RightMobileMenuButton"
                        class="menu-button right"
                        aria-label="Åben hovedmenu"
                        aria-expanded="false"
                        aria-controls="sidenavRight"
                        type="button"
                        role="button"
                        (click)="sidenavRight.toggle(); toggleAriaExpanded(RightMobileMenuButton, 'hovedmenu')"
                        *ngIf="indstillinger?.forsyningWeb.nodeId"
                    >
                        <mat-icon svgIcon="menu"></mat-icon>
                    </button>
                </div>

                <ng-container *ngTemplateOutlet="vaelgInstallation"></ng-container>
                <ng-container *ngTemplateOutlet="breadcrumb"></ng-container>
                <div class="navbar-mobile__bottom"></div>
            </div>
            <!-- content -->
            <div class="sidenav-wrapper" [style.top.px]="navbarMobileHeight$ | async">
                <mat-sidenav-container #sidenavContainer>
                    <mat-sidenav #sidenavLeft>
                        <eforsyning-sidenav-menu
                            id="sidenavLeft"
                            aria-labelledby="LeftMobileMenuButton"
                            role="navigation"
                            type="navigation"
                            aria-label="Side menu"
                            (itemClick)="sidenavContainer.close()"
                            [visLoggetUdMenu]="true"
                        ></eforsyning-sidenav-menu>
                    </mat-sidenav>
                    <mat-sidenav
                        #sidenavRight
                        position="end"
                        id="sidenavRight"
                        aria-labelledby="RightMobileMenuButton"
                        role="navigation"
                        type="navigation"
                        aria-label="Hoved menu"
                    >
                        <forsyningweb-sidenav-menu (itemClick)="sidenavContainer.close()"></forsyningweb-sidenav-menu>
                    </mat-sidenav>
                    <mat-sidenav-content id="MatSidenavContent" #sidenavContent>
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </mat-sidenav-content>
                </mat-sidenav-container>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<!-- Indhold, indsættes i enten Desktop eller Mobil-containeren -->
<ng-template #content><ng-content></ng-content></ng-template>
<ng-template #breadcrumb><ng-content select=".breadcrumb"></ng-content></ng-template>
<ng-template #vaelgInstallation><ng-content select=".vaelg-installation"></ng-content></ng-template>
<ng-template #leftmenu><ng-content select=".leftmenu"></ng-content></ng-template>
