import { AbstractControl, UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export function updateValueAndValidity(control: AbstractControl): void {
    getControlsAsArray(control).forEach((c) => {
        updateValueAndValidity(c);
    });
    control.updateValueAndValidity({ onlySelf: true });
}

export function markAsTouched(control: AbstractControl): void {
    getControlsAsArray(control).forEach((c) => {
        markAsTouched(c);
    });
    control.markAsTouched({ onlySelf: true });
}

export function updateDirty(control: AbstractControl): void {
    if (control.dirty) {
        control.markAsDirty();
    }
    getControlsAsArray(control).forEach((c) => {
        updateDirty(c);
    });
}

function getControlsAsArray(control: AbstractControl): AbstractControl[] {
    return control instanceof UntypedFormGroup || control instanceof UntypedFormArray ? controlsToArray(control.controls) : [];
}

export function controlsToArray(controls: { [key: string]: AbstractControl } | AbstractControl[]): AbstractControl[] {
    return Array.isArray(controls) ? controls : Object.keys(controls).map((key) => controls[key]);
}
