import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { mdiAlertOutline } from "@mdi/js";

import { defineCustomElements, SvgIconRegistry } from "@dffedb/util";

import { DffDropdownButtonModule } from "../shared/dff-dropdown-button/dff-dropdown-button.module";
import { DffedbDriftsstatusKnapComponent } from "./dffedb-driftsstatus-knap.component";

@NgModule({
    imports: [CommonModule, MatIconModule, DffDropdownButtonModule],
    exports: [DffedbDriftsstatusKnapComponent],
    declarations: [DffedbDriftsstatusKnapComponent],
    providers: []
})
export class DffedbDriftsstatusKnapModule {
    constructor(injector: Injector, svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiAlertOutline, "driftsstatus-active");
        defineCustomElements("dffedb-driftsstatus-knap", DffedbDriftsstatusKnapComponent, { injector });
    }
}
