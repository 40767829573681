import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

export class ObservableStorageBase {
    private subject: BehaviorSubject<Storage>;
    private observable$: Observable<Storage>;
    private updateCount = 0;

    constructor(private readonly storage: Storage) {
        this.subject = new BehaviorSubject(storage);
        this.observable$ = this.subject.asObservable();
    }

    public get length(): number {
        return this.storage.length;
    }

    public removeItem(key: string): void {
        this.storage.removeItem(key);
        this.notifyChanged();
    }

    public getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    public select(key: string): Observable<string> {
        return this.observable$.pipe(
            map((s) => s[key]),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    public setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
        this.notifyChanged();
    }

    public clear(): void {
        this.storage.clear();
        this.notifyChanged();
    }

    public beginUpdate(): void {
        this.updateCount++;
    }

    public endUpdate(): void {
        this.updateCount--;
        this.notifyChanged();
    }

    private notifyChanged(): void {
        if (this.updateCount === 0) {
            this.subject.next(this.storage);
        }
    }
}
