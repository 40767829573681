import { AbstractControl } from "@angular/forms";

import { BehaviorSubject, Observable } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { EBoksTilmelding } from "../model/e-boks-tilmelding.model";

export function maskCprNummer(value: string): string {
    return (value && value.slice(0, 4) + "******") || "";
}

export function formatterCprNummer(value: string): string {
    return (value && value.replace(/(.{6})(.{4})/, "$1-$2")) || "";
}

export function formatterCvrNummer(value: string): string {
    return (value && `DK ${value}`) || "";
}

export function formatterCprEllerCvrNummer(value: string): string {
    value = value || "";
    return value.length === 10 ? formatterCprNummer(value) : formatterCvrNummer(value);
}

export function formatterPNummer(value: string): string {
    return value;
}

export function opretTomEBoksTilmelding(): EBoksTilmelding {
    const tilmelding: EBoksTilmelding = {
        id: null,
        cprNummer: null,
        cvrNummer: null,
        pNummer: null,
        samtykkeTekst: null
    };
    return tilmelding;
}

export interface SamtykkeTekstData {
    firmaNavn: string;
    telefon: string;
    cprNummer: string;
    cvrNummer: string;
    pNummer: string;
}

export function opretSamtykkeTekst(data: SamtykkeTekstData): string {
    const cprNummer = (data.cprNummer && `CPR-nummer: ${formatterCprNummer(maskCprNummer(data.cprNummer))}`) || "";
    const cvrNummer = (data.cvrNummer && `CVR-nummer: ${formatterCvrNummer(data.cvrNummer)}`) || "";
    const pNummer = (data.pNummer && `P-nummer: ${data.pNummer}`) || "";
    const samtykker = `${cprNummer}\r\n${cvrNummer}\r\n${pNummer}`.trim().replace("\r\n", "<br/>");
    const samtykkeTekstSkabelon = `
        <p>
            [FIRMANAVN] må bruge mit CPR- eller CVR-nummer til at sende mig breve i e-Boks,
            som jeg ellers ville modtage med brevomdeling.
        </p>
        <p>
            [SAMTYKKER]
        </p>
        <p>
            <strong>Betingelser:</strong><br>
            Du har altid mulighed for at trække dit samtykke tilbage. Det kan du gøre ved at kontakte [FIRMANAVN] på telefon [TELEFON].
        </p>
        <p>
            [FIRMANAVN] bruger ikke dit CPR- eller CVR-nummer til markedsføring, og vi videregiver det ikke til andre.
            Alle medarbejdere ved [FIRMANAVN] er underlagt tavshedspligt. Se vores privatlivspolitik på vores hjemmeside.
        </p>
        <p>
            Du bekræfter, at dette samtykke er afgivet frivilligt, og at du er bekendt med dine rettigheder i forbindelse
            med afgivelse af samtykkeerklæringen: Samtykke skal, for at være gyldigt, være afgivet ud fra følgende kriterier:
        </p>
        <ul>
            <li>Samtykket skal være frivilligt, specifikt, informeret og utvetydigt</li>
            <li>Samtykket kan til enhver tid trækkes tilbage</li>
        </ul>`;
    return samtykkeTekstSkabelon
        .replace(/\[firmanavn\]/gi, data.firmaNavn)
        .replace(/\[telefon\]/gi, data.telefon)
        .replace(/\[samtykker\]/gi, samtykker);
}

// Returnerer en observable, som reagerer ligesom formGroup.valueChanges
// Fordelen frem for formGroup.valueChanges er, at observe() returnerer en observable, som husker sin sidste værdi.
export function valueChanges<T>(control: AbstractControl<T>): Observable<T> {
    return toBehaviorObservable(control.valueChanges, control.value);
}

// toBehaviorObservable() bruges til at konvertere en Observable om til en ny Observable, som husker seneste submition.
// Findes der mon nogle operatorer, som kan gøre dette?
export function toBehaviorObservable<T>(observable: Observable<T>, initialValue: T = null): Observable<T> {
    const result = new BehaviorSubject<T>(initialValue);
    observable.pipe(takeUntil(result.pipe(filter(() => result.closed)))).subscribe(
        (value) => result.next(value),
        (error) => result.error(error)
    );
    return result.asObservable();
}
