import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SafeHtml } from "@angular/platform-browser";

import { DffSimpelDialogComponent } from "./dff-simpel-dialog.component";

// Provides i DffSimpelDialogModule (og ikke "root") for at understøtte dynamisk oprettelse af DffSimpelDialogComponent
@Injectable()
export class DffSimpelDialog {
    constructor(private readonly dialog: MatDialog) {}

    public async open(titel: string, tekst: string | SafeHtml, knapper: string[], config: DffSimpelDialogConfig = {}): Promise<any> {
        const matDialogConfig = { ...config, data: { titel, tekst, knapper } };
        const dialog = this.dialog.open(DffSimpelDialogComponent, matDialogConfig);
        return await dialog.afterClosed().toPromise();
    }

    public async openJaNej(titel: string, tekst: string | SafeHtml, config: DffSimpelDialogConfig = {}): Promise<boolean> {
        return (await this.open(titel, tekst, ["JA", "NEJ"], config)) === "JA";
    }

    public async openOkAnnuller(titel: string, tekst: string | SafeHtml, config: DffSimpelDialogConfig = {}): Promise<boolean> {
        return (await this.open(titel, tekst, ["ANNULLER", "OK"], config)) === "OK";
    }

    public async openOk(titel: string, tekst: string | SafeHtml, config: DffSimpelDialogConfig = {}): Promise<void> {
        await this.open(titel, tekst, ["OK"], config);
    }
}

export interface DffSimpelDialogConfig {
    maxWidth?: number;
}
