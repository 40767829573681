import { AbstractControlOptions, AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";

import { DffOprindeligAktuelVaerdier } from "@dffedb/util";

import { DffFormControl, DffFormControlOptions } from "../dff-form-control/dff-form-control";

export class DffOprindeligAktuelVaerdiFormGroup extends UntypedFormGroup {
    private aktuel: DffFormControl;

    constructor(
        formState: DffOprindeligAktuelVaerdier,
        options: DffFormControlOptions = {},
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        const oprindelig = new UntypedFormControl(formState.oprindelig);
        const aktuel = new DffFormControl(formState.aktuel, options, validatorOrOpts, asyncValidator);
        super({ aktuel, oprindelig });
        this.aktuel = aktuel;

        this.valueChanges.subscribe(() => this.tjekOmDerErSketAendringer());
        setTimeout(() => this.tjekOmDerErSketAendringer());
    }

    public setOptions(value: DffFormControlOptions): void {
        this.aktuel.setOptions(value);
    }

    public setValidators(newValidator: ValidatorFn | ValidatorFn[] | null): void {
        this.aktuel.setValidators(newValidator);
    }

    public setAsyncValidators(newValidator: AsyncValidatorFn | AsyncValidatorFn[] | null): void {
        this.aktuel.setAsyncValidators(newValidator);
    }

    private tjekOmDerErSketAendringer(): void {
        const aktuel = this.controls["aktuel"];
        const oprindelig = this.controls["oprindelig"];

        const aktuelVaerdi = this.erNullEllerUndefined(aktuel.value) ? "" : aktuel.value.toString();
        const oprindeligVaerdi = this.erNullEllerUndefined(oprindelig.value) ? "" : oprindelig.value.toString();

        if (aktuelVaerdi === oprindeligVaerdi) {
            aktuel.markAsPristine();
        } else {
            aktuel.markAsDirty();
        }
    }

    private erNullEllerUndefined(value: any): boolean {
        return value === null || value === undefined;
    }
}
