import { EforsyningIndstilling } from "./e-forsyning-indstilling.model";
import { ForsyningWebIndstilling } from "./forsyning-web-indstilling.model";

export class UmbracoIndstillinger {
    public eforsyninger: EforsyningIndstilling[] = [];
    public aktuelEforsyningNodeId: number;

    public forsyningWeb: ForsyningWebIndstilling = {};

    public firmaNavn: string;
    public vaerkslisteUrl: string;
    public vaerkNodeId: number;
    public themeUrl: string;
    public logoUrl: string;
    public visDriftsstatus: boolean;
    public driftsstatusForstyrrelserTekst: string;
    public driftsstatusIngenForstyrrelserTekst: string;
    public matomoSiteId: string;

    public featureToggles: FeatureToggles = {
        cookiepolitik: false
    };

    public get aktuelEforsyning(): EforsyningIndstilling {
        const result = this.eforsyninger.find((e) => e.nodeId === this.aktuelEforsyningNodeId);
        return result;
    }
}

export interface FeatureToggles {
    cookiepolitik: boolean;
}
