import { IAppserverCredentialsRepository } from "./appserver-credentials-repository.interface";
import { AppserverCredentials } from "./appserver-credentials.model";

export class AppserverEksternForsyningCredentialsRepository implements IAppserverCredentialsRepository {
    constructor(private readonly credentials: AppserverCredentials) {}

    public hent(): AppserverCredentials {
        return this.credentials;
    }
}
