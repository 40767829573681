import { ErrorHandler, Injectable, Injector } from "@angular/core";

import { LogService } from "../log/log.service";

@Injectable()
export class EforsyningErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    public handleError(error: any): void {
        const logService = this.injector.get(LogService);
        logService.logError(error);
        throw error;
    }
}
