import { NgModule } from "@angular/core";

import { DffInfoComponent } from "./dff-info.component";

@NgModule({
    imports: [],
    exports: [DffInfoComponent],
    declarations: [DffInfoComponent],
    providers: []
})
export class DffInfoModule {}
