import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

import { Observable, of } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";

import { DffRouteChangingObserver } from "@dffedb/router";

@Component({
    selector: "dff-side",
    template: `
        <ng-container *ngIf="vm$ | async as vm">
            <ng-content *ngIf="vm.showContent"></ng-content>
            <page-error *ngIf="vm.error" [error]="vm.error"></page-error>
            <mat-spinner *ngIf="!vm.showContent && !vm.error"></mat-spinner>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DffSideComponent implements OnInit {
    public vm$: Observable<{ showContent: boolean; error: any }>;
    @Input() public observable: Observable<any>;

    constructor(private readonly routeChangingObserver: DffRouteChangingObserver) {}

    public ngOnInit(): void {
        // <dff-side> viser en spinner:
        // - Når data loades på aktuelle side - kræver angivelse af [observable].
        // - Når der navigeres til en anden side (intil navigationen er overstået).
        //   Dette er lavet i tilfælde af langsom route guard (skal måske spørge serveren om vi er authenticated).
        const value$ = this.observable || of({});
        const notNavigating$ = this.routeChangingObserver.valueChanges.pipe(map(changing => !changing));
        const showContent$ = value$.pipe(switchMap(() => notNavigating$));
        this.vm$ = showContent$.pipe(
            startWith(false),
            map(showContent => ({ showContent, error: null })),
            catchError(error => of({ showContent: false, error }))
        );
    }
}
