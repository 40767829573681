import { Rolle } from "@e-forsyning/common/bruger";

export interface IInstallationsoplysninger {
    navn: string;
    vejnavn: string;
    husnr: string;
    litra: string;
    postnr: string;
    bynavn: string;
    telefonnr: string;
    beboeremail: string;
    ejendomnr: string;
    navn2: string;
    litra2: string;
    id: number;
    rolle: Rolle;
    mail: string;
    ejerNavn: string;
}
