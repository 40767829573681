<table>
    <ng-container *ngFor="let group of kvittering.groups; let i = index">
        <tr class="spacer" *ngIf="i !== 0">
            <td colspan="2"><hr /></td>
        </tr>
        <tr class="header">
            <td colspan="2">{{ group.title }}</td>
        </tr>
        <tr class="data" *ngFor="let row of group.rows">
            <td>{{ row.key }}:</td>
            <td [innerHTML]="row.value"></td>
        </tr>
    </ng-container>
</table>
