import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";

import { DffInfoPopupModule } from "@dffedb/ui";
import { defineCustomElements } from "@dffedb/util";

import { DffedbInfoPopupComponent } from "./dffedb-info-popup.component";

@NgModule({
    imports: [CommonModule, DffInfoPopupModule],
    exports: [DffedbInfoPopupComponent],
    declarations: [DffedbInfoPopupComponent],
    providers: []
})
export class DffedbInfoPopupModule {
    constructor(injector: Injector) {
        defineCustomElements("dffedb-info-popup", DffedbInfoPopupComponent, { injector });
    }
}
