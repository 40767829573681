import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { shareReplay, switchMap } from "rxjs/operators";

import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger/umbraco-indstillinger.observer";
import { ForsyningIndstillingerRepository } from "./forsyning-indstillinger.repository";
import { ForsyningIndstillinger } from "./model/forsyning-indstillinger.model";

@Injectable({ providedIn: "root" })
export class AktuelForsyningIndstillingerObserver {
    private readonly _valueChanges: Observable<ForsyningIndstillinger>;

    constructor(
        private readonly indstillinger: UmbracoIndstillingerObserver,
        private readonly repository: ForsyningIndstillingerRepository
    ) {
        this._valueChanges = this.indstillinger.valueChanges.pipe(
            switchMap(
                i => (i && i.aktuelEforsyning && this.repository.hentForsyningIndstillinger(i.aktuelEforsyning.forsyningId)) || of(null)
            ),
            shareReplay(1)
        );
    }

    public get valueChanges(): Observable<ForsyningIndstillinger> {
        return this._valueChanges;
    }
}
