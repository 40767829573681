import { HttpResponseBase } from "@angular/common/http";

import { fromError, StackFrame, StackTraceOptions } from "stacktrace-js";

/*
 * Vi benytter nogle 3. parts-værktøjer til at parse stack trace.
 * I tilfælde af minificeret JavaScript, gør disse værktøjer brug af map-filen til at omforme til de korrekte filer og linjenumre.
 */

export async function getFormattedStackTrace(error: any): Promise<string> {
    const stackFrames = await getStackTrace(error);
    return formatStackTrace(stackFrames);
}

export async function getStackTrace(error: any): Promise<StackFrame[]> {
    // HttpResponseBase indeholder ikke stack-trace. Så vi returnerer en tom trace.
    if (error instanceof HttpResponseBase) {
        return [];
    }
    if (!(error instanceof Error)) {
        return [];
    }

    // fromError() prøver at loade filer for at danne korrekt stack.
    // I tilfælde af en stack-linje i html-fil, vil den være markeret som 'ng:'. Denne kan ikke loades så vi skipper den.
    const options: StackTraceOptions = {
        filter: (stackFrame: StackFrame) => !stackFrame.fileName?.startsWith("ng:")
    };
    return await fromError(error, options).catch(() => []);
}

export function formatStackTrace(stackFrames: StackFrame[]): string {
    return stackFrames.map((f) => `  at ${f.functionName} (${f.fileName}:${f.lineNumber})`).join("\r\n");
}
