import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { ControlsOf, cprNummerValidator, cvrNummerValidator, mustMatchValidator, pNummerValidator } from "@dffedb/forms";

import { EBoksTilmelding } from "../model/e-boks-tilmelding.model";

export interface EBoksTilmeldingIdentitetModel {
    type: number;
    person: {
        cprNummer: string;
        cprNummerGentag: string;
    };
    virksomhed: {
        cvrNummer: string;
        pNummer: string;
    };
}

export interface EBoksTilmeldingSamtykkeModel {
    samtykkeTekstAccepteret: boolean;
}

export interface EBoksTilmeldingModel {
    id: number;
    identitet: EBoksTilmeldingIdentitetModel;
    samtykke: EBoksTilmeldingSamtykkeModel;
}

export class EBoksTilmeldingFormBuilder {
    public static eBoksTilmeldingToFormGroup(tilmelding: EBoksTilmelding, disabled: boolean): FormGroup<ControlsOf<EBoksTilmeldingModel>> {
        const result = new FormGroup<ControlsOf<EBoksTilmeldingModel>>({
            id: new FormControl(tilmelding.id),
            identitet: new FormGroup({
                type: new FormControl(this.beregnIdentitetType(tilmelding)),
                person: new FormGroup({
                    cprNummer: new FormControl(tilmelding.cprNummer),
                    cprNummerGentag: new FormControl("")
                }),
                virksomhed: new FormGroup({
                    cvrNummer: new FormControl(tilmelding.cvrNummer),
                    pNummer: new FormControl(tilmelding.pNummer)
                })
            }),
            samtykke: new FormGroup({
                samtykkeTekstAccepteret: new FormControl(false, [Validators.requiredTrue])
            })
        });

        this.updateDisabled(result, disabled);
        this.updateStatus(result);
        result.valueChanges.subscribe(() => this.updateStatus(result));

        return result;
    }

    public static updateDisabled(formGroup: FormGroup<ControlsOf<EBoksTilmeldingModel>>, disabled: boolean): void {
        const id = formGroup.get("id").value;
        this.setDisabled(formGroup.get("identitet.type"), disabled);
        this.setDisabled(formGroup.get("identitet.person.cprNummer"), !!id || disabled);
        this.setDisabled(formGroup.get("identitet.person.cprNummerGentag"), !!id || disabled);
        this.setDisabled(formGroup.get("identitet.virksomhed.cvrNummer"), !!id || disabled);
        this.setDisabled(formGroup.get("identitet.virksomhed.pNummer"), !!id || disabled);
        this.setDisabled(formGroup.get("samtykke.samtykkeTekstAccepteret"), disabled);
    }

    public static formGroupToEBoksTilmelding(
        formGroup: FormGroup<ControlsOf<EBoksTilmeldingModel>>,
        samtykkeTekst: string
    ): EBoksTilmelding {
        const type = +formGroup.get("identitet.type").value;
        const result: EBoksTilmelding = {
            id: +formGroup.get("id").value,
            cprNummer: type === 0 ? formGroup.get("identitet.person.cprNummer").value : null,
            cvrNummer: type === 1 ? formGroup.get("identitet.virksomhed.cvrNummer").value : null,
            pNummer: type === 1 ? formGroup.get("identitet.virksomhed.pNummer").value : null,
            samtykkeTekst
        };
        return result;
    }

    private static setDisabled(control: AbstractControl, disabled: boolean): void {
        if (disabled) {
            control.disable();
        } else {
            control.enable();
        }
    }

    private static beregnIdentitetType(tilmelding: EBoksTilmelding): number {
        return tilmelding.cvrNummer ? 1 : 0;
    }

    private static updateStatus(formGroup: FormGroup<ControlsOf<EBoksTilmeldingModel>>): void {
        const cprNummerValidators = [Validators.required, Validators.minLength(10), Validators.maxLength(10), cprNummerValidator()];
        const cprNummerGentagValidators = [mustMatchValidator("cprNummer")];
        const cvrNummerValidators = [Validators.required, Validators.minLength(8), Validators.maxLength(8), cvrNummerValidator()];
        const pNummerValidators = [Validators.minLength(10), Validators.maxLength(10), pNummerValidator()];
        const identitetType = formGroup.get("identitet.type").value;
        const erPrivatPerson = identitetType === 0;
        this.setValidators(formGroup.get("identitet.person.cprNummer"), erPrivatPerson ? cprNummerValidators : []);
        this.setValidators(formGroup.get("identitet.person.cprNummerGentag"), erPrivatPerson ? cprNummerGentagValidators : []);
        this.setValidators(formGroup.get("identitet.virksomhed.cvrNummer"), erPrivatPerson ? [] : cvrNummerValidators);
        this.setValidators(formGroup.get("identitet.virksomhed.pNummer"), erPrivatPerson ? [] : pNummerValidators);
    }

    private static setValidators(control: AbstractControl, validators: ValidatorFn[]): void {
        control.setValidators(validators);
        control.updateValueAndValidity({ emitEvent: false });
    }
}
