import { Component, HostBinding, Input } from "@angular/core";

@Component({
    selector: "dff-button-panel",
    templateUrl: "./dff-button-panel.component.html",
    styleUrls: ["./dff-button-panel.component.scss"]
})
export class DffButtonPanelComponent {
    @HostBinding("class") public get cssClass(): string {
        return `dff-button-panel align-${this.align}`;
    }

    @Input() public align: "left" | "right" = "right";
}
