import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable, Injector } from "@angular/core";

import { EBoksPopupContentComponent } from "./e-boks-popup-content/e-boks-popup-content.component";
import { EBoksPopupRef } from "./e-boks-popup-ref";

@Injectable({ providedIn: "root" })
export class EBoksPopup {
    public popupRef: EBoksPopupRef;
    constructor(private readonly injector: Injector, private readonly overlay: Overlay) {}

    public open(): EBoksPopupRef {
        if (this.popupRef) {
            return this.popupRef;
        }
        const overlayRef = this.overlay.create({
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            hasBackdrop: true,
            backdropClass: "e-boks-popup-backdrop",
            scrollStrategy: this.overlay.scrollStrategies.block()
        });
        this.popupRef = new EBoksPopupRef(overlayRef);
        this.popupRef.closed.subscribe(() => {
            this.popupRef = null;
        });
        this.attachContainer(overlayRef, this.popupRef);

        return this.popupRef;
    }

    private attachContainer(overlayRef: OverlayRef, popupRef: EBoksPopupRef): EBoksPopupContentComponent {
        const injector = this.createInjector(popupRef);
        const containerPortal = new ComponentPortal(EBoksPopupContentComponent, null, injector);
        const containerRef = overlayRef.attach(containerPortal);
        return containerRef.instance;
    }

    private createInjector(popupRef: EBoksPopupRef): Injector {
        return Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: EBoksPopupRef,
                    useValue: popupRef
                }
            ]
        });
    }
}
