import { Injectable } from "@angular/core";

import { Observable, ReplaySubject } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";

import { ToastService } from "@dffedb/ui";
import { LogLevel, LogService } from "@e-forsyning/common/log";

import { AdresseSearch, ForsyningPaaAdresseResult } from "../model/forsyning-paa-adresse.model";
import { ForsyningPaaAdresseViewModel } from "../model/forsyning-paa-adresse.view-model";
import { ForsyningPaaAdresseRepository } from "./forsyning-paa-adresse.repository";

@Injectable({ providedIn: "root" })
export class ForsyningPaaAdresseSandbox {
    private viewModelSubject = new ReplaySubject<ForsyningPaaAdresseViewModel>(1);

    constructor(private readonly repository: ForsyningPaaAdresseRepository, private readonly log: LogService, private readonly toast: ToastService) {}

    public selectViewModel(nodeId: number): Observable<ForsyningPaaAdresseViewModel> {
        this.repository
            .selectKonfiguration(nodeId)
            .pipe(first())
            .subscribe((konfiguration) => {
                this.viewModelSubject.next({ konfiguration });
            });
        return this.viewModelSubject.asObservable();
    }

    public findAdresser(value: string): Observable<AdresseSearch[]> {
        return this.viewModelSubject.pipe(
            map((vm) => vm.konfiguration.afgraensning.postNr),
            switchMap((postNr) => this.repository.findAdresser(value, postNr)),
            catchError((err) => {
                this.log.logError("DAWA ser ud til at være nede", LogLevel.Warning);
                this.toast.visFejl("Adressesøgning fungerer ikke i øjeblikket - prøv igen senere.");
                console.warn("DAWA ser ud til at være nede", err);
                return [];
            })
        );
    }

    public checkOmDerLeveresPaaAdressen(adresse: AdresseSearch): Observable<ForsyningPaaAdresseResult> {
        return this.viewModelSubject.pipe(
            switchMap((vm) => this.repository.checkOmDerLeveresPaaAdressen(vm.konfiguration.eforsyningNodeId, adresse)),
            map((result) => this.sanitize(result))
        );
    }

    private sanitize(result: ForsyningPaaAdresseResult): ForsyningPaaAdresseResult {
        // FOF returnerer en tom paragraph i stedet for tom tekst.
        // Det skal vi lige have rettet op på, så vi får taget fallback-teksten fra Umbraco
        const statusTekst = result.statusTekst === "<p></p>" ? null : result.statusTekst;
        return { ...result, statusTekst };
    }
}
