import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatTableModule } from "@angular/material/table";

import { InstallationListeComponent } from "./installation-liste.component";

@NgModule({
    imports: [CommonModule, MatTableModule],
    declarations: [InstallationListeComponent],
    exports: [InstallationListeComponent]
})
export class InstallationListeModule {}
