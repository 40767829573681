import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { DffCookieConsentDialogComponent } from "./dff-cookie-consent-dialog.component";

@Injectable({ providedIn: "root" })
export class DffCookieConsentDialog {
    constructor(private readonly dialog: MatDialog) {}

    public open(): void {
        this.dialog.open(DffCookieConsentDialogComponent, { disableClose: true, width: "500px" });
    }
}
