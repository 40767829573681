<dff-dropdown-button *ngIf="vm.visible" titel="{{ titel }}" [svgIcon]="vm.svgIcon" [class]="class">
    <div [class]="class">
        <div class="overlay" *ngIf="vm.active">
            <p class="text">{{ driftsstatusforstyrrelsertekst }}</p>
            <a [href]="vm.href">Læs mere <span class="sr-only">om driftsforstyrrelser</span></a>
        </div>
        <div class="overlay" *ngIf="!vm.active">
            <span class="text">{{ driftsstatusingenforstyrrelsertekst }}</span>
        </div>
    </div>
</dff-dropdown-button>
