export interface DffCookieConsent {
    version: string;
    utc: Date;
    consent: {
        necessary: boolean;
        statistics: boolean;
        thirdparty: boolean;
    };
}

export interface DffCookieConsentChanged extends DffCookieConsent {
    reloading: boolean;
}

export function cookieConsentsEqual(consent1: DffCookieConsent, consent2: DffCookieConsent): boolean {
    return JSON.stringify(consent1.consent) === JSON.stringify(consent2.consent);
}

export function cookieConsentToString(value: DffCookieConsent): string {
    return `version=${value.version}&utc=${value.utc.getTime()}&consent.necessary=${value.consent.necessary}&consent.thirdparty=${
        value.consent.thirdparty
    }&consent.statistics=${value.consent.statistics}`;
}

export function stringToCookieConsent(value: string): DffCookieConsent {
    const keyValuePairs = value.split("&").map(cc => cc.split("="));
    return {
        version: getAsString(keyValuePairs, "version"),
        utc: getAsDate(keyValuePairs, "utc"),
        consent: {
            necessary: true,
            thirdparty: getAsBoolean(keyValuePairs, "consent.thirdparty", false),
            statistics: getAsBoolean(keyValuePairs, "consent.statistics", false)
        }
    };
}

export function getAllEnabledCookieConsent(version: string): DffCookieConsent {
    return {
        version,
        utc: new Date(Date.now()),
        consent: {
            necessary: true,
            thirdparty: true,
            statistics: true
        }
    };
}

function getAsString(keyValuePairs: string[][], key: string): string {
    const item = keyValuePairs.find(kv => kv[0] === key) || ["", "0"];
    return item[1];
}

function getAsDate(keyValuePairs: string[][], key: string): Date {
    const item = keyValuePairs.find(kv => kv[0] === key) || ["", new Date().getTime().toString()];
    return new Date(parseInt(item[1], 10));
}

function getAsBoolean(keyValuePairs: string[][], key: string, defaultValue: boolean): boolean {
    const item = keyValuePairs.find(kv => kv[0] === key);
    return item ? item[1] === "true" : defaultValue;
}
