import { Injectable } from "@angular/core";

import { ObserverBasedService } from "../util";
import { AktuelBrugersInstallationerObserver } from "./aktuel-brugers-installationer.observer";
import { Installation } from "./model/installation.model";

@Injectable({ providedIn: "root" })
export class AktuelBrugersInstallationerService extends ObserverBasedService<Installation[]> {
    constructor(observer: AktuelBrugersInstallationerObserver) {
        super(observer);
    }
}
