import { genererCorrelationId } from "../correlation-id/correlation-id.service";
import { sessionId } from "../session-id/session-id.service";

export interface DffErrorParams {
    friendlyMessage?: string;
    innerError?: any;
    correlationId?: string;
    showNotification?: boolean;
}

export class DffError extends Error {
    public readonly correlationId: string;
    public readonly friendlyMessage: string;
    public readonly showNotification: boolean;

    constructor(message: string, private readonly params: DffErrorParams = {}) {
        super(message);
        Object.setPrototypeOf(this, DffError.prototype);
        this.name = "DffError";

        this.correlationId =
            this.params.correlationId || (this.params.innerError && this.params.innerError.correlationId) || genererCorrelationId();
        this.friendlyMessage = params.friendlyMessage || "";
        this.showNotification = !!params.showNotification;
    }

    public get logId(): string {
        return `${sessionId}-${this.correlationId}`;
    }
}
