import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger";
import { MailHelper } from "./mail-helper";

@Injectable({ providedIn: "root" })
export class EforsyningMailBuilderService {
    constructor(private readonly indstillinger: UmbracoIndstillingerObserver) {}

    public genererAsync(titel: string, ...body: string[]): Observable<string> {
        return this.indstillinger.valueChanges.pipe(map((i) => this.generer(...body)));
    }

    public generer(...body: string[]): string {
        return this.genererMailBody(
            //titel,
            //this.genererLogo(firmaOplysninger.logoUrl, firmaOplysninger.firmaNavn),
            //firmaOplysninger,
            ...body
        );
    }

    private genererLogo(logoUrl: string, fallbackFirmaNavn: string): string {
        return (logoUrl && MailHelper.image(`${logoUrl}?height=100&width=600&mode=max&upscale=false`)) || MailHelper.h1(fallbackFirmaNavn);
    }

    private genererMailBody(...body: string[]): string {
        const html = `
                                        ${body.join(" ")}
                                        `;
        return html;
    }
}
