import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";

import { mdiCheckCircleOutline, mdiClose, mdiPencil } from "@mdi/js";
import { NgxMaskModule } from "ngx-mask";

import { DffButtonModule, DffInfoDialogModule, DffSimpelDialogModule, RevealOnFocusModule } from "@dffedb/ui";
import { SvgIconRegistry } from "@dffedb/util";

import { EBoksPopupContentComponent } from "./e-boks-popup/e-boks-popup-content/e-boks-popup-content.component";
import { EBoksTilmeldingComponent } from "./e-boks-tilmelding/e-boks-tilmelding.component";
import { StepAdresseKodeComponent } from "./e-boks-tilmelding/steps/step-adresse-kode/step-adresse-kode.component";
// eslint-disable-next-line max-len
import { StepEksisterendeTilmeldingerComponent } from "./e-boks-tilmelding/steps/step-eksisterende-tilmeldinger/step-eksisterende-tilmeldinger.component";
import { StepIdentitetComponent } from "./e-boks-tilmelding/steps/step-identitet/step-identitet.component";
import { StepSamtykkeComponent } from "./e-boks-tilmelding/steps/step-samtykke/step-samtykke.component";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatTabsModule,
        NgxMaskModule.forRoot(),
        DffSimpelDialogModule,
        DffButtonModule,
        RevealOnFocusModule,
        DffInfoDialogModule
    ],
    declarations: [
        EBoksTilmeldingComponent,
        StepAdresseKodeComponent,
        StepEksisterendeTilmeldingerComponent,
        StepIdentitetComponent,
        StepSamtykkeComponent,
        EBoksPopupContentComponent
    ],
    exports: [
        EBoksTilmeldingComponent,
        StepAdresseKodeComponent,
        StepEksisterendeTilmeldingerComponent,
        StepIdentitetComponent,
        StepSamtykkeComponent
    ]
})
export class EBoksTilmeldingModule {
    constructor(iconRegistry: SvgIconRegistry) {
        iconRegistry.registerIcon(mdiClose, "e-boks-dialog-close-icon");
        iconRegistry.registerIcon(mdiCheckCircleOutline, "e-boks-success-icon");
        iconRegistry.registerIcon(mdiPencil, "e-boks-stepper-icon");
    }
}
