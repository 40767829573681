export interface DffGroupedContent {
    groups: DffContentGroup[];
}

export interface DffContentGroup {
    title: string;
    rows: DffContentRow[];
}

export interface DffContentRow {
    key: string;
    value: string;
}
