import { Component, HostBinding, Input, OnInit } from "@angular/core";

import { interval } from "rxjs";
import { filter, first } from "rxjs/operators";

import { isValidDate } from "@dffedb/util";

@Component({
    selector: "dff-info-popup",
    styleUrls: ["./dff-info-popup.component.scss"],
    templateUrl: "./dff-info-popup.component.html"
})
export class DffInfoPopupComponent implements OnInit {
    private manuallyHidden = false;

    @HostBinding("class.dff-info-popup") public classDffInfoPopup = true;
    @HostBinding("class.init") public classInit = true;
    @HostBinding("class.visible") public classVisible = false;

    @Input() public header: string;
    @Input() public content: string;
    @Input() public imageSrc: string;
    @Input() public buttonText: string;
    @Input() public buttonHref: string;
    @Input() public buttonTarget: string;
    @Input() public showAfterMs: number;
    @Input() public hideAfterMs: number;
    @Input() public visibleStartTime: Date;
    @Input() public visibleEndTime: Date;

    constructor() {}

    public ngOnInit(): void {
        this.showAfterMs = +this.showAfterMs;
        this.hideAfterMs = +this.hideAfterMs;
        let startDate = this.visibleStartTime;
        let endDate = this.visibleEndTime;

        // Adjusts the time zone to GMT/GMT+0, as that is what comes from Umbraco calendar component
        startDate.setHours(startDate.getHours() - 2);
        endDate.setHours(endDate.getHours() - 2);

        if (!this.isDateInInterval(new Date(), startDate, endDate)) {
            return;
        }

        interval(this.showAfterMs)
            .pipe(first())
            .subscribe(() => {
                this.classInit = false;
                this.classVisible = true;
            });

        if (this.hideAfterMs !== 0) {
            interval(this.showAfterMs + this.hideAfterMs)
                .pipe(
                    filter(() => !this.manuallyHidden),
                    first()
                )
                .subscribe(() => {
                    this.classVisible = false;
                });
        }
    }

    public isDateInInterval(date: Date, intervalFrom: Date, intervalTo: Date): boolean {
        if (!isValidDate(date)) {
            return false;
        }
        if (isValidDate(intervalFrom) && isValidDate(intervalTo)) {
            return date >= intervalFrom && date <= intervalTo;
        }
        if (isValidDate(intervalFrom)) {
            return date >= intervalFrom;
        }
        if (isValidDate(intervalTo)) {
            return date <= intervalTo;
        }
        return true;
    }

    public onShowPopup(event: MouseEvent): void {
        event.preventDefault();
        this.classVisible = true;
    }

    public onHidePopup(event: MouseEvent): void {
        event.preventDefault();
        this.manuallyHidden = true;
        this.classVisible = false;
    }
}
