import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";

import { AppShellComponent } from "@e-forsyning/common/app-shell";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements AfterViewInit {
    @ViewChild(AppShellComponent, { read: ElementRef }) public appShell: ElementRef;
    @ViewChild("content", { read: ElementRef }) public contentContainer: ElementRef;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public ngAfterViewInit(): void {
        this.flytContentTilAppShell();
    }

    private flytContentTilAppShell(): void {
        // Hent content, som er renderet via ASP.NET/C#, og flyt den ind i <app-shell>,
        // så vi kan benytte Material Design Sidenav til mobil menu.
        const content = this.document.querySelector("#content");
        if (content) {
            this.contentContainer.nativeElement.appendChild(content);
        }
    }
}
