import { Component, Input, OnInit } from "@angular/core";

import { Observable } from "rxjs";

import { DffedbCookieConsentSandbox } from "./dffedb-cookie-consent.sandbox";
import { DffedbCookieConsentViewModel } from "./dffedb-cookie-consent.view-model";

@Component({
    templateUrl: "./dffedb-cookie-consent.component.html",
    styleUrls: ["./dffedb-cookie-consent.component.scss"]
})
export class DffedbCookieConsentComponent implements OnInit {
    public vm$: Observable<DffedbCookieConsentViewModel>;

    @Input() public version: string;
    @Input() public pageReload: boolean | string = false;

    constructor(private readonly sandbox: DffedbCookieConsentSandbox) {}

    public async ngOnInit(): Promise<void> {
        await this.sandbox.initialize(this.version, this.pageReload);
        this.vm$ = this.sandbox.selectViewModel();
        if (this.sandbox.isCookieConsentUpdateNeeded()) {
            this.sandbox.showCookieConsentDialog();
        } else {
            this.sandbox.renewCookieConsent();
        }
    }

    public onClick(event: Event): void {
        event.preventDefault();
        this.sandbox.showCookieConsentDialog();
    }
}
