import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AktuelBrugerObserver, Rolle } from "@e-forsyning/common/bruger";
import { AktuelForsyningIndstillingerObserver } from "@e-forsyning/common/forsyning-indstillinger";

import { AktuelInstallationKeyObserver } from "../aktuel-installation-key.observer";
import { Installation } from "../model/installation.model";

const visteKolonnerForbruger: string[] = ["adresse", "maalerOgForsyning", "forbruger"];
const visteKolonnerForbrugerXs: string[] = ["adresse", "maalerOgForsyning"];
const visteKolonnerIkkeForbruger: string[] = ["adresse", "maalerOgType", "forbruger"];
const visteKolonnerIkkeForbrugerXs: string[] = ["adresse", "maalerOgType"];
const visteKolonner = [
    [visteKolonnerIkkeForbruger, visteKolonnerIkkeForbrugerXs],
    [visteKolonnerForbruger, visteKolonnerForbrugerXs]
];

@Component({
    selector: "installation-liste",
    styleUrls: ["./installation-liste.component.scss"],
    templateUrl: "./installation-liste.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallationListeComponent implements OnInit {
    private readonly erForbruger$ = this.aktuelBrugerObserver.valueChanges.pipe(map((bruger) => Rolle.erForbruger(bruger.rolle)));
    private readonly erLilleSkaerm$ = this.breakpointObserver.observe("(min-width: 480px)").pipe(map((x) => !x.matches));
    public visteKolonner$: Observable<string[]>;

    @Input()
    public installationer: Installation[];

    @Output()
    public select = new EventEmitter<Installation>();

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly aktuelBrugerObserver: AktuelBrugerObserver,
        private readonly aktuelInstallationKeyObserver: AktuelInstallationKeyObserver,
        private readonly aktuelForsyningIndstillingObserver: AktuelForsyningIndstillingerObserver
    ) {}

    public ngOnInit(): void {
        this.visteKolonner$ = combineLatest([this.erForbruger$, this.erLilleSkaerm$]).pipe(
            map(([erForbruger, erLilleSkaerm]) => visteKolonner[+erForbruger][+erLilleSkaerm])
        );
    }

    public erAktuelleInstallation$(installation: Installation): Observable<boolean> {
        const aktuelInstallation$ = combineLatest([
            this.aktuelForsyningIndstillingObserver.valueChanges,
            this.aktuelInstallationKeyObserver.valueChanges
        ]).pipe(map(([forsyning, i]) => ({ ...i, forsyningId: forsyning.forsyningId })));
        return aktuelInstallation$.pipe(
            map(
                (i) =>
                    i.forsyningId === installation.forsyningIndstillinger.forsyningId &&
                    i.ejendomNr === installation.ejendomNr &&
                    i.aktivNr === installation.aktivNr &&
                    i.installationNr === installation.installationNr
            )
        );
    }
}
