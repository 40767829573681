import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { shareReplay, switchMap } from "rxjs/operators";

import { AppserverInstallationCredentialsRepository } from "@e-forsyning/common/credentials";

import { BrugerRepository } from "./bruger.repository";
import { Bruger } from "./model/bruger.model";

@Injectable({ providedIn: "root" })
export class ValgtForbrugerObserver {
    private _valueChanges: Observable<Bruger>;

    constructor(credentials: AppserverInstallationCredentialsRepository, repository: BrugerRepository) {
        this._valueChanges = credentials.valueChanges.pipe(
            switchMap((c) => (c && c.cryptId && repository.hentBrugerForAktuelleInstallation()) || of(null)),
            shareReplay(1)
        );
    }

    public get valueChanges(): Observable<Bruger> {
        return this._valueChanges;
    }
}
