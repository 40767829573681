import { AppserverCredentials } from "../../credentials";
import { ForsyningIndstillinger } from "../../forsyning-indstillinger";

export interface ForbrugerTilknytning<T = AppserverCredentials> {
    tilknytningId: string;
    ejendomNr: number;
    forbrugerNavn: string;
    forbrugerAdresse: string;
    erAktuelleSite: boolean;
    erInternTilknytning: boolean;
    erEksternTilknytning: boolean;
    credentials: T;
    forsyningIndstillinger: ForsyningIndstillinger;
}
