import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { UmbracoHttpClient } from "../http/umbraco-http-client.service";
import { UmbracoIndstillinger } from "./model/umbraco-indstillinger.model";

@Injectable({ providedIn: "root" })
export class UmbracoIndstillingerRepository {
    constructor(private readonly umbracoHttpClient: UmbracoHttpClient) { }

    public hent(nodeId: number): Observable<UmbracoIndstillinger> {
        return this.umbracoHttpClient.get<UmbracoIndstillinger>(`HentUmbracoIndstillinger?id=${nodeId}`);
    }
}
