import { Injectable } from "@angular/core";

import { SubSink } from "@dffedb/rxjs";
import { BodyClassService } from "@dffedb/util";

import { AktuelBrugerObserver, Rolle } from "../bruger";

@Injectable({ providedIn: "root" })
export class RolleBodyClassService {
    private readonly subs = new SubSink();

    constructor(private readonly aktuelBrugerObserver: AktuelBrugerObserver, private readonly bodyClassService: BodyClassService) {}

    public setup(): void {
        this.subs.sink = this.aktuelBrugerObserver.valueChanges.subscribe((bruger) => {
            this.bodyClassService.set("rolle-ingen", !bruger);
            this.bodyClassService.set("rolle-forbruger", bruger && bruger.rolle === Rolle.Forbruger);
            this.bodyClassService.set("rolle-fofbruger", bruger && bruger.rolle === Rolle.FofBruger);
            this.bodyClassService.set("rolle-ejendomsadministrator", bruger && bruger.rolle === Rolle.Ejendomsadministrator);
        });
    }

    public teardown(): void {
        this.subs.unsubscribe();
    }
}
