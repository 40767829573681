import { Injectable } from "@angular/core";

import { Observable, ReplaySubject } from "rxjs";
import { first } from "rxjs/operators";

import { UmbracoIndstillinger } from "./model/umbraco-indstillinger.model";

@Injectable({ providedIn: "root" })
export class UmbracoIndstillingerObserver {
    public initialized = false;
    private valueSubject = new ReplaySubject<UmbracoIndstillinger>(1);

    public get valueChanges(): Observable<UmbracoIndstillinger> {
        return this.valueSubject.asObservable();
    }

    public getValue(): Promise<UmbracoIndstillinger> {
        return this.valueChanges.pipe(first()).toPromise();
    }

    public next(value: UmbracoIndstillinger): void {
        this.initialized = true;
        this.valueSubject.next(value);
    }
}
