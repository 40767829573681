import { Injectable } from "@angular/core";

import { Adresse } from "./model/adresse.model";

@Injectable({ providedIn: "root" })
export class AdresseFormatter {
    public formatter(adresse: Partial<Adresse>): string {
        if (!adresse) {
            return "";
        }

        const husnr = adresse.husnr === 0 ? "" : "" + adresse.husnr;
        const vej = `${adresse.vejnavn} ${husnr} ${adresse.litra} ${adresse.litra2}`.trim();

        const postnr = adresse.postnr === "0" ? "" : adresse.postnr;
        const postnrBy = `${postnr} ${adresse.bynavn}`.trim();

        const result = (
            adresse.adresselinie1 +
            "<br>" +
            adresse.adresselinie2 +
            "<br>" +
            adresse.adresselinie3 +
            "<br>" +
            adresse.adresselinie4 +
            "<br>" +
            vej +
            "<br>" +
            postnrBy
        ).replace(/<br>(<br>)+/g, "<br>");

        return result;
    }
}
