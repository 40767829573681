import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";

import { NgxMaskModule } from "ngx-mask";

import { DffSideModule, SpinnerModule } from "@dffedb/ui";
import { SvgIconRegistry } from "@dffedb/util";

import { EjendomsfaktorModule } from "../ejendomsfaktor/ejendomsfaktor.module";
import { ForsyningPaaAdresseModule } from "../forsyning-paa-adresse";
import { TilslutningsanmodningStepEjendomsfaktorerComponent } from "./components/step-ejendsomfaktorer/step-ejendomsfaktorer.component";
import { TilslutningsanmodningStepOplysningerComponent } from "./components/step-oplysninger/step-oplysninger.component";
import { TilslutningsanmodningComponent } from "./tilslutningsanmodning.component";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxMaskModule,
        DffSideModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatStepperModule,
        MatSlideToggleModule,
        SpinnerModule,
        EjendomsfaktorModule,
        ForsyningPaaAdresseModule
    ],
    declarations: [
        TilslutningsanmodningComponent,
        TilslutningsanmodningStepOplysningerComponent,
        TilslutningsanmodningStepEjendomsfaktorerComponent
    ],
    exports: [TilslutningsanmodningComponent]
})
export class TilslutningsanmodningModule {
    constructor(iconRegistry: SvgIconRegistry) {}
}
