import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ControlsOf } from "@dffedb/forms";

import { EBoksTilmeldingSamtykkeModel } from "../../../shared/e-boks-tilmelding.form-builder";

@Component({
    selector: "step-samtykke",
    templateUrl: "./step-samtykke.component.html",
    styleUrls: ["./step-samtykke.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepSamtykkeComponent {
    @Input()
    public formGroup: FormGroup<ControlsOf<EBoksTilmeldingSamtykkeModel>>;

    @Input()
    public samtykkeTekst: string;
}
