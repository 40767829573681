import { NgModule } from "@angular/core";

import { TrustHtmlPipe } from "./trust-html.pipe";

@NgModule({
    imports: [],
    exports: [TrustHtmlPipe],
    declarations: [TrustHtmlPipe],
    providers: []
})
export class TrustHtmlModule {}
