import { Injectable } from "@angular/core";

import { genererUnikId } from "../unik-id/unik-id.util";

export const sessionId = genererUnikId();

@Injectable({ providedIn: "root" })
export class SessionIdService {
    public hentId(): string {
        return sessionId;
    }
}
