import { Injectable } from "@angular/core";

import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

import { DffError } from "@dffedb/util";

import { AktuelForsyningIndstillingerObserver } from "./aktuel-forsyning-indstillinger.observer";
import { ForsyningIndstillingerRepository } from "./forsyning-indstillinger.repository";
import { ForsyningIndstillinger } from "./model/forsyning-indstillinger.model";

@Injectable({ providedIn: "root" })
export class ForsyningIndstillingerService {
    constructor(
        private readonly aktuelForsyningIndstillingerObserver: AktuelForsyningIndstillingerObserver,
        private readonly forsyningIndstillingerRepository: ForsyningIndstillingerRepository
    ) {}

    public hentForsyningIndstillinger(forsyningId: string): Observable<ForsyningIndstillinger> {
        // Vi kigger først i cachen (indstillinger for den aktuelle E|Forsyning)
        // Er der tale om en anden E|Forsyning, laver vi opslag i databasen.
        return this.aktuelForsyningIndstillingerObserver.valueChanges.pipe(
            switchMap((aktuelForsyningIndstillinger) =>
                this.erSammeForsyningId(aktuelForsyningIndstillinger && aktuelForsyningIndstillinger.forsyningId, forsyningId)
                    ? of(aktuelForsyningIndstillinger)
                    : forsyningId
                    ? this.forsyningIndstillingerRepository.hentForsyningIndstillinger(forsyningId)
                    : of(null)
            ),
            catchError((e) => throwError(new DffError("Fejl ved hent af forsyningsindstillinger.", { innerError: e })))
        );
    }

    private erSammeForsyningId(id1: string, id2: string): boolean {
        return (id1 || "").toUpperCase() === (id2 || "").toUpperCase();
    }
}
