import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class DffRouteParams {
    constructor(private readonly router: Router) {}

    public get(key: string): any {
        let route = this.router.routerState.snapshot.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.paramMap.get(key) || route.queryParamMap.get(key);
    }
}
