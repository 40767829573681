import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";

import { DffSidenavMenuComponent } from "./dff-sidenav-menu/dff-sidenav-menu.component";

@NgModule({
    imports: [CommonModule, MatIconModule, MatExpansionModule, RouterModule],
    declarations: [DffSidenavMenuComponent],
    exports: [DffSidenavMenuComponent]
})
export class MenuModule {}
