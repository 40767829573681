import { Adresse } from "@e-forsyning/common/adresse";

import { Rolle } from "./rolle.model";

export interface Bruger {
    id: number;
    navn: string;
    rolle: Rolle;
    adresse: Adresse;
    email: string;
    huskelisteId?: number; // Hvis udfyldt, har brugeren kun adgang til installationer på huskelisten
    menupunkter: string[]; // Indeholder liste over menupunkter, brugeren har adgang til
}
