import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { filter, pairwise } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class PreviousRouteService {
    private previousUrl = "";

    constructor(private router: Router) {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                pairwise()
            )
            .subscribe(([prev]) => {
                this.previousUrl = (prev as NavigationEnd).url;
            });
    }

    public get value(): string {
        return this.previousUrl;
    }
}
