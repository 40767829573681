import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UmbracoHttpClient } from "../http";
import { ForsyningIndstillinger } from "./model/forsyning-indstillinger.model";

@Injectable({ providedIn: "root" })
export class ForsyningIndstillingerRepository {
    constructor(private readonly http: UmbracoHttpClient) {}

    public hentForsyningIndstillinger(forsyningId: string): Observable<ForsyningIndstillinger> {
        const url = `GetVaerkSettings?forsyningid=${forsyningId}`;
        return this.http.get(url).pipe(
            map((data: any) => ({
                forsyningNodeId: data.EForsyningId,
                forsyningId: data.ForsyningId,
                forsyningNavn: data.ForsyningsNavn,
                eforsyningUrl: data.EforsyningUri,
                appServerUrl: data.AppServerUri,
                sideId: data.Id
            }))
        );
    }
}
