import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { AktuelBrugerObserver } from "@e-forsyning/common/bruger";
import { AktuelInstallationObserver } from "@e-forsyning/common/installation";
import { AktuelBrugersInstallationerObserver } from "@e-forsyning/common/installation";

import { EforsyningMenuForSidebarObserver } from "../observers/eforsyning-menu-for-sidebar.observer";
import { EforsyningSidenavMenuViewModel } from "./eforsyning-sidenav-menu.view-model";

@Component({
    selector: "eforsyning-sidenav-menu",
    templateUrl: "./eforsyning-sidenav-menu.component.html",
    styleUrls: ["./eforsyning-sidenav-menu.component.scss"]
})
export class EforsyningSidenavMenuComponent implements OnInit {
    public vm$: Observable<EforsyningSidenavMenuViewModel>;

    @Output()
    public itemClick = new EventEmitter<MouseEvent>();

    @Input()
    public visLoggetUdMenu = false;

    constructor(
        private readonly eforsyningSidebarMenuObserver: EforsyningMenuForSidebarObserver,
        private readonly aktuelBrugerObserver: AktuelBrugerObserver,
        private readonly aktuelBrugersInstallationerObserver: AktuelBrugersInstallationerObserver,
        private readonly aktuelInstallationObserver: AktuelInstallationObserver,
        private menu: ElementRef
    ) {}

    @ViewChild("dropdown") public dropdown: ElementRef;

    @HostListener("keydown.arrowdown", ["$event"]) onArrowDown(event: KeyboardEvent) {
        event.preventDefault();
        const focusableElementsNodeList = this.menu.nativeElement.querySelectorAll("[href]");
        const focusableElementsArray = Array.from(focusableElementsNodeList);
        const focusedElement = document.activeElement;
        const currentIndex = focusableElementsArray.indexOf(focusedElement);
        const nextIndex = (currentIndex + 1) % focusableElementsArray.length;
        const nextElement = focusableElementsArray[nextIndex] as HTMLElement;
        if (nextElement !== null) {
            nextElement.focus();
        }
    }

    @HostListener("keydown.arrowup", ["$event"]) onArrowUp(event: KeyboardEvent) {
        event.preventDefault();
        const focusableElementsNodeList = this.menu.nativeElement.querySelectorAll("[href]");
        const focusableElementsArray = Array.from(focusableElementsNodeList);
        const focusedElement = document.activeElement;
        const currentIndex = focusableElementsArray.indexOf(focusedElement);
        const nextIndex = (currentIndex - 1) % focusableElementsArray.length;
        const prevElement = focusableElementsArray[nextIndex] as HTMLElement;
        if (currentIndex === 0) {
            const lastElement = focusableElementsArray[focusableElementsArray.length - 1] as HTMLElement;
            lastElement.focus();
        } else if (prevElement !== null) {
            prevElement.focus();
        }
    }

    @HostListener("keydown.tab", ["$event"])
    onTab(event: KeyboardEvent) {
        const focusableElements = this.menu.nativeElement.querySelectorAll(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const lastElement = focusableElements[focusableElements.length - 1];
        if (document.activeElement === lastElement && !event.shiftKey) {
            event.preventDefault();
            this.itemClick.emit();
        }
    }

    public ngOnInit(): void {
        const menuItems$ = this.eforsyningSidebarMenuObserver.valueChanges;
        const aktuelBruger$ = this.aktuelBrugerObserver.valueChanges;
        const installationer$ = this.aktuelBrugersInstallationerObserver.valueChanges;
        const aktuelInstallation$ = this.aktuelInstallationObserver.valueChanges;
        this.vm$ = combineLatest([menuItems$, aktuelBruger$, installationer$, aktuelInstallation$]).pipe(
            map(([menuItems, aktuelBruger, installationer, aktuelInstallation]) => {
                const visMenu = !!aktuelBruger || this.visLoggetUdMenu;
                return {
                    menuItems: visMenu ? menuItems : [],
                    aktuelInstallation,
                    visInstallationMenuItem: !!aktuelBruger,
                    vaelgInstallationHref: installationer.length > 1 ? "#/install-picker" : ""
                };
            })
        );
    }
}
