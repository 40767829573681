import { Injectable } from "@angular/core";

import { combineLatest, of } from "rxjs";
import { map, mapTo } from "rxjs/operators";

import { AppserverBrugerCredentialsRepository } from "../credentials";
import { AktuelBrugersForbrugerTilknytningerObserver } from "../forbruger-tilknytning";
import { LogService } from "../log/log.service";
import { ObserverBase } from "../util/observer-base.service";
import { InstallationService } from "./installation.service";
import { Installation } from "./model/installation.model";

@Injectable({ providedIn: "root" })
export class AktuelBrugersInstallationerObserver extends ObserverBase<void, Installation[]> {
    constructor(
        logService: LogService,
        aktuelBrugersForbrugerTilknytningerObserver: AktuelBrugersForbrugerTilknytningerObserver,
        brugerCredentials: AppserverBrugerCredentialsRepository,
        installationService: InstallationService
    ) {
        super(
            logService,
            combineLatest([brugerCredentials.valueChanges, aktuelBrugersForbrugerTilknytningerObserver.valueChanges]).pipe(mapTo(null)),
            () => installationService.hentInstallationer().pipe(map(r => r.items)),
            () => of([])
        );
    }
}
