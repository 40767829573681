import { PrisberegnerVarmekilde } from "./prisberegner-konfiguration.model";

export interface PrisberegnerVarmekilder {
    el: PrisberegnerVarmekilde;
    naturgas: PrisberegnerVarmekilde;
    olie: PrisberegnerVarmekilde;
    traepiller: PrisberegnerVarmekilde;
    varmepumpe: PrisberegnerVarmekilde;
    alle: () => PrisberegnerVarmekilde[];
}

export const prisberegnerVarmekilder: PrisberegnerVarmekilder = {
    el: { id: 1, navn: "Elradiator" },
    naturgas: { id: 2, navn: "Naturgas" },
    olie: { id: 3, navn: "Olie" },
    traepiller: { id: 4, navn: "Træpiller" },
    varmepumpe: { id: 5, navn: "Varmepumpe" },
    alle: () => [
        prisberegnerVarmekilder.el,
        prisberegnerVarmekilder.naturgas,
        prisberegnerVarmekilder.olie,
        prisberegnerVarmekilder.traepiller,
        prisberegnerVarmekilder.varmepumpe
    ]
};
