import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

import { trimRight } from "@dffedb/util";

@Injectable({ providedIn: "root" })
export class DffCurrentRoute {
    constructor(private readonly router: Router) {}

    public get value(): DffRoute {
        const path = this.doGetPath(this.router.routerState.snapshot.root);
        const params = this.router.routerState.snapshot.root.queryParams;
        return { path, params };
    }

    private doGetPath(snapshot: ActivatedRouteSnapshot): string {
        if (!snapshot) {
            return "";
        }
        const currentUrl = snapshot.url.map((u) => u.path).join("/");
        const childUrl = this.doGetPath(snapshot.firstChild);
        return trimRight(`${currentUrl}/${childUrl}`, "/");
    }
}

export interface DffRoute {
    path: string;
    params: any;
}
