import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

import { UmbracoHttpClient } from "../http/umbraco-http-client.service";
import { UmbracoIndstillingerObserver } from "../umbraco-indstillinger";

@Injectable({ providedIn: "root" })
export class DriftsstatusService {
    constructor(
        private readonly umbracoHttpClient: UmbracoHttpClient,
        private readonly indstillingerObserver: UmbracoIndstillingerObserver
    ) {}

    public erDerAktuelleDriftsforstyrrelser(): Observable<boolean> {
        return this.indstillingerObserver.valueChanges.pipe(
            map(i => i.forsyningWeb && i.forsyningWeb.nodeId),
            switchMap(nodeId => (nodeId && this.umbracoHttpClient.get(`ErDerAktuelleDriftsforstyrrelser?nodeId=${nodeId}`)) || of(false))
        );
    }
}
