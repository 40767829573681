import { Injectable } from "@angular/core";

import { LocalStorage } from "@dffedb/util";

import { HuskMigCredentials } from "./husk-mig-credentials.model";

@Injectable({ providedIn: "root" })
export class HuskMigCredentialsService {
    constructor(private readonly localStorage: LocalStorage) {}

    public clear(): void {
        this.localStorage.removeItem("userCredentials");
    }

    public hent(): HuskMigCredentials {
        const userCredentials = this.localStorage.getItem("userCredentials");
        const value = (userCredentials && JSON.parse(atob(userCredentials))) || {
            username: "",
            password: "",
            rememberMe: false
        };
        const result: HuskMigCredentials = {
            brugernavn: value.username,
            kodeord: value.password,
            huskMig: value.rememberMe
        };
        return result;
    }

    public gem(credentials: HuskMigCredentials): void {
        const value = {
            username: credentials.brugernavn,
            password: credentials.kodeord,
            rememberMe: credentials.huskMig
        };
        this.localStorage.setItem("userCredentials", btoa(JSON.stringify(value)));
    }
}
