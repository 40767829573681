import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AuthenticationService } from "../auth/authentication.service";
import { AppserverInstallationCredentialsRepository } from "../credentials";
import { AppserverHttpClient } from "./appserver-http-client.service";

@Injectable({ providedIn: "root" })
export class InstallationHttpClient extends AppserverHttpClient {
    constructor(
        http: HttpClient,
        credentialsRepository: AppserverInstallationCredentialsRepository,
        authenticationService: AuthenticationService
    ) {
        super(http, credentialsRepository, authenticationService);
    }
}
