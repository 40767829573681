import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { first, map, mapTo, switchMap, tap } from "rxjs/operators";

import { dbToBoolean, dbToString } from "@dffedb/util";
import { EjendomsfaktorDefinition } from "@e-forsyning/common/ejendomsfaktor/model/ejendomsfaktor-definition.model";
import { UmbracoHttpClient } from "@e-forsyning/common/http";
import { UmbracoIndstillinger, UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import {
    TilslutningsanmodningDto,
    TilslutningsanmodningKonfigurationDto,
    TilslutningsanmodningSkabelonDto
} from "../model/tilslutningsanmodning.dto";
import { TilslutningsanmodningKonfiguration, TilslutningsanmodningModel } from "../model/tilslutningsanmodning.model";
import { TilslutningsanmodningMapper } from "./tilslutningsanmodning.mapper";

@Injectable({ providedIn: "root" })
export class TilslutningsanmodningRepository {
    constructor(
        private readonly indstillinger: UmbracoIndstillingerObserver,
        private readonly httpClient: HttpClient,
        private readonly umbracoHttpClient: UmbracoHttpClient,
        private readonly mapper: TilslutningsanmodningMapper
    ) {}

    public selectKonfiguration(nodeId: number): Observable<TilslutningsanmodningKonfiguration> {
        const indstillinger$ = this.indstillinger.valueChanges;
        const sideData$ = this.umbracoHttpClient.hentSideDataForId<TilslutningsanmodningKonfigurationDto>(nodeId);
        return combineLatest([indstillinger$, sideData$]).pipe(
            map(([indstillinger, dto]) => this.mapDtoToTilslutningsamodningKonfiguration(indstillinger, dto))
        );
    }

    public hentEjendomsfaktorDefinitioner(eforsyningNodeId: number): Observable<EjendomsfaktorDefinition[]> {
        return this.indstillinger.valueChanges.pipe(
            map((indstillinger) => indstillinger.eforsyninger.find((e) => e.nodeId === eforsyningNodeId)),
            map((eforsyning) => eforsyning.appServerUrl),
            switchMap((appServerUrl) =>
                this.httpClient.get<TilslutningsanmodningSkabelonDto>(`${appServerUrl}api/HentTilslutningsanmodningsSkabelon`)
            ),
            map((skabelon) => this.mapper.dtoToEjendomsfaktorDefinitioner(skabelon.ejendomsfaktorer))
        );
    }

    public submit(eforsyningNodeId: number, ejendomNr: number, data: TilslutningsanmodningModel): Promise<void> {
        const dto = this.mapper.tilslutningsanmodningModelToDto(data);
        return this.indstillinger.valueChanges
            .pipe(
                map((i) => i.eforsyninger.find((e) => e.nodeId === eforsyningNodeId)),
                tap((e) => console.log("Submitter", e.appServerUrl, ejendomNr, dto)),
                switchMap((e) => this.httpSubmit(e.appServerUrl, ejendomNr, dto)),
                //delay(1000),
                tap((e) => console.log("Submittet", e, ejendomNr, dto)),
                first(),
                mapTo(null)
            )
            .toPromise();
    }

    private httpSubmit(appServerUrl: string, ejendomNr: number, data: TilslutningsanmodningDto): Observable<unknown> {
        const url = `${appServerUrl}api/OpretTilslutningsAnmodning?ejendomNr=${ejendomNr}`;
        return this.httpClient
            .post(url, data)
            .pipe(tap((result) => console.log("Oprettet tilslutningsanmodning for ejendom: ", ejendomNr, result)));
    }

    private mapDtoToTilslutningsamodningKonfiguration(
        indstillinger: UmbracoIndstillinger,
        dto: TilslutningsanmodningKonfigurationDto
    ): TilslutningsanmodningKonfiguration {
        const eforsyning = indstillinger.eforsyninger.find((e) => e.nodeId === +dto.eforsyningNodeId);
        const widgetNodeId = "";
        return {
            eforsyning,
            widgetNodeId,
            visEBoksTilmelding: dbToBoolean(dto.visEBoksTilmelding),
            alternativVaerksEmail: dbToString(dto.alternativVaerksEmail),
            steps: {
                adresseSoegning: {
                    knapTekst: dto.interesseKnapTekst,
                    overskrift: dto.adresseOverskrift,
                    tekst: dto.adresseTekst
                },
                ejendomsfaktorer: {
                    overskrift: dto.ejendomsfaktorerOverskrift,
                    tekst: dto.ejendomsfaktorerTekst
                },
                personligeOplysninger: {
                    overskrift: dto.personligeOplysningerOverskrift,
                    tekst: dto.personligeOplysningerTekst,
                    visBemaerkning: !dbToBoolean(dto.personligeOplysningerSkjulBemaerkning),
                    sendKnapTekst: dto.personligeOplysningerSendKnapTekst === "" ? "Send" : dto.personligeOplysningerSendKnapTekst
                },
                kvittering: {
                    overskrift: dto.kvitteringOverskrift,
                    tekst: dto.kvitteringTekst,
                    emailSubject: dto.emailSubject,
                    emailBody: dto.emailBody
                }
            }
        };
    }
}
