import { LogentriesEnvironment } from "@dffedb/util";
import { EforsyningEnvironment } from "@e-forsyning/common/environment";

export const environment: EforsyningEnvironment & LogentriesEnvironment = {
    logentries: {
        application: "Forsyning|WEB",
        token: "96fbbd46-3197-4578-bd32-215cdc748616"
    },
    production: false
};
