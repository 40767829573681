import { Component } from "@angular/core";

import { DffCookieConsentDialog } from "../dff-cookie-consent-dialog";

@Component({
    selector: "dff-cookie-consent-overlay",
    templateUrl: "./dff-cookie-consent-overlay.component.html",
    styleUrls: ["./dff-cookie-consent-overlay.component.scss"]
})
export class DffCookieConsentOverlayComponent {
    constructor(private readonly cookieConsentDialog: DffCookieConsentDialog) {}

    public onShowCookieConsent(event: Event): void {
        event.preventDefault();
        this.cookieConsentDialog.open();
    }
}
