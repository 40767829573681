import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

import { mdiCheckCircleOutline, mdiCloseCircleOutline } from "@mdi/js";

import { SvgIconRegistry } from "@dffedb/util";

import { DffInfoDialog } from "./dff-info-dialog";
import { DffInfoDialogComponent } from "./dff-info-dialog.component";

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatButtonModule, MatIconModule],
    declarations: [DffInfoDialogComponent],
    providers: [DffInfoDialog]
})
export class DffInfoDialogModule {
    constructor(iconRegistry: SvgIconRegistry) {
        iconRegistry.registerIcon(mdiCheckCircleOutline, "dff-info-dialog-success-icon");
        iconRegistry.registerIcon(mdiCloseCircleOutline, "dff-info-dialog-error-icon");
    }
}
