import { Directive, HostListener, Input } from "@angular/core";

import scrollIntoView from "scroll-into-view-if-needed";

/**
 * @description
 * Directive som ved fokus af et element (f.eks. input-felt) sørger for at scrolle siden,
 * så et andet element (f.eks. en næste-knap) bliver synligt.
 * @usageNotes
 * ### Eksempel
 * Følgende sørger for, at der scrolles til knappen, når man sætter fokus i input-feltet.
 * ```html
 * <input [revealOnFocus]="nextButton" />
 * <button #nextButton>Næste</button>
 * ```
 */
@Directive({
    selector: "[revealOnFocus]"
})
export class RevealOnFocusDirective {
    @Input()
    public revealOnFocus: any;

    @HostListener("focus")
    public onFocus(): void {
        if (this.nativeElement) {
            // Scroll hurtigt for enheder uden software-tastatur
            this.scrollIntoView(this.nativeElement);
            // Scroll efter lidt tid for enheder, som viser et software-tastatur
            setTimeout(() => this.scrollIntoView(this.nativeElement), 400);
        }
    }

    public get nativeElement(): Element {
        return this.revealOnFocus instanceof HTMLElement
            ? this.revealOnFocus
            : this.revealOnFocus && this.revealOnFocus._elementRef && this.revealOnFocus._elementRef.nativeElement;
    }

    private scrollIntoView(element: Element): void {
        scrollIntoView(element, {
            behavior: "smooth",
            block: "end",
            inline: "nearest",
            scrollMode: "if-needed"
        });
    }
}
