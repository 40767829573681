import { Component, Input, ViewChild } from "@angular/core";
import { NgForm, UntypedFormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

import { DffErrorStateMatcher, IFormGroup } from "@dffedb/forms";
import { DffSimpelDialog } from "@dffedb/ui";

import { TilslutningsanmodningKonfiguration, TilslutningsanmodningOplysningerModel } from "../../model/tilslutningsanmodning.model";
import { TilslutningsanmodningFormBuilder } from "../../services/tilslutningsanmodning.form-builder";

@Component({
    selector: "tilslutningsanmodning-step-oplysninger",
    templateUrl: "./step-oplysninger.component.html",
    styleUrls: ["./step-oplysninger.component.scss"],
    providers: [{ provide: ErrorStateMatcher, useClass: DffErrorStateMatcher }]
})
export class TilslutningsanmodningStepOplysningerComponent {
    @ViewChild("form") public form: NgForm;
    @Input() public formGroup: IFormGroup<TilslutningsanmodningOplysningerModel>;
    @Input() public konfiguration: TilslutningsanmodningKonfiguration;
    @Input() public adresse2: string;

    constructor(
        private readonly formBuilder: TilslutningsanmodningFormBuilder,
        private readonly errorStateMatcher: ErrorStateMatcher,
        private readonly dialog: DffSimpelDialog
    ) {}

    public get valid(): boolean {
        return this.formGroup?.valid || false;
    }

    public valider(): boolean {
        this.formGroup.markAllAsTouched();
        return this.formGroup.valid;
    }

    public isErrorState(formControlName: keyof TilslutningsanmodningOplysningerModel): boolean {
        return this.errorStateMatcher.isErrorState(this.formGroup.get(formControlName) as UntypedFormControl, this.form);
    }

    public isRequired(formControlName: keyof TilslutningsanmodningOplysningerModel): boolean {
        return this.formBuilder.isRequired(this.formGroup, formControlName);
    }

    public isDirty(formControlName: keyof TilslutningsanmodningOplysningerModel): boolean {
        return this.formGroup.get(formControlName).dirty;
    }
    public onLaesSamtykkeClick(event: MouseEvent, samtykkeTekst: string): void {
        event.preventDefault();
        this.dialog.openOk("Samtykke", this.formBuilder.opretSamtykkeTekst(this.formGroup, this.konfiguration));
    }
}
