import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "dff-simpel-dialog",
    styleUrls: ["./dff-simpel-dialog.component.scss"],
    templateUrl: "./dff-simpel-dialog.component.html",
    host: {
        role: "dialog",
        "[attr.aria-labelledby]": "dialogTitle",
        "[attr.aria-describedby]": "dialogDesc"
    }
})
export class DffSimpelDialogComponent {
    constructor(public dialogRef: MatDialogRef<DffSimpelDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    public dialogTitle = "dialogTitle";
    public dialogDesc = "dialogDesc";
}
