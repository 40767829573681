import { BreakpointObserver } from "@angular/cdk/layout";
import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatSidenavContent } from "@angular/material/sidenav";

import { combineLatest, Observable, timer } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { AktuelBrugerObserver } from "../bruger";
import { UmbracoIndstillinger, UmbracoIndstillingerObserver } from "../umbraco-indstillinger";
import { AppShellScrollService } from "./app-shell-scroll.service";
import { AppShellSettings } from "./app-shell-settings.model";

@Component({
    selector: "app-shell",
    templateUrl: "./app-shell.component.html",
    styleUrls: ["./app-shell.component.scss"]
})
export class AppShellComponent implements AfterViewInit {
    public settings$: Observable<AppShellSettings>;
    public indstillinger$: Observable<UmbracoIndstillinger>;
    public navbarMobileHeight$ = new Observable<number>();
    public eforsyningIcon$ = this.aktuelBrugerObserver.valueChanges.pipe(map((b) => (b && "lock-open") || "lock"));

    @ViewChild(MatSidenavContent)
    public set setMatSidenavContent(value: MatSidenavContent) {
        this.scrollService.init(value);
    }

    @ViewChild("navbarMobile")
    public navbarMobile: ElementRef;

    constructor(
        breakpointObserver: BreakpointObserver,
        @Inject(DOCUMENT) private document: Document,
        indstillingerObserver: UmbracoIndstillingerObserver,
        private readonly aktuelBrugerObserver: AktuelBrugerObserver,
        private readonly scrollService: AppShellScrollService
    ) {
        this.indstillinger$ = indstillingerObserver.valueChanges;
        const breakpointChange$ = breakpointObserver.observe(["(min-width: 768px)"]);
        this.settings$ = combineLatest([this.indstillinger$, breakpointChange$]).pipe(
            map(([indstillinger, state]) => ({
                isDesktop: state.matches,
                isMobile: !state.matches,
                visDriftsstatus: indstillinger.visDriftsstatus,
                driftsstatusForstyrrelserTekst: indstillinger.driftsstatusForstyrrelserTekst,
                driftsstatusIngenForstyrrelserTekst: indstillinger.driftsstatusIngenForstyrrelserTekst
            }))
        );
    }

    public toggleAriaExpanded(id: HTMLElement, menuType: string) {
        id.attributes.getNamedItem("aria-expanded").value = id.attributes.getNamedItem("aria-expanded").value === "true" ? "false" : "true";
        id.attributes.getNamedItem("aria-label").value =
            id.attributes.getNamedItem("aria-expanded").value === "true" ? "Luk " + menuType : "Åben " + menuType;
    }

    public ngAfterViewInit(): void {
        // Ville gerne sætte højden i html til [style.top.px]="navbarMobile.offsetHeight"
        // Det giver imidlertid fejlen: ExpressionChangedAfterItHasBeenCheckedError
        this.navbarMobileHeight$ = timer(0, 100).pipe(
            map(() => this.navbarMobile.nativeElement.offsetHeight),
            distinctUntilChanged()
        );
    }
}
