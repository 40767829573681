import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { combineLatest, Observable, of, throwError } from "rxjs";
import { catchError, map, mapTo, shareReplay, switchMap } from "rxjs/operators";

import { Adresse } from "@e-forsyning/common/adresse";
import { InstallationHttpClient, ParametreForAktuelleInstallation, UmbracoHttpClient } from "@e-forsyning/common/http";
import { EforsyningIndstilling, UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { EBoksIndstillinger } from "../model/e-boks-indstillinger.model";
import { EBoksTilmelding } from "../model/e-boks-tilmelding.model";
import { EBoksTilmeldingMapper } from "./e-boks-tilmelding.mapper";

@Injectable({ providedIn: "root" })
export class EBoksTilmeldingRepository {
    private eforsyningIndstillingCache = new Map<string, Observable<EforsyningIndstilling>>();

    constructor(
        private readonly httpClient: HttpClient,
        private readonly umbracoHttpClient: UmbracoHttpClient,
        private readonly appServerHttpClient: InstallationHttpClient,
        private readonly indstillinger: UmbracoIndstillingerObserver,
        private readonly mapper: EBoksTilmeldingMapper
    ) {}

    public hentTilmeldinger(): Observable<EBoksTilmelding[]> {
        return this.appServerHttpClient
            .get("api/hentEBoksTilmeldinger", ParametreForAktuelleInstallation.true)
            .pipe(map((data) => this.mapper.dtoToEBoksTilmeldinger(data)));
    }

    public opretTilmelding(tilmelding: EBoksTilmelding): Observable<EBoksTilmelding> {
        return this.appServerHttpClient
            .post("api/opretEBoksTilmelding", this.mapper.eboksTilmeldingToDto(tilmelding), ParametreForAktuelleInstallation.true)
            .pipe(mapTo(tilmelding));
    }

    public fjernTilmelding(tilmelding: EBoksTilmelding): Observable<void> {
        return this.appServerHttpClient
            .post("api/sletEBoksTilmelding", this.mapper.eboksTilmeldingToDto(tilmelding), ParametreForAktuelleInstallation.true)
            .pipe(mapTo(null));
    }

    public hentTilmeldingerMedAdresseKode(adresseKode: string): Observable<EBoksTilmelding[]> {
        return this.hentUrl(`HentEBoksTilmeldingerMedAdressekode`, adresseKode).pipe(
            switchMap((url) => this.httpClient.get(url)),
            map((data) => this.mapper.dtoToEBoksTilmeldinger(data))
        );
    }

    public opretTilmeldingMedAdresseKode(adresseKode: string, tilmelding: EBoksTilmelding): Observable<EBoksTilmelding> {
        return this.hentUrl(`OpretEBoksTilmeldingMedAdressekode`, adresseKode).pipe(
            switchMap((url) => this.httpClient.post(url, this.mapper.eboksTilmeldingToDto(tilmelding))),
            mapTo(null)
        );
    }

    public fjernTilmeldingMedAdresseKode(adresseKode: string, tilmelding: EBoksTilmelding): Observable<void> {
        return this.hentUrl(`SletEBoksTilmeldingMedAdressekode`, adresseKode).pipe(
            switchMap((url) => this.httpClient.post(url, this.mapper.eboksTilmeldingToDto(tilmelding))),
            mapTo(null)
        );
    }

    public hentAdresseMedAdresseKode(adresseKode: string): Observable<Adresse> {
        return this.hentUrl(`HentAdresseMedAdressekode`, adresseKode).pipe(
            switchMap((url) => this.httpClient.get(url)),
            map((data) => this.mapper.dtoToAdresse(data))
        );
    }

    public hentEforsyningIndstillingMedAdresseKode(adresseKode: string): Observable<EforsyningIndstilling> {
        if (this.eforsyningIndstillingCache.has(adresseKode)) {
            return this.eforsyningIndstillingCache.get(adresseKode);
        }
        const result$ = this.indstillinger.valueChanges.pipe(
            switchMap((indstillinger) => {
                if (!indstillinger.eforsyninger.length) {
                    return throwError(new Error("Indstillinger mangler angivelse af E|Forsyninger"));
                }
                return combineLatest(
                    indstillinger.eforsyninger.map((e) =>
                        this.httpClient.get(`${e.appServerUrl}api/hentAdresseMedAdressekode/adressekode/${adresseKode}`).pipe(
                            mapTo(e),
                            catchError(() => of(null as EforsyningIndstilling))
                        )
                    )
                );
            }),
            shareReplay(),
            map((e) => e.find((ee) => !!ee)),
            switchMap((e) => (e ? of(e) : throwError(new Error(`Kan ikke finde forsyning tilhørende adressekoden ${adresseKode}`))))
        );
        this.eforsyningIndstillingCache.set(adresseKode, result$);
        return result$;
    }

    public hentIndstillinger(): Observable<EBoksIndstillinger> {
        return this.umbracoHttpClient.hentSideData("mine-oplysninger").pipe(
            map((value) => ({
                visEBoksKampagne: value.visEBoksKampagne === "True",
                visEBoksSektion: value.visEBoksSektion === "True"
            })),
            shareReplay()
        );
    }

    private hentUrl(relativeUrl: string, adresseKode: string): Observable<string> {
        return this.hentAppServerUrl(adresseKode).pipe(map((url) => `${url}${relativeUrl}/adressekode/${adresseKode}`));
    }

    private hentAppServerUrl(adresseKode: string): Observable<string> {
        return this.hentEforsyningIndstillingMedAdresseKode(adresseKode).pipe(map((e) => `${e.appServerUrl}api/`));
    }
}
