import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "dff-info-dialog",
    styleUrls: ["./dff-info-dialog.component.scss"],
    templateUrl: "./dff-info-dialog.component.html"
})
export class DffInfoDialogComponent {
    constructor(public dialogRef: MatDialogRef<DffInfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
}
