import { Component, Input } from "@angular/core";

import { IFormControl } from "@rxweb/types";

import { DffFormControl, IFormGroup } from "@dffedb/forms";

import { EjendomsfaktorDefinition } from "../model/ejendomsfaktor-definition.model";
import { Ejendomsfaktor } from "../model/ejendomsfaktor.model";

@Component({
    selector: "ejendomsfaktor",
    templateUrl: "./ejendomsfaktor.component.html"
})
export class EjendomsfaktorComponent {
    @Input() public formGroup: IFormGroup<Ejendomsfaktor>;
    @Input() public ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[];

    public asDffFormControl(control: IFormControl<string>): DffFormControl {
        return control as DffFormControl; // TODO: Flyt til IFormControl?
    }
}
