import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";

import { DffSimpelDialog } from "./dff-simpel-dialog";
import { DffSimpelDialogComponent } from "./dff-simpel-dialog.component";

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MatDialogModule, MatButtonModule],
    declarations: [DffSimpelDialogComponent],
    providers: [DffSimpelDialog]
})
export class DffSimpelDialogModule {}
