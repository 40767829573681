import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

import { DffEmailFieldComponent } from "./dff-email-field.component";

@NgModule({
    imports: [CommonModule, FormsModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule],
    exports: [DffEmailFieldComponent],
    declarations: [DffEmailFieldComponent],
    providers: []
})
export class DffEmailFieldModule {}
