import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { isNumeric } from "@dffedb/util";

import { AktuelInstallationKeyObserver } from "../installation";

/**
 * InstallationGuard tjekker om der er valgt en installation. I modsat fald sendes man videre til installationslisten.
 */
@Injectable({ providedIn: "root" })
export class InstallationGuard implements CanActivate {
    constructor(private readonly router: Router, private readonly aktuelInstallationKeyObserver: AktuelInstallationKeyObserver) {}

    public canActivate(_: ActivatedRouteSnapshot, routerStateSnapShot: RouterStateSnapshot): Observable<boolean | UrlTree> {
        // TODO AJH: Dette er et hack, som gør det muligt at se et dokument uden der er valgt en installation.
        // Hvis klikker på et link i f.eks. BI (man er logget ind i EForsyning), så guarden ikke sende brugeren til dokumenetet,
        // men videre til installationslisten fordi den aktuel installation ikke er valgt endnu. Hvis brugeren først skal logge
        // ind så er der ikke et problem, fordi denne proces automatisk vælger den første installation.
        const spilts = routerStateSnapShot.url.split("/");
        if (routerStateSnapShot.url.includes("/mine-dokumenter/") && spilts[2] && isNumeric(spilts[2])) {
            return of(true);
        } else {
            return this.aktuelInstallationKeyObserver.valueChanges.pipe(map((key) => !!key || this.router.parseUrl("install-picker")));
        }
    }
}
