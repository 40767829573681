import { Component, EventEmitter, Input, Output } from "@angular/core";

import { DffRedirectService } from "@dffedb/router";

import { MenuItem } from "../model/menu-item.model";

@Component({
    selector: "dff-sidenav-menu",
    templateUrl: "./dff-sidenav-menu.component.html",
    styleUrls: ["./dff-sidenav-menu.component.scss"]
})
export class DffSidenavMenuComponent {
    @Input()
    public items: MenuItem[];

    @Output()
    public itemClick: EventEmitter<MouseEvent> = new EventEmitter();

    constructor(private readonly redirectService: DffRedirectService) {} // TODO NIM: Findes ikke i Forsyning|WEB

    public killClick(event: MouseEvent): void {
        event.stopPropagation();
    }

    public onItemClick(event: MouseEvent, href: string): void {
        // Hvis der ikke er en url at navigere til (menupunktet Installation for forbruger med én installation)
        // sørger vi for at ignorere klikket. Vi ønsker hverken at navigere eller collapse side-menuen.
        if (!href) {
            event.preventDefault();
            return;
        }
        // Fix: iOS burde ikke fange intern routing og kan dermed ikke vælge at åbne siden i Safari.
        // Fixet bør fjernes når vi engang har tilrettet vores iOS App til at genkende url'er fra Umbraco.
        const route = (href || "").split("#")[1];
        if (route && !!this.redirectService) {
            event.preventDefault();
            this.redirectService.redirect(route);
        }
        // Send event videre, så vi kan få collapset side-menuen
        this.itemClick.emit(event);
    }
}
