import { OverlayRef } from "@angular/cdk/overlay";
import { EventEmitter } from "@angular/core";

export interface EBoksPopupCloseArgs {
    spoergMigIkkeIgen: boolean;
}

export class EBoksPopupRef {
    public closed = new EventEmitter<EBoksPopupCloseArgs>();
    constructor(private overlayRef: OverlayRef) {}

    public close(args: EBoksPopupCloseArgs): void {
        this.overlayRef.dispose();
        this.closed.emit(args);
    }
}
