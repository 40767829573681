import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DffCookieConsentService } from "../shared/dff-cookie-consent";
import { DffedbCookieConsentOverlayViewModel } from "./dffedb-cookie-consent-overlay.view-model";

@Injectable({ providedIn: "root" })
export class DffedbCookieConsentOverlaySandbox {
    constructor(private readonly cookieConsentService: DffCookieConsentService) {}

    public selectViewModel(shouldOverlayEverBeVisible: boolean | string): Observable<DffedbCookieConsentOverlayViewModel> {
        return this.cookieConsentService.selectCookieConsent().pipe(
            map(c => c.consent.thirdparty && c.consent.statistics),
            map(consentAccepted => ({
                overlayVisible: !consentAccepted && this.toBoolean(shouldOverlayEverBeVisible)
            }))
        );
    }

    public setCookieConsentChangeRequiresPageReload(value: boolean | string): void {
        if (this.toBoolean(value)) {
            this.cookieConsentService.setCookieConsentChangeRequiresPageReload();
        }
    }

    private toBoolean(value: boolean | string): boolean {
        return value === true || value === "true";
    }
}
