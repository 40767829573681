import { DffContentGroup, DffContentRow, DffGroupedContent } from "@dffedb/util";

export function genererKvitteringMailHtml(model: DffGroupedContent): string {
    const css = "width:100%;";
    const groupsHtml = model.groups.map((group, index) => buildGroupHtml(group, index)).join("");
    return buildPanelHtml(`<table style="${css}">${groupsHtml}</table>`);
}

function buildGroupHtml(group: DffContentGroup, index: number): string {
    return buildSpacerHtml(index) + buildHeaderHtml(group) + buildRowsHtml(group);
}

function buildSpacerHtml(index: number): string {
    const css = "font-size:0;height:0;margin:10px 0;border-top:1px dashed silver;";
    return index === 0 ? "" : `<tr><td colspan="2"><div style="${css}">&nbsp;</div></td></tr>`;
}

function buildHeaderHtml(group: DffContentGroup): string {
    const css = "margin: 0;font-weight: bold;text-transform: uppercase;font-size: 0.8em;padding-bottom: 5px;";
    return `<tr><td colspan="2" style="${css}">${group.title}</td></tr>`;
}

function buildRowsHtml(group: DffContentGroup): string {
    return group.rows.map(row => buildRowHtml(row)).join("");
}

function buildRowHtml(row: DffContentRow): string {
    const css1 = "vertical-align:top;white-space:nowrap;";
    const css2 = "width:100%;padding-left:5px;font-weight:bold;";
    const value = row.value || "&nbsp;";
    return `<tr><td style="${css1}">${row.key}:</td><td style="${css2}">${value}</td></tr>`;
}

function buildPanelHtml(content: string): string {
    return `
        <table style="width:100%;">
            <tr>
                <td style="padding:10px;border:1px solid #eee;background-color:#fdfdfd;">
                    ${content}
                </td>
            </tr>
        </table>`;
}
