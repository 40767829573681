import { AbstractControl } from "@angular/forms";

import { asValidDateOrNull, DffOprindeligAktuelVaerdi, parseDate } from "@dffedb/util";

export class DffReactiveFormControlValueMapper {
    public static toOprindeligAktuelStrings(control: AbstractControl): DffOprindeligAktuelVaerdi<string> {
        const oprindelig = this.toString(control.get("oprindelig"));
        const aktuel = this.toString(control.get("aktuel"));
        return { oprindelig, aktuel };
    }

    public static toString(control: AbstractControl): string {
        return this.hasValue(control) ? control.value.toString() : null;
    }

    public static toOprindeligAktuelIntegers(control: AbstractControl, defaultValue: number = null): DffOprindeligAktuelVaerdi<number> {
        const oprindelig = this.toInteger(control.get("oprindelig"), defaultValue);
        const aktuel = this.toInteger(control.get("aktuel"), defaultValue);
        return { oprindelig, aktuel };
    }

    public static toInteger(control: AbstractControl, defaultValue: number = null): number {
        return this.hasValue(control) ? parseInt(control.value.toString(), 10) : defaultValue;
    }

    public static toOprindeligAktuelFloats(control: AbstractControl, defaultValue: number = null): DffOprindeligAktuelVaerdi<number> {
        const oprindelig = this.toFloat(control.get("oprindelig"), defaultValue);
        const aktuel = this.toFloat(control.get("aktuel"), defaultValue);
        return { oprindelig, aktuel };
    }

    public static toFloat(control: AbstractControl, defaultValue: number = null): number {
        return this.hasValue(control) ? parseFloat(control.value.toString()) : defaultValue;
    }

    public static toOprindeligAktuelBooleans(control: AbstractControl): DffOprindeligAktuelVaerdi<boolean> {
        const oprindelig = this.toBoolean(control.get("oprindelig"));
        const aktuel = this.toBoolean(control.get("aktuel"));
        return { oprindelig, aktuel };
    }

    public static toBoolean(control: AbstractControl): boolean {
        return control && !!control.value;
    }

    public static toOprindeligAktuelDates(control: AbstractControl, defaultValue: Date = null): DffOprindeligAktuelVaerdi<Date> {
        const oprindelig = this.toDate(control.get("oprindelig"), defaultValue);
        const aktuel = this.toDate(control.get("aktuel"), defaultValue);
        return { oprindelig, aktuel };
    }

    public static toDate(control: AbstractControl, defaultValue: Date = null): Date {
        const value = control && control.value;
        if (!value) {
            return defaultValue;
        }

        if (value instanceof Date) {
            return value;
        }

        const dffDate = parseDate(value, "DD-MM-YYYY");
        const javascriptDate = new Date(value);
        return asValidDateOrNull(dffDate) || asValidDateOrNull(javascriptDate);
    }

    private static hasValue(control: AbstractControl): boolean {
        return control && control.value !== undefined && control.value !== null;
    }
}
