import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";

@Component({
    selector: "dff-email-field",
    templateUrl: "./dff-email-field.component.html",
    styleUrls: ["./dff-email-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: DffEmailFieldComponent,
            multi: true
        }
    ]
})
export class DffEmailFieldComponent implements ControlValueAccessor, OnInit {
    @Input() public formControl: UntypedFormControl;
    @Input() public placeholder: string;
    @Input() public hint: string;

    public ngOnInit(): void {
        if (!this.formControl) {
            throw new Error("DffEmailFieldComponent: formControl forventer en FormControl-instans.");
        }
    }

    public getErrorText(formControl: UntypedFormControl): string {
        return (
            this.getRequiredErrorText(formControl.errors) ||
            this.getEmailErrorText(formControl.errors) ||
            this.getAnyErrorText(formControl.errors)
        );
    }

    // Dummy ControlValueAccessor-metoder for at kunne have en [formControl]-property
    public writeValue(): void {}
    public registerOnChange(): void {}
    public registerOnTouched(): void {}

    private getRequiredErrorText(errors: any): string {
        if (!errors.required) {
            return null;
        }
        return errors.matDatepickerParse ? null : "Indtast en værdi";
    }

    private getEmailErrorText(errors: any): string {
        return errors.email ? "Indtast en gyldig e-mail" : null;
    }

    private getAnyErrorText(errors: any): string {
        return errors ? "Indtast en gyldig e-mail" : null;
    }
}
