import { Injectable, RendererFactory2 } from "@angular/core";

import { Observable, of, Subject } from "rxjs";
import { first, mapTo, shareReplay, switchMap, tap } from "rxjs/operators";

import { UmbracoHttpClient } from "@e-forsyning/common/http";
import {
    UmbracoIndstillinger,
    UmbracoIndstillingerObserver,
    UmbracoIndstillingerRepository
} from "@e-forsyning/common/umbraco-indstillinger";

import { getUmbracoBaseUrl, getUrlQuery } from "../util/util";

@Injectable({ providedIn: "root" })
export class WebComponentInitializerService {
    constructor(
        private readonly rendererFactory: RendererFactory2,
        private readonly umbracoHttpClient: UmbracoHttpClient,
        private readonly umbracoIndstillingerRepository: UmbracoIndstillingerRepository,
        private readonly umbracoIndstillingerObserver: UmbracoIndstillingerObserver
    ) {}

    public init(nodeId: number): Observable<void> {
        // Hvis WebComponents benyttes i E|Forsyning eller Forsyning|WEB, har vi allerede initialiseret UmbracoIndstillingerObservable
        console.log("WebComponentInitializerService: Undersøger om instillinger er indlæst", nodeId);
        if (this.umbracoIndstillingerObserver.initialized) {
            console.log("WebComponentInitializerService: Indstillinger er allerede indlæst", nodeId);
            return of(null);
        }

        // Køres WebComponents på ekstern hjemmeside, initialiserer vi UmbracoIndstillingerObservable på baggrund af 'nodeId'
        console.log("WebComponentInitializerService: Indlæser indstillinger", nodeId);
        this.umbracoHttpClient.setBaseUrl(getUmbracoBaseUrl());
        const result$ = this.umbracoIndstillingerRepository.hent(nodeId).pipe(
            switchMap((indstillinger) => this.loadStyleSheet(indstillinger)),
            tap((indstillinger) => this.setUmbracoIndstillinger(indstillinger)),
            mapTo(null),
            first(),
            shareReplay(1)
        );
        result$.subscribe();
        return result$;
    }

    private setUmbracoIndstillinger(indstillinger: UmbracoIndstillinger): void {
        // Initialiser UmbracoIndstillinger
        // Vi tilføjer metoder som er angivet på klassen UmbracoIndstillinger.
        Object.setPrototypeOf(indstillinger, new UmbracoIndstillinger());
        this.umbracoIndstillingerObserver.next(indstillinger);
    }

    private loadStyleSheet(indstillinger: UmbracoIndstillinger): Observable<UmbracoIndstillinger> {
        // Load stylesheet og resolve først når det er loadet
        const result$ = new Subject<UmbracoIndstillinger>();
        if (getUrlQuery().nostyle !== undefined) {
            result$.next(indstillinger);
            return result$.asObservable();
        }
        const renderer = this.rendererFactory.createRenderer(null, null);
        const link = renderer.createElement("link");
        renderer.setAttribute(link, "rel", "stylesheet");
        renderer.setAttribute(link, "type", "text/css");
        renderer.setAttribute(link, "href", indstillinger.themeUrl);
        renderer.listen(link, "load", () => result$.next(indstillinger));
        renderer.appendChild(document.head, link);
        return result$.asObservable();
    }
}
