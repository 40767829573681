import { Observable } from "rxjs";

import { IAppserverCredentialsRepository } from "./appserver-credentials-repository.interface";
import { AppserverCredentialsRepository } from "./appserver-credentials-repository.service";
import { AppserverCredentials } from "./appserver-credentials.model";

export class AppserverCredentialsRepositoryBase implements IAppserverCredentialsRepository {
    private readonly _valueChanges: Observable<AppserverCredentials>;

    constructor(private readonly repository: AppserverCredentialsRepository, private readonly nodeIdKey: string) {
        this._valueChanges = this.repository.valueChanges(this.nodeIdKey);
    }

    public get valueChanges(): Observable<AppserverCredentials> {
        return this._valueChanges;
    }

    public hent(): AppserverCredentials {
        return this.repository.hent(this.nodeIdKey);
    }

    public gem(credentials: AppserverCredentials): void {
        return this.repository.gem(this.nodeIdKey, credentials);
    }
}
