import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";

import { combineLatest, Observable, Subject, timer } from "rxjs";
import { debounceTime, delay, filter, first, map, startWith, takeUntil } from "rxjs/operators";
import scrollIntoView from "scroll-into-view-if-needed";

import { ControlsOf } from "@dffedb/forms";
import { DffInfoDialog } from "@dffedb/ui";
import { EforsyningIndstilling } from "@e-forsyning/common/umbraco-indstillinger";

import { EBoksTilmelding } from "../model/e-boks-tilmelding.model";
import {
    EBoksTilmeldingFormBuilder,
    EBoksTilmeldingIdentitetModel,
    EBoksTilmeldingModel,
    EBoksTilmeldingSamtykkeModel
} from "../shared/e-boks-tilmelding.form-builder";
import { EBoksTilmeldingService } from "../shared/e-boks-tilmelding.service";
import { opretSamtykkeTekst, opretTomEBoksTilmelding, valueChanges } from "../shared/e-boks-util";

@Component({
    selector: "e-boks-tilmelding",
    templateUrl: "./e-boks-tilmelding.component.html",
    styleUrls: ["./e-boks-tilmelding.component.scss"]
})
export class EBoksTilmeldingComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public afkraevAdressekode = false;

    @Input()
    public disabled = false;

    @Output()
    public tilmeldt = new EventEmitter<void>();

    @ViewChild("stepper")
    public stepper: MatStepper;

    @ViewChild("top")
    public top: ElementRef;

    @ViewChild("bottom")
    public bottom: ElementRef;

    @ViewChild("AdresseKodeNextButton", { read: ElementRef })
    public adresseKodeNextButton: ElementRef;

    public formGroup: FormGroup<ControlsOf<EBoksTilmeldingModel>>;
    public samtykkeTekst$: Observable<string>;
    public valueChanges$: Observable<any>;
    public initialiserer$ = this.service.initialiserer$.pipe(
        delay(200),
        startWith({ processing: true }),
        map((i) => i.processing)
    );
    public tilmeldinger$ = this.service.tilmeldinger$;
    public eforsyningIndstilling$ = this.service.eforsyningIndstilling$;
    public validererAdresseKode$ = this.service.validererAdresseKode$;
    public tilmelder$ = this.service.tilmelder$;
    public afmelder$ = this.service.afmelder$;

    private destroy$ = new Subject<void>();

    constructor(private readonly service: EBoksTilmeldingService, private readonly dialog: DffInfoDialog) {}

    public get identitetFormGroup(): FormGroup<ControlsOf<EBoksTilmeldingIdentitetModel>> {
        return this.formGroup.get("identitet") as FormGroup<ControlsOf<EBoksTilmeldingIdentitetModel>>;
    }

    public get samtykkeFormGroup(): FormGroup<ControlsOf<EBoksTilmeldingSamtykkeModel>> {
        return this.formGroup.get("samtykke") as FormGroup<ControlsOf<EBoksTilmeldingSamtykkeModel>>;
    }

    public ngOnInit(): void {
        this.formGroup = EBoksTilmeldingFormBuilder.eBoksTilmeldingToFormGroup(opretTomEBoksTilmelding(), this.disabled);
        const tilmeldt$ = this.tilmelder$.pipe(filter((t) => t.justProcessed));
        const samtykkeTekstAccepteret$ = this.formGroup
            .get("samtykke.samtykkeTekstAccepteret")
            .valueChanges.pipe(filter((accepteret: boolean) => accepteret));
        tilmeldt$.pipe(takeUntil(this.destroy$)).subscribe(() => this.tilmeldUdfoert());
        this.samtykkeTekst$ = combineLatest([this.eforsyningIndstilling$, valueChanges(this.formGroup)]).pipe(
            map(([e]) => this.genererSamtykkeTekst(e))
        );

        // Når man har indtastet adressekode, trykket søg og fundet en adresse, skifter vi fokus til 'Næste'-knappen
        this.validererAdresseKode$
            .pipe(
                filter((r) => r.processed),
                debounceTime(0),
                takeUntil(this.destroy$)
            )
            .subscribe(() => this.adresseKodeNextButton.nativeElement.focus());

        // Når man har checket af i 'Accepter samtykke' scroller vi ned til 'Udfør'-knappen
        samtykkeTekstAccepteret$.pipe(takeUntil(this.destroy$)).subscribe(() => this.scrollIntoView(this.bottom, "end"));

        if (!this.afkraevAdressekode) {
            this.service.hentTilmeldinger();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.formGroup && changes.disabled && changes.disabled.currentValue !== changes.disabled.previousValue) {
            EBoksTilmeldingFormBuilder.updateDisabled(this.formGroup, changes.disabled.currentValue);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    public onAdresseKodeChange(adresseKode: string): void {
        this.service.validerAdresseKode(adresseKode);
    }

    public onAfmeld(tilmelding: EBoksTilmelding): void {
        this.service.afmeld(tilmelding);
    }

    public onTilmeld(): void {
        this.samtykkeTekst$.pipe(first()).subscribe((samtykkeTekst) => {
            this.service.tilmeld(EBoksTilmeldingFormBuilder.formGroupToEBoksTilmelding(this.formGroup, samtykkeTekst));
        });
    }

    public async tilmeldUdfoert(): Promise<void> {
        this.scrollIntoView(this.top, "start");
        timer(200)
            .pipe(first(), takeUntil(this.destroy$))
            .subscribe(() => {
                this.stepper.selectedIndex = 0;
                this.afkraevAdressekode = false;
                this.formGroup.reset({ identitet: { type: 0 } });
            });
        await this.dialog.success("", "Tak for din tilmelding til digital post").toPromise();
        this.tilmeldt.emit();
    }

    private genererSamtykkeTekst(eforsyningIndstilling: EforsyningIndstilling): string {
        const firmaNavn =
            (eforsyningIndstilling && eforsyningIndstilling.firmaInfo && eforsyningIndstilling.firmaInfo.firmaNavn) || "{NAVN}";
        const telefon =
            (eforsyningIndstilling && eforsyningIndstilling.firmaInfo && eforsyningIndstilling.firmaInfo.telefon) || "{TELEFON}";
        const erPrivatperson = this.formGroup.get("identitet.type").value === 0;
        const cprNummer = erPrivatperson ? this.formGroup.get("identitet.person.cprNummer").value : "";
        const cvrNummer = erPrivatperson ? "" : this.formGroup.get("identitet.virksomhed.cvrNummer").value;
        const pNummer = erPrivatperson ? "" : this.formGroup.get("identitet.virksomhed.pNummer").value;
        return opretSamtykkeTekst({ firmaNavn, telefon, cprNummer, cvrNummer, pNummer });
    }

    private scrollIntoView(element: ElementRef, block: "start" | "end"): void {
        scrollIntoView(element.nativeElement, {
            behavior: "smooth",
            block,
            inline: "nearest",
            scrollMode: "if-needed"
        });
    }
}
