import { Component, HostBinding, Input, OnInit } from "@angular/core";

import { switchMap } from "rxjs/operators";

import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { WebComponentInitializerService } from "../shared/web-component-initializer.service";
import { DffedbEBoksTilmeldService } from "./dffedb-e-boks-tilmeld.service";

@Component({
    templateUrl: "./dffedb-e-boks-tilmeld.component.html",
    styleUrls: ["./dffedb-e-boks-tilmeld.component.scss"]
})
export class DffedbEBoksTilmeldComponent implements OnInit {
    public initialiserer = true;
    @HostBinding("class.mat-app-background") public classMatAppBackground = true;
    @Input() public nodeId: number;

    constructor(
        private readonly webComponentInitializer: WebComponentInitializerService,
        private readonly umbracoIndstillingerObserver: UmbracoIndstillingerObserver,
        private readonly eBoksTilmeldService: DffedbEBoksTilmeldService
    ) {}

    public ngOnInit(): void {
        this.webComponentInitializer.init(this.nodeId);
        this.umbracoIndstillingerObserver.valueChanges
            .pipe(switchMap(() => this.eBoksTilmeldService.skalRedirecteTilEforsyning()))
            .subscribe((skalRedirecteTilEforsyning) => {
                if (skalRedirecteTilEforsyning) {
                    this.eBoksTilmeldService.redirecTilEforsyning();
                } else {
                    this.initialiserer = false;
                }
            });
    }
}
