import { Injectable } from "@angular/core";

import { of } from "rxjs";

import { InstallationKey } from "../installation";
import { AktuelInstallationKeyObserver } from "../installation/aktuel-installation-key.observer";
import { LogService } from "../log/log.service";
import { ObserverBase } from "../util/observer-base.service";
import { ForbrugerOplysningerService } from "./forbruger-oplysninger.service";
import { ForbrugerOplysninger } from "./model/forbruger-oplysninger.model";

@Injectable({ providedIn: "root" })
export class AktuelForbrugerOplysningerObserver extends ObserverBase<InstallationKey, ForbrugerOplysninger> {
    constructor(
        logService: LogService,
        aktuelInstallationKeyObserver: AktuelInstallationKeyObserver,
        repository: ForbrugerOplysningerService
    ) {
        super(
            logService,
            aktuelInstallationKeyObserver.valueChanges,
            key => (key && key.ejendomNr && repository.hentForbrugerOplysningerForAktuelleInstallation()) || of(null)
        );
    }
}
