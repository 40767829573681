<mat-form-field>
    <mat-label>{{ placeholder }}</mat-label>
    <input
        matInput
        #dateinput
        [matDatepicker]="datepicker"
        [formControl]="formControl"
        [max]="max"
        [min]="min"
        [required]="required"
        [attr.aria-required]="required"
        [attr.aria-label]="label"
        (focus)="datepicker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker" tabIndex="-1"></mat-datepicker-toggle>
    <mat-datepicker #datepicker (closed)="dateinput.blur()"></mat-datepicker>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="formControl.errors">{{ getErrorText(formControl) }}</mat-error>
</mat-form-field>
