import { Injectable } from "@angular/core";

import { Observable, ReplaySubject } from "rxjs";

import { UmbracoMenu } from "../model/umbraco-menu.model";

@Injectable({ providedIn: "root" })
export class UmbracoMenuObserver {
    private valueSubject = new ReplaySubject<UmbracoMenu>(1);

    public get valueChanges(): Observable<UmbracoMenu> {
        return this.valueSubject.asObservable();
    }

    public next(value: UmbracoMenu): void {
        this.valueSubject.next(value);
    }
}
