import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTabsModule } from "@angular/material/tabs";

import { mdiArrowLeft } from "@mdi/js";

import { SvgIconRegistry } from "@dffedb/util";

import { DffCookieConsentCookieListModule } from "../dff-cookie-consent-cookie-list/dff-cookie-consent-cookie-list.module";
import { DffCookieConsentDialogComponent } from "./dff-cookie-consent-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        DffCookieConsentCookieListModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatTabsModule
    ],
    exports: [DffCookieConsentDialogComponent],
    declarations: [DffCookieConsentDialogComponent],
    providers: []
})
export class DffCookieConsentDialogModule {
    constructor(svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiArrowLeft, "dff-cookie-consent-dialog.arrow-left");
    }
}
