import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { DffCookieConsentOverlayComponent } from "./dff-cookie-consent-overlay.component";

@NgModule({
    imports: [CommonModule],
    declarations: [DffCookieConsentOverlayComponent],
    exports: [DffCookieConsentOverlayComponent],
    providers: []
})
export class DffCookieConsentOverlayModule {}
