import { AbstractControlOptions, AsyncValidatorFn, UntypedFormControl, ValidatorFn, Validators } from "@angular/forms";

export enum DffFormControlType {
    TextInput,
    NumberInput,
    DateInput,
    DropdownList,
    Autocomplete,
    AutocompleteNumeric,
    Checkbox
}

export type DffFormControlDropdownListOptions = {} | { key: any; value: string }[];

export interface DffFormControlOptions {
    controlType?: DffFormControlType;
    placeholder?: string;
    suffix?: string;
    dropdownListOptions?: DffFormControlDropdownListOptions;
    disabled?: boolean;
    hidden?: boolean;
    hint?: string;
}

export class DffFormControl extends UntypedFormControl {
    public controlType: DffFormControlType;
    public placeholder: string;
    public suffix: string;
    public dropdownListOptions: DffFormControlDropdownListOptions;
    public hidden: boolean;
    public hint: string;
    public required = false;

    constructor(
        formState?: any,
        options: DffFormControlOptions = {},
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ) {
        super(formState, validatorOrOpts, asyncValidator);
        this.setOptions(options);
        this.updateRequired(validatorOrOpts);
    }

    public setOptions(options: DffFormControlOptions): void {
        if (options.disabled) {
            this.disable();
        } else {
            this.enable();
        }

        this.controlType = options.controlType || DffFormControlType.TextInput;
        this.dropdownListOptions = options.dropdownListOptions || [];
        this.placeholder = options.placeholder || "";
        this.suffix = options.suffix || "";
        this.hidden = options.hidden || false;
        this.hint = options.hint || "";
    }

    public setValidators(newValidator: ValidatorFn | ValidatorFn[] | null): void {
        super.setValidators(newValidator);
        this.updateRequired(newValidator);
    }

    private updateRequired(validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null): void {
        const validators = validatorsToArray(validatorOrOpts);
        this.required = validators.includes(Validators.required);
    }
}

function validatorsToArray(validatorOrOpts: ValidatorFn | ValidatorFn[] | AbstractControlOptions): ValidatorFn[] {
    const validator = isOptionsObj(validatorOrOpts) ? (validatorOrOpts as AbstractControlOptions).validators : validatorOrOpts;
    return Array.isArray(validator) ? validator : [validator];
}

function isOptionsObj(
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null
): validatorOrOpts is AbstractControlOptions {
    return validatorOrOpts != null && !Array.isArray(validatorOrOpts) && typeof validatorOrOpts === "object";
}
