import { Injectable } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";

import { MenuItem } from "@dffedb/ui";
import { AktuelBrugerObserver, Bruger, Rolle } from "@e-forsyning/common/bruger";
import { AppserverInstallationCredentialsRepository } from "@e-forsyning/common/credentials";

import { EforsyningMenuerObserver } from "./eforsyning-menuer.observer";

@Injectable({ providedIn: "root" })
export class EforsyningMenuForSidebarObserver {
    private readonly _valueChanges: Observable<MenuItem[]>;

    constructor(
        private readonly eforsyningMenuerObserver: EforsyningMenuerObserver,
        private readonly installationCredentialsRepository: AppserverInstallationCredentialsRepository,
        private readonly aktuelBrugerObserver: AktuelBrugerObserver
    ) {
        const eforsyningNodeId$ = this.installationCredentialsRepository.valueChanges.pipe(
            map((c) => (c && c.forsyningNodeId) || 0),
            distinctUntilChanged()
        );
        const bruger$ = this.aktuelBrugerObserver.valueChanges;
        const menu$ = this.eforsyningMenuerObserver.valueChanges;
        this._valueChanges = combineLatest([menu$, eforsyningNodeId$, bruger$]).pipe(
            map(([menu, eforsyningNodeId, bruger]) => this.bygMenu(menu, eforsyningNodeId, bruger)),
            shareReplay(1)
        );
    }

    public get valueChanges(): Observable<MenuItem[]> {
        return this._valueChanges;
    }

    private bygMenu(menu: MenuItem, eforsyningNodeId: number, bruger: Bruger): MenuItem[] {
        return eforsyningNodeId && bruger ? this.bygLoggetIndMenu(menu, eforsyningNodeId, bruger) : this.bygLoggetUdMenu(menu);
    }

    private bygLoggetUdMenu(menu: MenuItem): MenuItem[] {
        return menu.children.length === 1
            ? [{ title: "Log ind", href: menu.children[0].href, adgangsstyret: false }]
            : menu.children.map((c) => ({ title: c.title, href: c.href, adgangsstyret: c.adgangsstyret }));
    }

    private bygLoggetIndMenu(menu: MenuItem, eforsyningNodeId: number, bruger: Bruger): MenuItem[] {
        const eforsyningMenu = menu.children.find((i) => i.id === eforsyningNodeId);
        const fofMenuItems = this.genererFofMenuItems(eforsyningMenu, bruger);
        const forbrugerMenuItems = this.genererForbrugerMenuItems(eforsyningMenu, bruger);
        const loginOgLogoutMenuItems = this.genererLoginOgLogoutMenuItems(eforsyningMenu);
        return [...fofMenuItems, ...forbrugerMenuItems, ...loginOgLogoutMenuItems];
    }

    private genererFofMenuItems(eforsyning: MenuItem, bruger: Bruger): MenuItem[] {
        const children = this.findFofMenuItems(eforsyning);
        return bruger && Rolle.erFofBruger(bruger.rolle) ? children : [];
    }

    private genererForbrugerMenuItems(eforsyning: MenuItem, bruger: Bruger): MenuItem[] {
        const children = this.fjernLoginOgLogoutMenuItems(this.findForbrugerMenuItems(eforsyning));
        return bruger && Rolle.erFofBruger(bruger.rolle) && !!children.length ? [this.genererForbrugerUndermenu(children)] : children;
    }

    private genererForbrugerUndermenu(children: MenuItem[]): MenuItem {
        return {
            title: "Forbruger",
            icon: "menu-forbruger",
            adgangsstyret: false,
            children
        };
    }

    private genererLoginOgLogoutMenuItems(eforsyning: MenuItem): MenuItem[] {
        return this.findLoginOgLogoutMenuItems(this.findForbrugerMenuItems(eforsyning));
    }

    private findFofMenuItems(eforsyning: MenuItem): MenuItem[] {
        const item = eforsyning && eforsyning.children.find((c) => c.name === "eforsyning-fof-bruger");
        return (item && item.children) || [];
    }

    private findForbrugerMenuItems(eforsyning: MenuItem): MenuItem[] {
        const item = eforsyning && eforsyning.children.find((c) => c.name === "eforsyning-forbruger");
        return (item && item.children) || [];
    }

    private fjernLoginOgLogoutMenuItems(menuItems: MenuItem[]): MenuItem[] {
        const loginOgLogout = this.findLoginOgLogoutMenuItems(menuItems);
        return menuItems.filter((i) => !loginOgLogout.includes(i));
    }

    private findLoginOgLogoutMenuItems(menuItems: MenuItem[]): MenuItem[] {
        return menuItems.filter((i) => i.icon === "menu-login" || i.icon === "menu-logout");
    }
}
