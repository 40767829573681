import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from "@angular/core";

import { DffError, getErrorDetails } from "@dffedb/util";

@Component({
    selector: "page-error",
    templateUrl: "./page-error.component.html",
    styleUrls: ["./page-error.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageErrorComponent implements OnChanges {
    @Input() public error: any;
    public message = "";
    public logId = "";
    public details = "";
    public detailsVisible = false;

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes["error"]?.currentValue !== changes["error"]?.previousValue) {
            this.message = getMessage(changes["error"].currentValue);
            this.logId = getLogId(changes["error"].currentValue);
            this.details = await getDetails(changes["error"].currentValue);
        }
    }
}

async function getDetails(error: any): Promise<string> {
    return await getErrorDetails(error);
}

function getMessage(error: any): string {
    return (error && (error.friendlyMessage || error.message || error)) || "Der er opstået en ukendt fejl";
}

function getLogId(error: any): string {
    return error instanceof DffError ? error.logId : "";
}
