import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { distinctUntilChanged, map, shareReplay, switchMap } from "rxjs/operators";

import { AktuelInstallationKeyObserver } from "./aktuel-installation-key.observer";
import { InstallationService } from "./installation.service";
import { InstallationKey } from "./model/installation-key.model";
import { Installation } from "./model/installation.model";

@Injectable({ providedIn: "root" })
export class AktuelInstallationObserver {
    private value$: Observable<Installation>;

    constructor(aktuelInstallationKeyObserver: AktuelInstallationKeyObserver, private readonly installationService: InstallationService) {
        this.value$ = aktuelInstallationKeyObserver.valueChanges.pipe(
            switchMap((key) => this.hentInstallationer(key).pipe(map((installationer) => ({ key, installationer })))),
            map(({ key, installationer }) => this.findInstallation(key, installationer)),
            distinctUntilChanged(),
            shareReplay(1)
        );
    }

    private hentInstallationer(key: InstallationKey): Observable<Installation[]> {
        return (key && this.installationService.hentInstallationerForEjendom(key.ejendomNr)) || of([]);
    }

    private findInstallation(key: InstallationKey, installationer: Installation[]): Installation {
        return (key && installationer && installationer.find((i) => i.installationNr === key.installationNr)) || null;
    }

    public get valueChanges(): Observable<Installation> {
        return this.value$;
    }
}
