<div>
    <mat-accordion displayMode="flat">
        <mat-expansion-panel class="mat-elevation-z0" #panel *ngFor="let tilmelding of tilmeldinger; let i = index">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div *ngIf="tilmelding.cprNummer"><small>CPR-nummer</small>{{ formatterCprNummer(tilmelding) }}</div>
                    <div *ngIf="tilmelding.cvrNummer"><small>CVR-nummer</small>{{ formatterCvrNummer(tilmelding) }}</div>
                    <div *ngIf="tilmelding.pNummer"><small>P-nummer</small>{{ formatterPNummer(tilmelding) }}</div>
                </mat-panel-title>
                <div class="buttons">
                    <button
                        class="afmeld-button"
                        mat-button
                        (click)="onAfmeld($event, tilmelding)"
                        color="warn"
                        [disabled]="disabled || afmeldStatus.processing"
                        id = "button-eksisterende-tilmeldinger-{{i}}"
                    >
                        <mat-spinner
                            *ngIf="afmeldStatus.processing && afmeldStatus.value?.id === tilmelding.id"
                            color="basic"
                            diameter="25"
                        ></mat-spinner>
                        SLET
                    </button>
                </div>
            </mat-expansion-panel-header>

            <div class="vilkaar" [innerHTML]="tilmelding.samtykkeTekst"></div>
        </mat-expansion-panel>
    </mat-accordion>
    <div class="error" *ngIf="afmeldStatus.error">
        {{ afmeldStatus.error }}
    </div>
</div>
