import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { Subject } from "rxjs";
import { distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { Processing } from "@dffedb/rxjs";

import { Adresse, AdresseFormatter } from "../../../../../adresse";
import { ValidererAdresseKodeResult } from "../../../model/validerer-adresse-kode-result";
import { EBoksAdressekodeControls, EBoksAdressekodeFormBuilder } from "../../../shared/e-boks-adressekode.form-builder";

@Component({
    selector: "step-adresse-kode",
    templateUrl: "./step-adresse-kode.component.html",
    styleUrls: ["./step-adresse-kode.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StepAdresseKodeComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public disabled = false;

    @Input()
    public adresseKodeStatus: Processing<ValidererAdresseKodeResult>;

    @Output()
    public adresseKodeChange = new EventEmitter<string>();

    public formGroup: FormGroup<EBoksAdressekodeControls>;

    private destroy$ = new Subject<void>();

    constructor(private readonly adresseFormatter: AdresseFormatter) {}

    public ngOnInit(): void {
        this.formGroup = EBoksAdressekodeFormBuilder.opretAdressekodeFormGroup(this.disabled);
        this.formGroup
            .get("kode")
            .valueChanges.pipe(
                takeUntil(this.destroy$),
                map((v: string) => v),
                distinctUntilChanged()
            )
            .subscribe(() => this.adresseKodeChange.emit(""));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.formGroup && changes.disabled && changes.disabled.currentValue !== changes.disabled.previousValue) {
            EBoksAdressekodeFormBuilder.updateDisabled(this.formGroup, changes.disabled.currentValue);
        }
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    public onSoeg(value: any): void {
        this.adresseKodeChange.emit(this.formGroup.get("kode").value);
    }

    public formatterAdresse(adresse: Partial<Adresse>): string {
        return this.adresseFormatter.formatter(adresse);
    }
}
