import { AbstractControl } from "@angular/forms";

export function cvrNummerValidator(): any {
    return (control: AbstractControl) => {
        if (erBlankVaerdi(control.value)) {
            return null; // Vi anser blank værdi for valid. Ønskes dette ikke, kan man benytte en RequiredValidator.
        }
        return erValidCvrNummer(control.value.toString()) ? null : error(control.value);
    };
}

function erBlankVaerdi(value: string): boolean {
    return value === null || value === undefined || value === "";
}

function erValidCvrNummer(value: string): boolean {
    return /^\d{8}$/.test(value) && erModulusValid(value);
}

function erModulusValid(value: string): boolean {
    const vaegt = [2, 7, 6, 5, 4, 3, 2, 1];
    const total = vaegt.reduce((acc, curr, i) => acc + curr * +value[i], 0);
    return total % 11 === 0;
}

function error(cvrNummer: string): any {
    return { cvrNummer };
}
