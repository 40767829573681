import { Injectable } from "@angular/core";

import { dbToInt, dbToString } from "@dffedb/util";

import { Adresse } from "./model/adresse.model";

@Injectable({ providedIn: "root" })
export class AdresseMapper {
    public dtoToAdresse(data: any): any {
        const result: Adresse = {
            adresselinie1: dbToString(data.adresselinie1),
            adresselinie2: dbToString(data.adresselinie2),
            adresselinie3: dbToString(data.adresselinie3),
            adresselinie4: dbToString(data.adresselinie4),
            adressenr: dbToInt(data.adressenr),
            bynavn: dbToString(data.bynavn),
            husnr: dbToInt(data.husnr),
            litra: dbToString(data.litra),
            litra2: dbToString(data.litra2),
            postnr: dbToString(data.postnr),
            vejnavn: dbToString(data.vejnavn),
            vejnr: dbToInt(data.vejnr)
        };
        return result;
    }
}
