import { Injectable } from "@angular/core";

import { genererUnikId } from "../unik-id/unik-id.util";

export const genererCorrelationId = (): string => genererUnikId(8);

@Injectable({ providedIn: "root" })
export class CorrelationIdService {
    public genererId(laengde: number = 8): string {
        return genererUnikId(laengde);
    }
}
