import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";

import { IFormArray, IFormBuilder, IFormGroup } from "@dffedb/forms";
import {
    DffFormControl,
    DffFormControlDropdownListOptions,
    DffFormControlType,
    DffOprindeligAktuelVaerdiFormGroup,
    DffValidators,
    DffReactiveFormControlValueMapper as Map
} from "@dffedb/forms";
import { dbToFloatString, DffOprindeligAktuelVaerdi, EntitetStatus, floatToDb } from "@dffedb/util";

import { EjendomsfaktorDefinition } from "./model/ejendomsfaktor-definition.model";
import { Ejendomsfaktor } from "./model/ejendomsfaktor.model";
import { EjendomsfaktorerData } from "./model/ejendomsfaktorer-data.model";

@Injectable({ providedIn: "root" })
export class EjendomsfaktorFormBuilder {
    private readonly fb: IFormBuilder;

    constructor(fb: UntypedFormBuilder) {
        this.fb = fb;
    }

    public ejendomsfaktorerDataToFormGroup(
        ejendomsfaktorerData: EjendomsfaktorerData,
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]
    ): IFormGroup<EjendomsfaktorerData> {
        const result = this.fb.group<EjendomsfaktorerData>({
            ejendomsfaktorer: this.ejendomsfaktorerToFormArray(ejendomsfaktorerData.ejendomsfaktorer, ejendomsfaktorDefinitioner)
        });
        return result;
    }

    public ejendomsfaktorerToFormArray(
        ejendomsfaktorer: Ejendomsfaktor[],
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]
    ): IFormArray<Ejendomsfaktor> {
        const result = ejendomsfaktorer.map((ejendomsfaktor) => this.ejendomsfaktorToFormGroup(ejendomsfaktor, ejendomsfaktorDefinitioner));
        return this.fb.array(result);
    }

    public ejendomsfaktorToFormGroup(
        ejendomsfaktor: Ejendomsfaktor,
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]
    ): IFormGroup<Ejendomsfaktor> {
        const ejendomsfaktorDefinition = ejendomsfaktorDefinitioner.find((definition) => definition.id === ejendomsfaktor.definitionId);
        const result = this.fb.group<Ejendomsfaktor>({
            definitionId: [ejendomsfaktor.definitionId],
            id: [ejendomsfaktor.id],
            status: [ejendomsfaktor.status],
            vaerdi: this.ejendomsfaktorVaerdiToFormGroup(ejendomsfaktor, ejendomsfaktorDefinition)
        });
        return result;
    }

    public ejendomsfaktorVaerdiToFormGroup(
        ejendomsfaktor: Ejendomsfaktor,
        ejendomsfaktorDefinition: EjendomsfaktorDefinition
    ): IFormGroup<DffOprindeligAktuelVaerdi<string>> {
        const vaerdi = this.dbToVaerdi(ejendomsfaktor.vaerdi, ejendomsfaktorDefinition);
        const validators = this.getValidators(ejendomsfaktorDefinition);
        const result = new DffOprindeligAktuelVaerdiFormGroup(
            vaerdi,
            {
                controlType: this.getFormControlType(ejendomsfaktorDefinition),
                dropdownListOptions: this.getOptions(ejendomsfaktorDefinition),
                placeholder: ejendomsfaktorDefinition && ejendomsfaktorDefinition.navn,
                suffix: ejendomsfaktorDefinition && ejendomsfaktorDefinition.enhed,
                disabled: ejendomsfaktorDefinition && ejendomsfaktorDefinition.readonly
            },
            validators
        ) as unknown as IFormGroup<DffOprindeligAktuelVaerdi<string>>;
        return result;
    }

    public getValidators(ejendomsfaktorDefinition: EjendomsfaktorDefinition): ValidatorFn[] {
        if (ejendomsfaktorDefinition.datatype !== 10) {
            return [];
        }

        const validators: ValidatorFn[] = [];

        if (ejendomsfaktorDefinition.decimaler >= 0) {
            validators.push(DffValidators.decimals(ejendomsfaktorDefinition.decimaler));
        }

        if (ejendomsfaktorDefinition.minVaerdi !== null && ejendomsfaktorDefinition.minVaerdi !== undefined) {
            validators.push(Validators.min(ejendomsfaktorDefinition.minVaerdi));
        }

        if (ejendomsfaktorDefinition.maxVaerdi !== null && ejendomsfaktorDefinition.maxVaerdi !== undefined) {
            validators.push(Validators.max(ejendomsfaktorDefinition.maxVaerdi));
        }

        return validators;
    }

    public dbToVaerdi(
        vaerdi: DffOprindeligAktuelVaerdi<string>,
        ejendomsfaktorDefinition: EjendomsfaktorDefinition
    ): DffOprindeligAktuelVaerdi<string> {
        const erTal = ejendomsfaktorDefinition.datatype === 10;
        return erTal
            ? { aktuel: dbToFloatString(vaerdi.aktuel), oprindelig: dbToFloatString(vaerdi.oprindelig) }
            : { aktuel: vaerdi.aktuel, oprindelig: vaerdi.oprindelig };
    }

    public getFormControlType(ejendomsfaktorDefinition: EjendomsfaktorDefinition): DffFormControlType {
        if (ejendomsfaktorDefinition && ejendomsfaktorDefinition.valgmuligheder.length) {
            return DffFormControlType.DropdownList;
        }

        if (ejendomsfaktorDefinition && ejendomsfaktorDefinition.datatype === 10) {
            return DffFormControlType.NumberInput;
        }

        return DffFormControlType.TextInput;
    }

    public getOptions(definition: EjendomsfaktorDefinition): DffFormControlDropdownListOptions {
        const valgmuligheder = (definition && definition.valgmuligheder) || [];
        return valgmuligheder.map((v) => ({ key: v.id, value: v.navn }));
    }

    public formGroupToEjendomsfaktorerData(formGroup: UntypedFormGroup): EjendomsfaktorerData {
        const result: EjendomsfaktorerData = {
            ejendomsfaktorer: this.formArrayToEjendomsfaktorer(formGroup.controls["ejendomsfaktorer"] as UntypedFormArray)
        };
        return result;
    }

    public formArrayToEjendomsfaktorer(formArray: UntypedFormArray): Ejendomsfaktor[] {
        const result: Ejendomsfaktor[] = formArray.controls.map((c) => this.formGroupToEjendomsfaktor(c as UntypedFormGroup));
        return result;
    }

    public formGroupToEjendomsfaktor(formGroup: UntypedFormGroup): Ejendomsfaktor {
        const result: Ejendomsfaktor = {
            definitionId: Map.toInteger(formGroup.controls["definitionId"]),
            id: Map.toInteger(formGroup.controls["id"]),
            status: Map.toInteger(formGroup.controls["status"]) as EntitetStatus,
            vaerdi: this.vaerdiToDb(formGroup.controls["vaerdi"] as DffOprindeligAktuelVaerdiFormGroup)
        };
        return result;
    }

    public vaerdiToDb(formGroup: DffOprindeligAktuelVaerdiFormGroup): DffOprindeligAktuelVaerdi<string> {
        const oprindelig = formGroup.get("oprindelig") as UntypedFormControl;
        const aktuel = formGroup.get("aktuel") as DffFormControl;
        const erTal = aktuel.controlType === DffFormControlType.NumberInput;
        return erTal
            ? { aktuel: floatToDb(aktuel.value), oprindelig: floatToDb(oprindelig.value) }
            : { aktuel: aktuel.value, oprindelig: oprindelig.value };
    }
}
