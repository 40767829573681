import { Injectable } from "@angular/core";

import { ObserverBasedService } from "../util";
import { AktuelForbrugerOplysningerObserver } from "./aktuel-forbruger-oplysninger.observer";
import { ForbrugerOplysninger } from "./model/forbruger-oplysninger.model";

@Injectable({ providedIn: "root" })
export class AktuelForbrugerOplysningerService extends ObserverBasedService<ForbrugerOplysninger> {
    constructor(observer: AktuelForbrugerOplysningerObserver) {
        super(observer);
    }
}
