import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";

@Component({
    selector: "dff-spinner-button",
    templateUrl: "./dff-spinner-button.component.html",
    styleUrls: ["./dff-spinner-button.component.scss"]
})
export class DffSpinnerButtonComponent {
    @HostBinding("class") public cssClass = "dff-spinner-button";
    @Input() public color = "primary";
    @Input() public disabled = false;
    @Input() public spinning = false;
    @Input() public buttonType: string = "button";
    @Output() public click = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
        if (!this.disabled) {
            this.click.emit(event);
        }
    }

    public killHostClickListener(event: MouseEvent): void {
        // Det er altid et (click)-event på en komponents host-element.
        // Når vi disabler knappen, vil mouse clicks ryge videre til host-elementet, som så sørger for at emitte click-eventet.
        // Vi lægger derfor et element bagved knappen og sørger for at fange mouse clicks via denne og sikre at de
        // ikke rygere videre op til host-elementet.
        event.stopPropagation();
    }
}
