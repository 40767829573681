export interface MenuItem {
    id?: number;
    name?: string;
    title?: string;
    route?: string;
    href?: string;
    icon?: string;
    adgangsstyret: boolean;
    children?: MenuItem[];
    roles?: number[];
}
