import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { AppserverHttpClient } from "../http";
import { ForbrugerOplysningerRepository } from "./forbruger-oplysninger.repository";
import { ForbrugerOplysninger } from "./model/forbruger-oplysninger.model";

@Injectable({ providedIn: "root" })
export class ForbrugerOplysningerService {
    constructor(private readonly forbrugerOplysningerRepository: ForbrugerOplysningerRepository) {}

    public hentForbrugerOplysningerForAktuelleInstallation(): Observable<ForbrugerOplysninger> {
        return this.forbrugerOplysningerRepository.hentForbrugerOplysningerForAktuelleInstallation();
    }

    public hentForbrugerOplysningerForBruger(ejendomNr?: number): Observable<ForbrugerOplysninger> {
        return this.forbrugerOplysningerRepository.hentForbrugerOplysningerForBruger(ejendomNr);
    }

    public hentForbrugerOplysninger(httpClient: AppserverHttpClient, ejendomNr?: number): Observable<ForbrugerOplysninger> {
        return this.forbrugerOplysningerRepository.hentForbrugerOplysninger(httpClient, ejendomNr);
    }
}
