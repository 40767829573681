import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { Observable } from "rxjs";

import { DffInfoDialogComponent } from "./dff-info-dialog.component";

// Provides i DffInfoDialogModule (og ikke "root") for at understøtte dynamisk oprettelse af DffInfoDialogComponent
@Injectable()
export class DffInfoDialog {
    constructor(private readonly dialog: MatDialog) {}

    public open(titel: string, tekst: string, cssClass: string): Observable<any> {
        const dialog = this.dialog.open(DffInfoDialogComponent, {
            data: { titel, tekst, cssClass, icon: `dff-info-dialog-${cssClass}-icon` }
        });
        return dialog.afterClosed();
    }

    public success(titel: string, tekst: string): Observable<void> {
        return this.open(titel, tekst, "success");
    }

    public error(titel: string, tekst: string): Observable<void> {
        return this.open(titel, tekst, "error");
    }
}
