import { Component, Input, OnInit } from "@angular/core";
import { MatStepper } from "@angular/material/stepper";

import { Observable } from "rxjs";

import { ForsyningPaaAdresseLeveringStatus, ForsyningPaaAdresseResult } from "@e-forsyning/common/forsyning-paa-adresse";

import { TilslutningsanmodningViewModel } from "./model/tilslutningsanmodning.view-model";
import { TilslutningsanmodningFormBuilder } from "./services/tilslutningsanmodning.form-builder";
import { TilslutningsanmodningSandbox } from "./services/tilslutningsanmodning.sandbox";

@Component({
    selector: "tilslutningsanmodning",
    templateUrl: "./tilslutningsanmodning.component.html",
    styleUrls: ["./tilslutningsanmodning.component.scss"]
})
export class TilslutningsanmodningComponent implements OnInit {
    @Input() public nodeId: number;
    public ForsyningPaaAdresseLeveringStatus = ForsyningPaaAdresseLeveringStatus;
    public submitter = false;
    public stepperVisible = false;
    public vm$: Observable<TilslutningsanmodningViewModel>;

    constructor(public readonly sandbox: TilslutningsanmodningSandbox, public readonly formBuilder: TilslutningsanmodningFormBuilder) {}

    public ngOnInit(): void {
        this.vm$ = this.sandbox.selectViewModel(+this.nodeId);
    }

    public onAdresseChange(value: ForsyningPaaAdresseResult): void {
        this.sandbox.setForsyningPaaAdresseResult(value);
    }

    public async submit(vm: TilslutningsanmodningViewModel, source: { valider: () => boolean }, stepper: MatStepper): Promise<void> {
        if (!source.valider()) {
            return;
        }

        this.submitter = true;
        try {
            await this.sandbox.submit(vm);
            stepper.next();
        } finally {
            this.submitter = false;
        }
    }
}
