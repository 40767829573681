import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";

@Injectable({ providedIn: "root" })
export class BodyClassService {
    private readonly renderer: Renderer2;

    constructor(@Inject(DOCUMENT) private readonly document: Document, rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public add(className: string): void {
        this.renderer.addClass(this.document.body, className);
    }

    public remove(className: string): void {
        this.renderer.removeClass(this.document.body, className);
    }

    public set(className: string, shouldAdd: boolean): void {
        if (shouldAdd) {
            this.add(className);
        } else {
            this.remove(className);
        }
    }
}
