<dff-sidenav-menu
    #DffSidenavMenu
    *ngIf="vm$ | async as vm; else spinner"
    [items]="vm.menuItems"
    (itemClick)="itemClick.emit($event)"
    id="eforsyning-sidenav-menu"
    role="navigation"
    id="sidenav-menu"
>
    <li *ngIf="vm.visInstallationMenuItem && vm.vaelgInstallationHref; else noLink" class="aktuel-installation">
        <a [href]="vm.vaelgInstallationHref" (click)="DffSidenavMenu.onItemClick($event, vm.vaelgInstallationHref)">
            <div *ngIf="vm.aktuelInstallation?.adresse" class="adresse">{{ vm.aktuelInstallation.adresse }}</div>
            <small *ngIf="vm.aktuelInstallation?.maalerNr">Måler: {{ vm.aktuelInstallation.maalerNr }}</small>
            <small *ngIf="!vm.aktuelInstallation?.maalerNr">Status: Passiv</small>
            <small *ngIf="vm.vaelgInstallationHref" class="skift">SKIFT...</small>
        </a>
    </li>
    <ng-template #noLink>
        <li class="aktuel-installation">
            <div>
                <span *ngIf="vm.aktuelInstallation?.adresse" class="adresse">{{ vm.aktuelInstallation.adresse }}</span>
                <small *ngIf="vm.aktuelInstallation?.maalerNr">Måler: {{ vm.aktuelInstallation.maalerNr }}</small>
                <small *ngIf="!vm.aktuelInstallation?.maalerNr">Status: Passiv</small>
                <small *ngIf="vm.vaelgInstallationHref" class="skift">SKIFT...</small>
            </div>
        </li>
    </ng-template>
</dff-sidenav-menu>
<ng-template #spinner>
    <div class="spinner-container">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
