export function getOffsetTop(elm: HTMLElement): number {
    return elm.getBoundingClientRect().top + getScrollTop(elm);
}

export function getOffsetLeft(elm: HTMLElement): number {
    return elm.getBoundingClientRect().left + getScrollLeft(elm);
}

export function getScrollTop(elm: HTMLElement): number {
    return elm ? elm.scrollTop + getScrollTop(elm.parentElement) : 0;
}

export function getScrollLeft(elm: HTMLElement): number {
    return elm ? elm.scrollLeft + getScrollLeft(elm.parentElement) : 0;
}

export function getScrollRight(elm: HTMLElement): number {
    return elm ? elm.scrollWidth - elm.scrollLeft - elm.clientWidth + getScrollRight(elm.parentElement) : 0;
}

export function getScrollBottom(elm: HTMLElement): number {
    return elm ? elm.scrollHeight - elm.scrollTop - elm.clientHeight + getScrollBottom(elm.parentElement) : 0;
}

export function getWidth(element: HTMLElement): number {
    if (!element) {
        return 0;
    }
    return element.offsetWidth || getWidth(element.parentElement);
}

export function getScrollbarWidth(): number {
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";

    document.body.appendChild(outer);

    const widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    const inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    const widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
}
