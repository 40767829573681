import { Injectable } from "@angular/core";

import { genererKvitteringMailHtml } from "@dffedb/ui";
import { DffGroupedContent } from "@dffedb/util";

import { DffMailFirmaoplysninger } from "./dff-mail.model";

@Injectable({ providedIn: "root" })
export class MailHelper {
    private static css = "color:#444;";

    public static center(content: string): string {
        return `
            <table class="headerTableCell" cellpadding="0" cellspacing="0" border="0" align="center" width="100%" 
                style="${this.css}border-collapse: collapse; border-spacing: 0;">
                <tr>
                    <td class="headerTitleCell" width="600" valign="top" align="center">
                        ${content}
                    </td>
                </tr>
            </table>`;
    }

    public static image(url: string): string {
        return `<img src="${url}">`;
    }

    public static table(...rows: string[]): string {
        return this.div(`<table cellpadding="0" cellspacing="0" border="0" id="backgroundTable" style="${
            this.css
        }border-collapse:collapse;border-spacing:0;margin:0;padding:0;width:100%;">
            ${rows.join("")}</table>`);
    }

    public static tableRow(key: string, value: string, tdCss: string = ""): string {
        const colon = !key ? "&nbsp;" : ":";
        return value === null
            ? `<tr>
                   <td style="${tdCss};width:1%;white-space:nowrap;vertical-align:top;" colspan=2><strong>${key}</strong></td>
               </tr>`
            : `<tr>
                   <td style="${tdCss}width:1%;white-space:nowrap;vertical-align:top;">${key}${colon}</td>
                   <td style="${tdCss}width:99%;white-space:nowrap;padding-left:10px;"><strong>${value}</strong></td>
               </tr>`;
    }

    public static hr(width: number = 600): string {
        return `<table cellspacing="0" cellpadding="0" border="0" width="100%" style="${this.css}width: 100% !important;">
                <tr>
                    <td align="left" valign="top" width="${width}px" height="1" style="background-color: #f0f0f0; 
                    border-collapse:collapse; mso-table-lspace: 0pt; mso-table-rspace: 0pt; mso-line-height-rule: exactly; 
                    line-height: 1px;"><!--[if gte mso 15]>&nbsp;<![endif]--></td>
                </tr>
            </table>`;
    }

    public static div(value: string): string {
        return `<div style="${this.css}">${value}</div>`;
    }

    public static h1(text: string): string {
        return `<h1 style="${this.css}font-size:20px;">${text}</h1>`;
    }

    public static space(marginTop: number = 0): string {
        return `<div style="${this.css}margin:${marginTop} 0 0 0">&nbsp;</div>`;
    }

    public static venligHilsen(firmaOplysninger: DffMailFirmaoplysninger): string {
        return firmaOplysninger.signatur || this.div("Venlig hilsen") + this.div(firmaOplysninger.firmaNavn);
    }

    public static kvitteringPanel(kvittering: DffGroupedContent): string {
        return genererKvitteringMailHtml(kvittering);
    }
}
