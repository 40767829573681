export class DffOprindeligAktuelVaerdi<T> {
    public oprindelig: T | null = null;
    public aktuel: T | null = null;
}

export type DffOprindeligAktuelVaerdier =
    | DffOprindeligAktuelVaerdi<string>
    | DffOprindeligAktuelVaerdi<number>
    | DffOprindeligAktuelVaerdi<Date>
    | DffOprindeligAktuelVaerdi<boolean>;
