import { Injectable } from "@angular/core";

import { Observable, ReplaySubject } from "rxjs";
import { distinctUntilChanged, shareReplay } from "rxjs/operators";

import { compareVersions, CookieStorage } from "@dffedb/util";

import {
    cookieConsentsEqual,
    cookieConsentToString,
    DffCookieConsent,
    DffCookieConsentChanged,
    getAllEnabledCookieConsent,
    stringToCookieConsent
} from "./dff-cookie-consent.model";

@Injectable({ providedIn: "root" })
export class DffCookieConsentService {
    private readonly cookieConsentSubject = new ReplaySubject<DffCookieConsentChanged>(1);
    private readonly key = "dff-edb.cookie-consent";
    private enabled: boolean;
    private requiredConsentVersion: string;
    private cookieConsentChangeRequiresPageReload = false;

    constructor(private readonly cookieStorage: CookieStorage) {}

    public async initialize(enableCookiepolitk: boolean, version: string, cookieConsentChangeRequiresPageReload: boolean): Promise<void> {
        this.enabled = enableCookiepolitk;
        this.cookieConsentChangeRequiresPageReload = cookieConsentChangeRequiresPageReload;
        this.requiredConsentVersion = version;
        const consent = this.getCookieConsent();
        this.cookieConsentSubject.next({ ...consent, reloading: false });
    }

    public isCookieConsentUpdateNeeded(): boolean {
        const version = this.getCookieConsent().version;
        return compareVersions(version, this.requiredConsentVersion) < 0;
    }

    public approveCookies(): void {
        const consent = this.getCookieConsent();
        consent.consent.necessary = true;
        consent.consent.statistics = true;
        consent.consent.thirdparty = true;
        this.setGoogleTagManagerCookieOptIn(true);
        this.setCookieConsent(consent);
    }

    public rejectCookies(): void {
        const consent = this.getCookieConsent();
        consent.consent.necessary = true; // Man kan ikke afvise 'krævede' cookies
        consent.consent.statistics = false;
        consent.consent.thirdparty = false;
        this.deleteCookies();
        this.setGoogleTagManagerCookieOptIn(false);
        this.setCookieConsent(consent);
    }

    public renewCookieConsent(): void {
        const consent = this.getCookieConsent();
        if (consent.version === this.requiredConsentVersion) {
            this.setCookieConsent(consent); // Fornyer datoen
        }
    }

    public getCookieConsent(): DffCookieConsent {
        return this.enabled
            ? stringToCookieConsent(this.cookieStorage.get(this.key))
            : getAllEnabledCookieConsent(this.requiredConsentVersion);
    }

    public setCookieConsent(value: DffCookieConsent): void {
        if (!this.enabled) {
            return;
        }
        const oldConsent = this.getCookieConsent();
        value.version = this.requiredConsentVersion;
        value.utc = new Date();
        this.cookieStorage.set(this.key, cookieConsentToString(value), 365, "/");

        if (cookieConsentsEqual(value, oldConsent)) {
            return;
        }

        if (this.cookieConsentChangeRequiresPageReload) {
            // Vi overfører den gamle Cookie Consent, fordi vi ikke ønsker UI-ændringer, inden hele siden reloades.
            this.cookieConsentChanged(oldConsent, true);
            window.location.reload();
        } else {
            this.cookieConsentChanged(value, false);
        }
    }

    public selectCookieConsent(): Observable<DffCookieConsentChanged> {
        return this.cookieConsentSubject.asObservable().pipe(distinctUntilChanged(), shareReplay());
    }

    // Vi kan ikke altid styre om elementer på siden kan slås til/fra client side.
    // Derfor kan en komponent (f.eks. vores RAW HTML i Umbraco) fortælle at den ønsker 'page reload'
    // når brugeren ændrer sin Cookie-accept.
    public setCookieConsentChangeRequiresPageReload(): void {
        this.cookieConsentChangeRequiresPageReload = true;
    }

    private cookieConsentChanged(consent: DffCookieConsent, reloading: boolean): void {
        this.cookieConsentSubject.next({ ...consent, reloading });
    }

    private setGoogleTagManagerCookieOptIn(value: boolean): void {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ((window as any).dataLayer || []).push({ CookieOptIn: value });
    }

    private deleteCookies(): void {
        const whitelist = [/^dff-edb.cookie-consent$/, /^UMB-.*$/];
        const cookies = this.cookieStorage.getAll();
        this.cookieStorage.beginUpdate();
        try {
            for (const name in cookies) {
                if (!whitelist.some((l) => l.test(name))) {
                    this.cookieStorage.delete(name, "/");
                }
            }
        } finally {
            this.cookieStorage.endUpdate();
        }
    }
}
