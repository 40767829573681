import { Component, Input } from "@angular/core";

@Component({
    selector: "panel",
    templateUrl: "./panel.component.html",
    styleUrls: ["./panel.component.scss"]
})
export class PanelComponent {
    @Input() public titel = "";
    @Input() public icon = "";
}
