export enum Rolle {
    LoggetUd = 0,
    Forbruger = 1,
    Ejendomsadministrator = 2,
    FofBruger = 9
}

export class Roller {
    public static readonly LoggetInd: Rolle[] = [Rolle.Forbruger, Rolle.Ejendomsadministrator, Rolle.FofBruger];
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Rolle {
    export function erLoggetUd(rolle: Rolle): boolean {
        return !erLoggetInd(rolle);
    }

    export function erLoggetInd(rolle: Rolle): boolean {
        return erForbruger(rolle) || erFofBruger(rolle) || erEjendomsadministrator(rolle);
    }

    export function erForbruger(rolle: Rolle): boolean {
        return rolle === Rolle.Forbruger;
    }

    export function erFofBruger(rolle: Rolle): boolean {
        return rolle === Rolle.FofBruger;
    }

    export function erEjendomsadministrator(rolle: Rolle): boolean {
        return rolle === Rolle.Ejendomsadministrator;
    }
}
