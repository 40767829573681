// Denne fil er en kopi af https://github.com/wardbell/subsink
// Den findes også som en npm-pakke, men den giver desværre fejl i vores tests:
// https://github.com/wardbell/subsink/issues/6
// Vi har derfor valgt at kopiere koden.

const isFunction = (fn: any) => typeof fn === "function";

export interface SubscriptionLike {
    unsubscribe(): void;
}

/**
 * Subscription sink that holds Observable subscriptions
 * until you call unsubscribe on it in ngOnDestroy.
 */
export class SubSink {
    protected _subs: SubscriptionLike[] = [];

    /**
     * Subscription sink that holds Observable subscriptions
     * until you call unsubscribe on it in ngOnDestroy.
     *
     * @example
     * In Angular:
     * ```
     *   private subs = new SubSink();
     *   ...
     *   this.subs.sink = observable$.subscribe(
     *   this.subs.add(observable$.subscribe(...));
     *   ...
     *   ngOnDestroy() {
     *     this.subs.unsubscribe();
     *   }
     * ```
     */
    constructor() {}

    /**
     * Add subscriptions to the tracked subscriptions
     *
     * @example
     *  this.subs.add(observable$.subscribe(...));
     */
    public add(...subscriptions: SubscriptionLike[]): void {
        this._subs = this._subs.concat(subscriptions);
    }

    /**
     * Assign subscription to this sink to add it to the tracked subscriptions
     *
     * @example
     *  this.subs.sink = observable$.subscribe(...);
     */
    public set sink(subscription: SubscriptionLike) {
        this._subs.push(subscription);
    }

    /**
     * Unsubscribe to all subscriptions in ngOnDestroy()
     *
     * @example
     *   ngOnDestroy() {
     *     this.subs.unsubscribe();
     *   }
     */
    public unsubscribe(): void {
        this._subs.forEach((sub) => sub && isFunction(sub.unsubscribe) && sub.unsubscribe());
        this._subs = [];
    }
}
