/**
 * Sammenligner to versionsnumre og returnerer 0, 1 eller -1:
 * 0 hvis value1 = value2
 * 1 hvis value1 > value 2
 * -1 hvis value1 < value2
 *
 * Eksempel:
 * compareVersions("1.0", "1.2") => -1
 * compareVersions("1.2", "1.0") => 1
 * compareVersions("1.2", "1.2") => 0
 * compareVersions("1.1.2", "1.2.1") => -1
 * compareVersions("1.2.0", "1.2") => 0 (manglende nummer svarer til 0)
 * compareVersions("1.2.1", "1.2") => 1
 * compareVersions("1.2.1", "1.3") => -1
 * compareVersoins("1.2.1", "1.2.a") => -1 (bogstaver eller tal/bogstav sammenlignes på tekstværdien, '1' er mindre end 'a')
 * compareVersions("1.2.12", "1.2.2") => 1 (to tal sammenlignes på talværdien, 12 er større end 2)
 */
export function compareVersions(value1: string, value2: string): number {
    const split1 = (value1 || "").split(".");
    const split2 = (value2 || "").split(".");
    const length = Math.max(split1.length, split2.length);
    let compare = 0;
    for (let i = 0; i < length; i++) {
        compare = compareSubVersions(split1[i] || "0", split2[i] || "0");
        if (compare !== 0) {
            break;
        }
    }
    return compare < 0 ? -1 : compare > 0 ? 1 : 0;
}

function compareSubVersions(value1: string, value2: string): number {
    if (!isNaN(+value1) && !isNaN(+value2)) {
        return +value1 < +value2 ? -1 : +value1 > +value2 ? 1 : 0;
    }
    return value1.localeCompare(value2);
}
