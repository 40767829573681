import { Type } from "@angular/core";
import { createCustomElement, NgElementConfig } from "@angular/elements";

import { asapScheduler } from "rxjs";

export function defineCustomElements(name: string, component: Type<any>, config: NgElementConfig): void {
    // customElements findes ikke i UnitTests
    if (!("customElements" in window)) {
        return;
    }

    // Køres asynkront for at sikre, APP_INITIALIZE kaldes inden komponentens OnInit
    asapScheduler.schedule(() => {
        if (!customElements.get(name)) {
            customElements.define(name, createCustomElement(component, config));
        }
    });
}
