import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { ForsyningPaaAdresseComponent } from "./forsyning-paa-adresse.component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
    exports: [ForsyningPaaAdresseComponent],
    declarations: [ForsyningPaaAdresseComponent],
    //entryComponents: [ForsyningPaaAdresseComponent],
    providers: []
})
export class ForsyningPaaAdresseModule {
    constructor(injector: Injector) {}
}
