import { Injectable } from "@angular/core";

import { Observable, of, throwError } from "rxjs";
import { catchError, first, map, switchMap } from "rxjs/operators";

import { LoginService } from "@e-forsyning/common/auth";
import { AktuelBrugerObserver, Rolle } from "@e-forsyning/common/bruger";
import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

@Injectable({ providedIn: "root" })
export class DffedbEBoksTilmeldService {
    constructor(
        private readonly loginService: LoginService,
        private readonly indstillinger: UmbracoIndstillingerObserver,
        private readonly brugerObserver: AktuelBrugerObserver
    ) {}

    public skalRedirecteTilEforsyning(): Observable<boolean> {
        return this.indstillinger.valueChanges.pipe(
            first(),
            map(indstillinger => indstillinger.eforsyninger),
            switchMap(eforsyninger => {
                // Ingen E|Forsyning? Så er der ikke grund til at redirecte
                if (eforsyninger.length === 0) {
                    return of(false);
                }
                // Flere E|Forsyninger? Så kan vi ikke være sikre på hvilken der skal redirectes til
                if (eforsyninger.length > 1) {
                    return of(false);
                }
                // Er vi logget ind? Ellers er der ingen grund til at redirecte til E|Forsyning
                if (!this.loginService.erLoggetInd()) {
                    return of(false);
                }
                // Er den aktuelle bruger 'Forbruger'? I så fald kan vi redirecte til E|Forsyning,
                // hvor man kan tilmelde e-Boks uden brug af to-delt kode.
                const erForbruger$ = this.brugerObserver.valueChanges.pipe(map(bruger => bruger.rolle === Rolle.Forbruger));
                const skalRedirecte$ = erForbruger$.pipe(catchError(error => (error.status === 401 ? of(false) : throwError(error))));
                return skalRedirecte$;
            })
        );
    }

    public redirecTilEforsyning(): void {
        this.indstillinger.valueChanges
            .pipe(first()) // Auto-unsubscribe
            .subscribe(indstillinger => {
                const url = indstillinger.eforsyninger[0].siteUrl + "#/mine-oplysninger";
                window.location.href = url;
            });
    }
}
