import { AbstractControl } from "@angular/forms";

export class DecimalsError {
    public max: number;
}

export function decimalsValidator(max: number = -1): any {
    const regexp =
        max === -1 // -1 indikerer at man kan angive et vilkårligt antal decimaler
            ? new RegExp(`^([-+]?\\d*([\\.,]\\d*)?)$`)
            : new RegExp(`^([-+]?\\d*([\\.,]\\d{0,${max}})?)$`);

    return (control: AbstractControl) => {
        if (control.value === null || control.value === undefined) {
            return null;
        }

        if (regexp.test(control.value)) {
            return null;
        }

        return { decimals: { max } };
    };
}
