import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class EforsyningMenuService {
    constructor(@Inject(DOCUMENT) private readonly document: Document) {}

    public init(): void {
        this.initialiserAutoLukVedMenuklik();
    }

    private initialiserAutoLukVedMenuklik(): void {
        const links = Array.from(this.document.querySelectorAll(".nav-desktop .nav-item.leaf-node > a"));
        links.forEach(link =>
            link.addEventListener("click", () => {
                const parent = link.closest("li.level-0");
                if (parent) {
                    const divs = Array.from(parent.children).filter(e => e.matches("div"));
                    divs.forEach(div => div.parentElement.removeChild(div));
                    setTimeout(() => {
                        divs.forEach(div => parent.appendChild(div));
                    }, 200);
                }
            })
        );
    }
}
