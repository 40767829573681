import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

import { mdiCameraPlus } from "@mdi/js";

import { SvgIconRegistry } from "@dffedb/util";

import { ImageUploadButtonComponent } from "./image-upload-button.component";

@NgModule({
    imports: [CommonModule, MatButtonModule, MatIconModule],
    exports: [ImageUploadButtonComponent],
    declarations: [ImageUploadButtonComponent],
    providers: []
})
export class ImageUploadButtonModule {
    constructor(svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiCameraPlus, "camera-plus");
    }
}
