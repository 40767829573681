import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { DffDropdownButtonComponent } from "./dff-dropdown-button.component";

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [DffDropdownButtonComponent],
    exports: [DffDropdownButtonComponent],
    providers: []
})
export class DffDropdownButtonModule {}
