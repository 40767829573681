import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "dff-upload-progress-overlay",
    templateUrl: "./dff-upload-progress-overlay.component.html",
    styleUrls: ["./dff-upload-progress-overlay.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DffUploadProgressOverlayComponent {
    @Input() public value: number;
}
