import { Injectable } from "@angular/core";

import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { DffCookieConsentDialog } from "../shared/dff-cookie-consent-dialog";
import { DffCookieConsentService } from "../shared/dff-cookie-consent/dff-cookie-consent.service";
import { DffedbCookieConsentViewModel } from "./dffedb-cookie-consent.view-model";

@Injectable({ providedIn: "root" })
export class DffedbCookieConsentSandbox {
    constructor(
        private readonly indstillingerObserver: UmbracoIndstillingerObserver,
        private readonly cookieConsentService: DffCookieConsentService,
        private readonly cookieConsentDialog: DffCookieConsentDialog
    ) {}

    public async initialize(version: string, cookieConsentChangeRequiresPageReload: boolean | string): Promise<void> {
        const indstillinger = await this.indstillingerObserver.getValue();
        await this.cookieConsentService.initialize(
            indstillinger.featureToggles.cookiepolitik,
            version,
            this.toBoolean(cookieConsentChangeRequiresPageReload)
        );
    }

    public selectViewModel(): Observable<DffedbCookieConsentViewModel> {
        const indstillinger$ = this.indstillingerObserver.valueChanges;
        const cookieConsent$ = this.cookieConsentService.selectCookieConsent();
        return combineLatest([indstillinger$, cookieConsent$]).pipe(
            map(([indstillinger, cookieConsent]) => ({
                cookieConsent,
                enabled: indstillinger.featureToggles.cookiepolitik,
                reloading: cookieConsent.reloading
            }))
        );
    }

    public isCookieConsentUpdateNeeded(): boolean {
        return this.cookieConsentService.isCookieConsentUpdateNeeded();
    }

    public showCookieConsentDialog(): void {
        this.cookieConsentDialog.open();
    }

    public renewCookieConsent(): void {
        this.cookieConsentService.renewCookieConsent();
    }

    private toBoolean(value: boolean | string): boolean {
        return value === true || value === "true";
    }
}
