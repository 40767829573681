import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, ValidatorFn, Validators } from "@angular/forms";

import {
    cprNummerValidator,
    cvrNummerValidator,
    IFormBuilder,
    IFormControl,
    IFormGroup,
    mustMatchValidator,
    pNummerValidator
} from "@dffedb/forms";
import { opretSamtykkeTekst, SamtykkeTekstData } from "@e-forsyning/common/e-boks";
import { EjendomsfaktorDefinition } from "@e-forsyning/common/ejendomsfaktor";
import { EjendomsfaktorFormBuilder } from "@e-forsyning/common/ejendomsfaktor/ejendomsfaktor.form-builder";
import { Ejendomsfaktor } from "@e-forsyning/common/ejendomsfaktor/model/ejendomsfaktor.model";

import {
    TilslutningsanmodningKonfiguration,
    TilslutningsanmodningModel,
    TilslutningsanmodningOplysningerModel
} from "../model/tilslutningsanmodning.model";

@Injectable({ providedIn: "root" })
export class TilslutningsanmodningFormBuilder {
    private readonly fb: IFormBuilder;

    constructor(fb: UntypedFormBuilder, private readonly ejendomsfaktorFormBuilder: EjendomsfaktorFormBuilder) {
        this.fb = fb;
    }

    public opretFormGroup(
        konfiguration: TilslutningsanmodningKonfiguration,
        ejendomsfaktorer: Ejendomsfaktor[],
        ejendomsfaktorDefinitioner: EjendomsfaktorDefinition[]
    ): IFormGroup<TilslutningsanmodningModel> {
        const result = this.fb.group<TilslutningsanmodningModel>({
            oplysninger: this.opretOplysningerFormGroup(konfiguration),
            ejendomsfaktorer: this.ejendomsfaktorFormBuilder.ejendomsfaktorerToFormArray(ejendomsfaktorer, ejendomsfaktorDefinitioner)
        });
        return result;
    }

    public formGroupToTilslutningsanmodningModel(formGroup: IFormGroup<TilslutningsanmodningModel>): TilslutningsanmodningModel {
        return formGroup.value;
    }

    public opretSamtykkeTekst(
        formGroup: IFormGroup<TilslutningsanmodningOplysningerModel>,
        konfiguration: TilslutningsanmodningKonfiguration
    ): string {
        const cprNummer = formGroup.control("erVirksomhed").value ? "" : formGroup.control("cprNummer").value;
        const cvrNummer = formGroup.control("erVirksomhed").value ? formGroup.control("cvrNummer").value : "";
        const pNummer = formGroup.control("erVirksomhed").value ? formGroup.control("pNummer").value : "";
        return this.doOpretSamtykkeTekst(cprNummer, cvrNummer, pNummer, konfiguration);
    }

    public isRequired(
        formGroup: IFormGroup<TilslutningsanmodningOplysningerModel>,
        formControlName: keyof TilslutningsanmodningOplysningerModel
    ): boolean {
        const erPrivatperson = !formGroup.get("erVirksomhed").value;
        const erVirksomhed = !!formGroup.get("erVirksomhed").value;
        const oenskerEboks = !!formGroup.get("oenskerEboks").value;
        const ekstraPerson = !!formGroup.get("ekstraPerson").value;
        const ekstraPersonOenskerEboks = !!formGroup.get("ekstraPersonOenskerEboks").value;
        switch (formControlName) {
            case "cprNummer":
                return erPrivatperson && oenskerEboks;
            case "cvrNummer":
                return erVirksomhed && oenskerEboks;
            case "samtykkeTekstAccepteret":
                return oenskerEboks;
            case "ekstraPersonNavn":
                return ekstraPerson;
            case "ekstraPersonCprNummer":
                return erPrivatperson && ekstraPerson && ekstraPersonOenskerEboks;
            case "ekstraPersonSamtykkeTekstAccepteret":
                return erPrivatperson && ekstraPerson && ekstraPersonOenskerEboks;
        }
        return true;
    }

    private opretOplysningerFormGroup(
        konfiguration: TilslutningsanmodningKonfiguration
    ): IFormGroup<TilslutningsanmodningOplysningerModel> {
        const result = this.fb.group<TilslutningsanmodningOplysningerModel>({
            erVirksomhed: this.fb.control(false),
            navn: this.fb.control("", [Validators.required, Validators.maxLength(30)]),
            telefon: this.fb.control("", [Validators.required, Validators.pattern(/^[+]?[0-9]{8,}$/)]),
            email: this.fb.control("", [Validators.required, Validators.email]),
            emailGentag: this.fb.control("", [Validators.required, Validators.email, mustMatchValidator("email")]),
            bemaerkning: this.fb.control(""),
            oenskerEboks: this.fb.control(konfiguration.visEBoksTilmelding ? true : false),
            cprNummer: this.fb.control(""),
            cprNummerGentag: this.fb.control(""),
            cvrNummer: this.fb.control(""),
            pNummer: this.fb.control(""),
            samtykkeTekst: this.fb.control(""),
            samtykkeTekstAccepteret: this.fb.control(false),
            ekstraPerson: this.fb.control(false),
            ekstraPersonNavn: this.fb.control(""),
            ekstraPersonOenskerEboks: this.fb.control(false),
            ekstraPersonCprNummer: this.fb.control(""),
            ekstraPersonCprNummerGentag: this.fb.control(""),
            ekstraPersonTelefon: this.fb.control(""),
            ekstraPersonEmail: this.fb.control("", [Validators.email]),
            ekstraPersonEmailGentag: this.fb.control("", [Validators.email, mustMatchValidator("ekstraPersonEmail")]),
            ekstraPersonSamtykkeTekst: this.fb.control(""),
            ekstraPersonSamtykkeTekstAccepteret: this.fb.control(false)
        });
        result.valueChanges.subscribe(() => this.updateStatus(result, konfiguration));
        return result;
    }

    private updateStatus(
        formGroup: IFormGroup<TilslutningsanmodningOplysningerModel>,
        konfiguration: TilslutningsanmodningKonfiguration
    ): void {
        const cprNummerValidators = [Validators.required, Validators.minLength(10), Validators.maxLength(10), cprNummerValidator()];
        const cprNummerGentagValidators = [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            cprNummerValidator(),
            mustMatchValidator("cprNummer")
        ];
        const ekstraPersonCprNummerGentagValidators = [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            cprNummerValidator(),
            mustMatchValidator("ekstraPersonCprNummer")
        ];
        const cvrNummerValidators = [Validators.required, Validators.minLength(8), Validators.maxLength(8), cvrNummerValidator()];
        const pNummerValidators = [Validators.minLength(10), Validators.maxLength(10), pNummerValidator()];

        this.setValidators(formGroup.get("cprNummer"), this.isRequired(formGroup, "cprNummer") ? cprNummerValidators : []);
        this.setValidators(formGroup.get("cprNummerGentag"), this.isRequired(formGroup, "cprNummer") ? cprNummerGentagValidators : []);
        this.setValidators(formGroup.get("cvrNummer"), this.isRequired(formGroup, "cvrNummer") ? cvrNummerValidators : []);
        this.setValidators(formGroup.get("pNummer"), this.isRequired(formGroup, "cvrNummer") ? pNummerValidators : []);
        this.setValidators(
            formGroup.get("ekstraPersonNavn"),
            this.isRequired(formGroup, "ekstraPersonNavn") ? [Validators.required, Validators.maxLength(30)] : []
        );
        this.setValidators(
            formGroup.get("ekstraPersonCprNummer"),
            this.isRequired(formGroup, "ekstraPersonCprNummer") ? cprNummerValidators : []
        );
        this.setValidators(
            formGroup.get("ekstraPersonCprNummerGentag"),
            this.isRequired(formGroup, "ekstraPersonCprNummer") ? ekstraPersonCprNummerGentagValidators : []
        );
        this.setValidators(
            formGroup.get("samtykkeTekstAccepteret"),
            this.isRequired(formGroup, "samtykkeTekstAccepteret") ? [Validators.requiredTrue] : []
        );
        this.setValidators(
            formGroup.get("ekstraPersonSamtykkeTekstAccepteret"),
            this.isRequired(formGroup, "ekstraPersonSamtykkeTekstAccepteret") ? [Validators.requiredTrue] : []
        );
        this.setValue(formGroup.control("ekstraPersonSamtykkeTekst"), this.opretEkstraPersonSamtykkeTekst(formGroup, konfiguration));
    }

    private setValidators(control: AbstractControl, validators: ValidatorFn[]): void {
        // Når man ændrer validatorer, bør man efterfølgende kalde updateValueAndValidity.
        // Ellers risikerer vi at få fejlen ExpressionChangedAfterItHasBeenCheckedError.
        control.setValidators(validators);
        control.updateValueAndValidity({ emitEvent: false });
    }

    private opretEkstraPersonSamtykkeTekst(
        formGroup: IFormGroup<TilslutningsanmodningOplysningerModel>,
        konfiguration: TilslutningsanmodningKonfiguration
    ): string {
        const cprNummer = formGroup.control("erVirksomhed").value ? "" : formGroup.control("ekstraPersonCprNummer").value;
        return this.doOpretSamtykkeTekst(cprNummer, "", "", konfiguration);
    }

    private doOpretSamtykkeTekst(
        cprNummer: string,
        cvrNummer: string,
        pNummer: string,
        konfiguration: TilslutningsanmodningKonfiguration
    ): string {
        const standardData: SamtykkeTekstData = {
            cprNummer: null,
            cvrNummer: null,
            pNummer: null,
            firmaNavn: konfiguration.eforsyning.firmaInfo.firmaNavn,
            telefon: konfiguration.eforsyning.firmaInfo.telefon
        };
        const samtykkeTekst = opretSamtykkeTekst({ ...standardData, cprNummer, cvrNummer, pNummer });
        return samtykkeTekst;
    }

    private setValue(formControl: IFormControl<string>, value: string): void {
        if (formControl.value !== value) {
            formControl.setValue(value);
        }
    }
}
