import { formatNumber as angularFormatNumber } from "@angular/common";

import vocaTrim from "voca/trim";
import vocaTrimRight from "voca/trim_right";

export function trim(subject: string, whitespace?: string): string {
    return vocaTrim(subject, whitespace);
}

export function trimRight(subject: string, whitespace?: string): string {
    return vocaTrimRight(subject, whitespace);
}

/**
 * @description
 * Formatterer et nummer som tekst.
 *
 * @param value Nummeret som skal formatteres.
 * @param digitInfo Repræsentation af decimaler specificeret som en streng i følgende format:
 * `{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}`.
 *
 * @returns Nummeret formatteret som en streng.
 */
export function formatNumber(value: number, digitInfo?: string): string {
    return angularFormatNumber(value, "DA-dk", digitInfo);
}

export function upperCaseFirstChar(value: string): string {
    if (!value) {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 *
 * @param value Maske i dansk format. F.eks.: #.##0,00
 * @returns Maske i internationalt format. F.eks.: #,##0.00
 */
export function dkMaskeTilInternationalMaske(maske: string): string {
    if (!maske) {
        return maske;
    }
    return maske.replace(".", "¤").replace(",", ".").replace("¤", ",");
}

export function beregnDecimalerIInternationalMaske(maske: string): number {
    if (!maske) {
        return -1;
    } // Ingen maske => Uendeligt antal decimaler
    const pos = maske.lastIndexOf(".");
    if (pos === -1) {
        return 0;
    } // Ingen decimal-separator => 0 decimaler
    return maske.length - pos - 1; // Decimal-separator => n decimaler
}
