import { Component, EventEmitter, Output } from "@angular/core";

import { getImageFromFile } from "../util/image-file.util";

@Component({
    selector: "image-upload-button",
    template: `
        <input
            style="display: none"
            type="file"
            accept="image/*,.pdf,capture=camera"
            multiple
            (change)="onFileChanged($event)"
            #fileInput
        />
        <button mat-fab type="button" color="primary" matTooltip="Tilføj billede" matTooltipPosiotion="above" (click)="fileInput.click()">
            <mat-icon svgIcon="camera-plus"></mat-icon>
        </button>
    `,
    styles: [
        `
            :host {
                display: block;
            }
        `
    ]
})
export class ImageUploadButtonComponent {
    @Output() public add = new EventEmitter<NoteBilagPDF | HTMLImageElement>();

    public onFileChanged(event: any): void {
        [...event.target.files].map((file: any) => {
            if (file.type === "application/pdf") {
                const reader = new FileReader();
                reader.onload = (ev: any) => {
                    const pdf = new Blob([ev.target.result], { type: "application/pdf" });
                    const pdfFile: NoteBilagPDF = {
                        filNavn: file.name,
                        fil: pdf
                    };
                    this.add.emit(pdfFile);
                };

                reader.readAsArrayBuffer(file);
                return;
            } else {
                getImageFromFile(file).then((image) => {
                    this.add.emit(image as HTMLImageElement);
                });
            }
        });
        event.target.value = ""; // Ellers reagerer feltet ikke på at man indsætter samme billede to gange.
    }
}

interface NoteBilagPDF {
    filNavn: string;
    fil: Blob;
}
