import { genererCorrelationId } from "../correlation-id";
import { getFormattedStackTrace } from "./dff-stack-trace";

export function getErrorMessage(error: any): string {
    error = unwrapError(error);
    return (error && (error.brugerbesked || error.message)) || error;
}

export function getCorrelationId(error: any): string {
    error = unwrapError(error);
    return (error && error.correlationId) || genererCorrelationId();
}

export async function getErrorDetails(error: any): Promise<string> {
    error = unwrapError(error);
    const details = await getErrorDetailsRecursive(error);
    const stringDetails = details.map(d => formatErrorDetail(d));
    return stringDetails.join("\r\n--- Inner error:\n");
}

function unwrapError(error: any): any {
    // Fejl som følge af promises, kan være et funky Error-objekt, som indeholder den faktiske fejl i en rejection-propery.
    return (error && error.rejection) || error;
}

async function getErrorDetailsRecursive(error: any): Promise<DffErrorDto[]> {
    error = (error && error.rejection) || error;
    if (!error) {
        return [];
    }

    const stack = await getFormattedStackTrace(error);
    const innerErrorDetails = await getErrorDetailsRecursive((error.params && error.params.innerError) || error.rejection);

    return [
        {
            friendlyMessage: error.friendlyMessage || "",
            logId: error.logId || "",
            message: typeof error === "string" ? error : `${error.name}: ${error.message}`,
            stack
        },
        ...innerErrorDetails
    ];
}

function formatErrorDetail(dto: DffErrorDto): string {
    const friendlyMessage = dto.friendlyMessage ? `\r\nBrugerbesked: ${dto.friendlyMessage}` : "";
    const logId = dto.logId ? `\r\nId: ${dto.logId}` : "";
    return `${dto.message}${friendlyMessage}${logId}\r\n${dto.stack}`;
}

interface DffErrorDto {
    logId: string;
    message: string;
    friendlyMessage: string;
    stack: string;
}
